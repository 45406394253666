import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Input from "../../../common/forms/input/Input";
import Label from "../../../common/forms/label/Label";
import sharedStyles from "../../../shared-styles/FormStyle.module.scss";
import styles from "./HiringCountriesForm.module.scss";

const HiringCountriesForm = ({
    onFormSubmit,
    onFormCancel,
    onChange,
    onBlur,
    currentHiringCountry,
    formElements,
    formStatus,
    formMode,
    errors,
}) => {
    const { t } = useTranslation();

    return (
        <form className={`p-3 d-flex flex-column ${styles.formContainer}`} onSubmit={onFormSubmit}>
            <div className="d-flex m-3 justify-content-between gap-5">
                {Object.keys(formElements).map((element) => (
                    <div key={element} className={`d-flex flex-column ${styles.fieldContainer}`}>
                        <Label htmlFor={formElements[element].id} requiredIndicator={formElements[element].required}>
                            {t(formElements[element].id)}
                        </Label>

                        <Input
                            id={formElements[element].id}
                            name={formElements[element].id}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={currentHiringCountry[formElements[element].id].data}
                            invalid={!!errors[formElements[element].id]}
                        />

                        {errors[formElements[element].id] && (
                            <p className={`mt-1 ${sharedStyles.errorMsg}`}>
                                {errors[formElements[element].id].message}
                            </p>
                        )}
                    </div>
                ))}
            </div>

            <div className="d-flex justify-content-end m-3 gap-3">
                <button type="reset" className="btn btn-danger text-uppercase" onClick={onFormCancel}>
                    {t("cancel")}
                </button>
                <button type="submit" className="btn btn-success text-uppercase">
                    {formMode === formStatus.ADDING ? t("create") : t("update")}
                </button>
            </div>
        </form>
    );
};

HiringCountriesForm.propTypes = {
    onFormSubmit: PropTypes.func.isRequired,
    onFormCancel: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    currentHiringCountry: PropTypes.object.isRequired,
    formElements: PropTypes.object.isRequired,
    formStatus: PropTypes.object.isRequired,
    formMode: PropTypes.string.isRequired,
    errors: PropTypes.object.isRequired,
};

export default HiringCountriesForm;
