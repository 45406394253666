import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Selector from "react-select";
import Button from "../../common/Button";
import Label from "../../common/forms/label/Label";
import OrganizationStatementList from "../../common/organization-statement-list/OrganizationStatementList";
import sharedStyle from "../../shared-styles/FormStyle.module.scss";
import { getSelectorStyle } from "../../shared-styles/StylesFunctions";
import style from "./OrganizationForm.module.scss";

const ROW_CLASS = "row justify-content-evenly gap-3";
const COL_CLASS = "col-10 col-lg-4";

export const FormNames = {
    FANTASY_NAME: "fantasy-name",
    COMPANY_POSITION: "company-position",
    NAME: "name",
    ADDRESS: "address",
    WEBSITE: "website",
    BUSINESS_SECTOR: "business-sector",
    COUNTRY: "country",
    TAX_ID: "tax-id",
};

const OrganizationForm = ({
    organizationData,
    organizationRawData,
    onEditClick,
    onSaveClick,
    errors,
    onInputChange,
    checkTaxId,
    onCountrySelectorChange,
    onBusinessSelectorChange,
    validateUrl,
    countryOptions,
    businessSectorsOptions,
    disabled = false,
}) => {
    const { t } = useTranslation();

    return (
        <div className={`${style.container} d-flex flex-column`}>
            <form className={`w-100 d-flex flex-column gap-3`}>
                <div className={ROW_CLASS}>
                    <div className={COL_CLASS}>
                        <Label htmlFor={FormNames.NAME} requiredIndicator>
                            {t("companyName")}:
                        </Label>

                        <input
                            type="text"
                            id={FormNames.NAME}
                            name={FormNames.NAME}
                            className={`${sharedStyle.inputText} ${
                                errors[FormNames.NAME] ? sharedStyle.invalidField : ""
                            } ${disabled ? style.disabledInput : ""}`}
                            value={organizationData[FormNames.NAME]}
                            disabled={disabled}
                            onChange={onInputChange}
                        />

                        {errors[FormNames.NAME] && (
                            <div className="mt-1">
                                <p className={sharedStyle.errorMsg}>{errors[FormNames.NAME].message}</p>
                            </div>
                        )}
                    </div>

                    <div className={COL_CLASS}>
                        <Label htmlFor={FormNames.COMPANY_POSITION}>{t("companyPosition")}:</Label>

                        <input
                            type="text"
                            id={FormNames.COMPANY_POSITION}
                            name={FormNames.COMPANY_POSITION}
                            className={`${sharedStyle.inputText} ${
                                errors[FormNames.COMPANY_POSITION] ? sharedStyle.invalidField : ""
                            } ${disabled ? style.disabledInput : ""}`}
                            value={organizationData[FormNames.COMPANY_POSITION]}
                            disabled={disabled}
                            onChange={onInputChange}
                        />

                        {errors[FormNames.COMPANY_POSITION] && (
                            <div className="mt-1">
                                <p className={sharedStyle.errorMsg}>{errors[FormNames.COMPANY_POSITION].message}</p>
                            </div>
                        )}
                    </div>
                </div>

                <div className={ROW_CLASS}>
                    <div className={COL_CLASS}>
                        <Label htmlFor={FormNames.FANTASY_NAME}>{t("businessName")}:</Label>

                        <input
                            type="text"
                            id={FormNames.FANTASY_NAME}
                            name={FormNames.FANTASY_NAME}
                            className={`${sharedStyle.inputText} ${
                                errors[FormNames.FANTASY_NAME] ? sharedStyle.invalidField : ""
                            } ${disabled ? style.disabledInput : ""}`}
                            value={organizationData[FormNames.FANTASY_NAME]}
                            disabled={disabled}
                            onChange={onInputChange}
                        />

                        {errors[FormNames.FANTASY_NAME] && (
                            <div className="mt-1">
                                <p className={sharedStyle.errorMsg}>{errors[FormNames.FANTASY_NAME].message}</p>
                            </div>
                        )}
                    </div>

                    <div className={COL_CLASS}>
                        <Label htmlFor={FormNames.ADDRESS}>{t("address")}:</Label>

                        <input
                            type="text"
                            id={FormNames.ADDRESS}
                            name={FormNames.ADDRESS}
                            className={`${sharedStyle.inputText} ${
                                errors[FormNames.ADDRESS] ? sharedStyle.invalidField : ""
                            } ${disabled ? style.disabledInput : ""}`}
                            value={organizationData[FormNames.ADDRESS]}
                            disabled={disabled}
                            onChange={onInputChange}
                        />

                        {errors[FormNames.ADDRESS] && (
                            <div className="mt-1">
                                <p className={sharedStyle.errorMsg}>{errors[FormNames.ADDRESS].message}</p>
                            </div>
                        )}
                    </div>
                </div>

                <div className={ROW_CLASS}>
                    <div className={COL_CLASS}>
                        <Label htmlFor={FormNames.WEBSITE}>{t("website")}:</Label>

                        <input
                            type="text"
                            id={FormNames.WEBSITE}
                            name={FormNames.WEBSITE}
                            className={`${sharedStyle.inputText} ${
                                errors[FormNames.WEBSITE] ? sharedStyle.invalidField : ""
                            } ${disabled ? style.disabledInput : ""}`}
                            value={organizationData[FormNames.WEBSITE]}
                            disabled={disabled}
                            onBlur={validateUrl}
                            onChange={onInputChange}
                        />

                        {errors[FormNames.WEBSITE] && (
                            <div className="mt-1">
                                <p className={sharedStyle.errorMsg}>{errors[FormNames.WEBSITE].message}</p>
                            </div>
                        )}
                    </div>

                    <div className={COL_CLASS}>
                        <Label htmlFor={FormNames.BUSINESS_SECTOR} requiredIndicator>
                            {t("businessSector")}:
                        </Label>

                        <Selector
                            id={FormNames.BUSINESS_SECTOR}
                            name={FormNames.BUSINESS_SECTOR}
                            styles={getSelectorStyle(!!errors[FormNames.BUSINESS_SECTOR])}
                            options={businessSectorsOptions.data}
                            value={organizationData[FormNames.BUSINESS_SECTOR]}
                            isLoading={businessSectorsOptions.loading}
                            isDisabled={disabled}
                            onChange={onBusinessSelectorChange}
                        />

                        {errors[FormNames.BUSINESS_SECTOR] && (
                            <div className="mt-1">
                                <p className={sharedStyle.errorMsg}>{errors[FormNames.BUSINESS_SECTOR].message}</p>
                            </div>
                        )}
                    </div>
                </div>

                <div className={ROW_CLASS}>
                    <div className={COL_CLASS}>
                        <Label htmlFor={FormNames.COUNTRY} requiredIndicator>
                            {t("countryJurisdiction")}:
                        </Label>

                        <Selector
                            id={FormNames.COUNTRY}
                            name={FormNames.COUNTRY}
                            styles={getSelectorStyle(!!errors[FormNames.COUNTRY])}
                            options={countryOptions.data}
                            value={organizationData[FormNames.COUNTRY]}
                            isLoading={countryOptions.loading}
                            isDisabled={disabled}
                            onChange={onCountrySelectorChange}
                        />

                        {errors[FormNames.COUNTRY] && (
                            <div className="mt-1">
                                <p className={sharedStyle.errorMsg}>{errors[FormNames.COUNTRY].message}</p>
                            </div>
                        )}
                    </div>

                    <div className={COL_CLASS}>
                        <Label htmlFor={FormNames.TAX_ID} requiredIndicator>
                            {t("taxID")}:
                        </Label>

                        <input
                            type="text"
                            id={FormNames.TAX_ID}
                            name={FormNames.TAX_ID}
                            className={`${sharedStyle.inputText} ${
                                errors[FormNames.TAX_ID] ? sharedStyle.invalidField : ""
                            } ${disabled ? style.disabledInput : ""}`}
                            value={organizationData[FormNames.TAX_ID]?.value}
                            disabled={disabled}
                            onBlur={checkTaxId}
                            onChange={onInputChange}
                        />

                        {errors[FormNames.TAX_ID] && (
                            <div className="mt-1">
                                <p className={sharedStyle.errorMsg}>{errors[FormNames.TAX_ID].message}</p>
                            </div>
                        )}
                    </div>
                </div>

                <Button
                    onClick={disabled ? onEditClick : onSaveClick}
                    cssClasses={[`${style.editBtn} ms-lg-auto mx-3 mt-5`]}
                >
                    {disabled ? t("edit") : t("save")}
                </Button>
            </form>

            <OrganizationStatementList organizationDocumentList={organizationRawData.organizationDocuments} />
        </div>
    );
};

OrganizationForm.propTypes = {
    organizationData: PropTypes.object.isRequired,
    organizationRawData: PropTypes.object.isRequired,
    onEditClick: PropTypes.func.isRequired,
    onSaveClick: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    onInputChange: PropTypes.func.isRequired,
    checkTaxId: PropTypes.func.isRequired,
    onCountrySelectorChange: PropTypes.func.isRequired,
    onBusinessSelectorChange: PropTypes.func.isRequired,
    validateUrl: PropTypes.func.isRequired,
    countryOptions: PropTypes.object.isRequired,
    businessSectorsOptions: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
};

export default OrganizationForm;
