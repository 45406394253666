import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    info: {
        linkedinLabel: { id: undefined, data: ""},
        linkedinUrl: { id: undefined, data: ""},
        address: { id: undefined, data: "" },
        phone: { id: undefined, data: "" },
        email: { id: undefined, data: "" },
    },
};

const tdbInformationSlice = createSlice({
    name: "tdbInformation",
    initialState,
    reducers: {
        setInfo(state, { payload }) {
            state.info = payload;
        },
    },
});

export const { setInfo } = tdbInformationSlice.actions;

export default tdbInformationSlice.reducer;
