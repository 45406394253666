export const LettersAndNumbersRegex = /^[a-zÀ-ÿ\d\s]+$/i;

export const LettersRegex = /^[a-zÀ-ÿ\s]+$/i;

export const OnlyLettersAndSpacesRegex = /^(?!^\s*$)[a-zÀ-ÿ\s]+$/i;

export const NumbersRegex = /^\d+$/i;

export const UrlRegex = /^(http|https|ftp)\:\/\/[a-z0-9\.-]+\.[a-z]{2,4}/i;

export const LettersNumbersAndSpecials = /^[a-z\d\s*+_\-/\\,.]+$/i;

export const UnitedStatesTINRegex = /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/i;

export const UnitedStatesEINRegex = /^[0-9]{2}-[0-9]{7}$/i;

export const NoWhitespaceRegex = /^\S+$/i;

export const PhoneRegex = /^\+?[0-9]{7,15}$/;

export const AddressRegex = /^[a-zA-Z0-9,.\-\s]+$/;

export const DomainRegex = /^(?!:\/\/)([a-z0-9_-]+\.)*[a-z0-9_-]+\.[a-z]{2,}$/i;

export const isValidUrl = (url) => UrlRegex.test(url);
