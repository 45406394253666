import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import styles from "./Info.module.scss";

const Info = ({ label, data }) => {
    const { t } = useTranslation();

    return (
        <div className={`${styles.infoContainer} d-flex align-items-center text-nowrap`}>
            <span className={styles.labelInfo}>{label}</span>
            <span className={styles.info} title={data}>
                {data}
            </span>
        </div>
    );
};

Info.propTypes = {
    label: PropTypes.string.isRequired,
    data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Info;
