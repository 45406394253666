import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import styles from "../../../components/shared-styles/OrganizationStatementList.module.scss";
import { createLinkDownloadStatementFile } from "../../../services/FileService";
import Label from "../forms/label/Label";

const OrganizationStatementList = ({ organizationDocumentList }) => {
    const { t } = useTranslation();

    return (
        <div className="ms-5">
            <Label>{t("statementOfWork")}</Label>
            <table className={`${styles.statementTable} w-100`}>
                <thead>
                    <tr>
                        <th>{t("projectName")}</th>
                        <th>{t("files")}</th>
                    </tr>
                </thead>
                <tbody>
                    {organizationDocumentList.length > 0 ? (
                        organizationDocumentList.map((document) => {
                            return (
                                <tr key={document.id}>
                                    <td>{document.project.name}</td>
                                    <td>
                                        <a
                                            className={styles.downloadCv}
                                            href={createLinkDownloadStatementFile(document.id)}
                                        >
                                            {document.statementOfWorkName}
                                        </a>
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr className={styles.noData}>
                            <td colSpan="3">{t("noDataToShow")}</td>
                        </tr>
                    )}
                </tbody>
                <tfoot className={styles.statementFooter}>
                    <tr>
                        <td colSpan="3"></td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};

OrganizationStatementList.propTypes = {
    organizationDocumentList: PropTypes.array.isRequired,
};

export default OrganizationStatementList;
