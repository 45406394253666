import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { ProjectStatusIds, PROJECT_TYPES, TAM_LABEL } from "../../base/js/constants";
import { getRolesAsync, getSenioritiesAsync } from "../../services/PositionService";
import { getProjectAsync } from "../../services/ProjectService";
import { getTechnologyListAsync } from "../../services/TechnologyService";
import { getTimezones } from "../../services/TimezoneService";
import { getUserContextualInfo } from "../../services/UserService";
import {
    restartState,
    setAdditionalComments,
    setAttachment,
    setId,
    setPositions,
    setProjectStatus,
    setProjectType,
    setSkills,
    setTeamName,
    setTimezone,
} from "../../store/slices/assembleYourTeamSlice";
import { setPendingSurvey } from "../../store/slices/userSlice";
import { parseTimezones } from "../../utils/timezonesParsing";
import useScrollToTop from "../hooks/UseScrollToTop";
import Loading from "../loading/Loading";
import AssembleYourTeam from "./assemble-your-team/AssembleYourTeam";
import { ContainerType, DATE_FORMAT } from "./assemble-your-team/constants";
// import { RoleCheckboxNames as firstSectionCheckboxNames } from "./assemble-your-team/first-section/FirstSection";
import style from "./BuildYourTeam.module.scss";
import BYTContext from "./BYTContext";
import Confirmation from "./confirmation/Confirmation";
import PhaseIndicator, { phases, phasesOrder } from "./PhaseIndicator";
import Resume from "./resume/Resume";
import TeamInfo from "./team-info/TeamInfo";

const DEFAULT_PHASE = phases.ASSEMBLE_YOUR_TEAM;

const getSubview = (currentPhase, handlePreviousPhase, handleNextPhase, handleRestartProcess) => {
    switch (currentPhase) {
        case phases.LOGIN:
            return null;
        case phases.ASSEMBLE_YOUR_TEAM:
            return <AssembleYourTeam previousPhase={handlePreviousPhase} nextPhase={handleNextPhase} />;
        case phases.TEAM_INFO:
            return <TeamInfo previousPhase={handlePreviousPhase} nextPhase={handleNextPhase} />;
        case phases.RESUME:
            return (
                <Resume
                    previousPhase={handlePreviousPhase}
                    nextPhase={handleNextPhase}
                    firstPhase={handleRestartProcess}
                />
            );
        case phases.CONFIRMATION:
            return <Confirmation resetPhase={handleRestartProcess} />;
        default:
            return null;
    }
};

const getSubviewToRedirect = (projectStatusId) => {
    switch (projectStatusId) {
        case ProjectStatusIds.STARTING_SELECTION:
            return phases.RESUME;
        case ProjectStatusIds.NOT_AGREE_AND_SELECTING:
            return phases.CONFIRMATION;
        case ProjectStatusIds.CREATING_NEW_PROJECT:
        case ProjectStatusIds.PARTIAL_RESELECTING:
        case ProjectStatusIds.ALL_RESOURCES_ALLOCATED:
            return phases.TEAM_INFO;
        default:
            return null;
    }
};

const redirectToSubview = (setPhase, phase) => {
    if (!phase) {
        setPhase(phases.ASSEMBLE_YOUR_TEAM);
        return;
    }

    setPhase(phase);
};

const DEFAULT_POSITION = {
    role: null,
    seniority: null,
    jobSkills: [],
    total: 1,
    time: false,
    estimatedStartDate: null,
    estimatedEndDate: null,
    jobDescription: {
        isLink: true,
        isValid: true,
        link: "",
        file: {
            name: "",
            url: null,
        },
    },
};

const TAM_ID = "tam_position";

const BuildYourTeam = () => {
    const [phase, setPhase] = useState(DEFAULT_PHASE);
    const dispatch = useDispatch();

    const { t } = useTranslation();
    const [searchParam, setSearchParam] = useSearchParams();
    const [timezones, setTimezones] = useState({ data: [], loading: true });
    const [technologies, setTechnologies] = useState({ data: [], loading: true });
    const [roles, setRoles] = useState({ data: [], loading: true });
    const [seniorities, setSeniorities] = useState({ data: [], loading: true });
    const [isProjectLoading, setIsProjectLoading] = useState(false);

    useScrollToTop(phase);

    // Check if we need to redirect the user to the Survey view
    useEffect(() => {
        return () => {
            (async () => {
                try {
                    const data = await getUserContextualInfo();

                    if (data) {
                        dispatch(setPendingSurvey(data?.surveyNeeded));
                    }
                } catch (error) {
                    console.error(error.message);
                }
            })();
        };
    }, []);

    // Fetch the information to provide it to all the subviews
    useEffect(() => {
        // Fetch roles
        (async () => {
            try {
                const jsonRoles = await getRolesAsync();

                if (jsonRoles) {
                    const mappedRoles = jsonRoles.map((role) => {
                        return { value: role.id, label: role.name };
                    });

                    setRoles((prevState) => ({ ...prevState, data: mappedRoles }));
                }
            } catch (e) {
                toast.error(`${t("unexpectedError")}: ${e.message}`);
            } finally {
                setRoles((prevState) => ({ ...prevState, loading: false }));
            }
        })();

        // Fetch seniorities
        (async () => {
            try {
                const jsonSeniorities = await getSenioritiesAsync();

                if (jsonSeniorities) {
                    const mappedSeniorities = jsonSeniorities.map((seniority) => {
                        return { value: seniority.id, label: seniority.name };
                    });

                    setSeniorities((prevState) => ({ ...prevState, data: mappedSeniorities }));
                }
            } catch (e) {
                toast.error(`${t("unexpectedError")}: ${e.message}`);
            } finally {
                setSeniorities((prevState) => ({ ...prevState, loading: false }));
            }
        })();

        // Fetch timezones
        (async () => {
            try {
                const body = await getTimezones();

                if (body) {
                    const parsedTimezones = parseTimezones(body);
                    setTimezones((prevState) => ({ ...prevState, data: parsedTimezones }));
                }
            } catch (e) {
                toast.error(`${t("unexpectedError")}: ${e.message}`);
            } finally {
                setTimezones((prevState) => ({ ...prevState, loading: false }));
            }
        })();

        // Fetch technologies
        (async () => {
            try {
                let jsonTechnologies = [];
                jsonTechnologies = (await getTechnologyListAsync()) ?? [];

                if (jsonTechnologies) {
                    const technologiesTemp = jsonTechnologies.map((skill) => {
                        return {
                            key: skill.id.toString(),
                            name: skill.name,
                            custom: false,
                            container: ContainerType.AVAILABLE_SKILLS,
                            alias: skill.alias?.split(",") || [],
                        };
                    });

                    setTechnologies((prevState) => ({ ...prevState, data: technologiesTemp }));
                }
            } catch (e) {
                toast.error(`${t("unexpectedError")}: ${e.message}`);
            } finally {
                setTechnologies((prevState) => ({ ...prevState, loading: false }));
            }
        })();
    }, []);

    // Fetch project data if we detect the "from" query parameter
    useEffect(() => {
        const from = searchParam.get("from");
        if (!from || technologies.loading || timezones.loading || roles.loading || seniorities.loading) {
            return;
        }

        (async () => {
            setIsProjectLoading(true);

            try {
                const data = await getProjectAsync(from);

                if (data) {
                    dispatch(setId(data.id));
                    dispatch(setTeamName(data.name));
                    // dispatch(setInvestmentExpectation(data.budget ? data.budget : ""));
                    // dispatch(setDecisionFlow(data.description));
                    dispatch(setProjectStatus(data.projectStatus.length > 0 ? data.projectStatus[0].status : null));

                    // const otherCheck =
                    //     firstSectionCheckboxNames.DECISION_MAKER != data.roleInvolvedInHiringProcess &&
                    //     firstSectionCheckboxNames.RECOMMENDER != data.roleInvolvedInHiringProcess &&
                    //     firstSectionCheckboxNames.USER != data.roleInvolvedInHiringProcess;
                    // dispatch(
                    //     setRolesInteracting({
                    //         [firstSectionCheckboxNames.DECISION_MAKER]:
                    //             firstSectionCheckboxNames.DECISION_MAKER === data.roleInvolvedInHiringProcess,
                    //         [firstSectionCheckboxNames.RECOMMENDER]:
                    //             firstSectionCheckboxNames.RECOMMENDER === data.roleInvolvedInHiringProcess,
                    //         [firstSectionCheckboxNames.USER]:
                    //             firstSectionCheckboxNames.USER === data.roleInvolvedInHiringProcess,
                    //         [firstSectionCheckboxNames.OTHERS]: otherCheck,
                    //     })
                    // );
                    // dispatch(setRoleInteractingCustom(otherCheck ? data.roleInvolvedInHiringProcess : ""));
                    // dispatch(setStartDate(format(parseISO(data.dateStart), DATE_FORMAT)));
                    // dispatch(setEndDate(format(parseISO(data.dateEnd), DATE_FORMAT)));

                    let zone = timezones.data.filter((zone) => {
                        return zone.value === data.timeZone;
                    });
                    zone = zone.length > 0 ? zone[0] : null;
                    dispatch(setTimezone(zone));

                    // dispatch(setConfirmationDate(format(parseISO(data.dateClientNeedsConfirmation), DATE_FORMAT)));

                    if (technologies?.data.length > 0) {
                        const skillsLoad = technologies?.data.map((skill) => {
                            let isTechnologySelected = false;
                            let techFound = null;
                            data.projectTechnologies.forEach((tech) => {
                                if (tech.technologyId.toString() === skill.key) {
                                    isTechnologySelected = true;
                                    techFound = tech;
                                }
                            });

                            if (isTechnologySelected) {
                                return {
                                    ...skill,
                                    container: techFound.mandatory
                                        ? ContainerType.MANDATORY_SKILLS
                                        : ContainerType.DESIRED_SKILLS,
                                };
                            }
                            return skill;
                        });

                        dispatch(setSkills(skillsLoad));
                    }

                    // const businessSectorsSelected = data.businessSectors?.map((business) => {
                    //     return {
                    //         label: business.sectorEn,
                    //         value: business.id,
                    //     };
                    // });
                    // dispatch(setTeamArea(businessSectorsSelected));
                    // dispatch(setProjectType(data.newProject ? PROJECT_TYPES[0] : PROJECT_TYPES[1]));
                    dispatch(setProjectType(PROJECT_TYPES[1]));
                    dispatch(setAdditionalComments(data.additionalComments || ""));
                    dispatch(
                        setAttachment(
                            {},
                            {
                                fileName: data.projectDoc ? data.projectDoc : "",
                                blobName: data.projectBlobDoc ? data.projectBlobDoc : "",
                                isLoading: false,
                                errors: [],
                                isValid: true,
                            }
                        )
                    );

                    const positions =
                        data.positions.length > 0
                            ? data.positions.map((position) => {
                                  return {
                                      id: position.id,
                                      role: { value: position.developmentRolId, label: position.developmentRol.name },
                                      seniority: { value: position.seniorityId, label: position.seniority.name },
                                      jobSkills: position.positionCustomValue
                                          ? position.positionCustomValue?.map((tag) => {
                                                return tag.customValue.value;
                                            })
                                          : [],
                                      total: position.total,
                                      time: position.partTime,
                                      estimatedStartDate: position.estimatedStartDate
                                          ? format(parseISO(position.estimatedStartDate), DATE_FORMAT)
                                          : null,
                                      estimatedEndDate: position.estimatedEndDate
                                          ? format(parseISO(position.estimatedEndDate), DATE_FORMAT)
                                          : null,
                                      jobDescription: {
                                          isLink: !position.jobDescriptionFile,
                                          isValid: true,
                                          link: position.jobDescriptionLink,
                                          file: {
                                              name: position.jobDescriptionFile,
                                              blobName: position.jobDescriptionBlob,
                                          },
                                      },
                                  };
                              })
                            : [];

                    const tamRole = roles.data.find((role) => role.label === TAM_LABEL);
                    const seniorSeniority = seniorities.data.find((seniority) => seniority.label === "Senior");

                    const TAM = {
                        ...DEFAULT_POSITION,
                        estimatedStartDate: format(parseISO(data.dateStart), DATE_FORMAT),
                        estimatedEndDate: format(parseISO(data.dateEnd), DATE_FORMAT),
                        id: TAM_ID,
                        role: tamRole,
                        seniority: seniorSeniority,
                    };

                    dispatch(setPositions([...positions, TAM]));

                    const phaseToRedirect = getSubviewToRedirect(
                        data.projectStatus[data.projectStatus.length - 1].status.id
                    );
                    redirectToSubview(setPhase, phaseToRedirect);
                }
            } catch (error) {
                console.error(error.message);
            } finally {
                setIsProjectLoading(false);
            }
        })();
    }, [technologies.loading, timezones.loading, roles.loading, seniorities.loading]);

    // Restore Redux state on view exit
    useEffect(() => {
        return () => {
            handleRestartProcess();
            dispatch(restartState());
        };
    }, []);

    const handleNextPhase = () => {
        const nextPhase = phasesOrder.indexOf(phase) >= 1 ? phasesOrder.indexOf(phase) + 1 : null;
        if (phasesOrder[nextPhase]) {
            setPhase(phasesOrder[nextPhase]);
        }
    };

    const handlePreviousPhase = () => {
        const previousPhase =
            phasesOrder.indexOf(phase) <= phasesOrder.length - 1 ? phasesOrder.indexOf(phase) - 1 : null;
        if (phasesOrder[previousPhase]) {
            setPhase(phasesOrder[previousPhase]);
        }
    };

    const handleRestartProcess = () => {
        setPhase(phases.ASSEMBLE_YOUR_TEAM);
    };

    return (
        <main className={style.section}>
            <BYTContext.Provider value={{ timezones, technologies, roles, seniorities }}>
                <PhaseIndicator activePhase={phase} />
                {getSubview(phase, handlePreviousPhase, handleNextPhase, handleRestartProcess)}
                {(roles.loading ||
                    seniorities.loading ||
                    technologies.loading ||
                    timezones.loading ||
                    isProjectLoading) && <Loading />}
            </BYTContext.Provider>
        </main>
    );
};

export default BuildYourTeam;
