import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { FORMATTER, ProjectStatus } from "../../base/js/constants";
import Routes from "../../base/js/routes";
import { getProjectAsync } from "../../services/ProjectService";
import TeamCard from "../common/team-card/TeamCard";

import styles from "./ProjectDevelopment.module.scss";

const ProjectDevelopment = () => {
    const { t } = useTranslation();
    const params = useParams();

    const navigate = useNavigate();
    const [project, setProject] = useState({});

    useEffect(() => {
        (async () => {
            try {
                const responseData = await getProjectAsync(params.projectId, false);

                if (responseData) {
                    const data = {
                        name: responseData.name,
                        status: responseData.projectStatus[responseData.projectStatus.length - 1].status.value,
                        monthPrice: responseData.monthPrice,
                        team: responseData.positions.map((position) => {
                            return {
                                role: position.developmentRol.name,
                                seniority: position.seniority.name,
                                skills: position.positionCustomValues.map((tag) => {
                                    return tag.customValue.value;
                                }),
                                rate: position.rate,
                                resource: {
                                    name: position.positionStaffs[position.positionStaffs.length - 1].staff.name,
                                    lastName:
                                        position.positionStaffs[position.positionStaffs.length - 1].staff.lastName,
                                    status: position.positionStaffs[position.positionStaffs.length - 1].status,
                                },
                            };
                        }),
                        technicalAccountManager: {
                            name: responseData.tam?.name,
                            email: responseData.tam?.email,
                            phone: responseData.tam?.phoneNumber,
                        },
                    };
                    setProject(data);
                }
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            }
        })();
    }, []);

    const handleClickReviews = (e) => {
        e.preventDefault();
        const url = Routes.buildReviewsPath(params.projectId);
        navigate(url);
    };

    const handleClickDevelopmentTimeline = (e) => {
        e.preventDefault();
        const url = Routes.buildProjectTimeline(params.projectId);
        navigate(url);
    };

    return (
        <main className={styles.mainContainer}>
            <section className={`${styles.teamInfoContainer} ms-auto`}>
                <div className={`${styles.paddingContainer} d-flex flex-column gap-5`}>
                    <div className="d-flex">
                        <div className="d-flex flex-column gap-2 col-6">
                            <h1 className={styles.title}>{t("teamInfo")}</h1>
                            <div className="d-flex flex-column gap-3">
                                <p className={styles.subtitle}>
                                    <span className="pe-3">{t("projectName")}:</span>
                                    <span className={styles.projectName}>{project.name}</span>
                                </p>
                                <p className={styles.subtitle}>
                                    <span className="pe-3">{t("projectStatus")}:</span>
                                    <span className={styles.projectStatus}>{project.status}</span>
                                </p>
                            </div>
                        </div>
                        <div className="d-flex justify-content-around col-5">
                            <div className="d-flex flex-column gap-4">
                                <div className="d-flex flex-column gap-2">
                                    <span className={styles.priceLabel}>{t("monthPrice")}:</span>
                                    <span className={styles.priceValue}>{FORMATTER.format(project.monthPrice)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h2 className={`${styles.title} my-4`}>{t("yourTeamMembers")}</h2>
                    </div>
                </div>
            </section>
            <section className={`${styles.cardSection} mx-auto`}>
                <div className={`${styles.cardContainer} d-flex flex-wrap gap-4 mb-5`}>
                    {project.team?.map((position, index) => {
                        return (
                            <div key={index}>
                                <TeamCard
                                    role={position.role}
                                    seniority={position.seniority}
                                    skills={position.skills}
                                    rate={position.rate}
                                    resource={position.resource}
                                    projectStatus={ProjectStatus.IN_DEVELOPMENT}
                                    positionStaffStatus={position.resource.status}
                                />
                            </div>
                        );
                    })}
                </div>
            </section>
            <section className={`${styles.technicalInformationSection} mx-auto`}>
                <div className={`${styles.technicalInformationContainer} d-flex flex-column align-items-center`}>
                    <h3 className={`${styles.technicalInformationTitle} mb-3`}>
                        {t("technicalAccountManagerInformation")}
                    </h3>
                    <div className="d-flex gap-5">
                        <div className="d-flex flex-column">
                            <span className={styles.technicalInformationLabel}>{t("name")}</span>
                            <span className={styles.technicalInformationValue}>
                                {project.technicalAccountManager?.name}
                            </span>
                        </div>
                        <div className="d-flex flex-column">
                            <span className={styles.technicalInformationLabel}>{t("emailAddress")}</span>
                            <span className={styles.technicalInformationValue}>
                                {project.technicalAccountManager?.email}
                            </span>
                        </div>
                        <div className="d-flex flex-column">
                            <span className={styles.technicalInformationLabel}>{t("phone")}</span>
                            <span className={styles.technicalInformationValue}>
                                {project.technicalAccountManager?.phone}
                            </span>
                        </div>
                    </div>
                    <div className="d-flex gap-3 m-5">
                        <button className={`${styles.btn} text-uppercase`} onClick={handleClickReviews}>
                            {t("reviews")}
                        </button>
                        <button className={`${styles.btn} text-uppercase`} onClick={handleClickDevelopmentTimeline}>
                            <Trans components={{ span: <span /> }}>showDevelopmentTimeline</Trans>
                        </button>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default ProjectDevelopment;
