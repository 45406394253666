import PropTypes from "prop-types";
import { useState } from "react";
import { useDrop } from "react-dnd";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { v4 as uuidV4 } from "uuid";
import { addPositionStaffAsync, removePositionStaffAsync } from "../../../../services/PositionService";
import Loading from "../../../loading/Loading";
import sharedStyle from "../../../shared-styles/FormStyle.module.scss";
import ResourceAssigned from "../resource-assigned/ResourceAssigned";
import styles from "./PositionCard.module.scss";

const PositionCard = ({
    setActive,
    position,
    active = false,
    refreshPositions,
    setRefreshPositions,
    isValid = true,
}) => {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);

    const [{ isOver }, drop] = useDrop(
        () => ({
            accept: "resource",
            drop: (item) => onDrop(item),
            collect: (monitor) => ({
                isOver: !!monitor.isOver(),
            }),
        }),
        [position]
    );
    const onDrop = async (item) => {
        try {
            setIsLoading(true);

            const response = await addPositionStaffAsync(position.id, item.id);

            if (response) setRefreshPositions(!refreshPositions);
        } catch (e) {
            toast.error(t("unexpectedError") + ": " + e.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleToggleActive = () => {
        if (position.positionStaffs.length < 3) {
            active ? setActive(null) : setActive(position.id);
        }
    };

    const handleDeleteClick = async (resourceId) => {
        if (resourceId && position.id) {
            try {
                setIsLoading(true);

                await removePositionStaffAsync(position.id, resourceId);

                setRefreshPositions(!refreshPositions);
                setActive(null);
                toast.success(t("positionResourceRemoveSuccess"));
            } catch (e) {
                console.error(t("unexpectedError") + ": " + e.message);
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <>
            <div className={`${styles.mainContainer} ${isOver ? styles.active : ""} d-flex justify-content-between`}>
                <div className="d-flex flex-column w-50">
                    <span className={`${styles.item} ${styles.itemText}`}>
                        {position.developmentRol.name} ({position.seniority.name})
                    </span>
                    <div>
                        <span className={styles.item}>{t("technologies")}: </span>
                        <span className={`${styles.itemText} ${styles.itemText}`}>
                            {position.positionCustomValues?.map((cv) => cv.customValue.value)?.join(", ") || "-"}
                        </span>
                    </div>
                    {(position.jobDescriptionLink || position.descriptionFile) && (
                        <span className="d-inline-block w-100 text-truncate">
                            {t("descriptionFile")}:{" "}
                            <a
                                href={position.jobDescriptionLink || position.descriptionFile}
                                className={sharedStyle.link}
                                target={position.jobDescriptionLink ? "_blank" : undefined}
                                download={!!position.descriptionFile}
                            >
                                {position.jobDescriptionLink || position.jobDescriptionFile}
                            </a>
                        </span>
                    )}
                    <span
                        className={`${styles.timePill} ${
                            position.partTime ? styles.partTimePill : styles.fullTimePill
                        }`}
                    >
                        {position.partTime ? t("partTime") : t("fullTime")}
                    </span>
                </div>
                <div ref={drop} className={`w-50 d-flex flex-wrap gap-3 justify-content-between position-relative`}>
                    {position.positionStaffs.length < 3 && (
                        <div
                            className={`${styles.rightContent} ${active ? styles.active : ""} ${
                                isValid && !active ? styles.invalid : ""
                            } d-flex flex-column align-items-center justify-content-center w-100`}
                            onClick={handleToggleActive}
                        >
                            <span className={styles.selectText}>{active ? t("pending") : t("clickToSelect")}</span>
                        </div>
                    )}

                    {position.positionStaffs?.map((positionStaff) => (
                        <ResourceAssigned
                            key={uuidV4()}
                            positionStaff={positionStaff}
                            short={!(position.positionStaffs.length === 3 && positionStaff.priority === 1)}
                            onDelete={handleDeleteClick}
                        />
                    ))}
                </div>
                {isLoading && <Loading />}
            </div>
        </>
    );
};

PositionCard.prototype = {
    setActive: PropTypes.func.isRequired,
    position: PropTypes.object.isRequired,
    active: PropTypes.bool,
    refreshPositions: PropTypes.bool.isRequired,
    setRefreshPositions: PropTypes.func.isRequired,
    isValid: PropTypes.bool,
};

export default PositionCard;
