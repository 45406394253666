import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { AccordionContext, useAccordionButton } from "react-bootstrap";

import styles from "./QuestionList.module.scss";

const CustomToggle = ({ children, eventKey }) => {
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(eventKey);

    const headerClasses = `${styles.questionHeader} ${
        activeEventKey === eventKey ? styles.active : ""
    } position-relative`;

    return (
        <div className={`${headerClasses} d-flex align-items-center`} onClick={decoratedOnClick}>
            {!(activeEventKey === eventKey) ? <FontAwesomeIcon icon={faPlus} /> : <FontAwesomeIcon icon={faMinus} />}
            <p>{children}</p>
        </div>
    );
};

export default CustomToggle;
