import { format, parse, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidV4 } from "uuid";
import Routes from "../../../base/js/routes";
import { createLinkDownloadProjectDescriptionFile } from "../../../services/FileService";
import { addNoteToOrganization } from "../../../services/OrganizationService";
import { getProjectAsync } from "../../../services/ProjectService";
import { DATE_FORMAT } from "../../build-your-team/assemble-your-team/constants";
import Countdown from "../../build-your-team/count-down/CountDown";
import Button, { variants } from "../../common/Button";
import Loading from "../../loading/Loading";
import HistoricalProjectLogModal from "../admin-project-tracing/historical-project-log-modal/HistoricalProjectLogModal";
import Info from "./info/Info";
import styles from "./OpeningInfo.module.scss";
import OrganizationNotes from "./organization-notes/OrganizationNotes";
import ProjectDescriptionSkillset from "./project-description-skillset/ProjectDescriptionSkillset";

const OpeningInfo = () => {
    const { t } = useTranslation();
    const user = useSelector((state) => state.user);
    const params = useParams();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [showHistoricalProjectLog, setShowHistoricalProjectLog] = useState(false);
    const [project, setProject] = useState(null);
    const [projectTechMandatory, setProjectTechMandatory] = useState([]);
    const [projectTechDesired, setProjectTechDesired] = useState([]);
    const [organization, setOrganization] = useState(null);
    const [noteList, setNoteList] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                const projectId = params.id;

                if (!projectId) {
                    toast.error("Project ID not found");
                    navigate(Routes.ADMIN_PROJECTS);

                    return;
                }

                setIsLoading(true);

                const data = await getProjectAsync(projectId, false);

                if (data) {
                    setProject(data);

                    extractSkills(data.projectCustomValues, data.projectTechnologies);

                    if (data.userProjectCreators[data.userProjectCreators?.length - 1].creator.organization) {
                        setOrganization(
                            data.userProjectCreators[data.userProjectCreators?.length - 1].creator.organization
                        );
                        if (
                            data.userProjectCreators[data.userProjectCreators?.length - 1].creator.organization
                                .organizationNotes.length > 0
                        ) {
                            setNoteList(
                                data.userProjectCreators[data.userProjectCreators?.length - 1].creator.organization
                                    .organizationNotes
                            );
                        }
                    }
                }
            } catch (error) {
                console.error(error.message);
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    const extractSkills = (customValues = [], technologies = []) => {
        setProjectTechMandatory([]);
        setProjectTechDesired([]);

        if (customValues.length > 0)
            customValues.map((customValue) => {
                if (customValue.customValue.mandatory) {
                    setProjectTechMandatory((prev) => {
                        return [...prev, <span className={styles.mandatoryTech}>{customValue.customValue.value}</span>];
                    });
                } else {
                    setProjectTechDesired((prev) => {
                        return [...prev, <span className={styles.desiredTech}>{customValue.customValue.value}</span>];
                    });
                }
            });

        if (technologies.length > 0)
            technologies.map((tech) => {
                if (tech.mandatory) {
                    setProjectTechMandatory((prev) => {
                        return [
                            ...prev,
                            <span key={uuidV4()} className={styles.mandatoryTech}>
                                {tech.technology.name}
                            </span>,
                        ];
                    });
                } else {
                    setProjectTechDesired((prev) => {
                        return [
                            ...prev,
                            <span key={uuidV4()} className={styles.desiredTech}>
                                {tech.technology.name}
                            </span>,
                        ];
                    });
                }
            });
    };

    const handleAddNote = async (newNote) => {
        try {
            const dataToSend = {
                note: newNote,
                organizationId: organization.id,
            };

            const newNoteId = await addNoteToOrganization(dataToSend);

            if (newNoteId) {
                const note = {
                    id: newNoteId,
                    note: newNote,
                    createdBy: {
                        name: user.username,
                        email: user.email,
                    },
                    createdAt: new Date().toISOString(),
                };

                setNoteList((prev) => {
                    return [...prev, note];
                });
            }
        } catch (error) {
            console.error(error.message);
        }
    };

    const navigateBack = (e) => {
        e.preventDefault();

        const routeToNavigate = Routes.buildProjectAllocationResourcePath(project.id);
        navigate(routeToNavigate, { replace: true });
    };

    const handleOpenHistoricalProjectLog = (e) => {
        e.preventDefault();
        setShowHistoricalProjectLog(true);
    };

    const handleCloseHistoricalProjectLog = (e) => {
        e.preventDefault();
        setShowHistoricalProjectLog(false);
    };

    return (
        <>
            <div className={`${styles.mainContainer} d-flex flex-column`}>
                <div className="d-flex gap-3 justify-content-between">
                    <div className={`${styles.section} d-flex flex-column justify-content-between`}>
                        <div>
                            <span className={styles.sectionTitle}>{t("projectInformation")}</span>
                            <div>
                                <div className={styles.contentInfo}>
                                    <div>
                                        <Info label={t("name")} data={project?.name} />
                                    </div>
                                    <div>
                                        <Info
                                            label={t("owner")}
                                            data={
                                                project?.userProjectCreators[project?.userProjectCreators?.length - 1]
                                                    .creator.name
                                            }
                                        />
                                        <Info
                                            label={t("email")}
                                            data={
                                                project?.userProjectCreators[project?.userProjectCreators?.length - 1]
                                                    .creator.email
                                            }
                                        />
                                    </div>
                                    <div>
                                        <Info
                                            label={t("status")}
                                            data={
                                                project?.projectStatus[project?.projectStatus.length - 1].status.value
                                            }
                                        />
                                        <Info
                                            label={t("timer")}
                                            data={
                                                <Countdown
                                                    startDate={project?.createdAt ? parseISO(project.createdAt) : null}
                                                    lite
                                                />
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <ProjectDescriptionSkillset
                                skillset={[...projectTechMandatory, ...projectTechDesired]}
                                description={project?.additionalInfo}
                            />
                        </div>

                        <div className={`${styles.projectInfoBtn} d-flex justify-content-between gap-4`}>
                            <Button
                                variant={variants.PRIMARY}
                                cssClasses={[styles.infoBtn]}
                                onClick={handleOpenHistoricalProjectLog}
                            >
                                {t("log")}
                            </Button>
                            <Button variant={variants.PRIMARY} disabled cssClasses={[styles.infoBtn]}>
                                {t("payment")}
                            </Button>
                            <Button variant={variants.PRIMARY} disabled cssClasses={[styles.infoBtn]}>
                                {t("sow")}
                            </Button>
                            {project?.projectDoc ? (
                                <a
                                    href={createLinkDownloadProjectDescriptionFile(project?.id)}
                                    className={`${styles.documentLink} ${
                                        project?.projectDoc ? "" : styles.documentLinkDisabled
                                    } d-flex align-items-center justify-content-center text-uppercase`}
                                    target="_blank"
                                    download
                                >
                                    {t("document")}
                                </a>
                            ) : (
                                <div
                                    className={`${styles.documentLink} ${styles.documentLinkDisabled} d-flex align-items-center justify-content-center text-uppercase`}
                                >
                                    {t("document")}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={`${styles.section} d-flex flex-column justify-content-between`}>
                        <div>
                            <span className={styles.sectionTitle}>{t("companyInformation")}</span>
                            <div>
                                <div className={styles.contentInfo}>
                                    <div>
                                        <Info label={t("name")} data={organization?.name ? organization.name : ""} />
                                    </div>
                                    <div>
                                        <Info
                                            label={t("address")}
                                            data={organization?.address ? organization.address : ""}
                                        />
                                    </div>
                                    <div>
                                        <Info label={t("country")} data={organization?.country?.name} />
                                        <Info
                                            label={t("website")}
                                            data={organization?.webSite ? organization.webSite : ""}
                                        />
                                    </div>
                                    <div>
                                        <Info label={t("email")} data="" />
                                        <Info
                                            label={t("phone")}
                                            data={organization?.number ? organization.number : ""}
                                        />
                                    </div>
                                    <div>
                                        <Info
                                            label={t("taxIDEIN")}
                                            data={organization?.taxId ? organization.taxId : ""}
                                        />
                                        <Info label={t("industry")} data={organization?.businessSector?.sectorEn} />
                                    </div>
                                    <div>
                                        <Info
                                            label={t("signUpDate")}
                                            data={
                                                organization?.createdAt
                                                    ? format(parseISO(organization?.createdAt), DATE_FORMAT)
                                                    : ""
                                            }
                                        />
                                        <Info
                                            label={t("msaDate")}
                                            data={
                                                organization?.msaDate
                                                    ? format(parseISO(organization?.msaDate), DATE_FORMAT)
                                                    : ""
                                            }
                                        />
                                    </div>
                                </div>
                                <OrganizationNotes notes={noteList} onAddNote={handleAddNote} />
                            </div>
                        </div>

                        <div className="d-flex justify-content-end">
                            <Button disabled variant={variants.PRIMARY} cssClasses={[styles.infoBtn]}>
                                {t("msa")}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={`${styles.sectionBtn} d-flex`}>
                    <Button variant={variants.PRIMARY_INVERSE} cssClasses={[styles.closeBtn]} onClick={navigateBack}>
                        {t("back")}
                    </Button>
                </div>
            </div>
            <HistoricalProjectLogModal onClose={handleCloseHistoricalProjectLog} show={showHistoricalProjectLog} />
            {isLoading && <Loading />}
        </>
    );
};

export default OpeningInfo;
