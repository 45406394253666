import { generateErrorToast } from "../base/js/httpError";
import { API_BASE, request } from "../utils/httpRequest";

export const addPaymentAsync = async (projectId) => {
    try {
        return await request(`${API_BASE}/api/payment`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ projectId }),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const addPaymentProofAsync = async (data) => {
    try {
        return await request(`${API_BASE}/api/payment/add-payment-proof`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const getPaymentsAsync = async (
    page = 1,
    pageSize = 5,
    projectId = null,
    organizationId = null,
    startDate = null,
    endDate = null,
    stripeStatus = null
) => {
    try {
        return await request(
            `${API_BASE}/api/payment?page=${page}&pageSize=${pageSize}${projectId ? "&projectId=" + projectId : ""}${
                organizationId ? "&organizationId=" + organizationId : ""
            }${stripeStatus ? "&stripeStatus=" + stripeStatus : ""}${startDate ? "&startDate=" + startDate : ""}${
                endDate ? "&endDate=" + endDate : ""
            }`
        );
    } catch (error) {
        generateErrorToast(error);
    }
};

export const verifyPaymentAsync = async (projectId) => {
    try {
        return await request(`${API_BASE}/api/payment/${projectId}/validate-payment`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const rejectPaymentAsync = async (projectId) => {
    try {
        return await request(`${API_BASE}/api/payment/${projectId}/reject-payment`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
        });
    } catch (error) {
        generateErrorToast(error);
    }
};
