import { generateErrorToast } from "../base/js/httpError";
import { API_BASE, request } from "../utils/httpRequest";

export const addProjectAsync = async (data) => {
    try {
        return await request(`${API_BASE}/api/project`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const updateProjectAsync = async (data, projectId) => {
    try {
        return await request(`${API_BASE}/api/project/${projectId}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const addProjectCreatorAsync = async (id, userId) => {
    try {
        return await request(`${API_BASE}/api/project/${id}/add-creator`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(userId),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const getProjectAsync = async (id, resume = false) => {
    try {
        return await request(`${API_BASE}/api/project/${id}${resume ? "?resume=true" : ""}`);
    } catch (error) {
        generateErrorToast(error);
    }
};

export const finishProjectCreationAsync = async (id) => {
    try {
        return await request(`${API_BASE}/api/project/finish-creation/${id}`, {
            method: "POST",
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const setHiringManagerAsync = async (id, hiringManagerId) => {
    try {
        return await request(`${API_BASE}/api/project/${id}/set-hiring-manager`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ id: hiringManagerId }),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const setTechnicalAccountManagerAsync = async (id, technicalAccountManagerId) => {
    try {
        return await request(`${API_BASE}/api/project/${id}/set-technical-account-manager`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ id: technicalAccountManagerId }),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const setAllResourcesAllocated = async (projectId) => {
    try {
        return await request(`${API_BASE}/api/project/${projectId}/all-resources-allocated`, {
            method: "POST",
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const getProjectsListAsync = async (page = 1, pageSize = 5, status = [], searchText = null) => {
    try {
        return await request(
            `${API_BASE}/api/project?page=${page}&pageSize=${pageSize}${
                status.length > 0 ? "&status=" + status.join(",") : ""
            }${searchText ? "&searchText=" + searchText : ""}`
        );
    } catch (error) {
        generateErrorToast(error);
    }
};

export const getProjectsByUserAsync = async (
    page = 1,
    pageSize = 5,
    startDate = null,
    endDate = null,
    searchText = null
) => {
    try {
        return await request(
            `${API_BASE}/api/project/projects-by-user?page=${page}&pageSize=${pageSize}${
                startDate ? "&startDate=" + startDate : ""
            }${endDate ? "&endDate=" + endDate : ""}${searchText ? "&searchText=" + searchText : ""}`
        );
    } catch (error) {
        generateErrorToast(error);
    }
};

export const rejectAllCandidates = async (projectId) => {
    try {
        return await request(`${API_BASE}/api/project/${projectId}/reject-all-candidates`, {
            method: "POST",
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const cancelProjectAsync = async (id) => {
    try {
        return await request(`${API_BASE}/api/project/${id}/cancel`, {
            method: "POST",
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const removeProject = async (id) => {
    try {
        return await request(`${API_BASE}/api/project/${id}`, {
            method: "DELETE",
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const setTeamConfirmed = async (projectId, rejectedStaff) => {
    try {
        return await request(`${API_BASE}/api/project/${projectId}/team-confirmed`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(rejectedStaff),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const getProjectHistory = async (projectId) => {
    try {
        return await request(`${API_BASE}/api/ProjectHistory/${projectId}`);
    } catch (error) {
        generateErrorToast(error);
    }
};

export const getAllProjectsBasic = async (organizationId = null, onlyWithStaff = false) => {
    try {
        const args = [];

        if (organizationId) {
            args.push(`organizationId=${organizationId}`);
        }

        if (onlyWithStaff) {
            args.push(`onlyWithStaff=${onlyWithStaff}`);
        }

        return await request(`${API_BASE}/api/project/all${args ? "?" + args.join("&") : ""}`);
    } catch (error) {
        generateErrorToast(error);
    }
};

export const getProjectsBasicByOrganization = async (organizationId, onlyWithStaff = false) => {
    try {
        return await request(
            `${API_BASE}/api/project/by-organization/${organizationId}?onlyWithStaff=${onlyWithStaff}`
        );
    } catch (error) {
        generateErrorToast(error);
    }
};

export const verifyProjectAsync = async (projectId) => {
    try {
        return await request(`${API_BASE}/api/project/${projectId}/validate-project`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const rejectProjectAsync = async (projectId, cause) => {
    try {
        return await request(`${API_BASE}/api/project/${projectId}/reject-project`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ cause: cause }),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};
