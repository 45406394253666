import { useRef } from "react";

function useFirstRender() {
    const ref = useRef(true);

    // Catch the previous value before updating it
    const firstRender = ref.current;
    // Update the reference for the next render
    ref.current = false;

    // Return the cached result
    return firstRender;
}

export default useFirstRender;
