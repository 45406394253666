import { format, formatISO, parseISO } from "date-fns";
import { lazy, Suspense, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DATE_FORMAT_ALTERNATIVE, ProjectStatusIds } from "../../base/js/constants";
import Routes from "../../base/js/routes";
import { getProjectsByUserAsync, removeProject } from "../../services/ProjectService";
import Button, { variants as btnVariants } from "../common/Button";
import styles from "./ManageTeams.module.scss";
import Table from "./table/Table";

const ConfirmProjectDeleteModal = lazy(() =>
    import("../common/confirm-project-delete-modal/ConfirmProjectDeleteModal")
);

const TABLE_HEADERS = ["", "name", "createdAt", "status", ""];
const ROWS_PER_PAGE = 5;

const ManageTeams = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const username = useSelector((state) => state.user.username);
    const [activeProject, setActiveProject] = useState(null);
    const [reloadData, setReloadData] = useState(false);
    const [projects, setProjects] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
    const [tableFilters, setTableFilters] = useState({ searchText: null, startDate: null, endDate: null });
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    // Fetch projects from API
    useEffect(() => {
        (async () => {
            try {
                const responseData = await getProjectsByUserAsync(
                    currentPage,
                    rowsPerPage,
                    tableFilters.startDate ? formatISO(tableFilters.startDate) : null,
                    tableFilters.endDate ? formatISO(tableFilters.endDate) : null,
                    tableFilters.searchText
                );

                if (responseData) {
                    const data = responseData.projects?.map((project) => {
                        const handleEditProjectAction = (e) => {
                            e.preventDefault();
                            navigate(Routes.BUILD_YOUR_TEAM + `?from=${project.id}`);
                        };

                        const handleManageProjectAction = (e) => {
                            e.preventDefault();

                            if (
                                project.projectStatus[project.projectStatus.length - 1].status.id ===
                                    ProjectStatusIds.IN_DEVELOPMENT ||
                                project.projectStatus[project.projectStatus.length - 1].status.id ===
                                    ProjectStatusIds.TEAM_CONFIRMED
                            ) {
                                navigate(Routes.buildInDevelopmentPath(project.id));
                                return;
                            }

                            navigate(Routes.buildTeamInfoResumePath(project.id));
                        };

                        const handleDeleteProjectAction = (e) => {
                            e.preventDefault();
                            setActiveProject(project.id);
                            setShowConfirmationModal(true);
                        };

                        // const handleDetailsProjectAction = (e) => {
                        //     e.preventDefault();
                        // };

                        const handleValidateHoursAction = (e) => {
                            e.preventDefault();
                            navigate(Routes.buildValidateHoursPath(project.id));
                        };

                        const generateDefaultActions = () => {
                            let actions = [];

                            if (
                                project.projectStatus[0].status.id === ProjectStatusIds.CREATING_NEW_PROJECT ||
                                project.projectStatus[0].status.id === ProjectStatusIds.STARTING_SELECTION
                            ) {
                                actions.push({
                                    label: "edit",
                                    action: handleEditProjectAction,
                                    color: "green",
                                });
                            }

                            if (
                                project.projectStatus[0].status.id !== ProjectStatusIds.CREATING_NEW_PROJECT &&
                                project.projectStatus[0].status.id !== ProjectStatusIds.STARTING_SELECTION &&
                                project.projectStatus[0].status.id !== ProjectStatusIds.CANCELLED
                            ) {
                                actions.push({
                                    label: "manage",
                                    action: handleManageProjectAction,
                                    color: "green",
                                });
                            }

                            //Commented functionality until design delivered
                            // if (project.projectStatus[0].status.id === PROJECT_STATUS_IDS.CANCELLED) {
                            //     actions.push({
                            //         label: "details",
                            //         action: handleDetailsProjectAction,
                            //         color: "green",
                            //     });
                            // }

                            if (
                                project.projectStatus[0].status.id === ProjectStatusIds.FINISHED ||
                                project.projectStatus[0].status.id === ProjectStatusIds.CANCELLED
                            ) {
                                actions.push({
                                    label: "delete",
                                    action: handleDeleteProjectAction,
                                    color: "red",
                                });
                            }

                            if (
                                project.projectStatus[project.projectStatus.length - 1].status.id ===
                                    ProjectStatusIds.IN_DEVELOPMENT &&
                                project.hasValidationHoursPending
                            ) {
                                actions.push({
                                    label: "validateHours",
                                    action: handleValidateHoursAction,
                                    color: "green",
                                });
                            }

                            return actions;
                        };

                        return {
                            pendingConfirmation: project.hasValidationHoursPending,
                            name: project.name,
                            date: format(parseISO(project.createdAt), DATE_FORMAT_ALTERNATIVE),
                            status: project.projectStatus[0].status.value,
                            actions: generateDefaultActions(),
                        };
                    });

                    setProjects(data);
                    setTotalRows(responseData.total);
                }
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            }
        })();
    }, [setProjects, currentPage, tableFilters, rowsPerPage, reloadData]);

    const handleBack = () => {
        navigate(Routes.HOME);
    };

    const handleOnPageChange = (page) => {
        setCurrentPage(page);
    };

    const handleOnFilterChange = (newFilters) => {
        setTableFilters(newFilters);
    };

    const handleOnRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setCurrentPage(1);
    };

    const handleAcceptDeleteProject = async () => {
        try {
            const response = await removeProject(activeProject);

            if (response) {
                toast.success(t("removeProjectSuccess"));
                setShowConfirmationModal(false);
                setReloadData(!reloadData);
            }
        } catch (e) {
            toast.error(t("unexpectedError") + ": " + e.message);
        }
    };

    const handleCancelDeleteProject = () => {
        setShowConfirmationModal(false);
    };

    return (
        <main className={styles.mainContainer}>
            <section className={styles.container}>
                <h3 className={styles.title}>{t("manageTeams")}</h3>

                <Table
                    headers={TABLE_HEADERS}
                    rows={projects}
                    totalRows={totalRows}
                    currentPage={currentPage}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleOnPageChange}
                    onRowsPerPageChange={handleOnRowsPerPageChange}
                    onFilterChange={handleOnFilterChange}
                />

                {/* We don't want to show something when the component is being loaded
                 because it would make no sense to show a loading indicator of a 
                 component that has not been shown yet. */}
                <Suspense fallback={<div style={{ display: "none" }}>Loading modal...</div>}>
                    <ConfirmProjectDeleteModal
                        show={showConfirmationModal}
                        onAccept={handleAcceptDeleteProject}
                        onCancel={handleCancelDeleteProject}
                    />
                </Suspense>
            </section>

            <Button cssClasses={[styles.btnBack]} variant={btnVariants.PRIMARY_INVERSE} onClick={handleBack}>
                {t("back")}
            </Button>
        </main>
    );
};

export default ManageTeams;
