import { generateErrorToast } from "../base/js/httpError";
import { API_BASE, request } from "../utils/httpRequest";

export const getInterviewRequestAsync = async (id) => {
    try {
        return await request(API_BASE + "/api/interviewrequest/" + id);
    } catch (error) {
        generateErrorToast(error);
    }
};

export const getInterviewRequestListAsync = async (page = 1, pageSize = 5, searchText = null) => {
    try {
        return await request(
            API_BASE +
                `/api/interviewrequest?page=${page}&pageSize=${pageSize}${
                    searchText ? "&searchText=" + searchText : ""
                }`,
            {
                method: "GET",
            }
        );
    } catch (error) {
        generateErrorToast(error);
    }
};
