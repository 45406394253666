import { generateErrorToast } from "../base/js/httpError";
import { API_BASE, request } from "../utils/httpRequest";

export const getTDBInformation = async () => {
    try {
        return await request(`${API_BASE}/api/tdbInformation`);
    } catch (e) {
        generateErrorToast(e);
    }
};

export const updateTDBInformation = async (tdbInformationCollection) => {
    try {
        return await request(`${API_BASE}/api/tdbInformation`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(tdbInformationCollection),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};
