import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./BtnUp.module.scss";

const BtnUp = () => {
    const onClick = () => {
        window.scrollTo(0, 0);
    };

    return (
        <section className="d-flex justify-content-end mt-4 d-block d-md-none d-lg-none">
            <div className={`${styles.btnUp} d-flex justify-content-center align-items-center`} onClick={onClick}>
                <FontAwesomeIcon icon={faChevronUp} />
            </div>
        </section>
    );
};

export default BtnUp;
