import Joi from "joi";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { ModalButtonVariant, TABLE_ROWS_LIMITS } from "../../../base/js/constants";
import {
    createDevelopmentRole,
    getRolesWithPagingAsync,
    removeDevelopmentRole,
    updateDevelopmentRole
} from "../../../services/RoleService";
import ErrorMessages from "../../common/error-messages/ErrorMessages";
import Input from "../../common/forms/input/Input";
import Label from "../../common/forms/label/Label";
import ModalButton from "../../common/modal/buttons/ModalButton";
import Modal from "../../common/modal/Modal";
import Paginator from "../../common/paginator/Paginator";
import useJoiValidation from "../../hooks/UseJoiValidation";
import { scrollToTop } from "../../hooks/UseScrollToTop";
import Loading from "../../loading/Loading";
import AdminTable from "../admin-tables/AdminTable";
import style from "./AdminDevelopmentRoles.module.scss";

const TABLE_HEADERS = ["name", "description", ""];

const DEFAULT_FORM_STATUS = { name: "", description: "" };

const SCHEMA = {
    name: Joi.string().required(),
    description: Joi.string().required(),
};

const ERROR_MESSAGES = {
    "string.empty": "requiredFieldIsEmpty",
};

const EDITOR_MODE = {
    Add: 1,
    Edit: 2,
};

const AdminDevelopmentRoles = () => {
    const { t, i18n } = useTranslation();
    const { validateSubSchemaFromEvent, validateSchema, errors } = useJoiValidation();

    // States related with the table
    const [activePage, setActivePage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchText, setSearchText] = useState("");
    const [showForm, setShowForm] = useState(false);
    const [formData, setFormData] = useState(DEFAULT_FORM_STATUS);
    const [loading, setLoading] = useState(false);
    const [editorMode, setEditorMode] = useState(EDITOR_MODE.Add);
    const [deleteModal, setDeleteModal] = useState(null);

    // State containing development roles
    const [developmentRolesData, setDevelopmentRolesData] = useState({ total: 0, developmentRoles: [] });

    const handleEditDevelopmentRole = (role) => {   
        return (event) => {
            event.preventDefault();

            setEditorMode(EDITOR_MODE.Edit);
            setShowForm(true);
            setFormData(role);
            scrollToTop();
        };
    };

    const handleDeleteDevelopmentRole = (role) => {
        return async (event) => {
            event.preventDefault();

            setDeleteModal(role);
        };
    };

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    const updateRolesList = async () => {
        try {
            const data = await getRolesWithPagingAsync(activePage, rowsPerPage, searchText);

            if (data) {
                const mappedData = data.developmentRoles.map((dr) => {
                    return {
                        ...dr,
                        actions: [
                            {
                                label: "edit",
                                action: handleEditDevelopmentRole(dr),
                                color: "",
                            },
                            {
                                label: "delete",
                                action: handleDeleteDevelopmentRole(dr),
                                color: "",
                            },
                        ],
                    };
                });

                setDevelopmentRolesData({ total: data.total, developmentRoles: mappedData });
            }
        } catch (e) {
            toast.error(t("unexpectedError") + ": " + e.message);
        }
    };

    // Update development role list
    useEffect(() => {
        updateRolesList()
    }, [rowsPerPage, activePage]);

    // Update development role on search text update
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            updateRolesList();
        }, 1000);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [searchText]);

    const handleChangePerPage = (event) => {
        setRowsPerPage(event.target.value);
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        validateSchema(SCHEMA, ERROR_MESSAGES, formData);

        if (!formData.name || !formData.description) {
            return;
        }

        try {
            setLoading(true);

            const response =
                editorMode === EDITOR_MODE.Add
                    ? await createDevelopmentRole(formData)
                    : // If we're editing, we have an ID to identify the development role
                      await updateDevelopmentRole(formData.id, formData);
            
            toast.success(editorMode === EDITOR_MODE.Add ? t("developmentRoleCreated") : t("developmentRoleUpdated"));
            setFormData(DEFAULT_FORM_STATUS);

            // If the user was editing a development role, we close the form since it won't be able of edit nothing else
            // (the form will be empty).
            if (editorMode === EDITOR_MODE.Edit) {
                setShowForm(false);
            }

            await updateRolesList();
            
        } catch (error) {
            console.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleBtnAddClick = () => {
        setEditorMode(EDITOR_MODE.Add);
        setShowForm(true);
    };

    const handleFormCancel = () => {
        setFormData(DEFAULT_FORM_STATUS);
        setShowForm(false);
    };

    const handleInputChange = (event) => {
        setFormData((prevState) => {
            return {
                ...prevState,
                [event.target.name]: event.target.value,
            };
        });

        validateSubSchemaFromEvent(SCHEMA, ERROR_MESSAGES, event);
    };

    const handleDeleteModalCancel = () => {
        setDeleteModal(null);
    };

    const handleDeleteModalContinue = async () => {
        try {
            setLoading(true);

            const response = await removeDevelopmentRole(deleteModal?.id);
            
            toast.success(t("developmentRoleDeleted"));
            setDeleteModal(null);

            await updateRolesList();
            
        } catch (error) {
            console.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className={style.container}>
                {/* Filters (with add button) */}
                <div className={`${style.filtersContainer} w-100 d-flex align-items-center justify-content-between`}>
                    <div className="d-flex flex-column w-100">
                        <div className={`${style.search} d-flex align-items-center gap-3`}>
                            <Label htmlFor="search">{t("search")}</Label>
                            <Input
                                id="search"
                                name="search"
                                className="px-2 w-25"
                                value={searchText}
                                onChange={handleSearchChange}
                            />
                        </div>
                    </div>

                    <div className="d-flex gap-2 mx-3">
                        <button
                            className={`${style.btnAdd} btn btn-success text-uppercase`}
                            disabled={showForm}
                            onClick={handleBtnAddClick}
                        >
                            {showForm ? (editorMode === EDITOR_MODE.Add ? t("adding") : t("editing")) : t("add")}
                        </button>

                        <div className={style.totalPerPage}>
                            <select
                                id="select-limit"
                                className="py-1 px-2"
                                defaultValue={TABLE_ROWS_LIMITS[0]}
                                onChange={handleChangePerPage}
                            >
                                {TABLE_ROWS_LIMITS.map((rowLimit, index) => {
                                    return (
                                        <option key={index} value={rowLimit}>
                                            {rowLimit}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                </div>

                {/* Form */}
                {showForm && (
                    <div className={style.formContainer}>
                        <form onSubmit={handleFormSubmit} className="py-3">
                            <div className="d-flex m-3 flex-column gap-5">
                                <div className={style.formRow}>
                                    <div className={style.fieldContainer}>
                                        <Label htmlFor="input-name" requiredIndicator>
                                            {t("name")}
                                        </Label>
                                        <Input
                                            id="input-name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleInputChange}
                                            invalid={!!errors.name}
                                        />
                                        {errors.name && <ErrorMessages errors={[errors.name.message]} />}
                                    </div>
                                    <div className={style.fieldContainer}>
                                        <Label htmlFor="input-description" requiredIndicator>
                                            {t("description")}
                                        </Label>
                                        <Input
                                            id="input-description"
                                            name="description"
                                            value={formData.description}
                                            onChange={handleInputChange}
                                            invalid={!!errors.description}
                                        />
                                        {errors.description && <ErrorMessages errors={[errors.description.message]} />}
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex mt-4 mx-3 justify-content-end gap-3">
                                <button
                                    type="reset"
                                    className="btn btn-danger text-uppercase"
                                    onClick={handleFormCancel}
                                >
                                    {t("cancel")}
                                </button>
                                <button type="submit" className="btn btn-success text-uppercase">
                                    {editorMode === EDITOR_MODE.Add ? t("create") : t("update")}
                                </button>
                            </div>
                        </form>
                    </div>
                )}

                {/* Table */}
                <div className="w-100">
                    <AdminTable
                        headers={TABLE_HEADERS}
                        data={developmentRolesData.developmentRoles.map((role) => ({
                            name: role.name,
                            description: role.description,
                            actions: role.actions,
                        }))}
                    />

                    <Paginator
                        setPage={setActivePage}
                        activePage={activePage}
                        lastPage={Math.ceil(developmentRolesData.total ? developmentRolesData.total / rowsPerPage : 1)}
                    />
                </div>
            </div>

            {loading && <Loading />}

            <Modal show={!!deleteModal}>
                <p className={`${style.modalTitle} text-center`}>{`${i18n.resolvedLanguage === "es" ? "¿" : ""}${t(
                    "areYouSureToDelete"
                )} "${deleteModal?.name || ""}"?`}</p>

                <div className="d-flex justify-content-evenly">
                    <ModalButton onClick={handleDeleteModalCancel} variant={ModalButtonVariant.WHITE}>
                        {t("cancel")}
                    </ModalButton>
                    <ModalButton onClick={handleDeleteModalContinue} variant={ModalButtonVariant.RED}>
                        {t("delete")}
                    </ModalButton>
                </div>
            </Modal>
        </>
    );
};

export default AdminDevelopmentRoles;
