export const buildAction = (label, color, callback, id) => ({
    label,
    action: (e) => {
        e.preventDefault();
        callback(id);
    },
    color,
});

export const onEditAction = (callback, id) => buildAction("edit", "green", callback, id);

export const onRemoveAction = (callback, id) => buildAction("delete", "red", callback, id);

export const onRestoreAction = (callback, id) => buildAction("restore", "green", callback, id);
