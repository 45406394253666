import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import Selector from "react-select";
import { ModalButtonVariant } from "../../../base/js/constants";
import { getUsersByUserRole } from "../../../services/AdminService";
import ModalButton from "../../common/modal/buttons/ModalButton";
import Modal from "../../common/modal/Modal";
import { getSelectorStyle } from "../../shared-styles/StylesFunctions";
import style from "./AddCreatorModal.module.scss";

const selectorStyle = getSelectorStyle(false);

const AddCreatorModal = ({ show, onAccept, onCancel }) => {
    const { t } = useTranslation();

    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedUser, setSelectedUser] = useState(null);

    const onAcceptBtnClick = () => {
        onAccept(selectedUser);
    };

    // Fetch users' list from the API
    useEffect(() => {
        (async () => {
            try {
                const data = await getUsersByUserRole();

                if (data) {
                    const mappedData = data.map((user) => ({ label: user.name, value: user.id }));

                    setUsers(mappedData);
                }
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    return (
        <Modal show={show}>
            <span className={`${style.modalTitle} text-bold d-block text-center`}>{t("addUserCreator")}</span>

            <Selector
                isDisabled={!users.length}
                isLoading={isLoading}
                options={users}
                onChange={setSelectedUser}
                styles={selectorStyle}
                className="mt-3"
            />

            <div className="d-flex justify-content-center gap-4 mt-4">
                <ModalButton variant={ModalButtonVariant.GREEN} onClick={onAcceptBtnClick}>
                    {t("accept")}
                </ModalButton>

                <ModalButton variant={ModalButtonVariant.WHITE} onClick={onCancel}>
                    {t("cancel")}
                </ModalButton>
            </div>
        </Modal>
    );
};

AddCreatorModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onAccept: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default AddCreatorModal;
