import { generateErrorToast } from "../base/js/httpError";
import { API_BASE, request } from "../utils/httpRequest";

export const postContactRequest = async (data) => {
    try {
        return await request(`${API_BASE}/api/contactus`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};
