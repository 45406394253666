import { format, parse, parseISO } from "date-fns";
import Joi from "joi";
import { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import Selector from "react-select";
import { HOURS_VALIDATION_STATUS, TABLE_ROWS_LIMITS } from "../../../base/js/constants";
import {
    addHoursValidationAsync,
    addNoteToHourValidation,
    getHoursValidationByProjectAndPeriodAsync,
    getHoursValidationOverviewAsync,
    sendToClientAsync,
    updateHoursValidationAsync,
} from "../../../services/HourValidationService";
import { getAllOrganizationListAsync } from "../../../services/OrganizationService";
import { getPositionsByProject } from "../../../services/PositionService";
import { getAllProjectsBasic, getProjectsBasicByOrganization } from "../../../services/ProjectService";
import { DATE_FORMAT } from "../../build-your-team/assemble-your-team/constants";
import Input from "../../common/forms/input/Input";
import Label from "../../common/forms/label/Label";
import Paginator from "../../common/paginator/Paginator";
import NotesValidateHoursModal from "../../common/validate-hours/notes-validate-hours-modal/NotesValidateHoursModal";
import TableResume from "../../home-resume/table-resume/TableResume";
import useJoiValidation from "../../hooks/UseJoiValidation";
import Loading from "../../loading/Loading";
import sharedStyle from "../../shared-styles/FormStyle.module.scss";
import { getSelectorStyle } from "../../shared-styles/StylesFunctions";
import AdminTable from "../admin-tables/AdminTable";
import style from "./AdminHoursValidation.module.scss";
import HoursValidationModal from "./hours-validation-modal/HoursValidationModal";

const BASE_SCHEMA = {
    project: Joi.object({
        value: Joi.string().empty("").required(),
        label: Joi.string().empty("").required(),
    })
        .empty(null)
        .required(),
    period: Joi.string().max(50).required(),
};

const SCHEMA_ERROR_MESSAGES = {
    "string.empty": "requiredFieldIsEmpty",
    "string.max": "fieldTooLong",
    "string.min": "****",
    "string.email": "invalidEmailFormat",
};

const HOURS_VALIDATION_ACTIONS = {
    ADD: "adding",
    EDIT: "editing",
};

const DateInput = forwardRef(({ value, onClick, date, isValid }, ref) => (
    <input
        ref={ref}
        id="input-start-date"
        type="text"
        className={`${sharedStyle.inputDate} ${isValid ? "" : sharedStyle.invalidField} px-3 py-2`}
        value={value}
        onClick={onClick}
        readOnly
    />
));

const AdminHoursValidation = () => {
    const { t } = useTranslation();
    const { validateSchema, validateSubSchemaFromEvent, resetErrors, errors } = useJoiValidation();
    const [showForm, setShowForm] = useState(false);
    const [showNotesValidateHoursModal, setShowNotesValidateHoursModal] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [loading, setLoading] = useState(false);
    const [reloadData, setReloadData] = useState(true);
    const [addBtnLabel, setAddBtnLabel] = useState(HOURS_VALIDATION_ACTIONS.ADD);
    const [activePage, setActivePage] = useState(1);
    const [totalPerPage, setTotalPerPage] = useState(5);
    const [organizationList, setOrganizationList] = useState({ organizations: [], loading: false });
    const [organizationSelected, setOrganizationSelected] = useState(null);
    const [projectList, setProjectList] = useState({ projects: [], loading: false });
    const [hoursValidationList, setHoursValidationList] = useState([]);
    const [hoursValidationTotal, setHoursValidationTotal] = useState(0);
    const [projectSelected, setProjectSelected] = useState(null);
    const [period, setPeriod] = useState("");
    const [hoursValidationActiveList, setHoursValidationActiveList] = useState([]);
    const [hoursValidationActive, setHoursValidationActive] = useState([]);
    const [activeNotes, setActiveNotes] = useState([]);
    const [showHoursValidationModal, setShowHoursValidationModal] = useState(false);
    const [editHourActive, setEditHourActive] = useState(null);
    const [activeHourValidationId, setActiveHourValidationId] = useState("");
    const [disableOrganization, setDisableOrganization] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);

                const responseData = await getHoursValidationOverviewAsync(activePage, totalPerPage, searchInput);

                if (responseData) {
                    setHoursValidationList(
                        responseData.projectsOverview.map((projectOverview) => {
                            const handleEditHoursValidation = async (e) => {
                                e.preventDefault();

                                try {
                                    setLoading(true);

                                    const data = await getHoursValidationByProjectAndPeriodAsync(
                                        projectOverview.projectId,
                                        projectOverview.period
                                    );

                                    if (data) {
                                        setHoursValidationActiveList(
                                            data.map((validationActive) => {
                                                const handleHoursValidationEdit = () => {
                                                    setEditHourActive({
                                                        id: validationActive.id,
                                                        staffName: `${validationActive.staff.name} ${validationActive.staff.lastName}`,
                                                        hours: validationActive.hours,
                                                        status: HOURS_VALIDATION_STATUS[validationActive.status]
                                                            ? HOURS_VALIDATION_STATUS[validationActive.status]
                                                            : "",
                                                        notes: validationActive.notes,
                                                    });
                                                    setShowHoursValidationModal(true);
                                                };

                                                const actions = [];
                                                if (
                                                    HOURS_VALIDATION_STATUS[validationActive.status] !=
                                                    HOURS_VALIDATION_STATUS.validated
                                                ) {
                                                    actions.push({
                                                        label: "edit",
                                                        action: handleHoursValidationEdit,
                                                        color: "",
                                                    });
                                                }

                                                return {
                                                    id: validationActive.id,
                                                    staffName: `${validationActive.staff.name} ${validationActive.staff.lastName}`,
                                                    hours: validationActive.hours,
                                                    status: HOURS_VALIDATION_STATUS[validationActive.status]
                                                        ? HOURS_VALIDATION_STATUS[validationActive.status]
                                                        : "",
                                                    notes: validationActive.notes,
                                                    actions: actions,
                                                };
                                            })
                                        );

                                        setAddBtnLabel(HOURS_VALIDATION_ACTIONS.EDIT);
                                        if (data.length) {
                                            setDisableOrganization(true);
                                            setProjectSelected({
                                                label: data[0].project.name,
                                                value: data[0].project.id,
                                            });
                                            setPeriod(format(parseISO(data[0].period), DATE_FORMAT));
                                        }
                                        setShowForm(true);
                                    }
                                } catch (error) {
                                    console.error(error.message);
                                } finally {
                                    setLoading(false);
                                }
                            };

                            const handleSendToClient = async () => {
                                try {
                                    setLoading(true);

                                    await sendToClientAsync({
                                        projectId: projectOverview.projectId,
                                        period: projectOverview.period,
                                    });

                                    toast.success(t("sentToClientSuccessfully"));
                                    setReloadData(!reloadData);
                                } catch (error) {
                                    console.error(error.message);
                                } finally {
                                    setLoading(false);
                                }
                            };

                            const actions = [
                                {
                                    label: "edit",
                                    action: handleEditHoursValidation,
                                    color: "",
                                },
                            ];

                            if (HOURS_VALIDATION_STATUS[projectOverview.status] === HOURS_VALIDATION_STATUS.created) {
                                actions.push({
                                    label: "sendToClient",
                                    action: handleSendToClient,
                                    color: "",
                                });
                            }

                            return {
                                organization: projectOverview.organizationName ? projectOverview.organizationName : "-",
                                project: projectOverview.projectName,
                                period: format(parseISO(projectOverview.period), "MMM - yyyy"),
                                status: HOURS_VALIDATION_STATUS[projectOverview.status]
                                    ? HOURS_VALIDATION_STATUS[projectOverview.status]
                                    : "",
                                hours: projectOverview.totalHours,
                                actions: actions,
                            };
                        })
                    );
                    setHoursValidationTotal(responseData.total);
                }
            } catch (error) {
                console.error(error.message);
            } finally {
                setLoading(false);
            }
        })();
    }, [reloadData, activePage]);

    useEffect(() => {
        (async () => {
            setOrganizationList((prevState) => {
                return {
                    ...prevState,
                    isLoading: true,
                };
            });

            const responseData = await getAllOrganizationListAsync();

            if (responseData) {
                setOrganizationList({
                    isLoading: false,
                    organizations: responseData.map((organization) => {
                        return {
                            value: organization.id,
                            label: organization.name,
                        };
                    }),
                });
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            setProjectList((prevState) => {
                return {
                    ...prevState,
                    isLoading: true,
                };
            });

            const responseData = await getAllProjectsBasic(null, true);

            if (responseData) {
                setProjectList({
                    isLoading: false,
                    projects: responseData.map((project) => {
                        return {
                            value: project.id,
                            label: project.name,
                        };
                    }),
                });
            }
        })();
    }, []);

    useEffect(() => {
        setProjectSelected(null);
        resetErrors();

        (async () => {
            setProjectList((prevState) => {
                return {
                    ...prevState,
                    isLoading: true,
                };
            });

            let responseData = null;

            if (organizationSelected) {
                responseData = await getProjectsBasicByOrganization(organizationSelected.value, true);
            } else {
                responseData = await getAllProjectsBasic(null, true);
            }

            if (responseData) {
                setProjectList({
                    isLoading: false,
                    projects: responseData.map((project) => {
                        return {
                            value: project.id,
                            label: project.name,
                        };
                    }),
                });
            }
        })();
    }, [organizationSelected]);

    useEffect(() => {
        (async () => {
            if (projectSelected && addBtnLabel === HOURS_VALIDATION_ACTIONS.ADD) {
                const responseData = await getPositionsByProject(projectSelected.value);

                if (responseData) {
                    setHoursValidationActiveList(
                        responseData.map((position) => {
                            const handleHoursValidationEdit = () => {
                                setEditHourActive({
                                    id: position.id,
                                    staffName: `${
                                        position.positionStaffs[position.positionStaffs.length - 1].staff.name
                                    } ${position.positionStaffs[position.positionStaffs.length - 1].staff.lastName}`,
                                    hours: 0,
                                    status: HOURS_VALIDATION_STATUS.creating,
                                });
                                setShowHoursValidationModal(true);
                            };

                            return {
                                id: position.id,
                                staffId: position.positionStaffs[position.positionStaffs.length - 1].staff.id,
                                staffName: `${position.positionStaffs[position.positionStaffs.length - 1].staff.name} ${
                                    position.positionStaffs[position.positionStaffs.length - 1].staff.lastName
                                }`,
                                hours: 0,
                                status: HOURS_VALIDATION_STATUS.creating,
                                notes: [],
                                actions: [
                                    {
                                        label: "edit",
                                        action: handleHoursValidationEdit,
                                        color: "green",
                                    },
                                ],
                            };
                        })
                    );
                }
            }
        })();
    }, [projectSelected]);

    useEffect(() => {
        setHoursValidationActive(
            hoursValidationActiveList.map((validationActive) => {
                return {
                    id: validationActive.id,
                    staffName: validationActive.staffName,
                    hours: validationActive.hours,
                    status: validationActive.status,
                    notes: validationActive.notes,
                    actions: validationActive.actions,
                };
            })
        );
    }, [hoursValidationActiveList]);

    const handleInputChange = (e) => {
        e.preventDefault();
        setSearchInput(e.target.value);
    };

    const handleChangePerPage = (event) => {
        setActivePage(1);
        setTotalPerPage(event.target.value);
    };

    const handleClickAdd = () => {
        setShowForm(true);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        switch (addBtnLabel) {
            case HOURS_VALIDATION_ACTIONS.ADD:
                const formData = {
                    project: projectSelected,
                    period: period,
                };

                let cantErrors = validateSchema(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, formData);

                if (!!cantErrors) {
                    return;
                }
                await handleFormSubmitAdd();
                break;
            case HOURS_VALIDATION_ACTIONS.EDIT:
                await handleFormSubmitEdit();
                break;
        }
    };

    const handleFormSubmitAdd = async () => {
        try {
            const dataToSend = hoursValidationActiveList.map((hourValidation) => {
                return {
                    projectId: projectSelected.value,
                    staffId: hourValidation.staffId,
                    hours: parseInt(hourValidation.hours),
                    period: period,
                    status: HOURS_VALIDATION_STATUS.created.toLowerCase(),
                };
            });

            setLoading(true);

            const response = await addHoursValidationAsync(dataToSend);

            if (response) {
                toast.success(t("hoursValidationSuccessfullyCreated"));

                handleFormCancel();
                setReloadData(!reloadData);
            }
        } catch (e) {
            console.error(e.message);
        } finally {
            setLoading(false);
        }
    };

    const handleFormSubmitEdit = async () => {
        try {
            const dataToSend = hoursValidationActiveList.map((hourValidation) => {
                return {
                    id: hourValidation.id,
                    hours: parseInt(hourValidation.hours),
                    period: period,
                };
            });

            setLoading(true);

            await updateHoursValidationAsync(dataToSend);

            toast.success(t("hoursValidationSuccessfullyUpdated"));

            handleFormCancel();
            setReloadData(!reloadData);
        } catch (e) {
            console.error(e.message);
        } finally {
            setLoading(false);
        }
    };

    const getMinDate = () => {
        const date = new Date();
        const month = date.getMonth();
        const year = date.getFullYear();
        return new Date(year, month, 1);
    };

    const clearForm = () => {
        setProjectSelected(null);
        setOrganizationSelected(null);
        setPeriod("");
        setDisableOrganization(false);
        setHoursValidationActiveList([]);
        resetErrors();
    };

    const handleFormCancel = () => {
        setShowForm(false);
        setAddBtnLabel(HOURS_VALIDATION_ACTIONS.ADD);
        clearForm();
    };

    const handleOrganizationChange = (selectedOrganization) => {
        setOrganizationSelected(selectedOrganization);
    };

    const handleProjectChange = (selectedProject) => {
        setProjectSelected(selectedProject);
        validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, {
            target: { id: "project", value: selectedProject, name: "project" },
        });
    };

    const onStartDateChange = (date) => {
        setPeriod(format(date, DATE_FORMAT));
        validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, {
            target: { id: "period", value: format(date, DATE_FORMAT), name: "period" },
        });
    };

    const handleClickAddNote = (hourValidationId, notes) => {
        setActiveNotes(notes);
        setActiveHourValidationId(hourValidationId);
        setShowNotesValidateHoursModal(true);
    };

    const handleCloseNotesModal = () => {
        setShowNotesValidateHoursModal(false);
    };

    const handleHoursValidationCancel = () => {
        setShowHoursValidationModal(false);
    };

    const handleHoursValidationAccept = (newData) => {
        setShowHoursValidationModal(false);
        let data = hoursValidationActiveList.map((item) => {
            if (item.id === newData.id) {
                return { ...item, hours: newData.hours };
            }

            return item;
        });

        setHoursValidationActiveList(data);
    };

    const handleSubmitNote = async (hourValidationId, newNote) => {
        try {
            setLoading(true);

            const dataToSend = {
                validationHourId: activeHourValidationId,
                note: newNote,
            };

            const response = await addNoteToHourValidation(dataToSend);

            if (response) {
                toast.success(t("noteAddedSuccessfully"));
                setReloadData(!reloadData);
            }
        } catch (error) {
            console.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className={style.filtersContainer}>
                <div className={style.searchContainer}>
                    <div className={style.search}>
                        <Input
                            id="search"
                            name="search"
                            className="px-2"
                            value={searchInput}
                            onChange={handleInputChange}
                            placeholder={`${t("search")}...`}
                        />
                    </div>
                </div>
                <div className="d-flex gap-2 mx-3">
                    <button className={`${style.btnAdd} btn btn-success`} disabled={showForm} onClick={handleClickAdd}>
                        {showForm ? t(addBtnLabel).toUpperCase() : t("add").toUpperCase()}
                    </button>
                    <div className={style.totalPerPage}>
                        <select
                            id="select-limit"
                            className="py-1 px-2"
                            defaultValue={totalPerPage}
                            onChange={handleChangePerPage}
                        >
                            {TABLE_ROWS_LIMITS.map((rowLimit, index) => {
                                return (
                                    <option key={index} value={rowLimit}>
                                        {rowLimit}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
            </div>

            <div className={`${style.contentContainer} mx-auto`}>
                {showForm && (
                    <div className={style.formContainer}>
                        <form onSubmit={handleFormSubmit} className="py-3">
                            <div className="d-flex m-3 flex-column gap-5">
                                <div className={style.formRow}>
                                    <div className={style.fieldContainer}>
                                        <Label htmlFor="organization">{t("organization")}</Label>
                                        <Selector
                                            name="organization"
                                            isDisabled={!organizationList.organizations.length || disableOrganization}
                                            isLoading={organizationList.isLoading}
                                            options={organizationList.organizations}
                                            value={organizationSelected}
                                            onChange={handleOrganizationChange}
                                            styles={getSelectorStyle(!!errors.organization)}
                                            isClearable
                                        />
                                        {errors.organization && (
                                            <div className="mt-1">
                                                <p className={sharedStyle.errorMsg}>{errors.organization.message}</p>
                                            </div>
                                        )}
                                    </div>
                                    <div className={style.fieldContainer}>
                                        <Label htmlFor="project" requiredIndicator>
                                            {t("project")}
                                        </Label>
                                        <Selector
                                            name="project"
                                            isDisabled={
                                                !projectList.projects.length ||
                                                addBtnLabel === HOURS_VALIDATION_ACTIONS.EDIT
                                            }
                                            isLoading={projectList.isLoading}
                                            options={projectList.projects}
                                            value={projectSelected}
                                            onChange={handleProjectChange}
                                            styles={getSelectorStyle(!!errors.project)}
                                            isClearable
                                        />
                                        {errors.project && (
                                            <div className="mt-1">
                                                <p className={sharedStyle.errorMsg}>{errors.project.message}</p>
                                            </div>
                                        )}
                                    </div>
                                    <div className={style.fieldContainer}>
                                        <Label htmlFor="period" requiredIndicator>
                                            {t("period")}
                                        </Label>
                                        <DatePicker
                                            id="period"
                                            selected={period ? parse(period, DATE_FORMAT, new Date()) : null}
                                            onChange={onStartDateChange}
                                            customInput={<DateInput startDate={period} isValid={!errors.period} />}
                                            dateFormat={"MMM - yyyy"}
                                            showMonthYearPicker
                                            minDate={getMinDate()}
                                            showPopperArrow={false}
                                        />
                                        {errors.period && (
                                            <div className="mt-1">
                                                <p className={sharedStyle.errorMsg}>{errors.period.message}</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className={style.formRow}>
                                    <div className={style.tableContainer}>
                                        <TableResume
                                            header={["staff", "hours", "status", "notes", "actions"]}
                                            data={hoursValidationActive}
                                            showFootContent={false}
                                            handleClickNotes={handleClickAddNote}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex m-3 justify-content-end gap-3">
                                <button
                                    type="reset"
                                    className="btn btn-danger text-uppercase"
                                    onClick={handleFormCancel}
                                >
                                    {t("cancel")}
                                </button>
                                <button type="submit" className="btn btn-success text-uppercase">
                                    {t("save")}
                                </button>
                            </div>
                        </form>
                    </div>
                )}
                <AdminTable
                    headers={["organization", "project", "period", "status", "hours", "actions"]}
                    data={hoursValidationList}
                />
                <div className={style.paginatorContainer}>
                    <Paginator
                        setPage={setActivePage}
                        activePage={activePage}
                        lastPage={Math.ceil(hoursValidationTotal ? hoursValidationTotal / totalPerPage : 1)}
                    />
                </div>
            </div>

            {loading && <Loading />}
            <NotesValidateHoursModal
                notes={activeNotes}
                onClose={handleCloseNotesModal}
                show={showNotesValidateHoursModal}
                validationHourId={activeHourValidationId}
                onSubmitNote={handleSubmitNote}
            />
            <HoursValidationModal
                show={showHoursValidationModal}
                data={editHourActive}
                onAccept={handleHoursValidationAccept}
                onCancel={handleHoursValidationCancel}
            />
        </>
    );
};

export default AdminHoursValidation;
