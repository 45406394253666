import { lazy, Suspense, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import colors from "../../../../base/js/colors";
import { getBusinessSectorsAsync } from "../../../../services/BusinessSectorService";
import { setSkills, setTeamArea } from "../../../../store/slices/assembleYourTeamSlice";
import Input from "../../../common/forms/input/Input";
import Label from "../../../common/forms/label/Label";
import Loading from "../../../loading/Loading";
import sharedStyle from "../../../shared-styles/FormStyle.module.scss";
import { getSelectorStyle } from "../../../shared-styles/StylesFunctions";
import BYTContext from "../../BYTContext";
import { ALLOWED_TYPE_NAMES, ContainerType } from "../constants";

const InputFile = lazy(() => import("../../../common/custom-input-file/CustomInputFile"));
const SkillsSelector = lazy(() => import("../skills-selector/SkillsSelector"));

const selectorStyle = {
    control: (provided, state) => ({
        ...provided,
        borderRadius: "8px",
        minHeight: "40px",
        boxShadow: "none",
        border: `${state.isFocused ? 2 : 1}px solid ${state.isFocused ? colors.GRAY550 : colors.GRAY350}`,
        ":hover": {
            borderColor: colors.GRAY550,
        },
    }),
};

export const FormElementsIds = {
    TEAM_NAME: "input-team-name",
    TEAM_AREA: "select-team-area",
    PROJECT_TYPE: "select-project-type",
    ADDITIONAL_COMMENTS: "text-area-additional-comments",
    FILE_ATTACH: "input-file-attach",
    DRAG_AND_DROP: "ayt-drag-and-drop",
    INPUT_CUSTOM_MANDATORY_SKILL: "input-custom-mandatory-skill",
    INPUT_CUSTOM_DESIRED_SKILL: "input-custom-desired-skill",
    TIMEZONE: "input-timezone",
};

const getBusinessSectorsByLanguage = (rowBusinessSectors, language) => {
    return rowBusinessSectors.map((sector) => ({
        value: sector.id,
        label: language === "en" ? sector.sectorEn : sector.sectorEs,
    }));
};

const SecondSection = ({
    teamNameState,
    // teamAreasState,
    // projectTypesState,
    additionalCommentsState,
    attachmentState,
    timezoneState,
    onChange,
    errors,
    validateSubSchemaFromEvent,
    validationSchema,
    schemaErrorMessages,
}) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { timezones, technologies } = useContext(BYTContext);

    const [rawBusinessSectors, setRawBusinessSectors] = useState([]);
    const [businessSectors, setBusinessSectors] = useState(
        getBusinessSectorsByLanguage(rawBusinessSectors, i18n.language)
    );
    const selectedBusinessSectors = useSelector((state) => state.assembleYourTeam.teamArea);
    const skills = useSelector((state) => state.assembleYourTeam.skills);

    // Fetch initial data from the API
    useEffect(() => {
        // Do not try to parse the data unless all the dependencies have been met
        if (technologies.loading) {
            return;
        }

        // Load business sector data
        (async () => {
            let businessSectors = [];
            const response = await getBusinessSectorsAsync();
            if (response) {
                businessSectors = response ?? [];

                setRawBusinessSectors(businessSectors);
                setBusinessSectors(getBusinessSectorsByLanguage(businessSectors, i18n.language));
            }
        })();

        // Load technologies data
        (async () => {
            const technologiesTemp = technologies.data.map((skill) => {
                const prevSkillState = skills?.find((s) => s.key === skill.key);

                return {
                    key: skill.key,
                    name: skill.name,
                    custom: false,
                    // If the skill is already on Redux, we restore its container
                    container: prevSkillState ? prevSkillState.container : ContainerType.AVAILABLE_SKILLS,
                    alias: skill.alias,
                };
            });

            dispatch(setSkills(technologiesTemp));
        })();
    }, [technologies]);

    // Reload business sectors when the language changes
    useEffect(() => {
        setBusinessSectors(getBusinessSectorsByLanguage(rawBusinessSectors, i18n.language));

        const updatedItems = [];

        // Translate the selected items to the selected language
        selectedBusinessSectors?.forEach((item) => {
            const element = rawBusinessSectors?.find((bs) => bs.id === item.value);
            if (!element) {
                return;
            }

            updatedItems.push({
                value: element.id,
                label: i18n.language === "en" ? element.sectorEn : element.sectorEs,
            });
        });

        dispatch(setTeamArea(updatedItems));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language, dispatch]);

    const onTeamAreaChange = (value) => {
        onChange({ target: { id: FormElementsIds.TEAM_AREA, value, name: "teamWorkArea" } });
    };

    const onProjectTypeChange = (value) => {
        onChange({ target: { id: FormElementsIds.PROJECT_TYPE, value, name: "typeOfProject" } });
    };

    const onTimezoneChange = (value) => {
        onChange({ target: { id: FormElementsIds.TIMEZONE, value, name: "timeZone" } });
    };

    return (
        <div className={sharedStyle.section}>
            <div className={`${sharedStyle.container} p-4 d-flex flex-column`}>
                <h2 className={sharedStyle.title}>{t("teamRequirements")}</h2>

                <div className="mt-4 mx-4">
                    <Label htmlFor={FormElementsIds.TEAM_NAME} requiredIndicator>
                        {t("teamName")}
                    </Label>
                    <Input
                        id={FormElementsIds.TEAM_NAME}
                        name="teamName"
                        invalid={!!errors.teamName}
                        className="px-3 py-2"
                        value={teamNameState}
                        onChange={onChange}
                    />
                    {errors.teamName && (
                        <div className="mt-1">
                            <p className={sharedStyle.errorMsg}>{errors.teamName.message}</p>
                        </div>
                    )}
                </div>

                <div className="d-flex flex-column align-items-center mt-4 mx-4">
                    <div className="mb-4 w-100">
                        <Suspense fallback={<Loading allScreen={false} fullWidth={false} withText={false} />}>
                            <SkillsSelector
                                ids={FormElementsIds}
                                errors={errors}
                                validateSubSchemaFromEvent={validateSubSchemaFromEvent}
                                validationSchema={validationSchema}
                                schemaErrorMessages={schemaErrorMessages}
                            />
                        </Suspense>
                    </div>

                    <div className="mb-4 align-items-start w-100">
                        <Label requiredIndicator={true} htmlFor={FormElementsIds.TIMEZONE}>
                            {t("timeZone")}:
                        </Label>

                        <div>
                            <Select
                                id={FormElementsIds.TIMEZONE}
                                styles={getSelectorStyle(!!errors.timeZone)}
                                options={timezones.data}
                                onChange={onTimezoneChange}
                                value={timezoneState}
                                placeholder={`${t("select")}...`}
                                isLoading={timezones.loading}
                            />
                            {errors.timeZone && (
                                <div className="mt-1">
                                    <p className={sharedStyle.errorMsg}>{errors.timeZone.message}</p>
                                </div>
                            )}
                        </div>
                    </div>

                    {/*<div className="mb-4">*/}
                    {/*    <Label htmlFor={FormElementsIds.TEAM_AREA}>{t("teamWorkArea")}</Label>*/}
                    {/*    <Select*/}
                    {/*        id={FormElementsIds.TEAM_AREA}*/}
                    {/*        options={businessSectors}*/}
                    {/*        styles={selectorStyle}*/}
                    {/*        value={teamAreasState}*/}
                    {/*        onChange={onTeamAreaChange}*/}
                    {/*        placeholder={`${t("select")}...`}*/}
                    {/*        isMulti*/}
                    {/*    />*/}
                    {/*    {errors.teamWorkArea && (*/}
                    {/*        <div className="mt-1">*/}
                    {/*            <p className={sharedStyle.errorMsg}>{errors.teamWorkArea.message}</p>*/}
                    {/*        </div>*/}
                    {/*    )}*/}
                    {/*</div>*/}

                    {/*<div className="mb-4">*/}
                    {/*    <Label htmlFor={FormElementsIds.PROJECT_TYPE}>{t("typeOfProject")}</Label>*/}
                    {/*    <Select*/}
                    {/*        id={FormElementsIds.PROJECT_TYPE}*/}
                    {/*        options={projectTypes}*/}
                    {/*        styles={selectorStyle}*/}
                    {/*        value={projectTypesState}*/}
                    {/*        onChange={onProjectTypeChange}*/}
                    {/*        placeholder={`${t("select")}...`}*/}
                    {/*    />*/}
                    {/*    {errors.typeOfProject && (*/}
                    {/*        <div className="mt-1">*/}
                    {/*            <p className={sharedStyle.errorMsg}>{errors.typeOfProject.message}</p>*/}
                    {/*        </div>*/}
                    {/*    )}*/}
                    {/*</div>*/}

                    <div className="mb-4 align-items-start w-100">
                        <Label htmlFor={FormElementsIds.ADDITIONAL_COMMENTS}>{t("additionalComments")}</Label>
                        <textarea
                            id={FormElementsIds.ADDITIONAL_COMMENTS}
                            value={additionalCommentsState}
                            onChange={onChange}
                            className={`${sharedStyle.textArea} px-3 py-2 mb-4`}
                            rows="4"
                        />
                        {errors.additionalComments && (
                            <div className="mt-1">
                                <p className={sharedStyle.errorMsg}>{errors.additionalComments.message}</p>
                            </div>
                        )}
                    </div>

                    <div className="mb-4 align-items-start w-100">
                        <Label htmlFor={FormElementsIds.FILE_ATTACH}>{t("attachFileAboutProject")}</Label>

                        <Suspense fallback={<Loading allScreen={false} withText={false} fullWidth={false} />}>
                            <InputFile
                                id={FormElementsIds.FILE_ATTACH}
                                inputFileChange={onChange}
                                invalid={!attachmentState.isValid}
                                isLoading={attachmentState.isLoading}
                                disabled={attachmentState.isLoading}
                            />
                        </Suspense>

                        <div className={!attachmentState.isValid ? "mt-1" : ""}>
                            {!attachmentState.isValid &&
                                attachmentState.errors.map((error, i) => (
                                    <p key={i} className={sharedStyle.errorMsg}>
                                        {error === "allowedFileTypes"
                                            ? t(error) + " " + ALLOWED_TYPE_NAMES.join(", ")
                                            : t(error)}
                                    </p>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SecondSection;
