import { Navigate, Outlet } from "react-router-dom";
import { ROLES } from "../../base/js/constants";
import Routes from "../../base/js/routes";
import useHasRole from "../hooks/UseHasRole";

const PrivateRoutes = ({ rolesNeeded = [ROLES.ADMIN], redirectTo = Routes.HOME }) => {
    const auth = useHasRole(rolesNeeded);
    return auth ? <Outlet /> : <Navigate to={redirectTo} />;
};

export default PrivateRoutes;
