import { generateErrorToast } from "../base/js/httpError";
import { API_BASE, request } from "../utils/httpRequest";

export const addTermsAndConditions = async (termsAndConditions) => {
    try {
        return await request(`${API_BASE}/api/termsandconditions`, {
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(termsAndConditions),
            method: "POST",
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const getTermsAndConditions = async () => {
    try {
        return await request(`${API_BASE}/api/termsandconditions`, {
            method: "GET",
        });
    } catch (error) {
        generateErrorToast(error);
    }
};
