import { v4 as uuidV4 } from "uuid";
import {
    AddressRegex,
    DomainRegex,
    PhoneRegex,
    UnitedStatesEINRegex,
    UnitedStatesTINRegex,
    UrlRegex,
} from "../../utils/formValidation";
import Routes from "./routes";

export const API_URL = "";

export const LANGUAGES = {
    EN: "en",
    ES: "es",
};

export const ROLES = {
    USER: "User",
    ADMIN: "Admin",
    HIRING_MANAGER: "HiringManager",
};

export const BREAKPOINTS = {
    XS: 0,
    SM: 576,
    MD: 768,
    LG: 992,
    XL: 1200,
    XXL: 1400,
};

export const DATE_FORMAT = "MM/dd/yyyy";
export const DATE_FORMAT_HOURS = "MM-dd-yyyy hh:mm";

export const DATE_FORMAT_HOURS_ALTERNATIVE = "MM/dd/yyyy hh:mm";

export const DATE_FORMAT_FULL_MONTH = "MMMM - yyyy";
export const DATE_FORMAT_ALTERNATIVE = "MM-dd-yyyy";
export const DATE_FORMAT_ALTERNATIVE2 = "d LLL, yyyy";
export const HOUR_AND_DATE_FORMAT = "p - cccc";
export const HOUR_FORMAT = "p";

export const ResourceStatus = {
    INTERVIEW_NEEDED: 2,
    NEEDS_MORE_INFO: 3,
    NOT_SATISFIED: 4,
};

export const PositionStaffStatus = {
    ASSIGNED: "assigned",
    UNASSIGNED: "unassigned",
    REJECTED: "rejected",
};

export const SEX = {
    MALE: "M",
    FEMALE: "F",
    OTHER: "X",
};

export const ModalButtonVariant = {
    GREEN: 1,
    WHITE: 2,
    BLUE: 3,
    RED: 4,
};

export const NOTIFICATION_DATE_FORMAT = "MMM/d HH:mm";

export const NotificationPriority = {
    HIGH: "High",
    MEDIUM: "Medium",
    LOW: "Low",
};

export const SURVEY_RATES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const FILES_FORMATS = ["PDF", "JPG", "PNG", "SVG"];

export const ACCEPTED_FILES_MYME_TYPES = {
    "image/jpeg": [".jpeg", ".jpg", ".png", ".docx", ".doc", ".svg", ".pdf"],
    "image/png": [],
    "image/svg+xml": [],
    "application/pdf": [],
    "application/msword": [],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [],
};

export const ProjectStatus = {
    CREATING_NEW_PROJECT: "creatingNewProject",
    STARTING_SELECTION: "selectingPositions",
    PENDING_ALLOCATION: "pendingAllocationOfResources",
    RESELECTING_RESOURCES: "reselectingResources",
    ALL_RESOURCES_ALLOCATED: "allResourcesAllocated2",
    NOT_AGREE_AND_SELECTING: "notAgreeAndReselecting",
    RESELECTED_AND_PENDING_ALLOCATION: "reselectedAndPendingAllocation",
    INTERVIEWS: "interviews",
    TEAM_CONFIRMED: "teamConfirmed",
    PENDING_CONFIRMATION: "pendingConfirmation",
    IN_DEVELOPMENT: "projectInDevelopment",
    FINISHED: "finishedProject",
    CANCELLED: "cancelled",
};

export const ProjectStatusIds = {
    CREATING_NEW_PROJECT: 1,
    STARTING_SELECTION: 2,
    PENDING_ALLOCATION: 3,
    ALL_RESOURCES_ALLOCATED: 4,
    NOT_AGREE_AND_SELECTING: 5,
    PARTIAL_RESELECTING: 6,
    INTERVIEWS: 7,
    TEAM_CONFIRMED: 8,
    PENDING_CONFIRMATION: 9,
    IN_DEVELOPMENT: 10,
    FINISHED: 11,
    CANCELLED: 12,
};

export const TABLE_ROWS_LIMITS = [5, 10, 15, 20];

export const HourSelectorOptions = [
    { value: 9, label: "9:00 AM" },
    { value: 10, label: "10:00 AM" },
    { value: 11, label: "11:00 AM" },
    { value: 12, label: "12:00 AM" },
    { value: 13, label: "1:00 PM" },
    { value: 14, label: "2:00 PM" },
    { value: 15, label: "3:00 PM" },
    { value: 16, label: "4:00 PM" },
    { value: 17, label: "5:00 PM" },
    { value: 18, label: "6:00 PM" },
];

export const MenuLinks = {
    public: [
        { id: uuidV4(), label: "home", to: Routes.HOME, onlyMobile: true },
        { id: uuidV4(), label: "getYourTeam", to: Routes.BUILD_YOUR_TEAM, onlyMobile: true },
        // { id: uuidV4(), label: "howToStart", to: Routes.HOW_TO_START, onlyMobile: true },
    ],
    private: [
        { id: uuidV4(), label: "home", to: Routes.HOME, onlyMobile: true },
        { id: uuidV4(), label: "getYourTeam", to: Routes.BUILD_YOUR_TEAM, onlyMobile: true },
        // { id: uuidV4(), label: "howToStart", to: Routes.HOW_TO_START, onlyMobile: true },
        { id: uuidV4(), label: "manageTeams", to: Routes.MANAGE_TEAMS, onlyMobile: false },
    ],
    admin: [{ id: uuidV4(), label: "admin", to: `${Routes.ADMIN}/${Routes.ADMIN_PROJECTS}`, onlyMobile: false }],
};

export const FORMATTER = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

// Label used to identify the role "Technical Account Manager"
export const TAM_LABEL = "Technical Account Manager";

export const SURVEYS_TYPES = {
    INITIAL: {
        value: "Initial",
        label: "Initial",
    },
    SECONDARY: {
        value: "Secondary",
        label: "Secondary",
    },
};

export const SURVEYS_EXPORT_FILE_TYPES = {
    EXCEL: "export-excel",
    PDF: "export-pdf",
};

export const PROJECT_TYPES = [
    { value: 1, label: "New project from scratch" },
    { value: 2, label: "Existing project that need more resources" },
];

export const TAM_POSITION = {
    id: -1,
    role: TAM_LABEL,
    seniority: "Senior",
    skills: "",
    rate: 0,
    selected: true,
    order: null,
    positionStaffs: [
        {
            status: PositionStaffStatus.ASSIGNED,
            staff: {
                name: "-",
                developmentRols: [{ name: TAM_LABEL }],
                seniority: { name: "Senior" },
                technologies: [],
                monthPrice: 0,
            },
        },
    ],
};

export const PasswordRules = [
    { label: "passwordCharacterLimit", rule: /^.{8,255}$/ },
    { label: "passwordSpecialCharacter", rule: /[@#$%^&+=.\-_*]+/ },
    { label: "passwordNumberCharacter", rule: /\d+/ },
    { label: "passwordCharacterLower", rule: /^(?=.*[a-z]).+$/ },
    { label: "passwordCharacterUpper", rule: /^(?=.*[A-Z]).+$/ },
];

// This should be used as a custom validator on a Joi schema (for a specific field, not the entire schema)
export const JoiPasswordStrengthValidator = (value, helpers) => {
    let validPassword = true;
    PasswordRules.forEach((rule) => {
        if (!rule.rule.test(value)) {
            validPassword = false;
        }
    });
    return validPassword ? value : helpers.error("any.required");
};

export const JoiWebsiteValidator = (value, helpers) => {
    if (value === "") {
        return value;
    }
    if (UrlRegex.test(value)) {
        return value;
    } else {
        return helpers.error("any.invalid");
    }
};

export const JoiTaxIdValidator = (value, helpers) => {
    //The country selected is United States of America and need to validate the taxtId format
    if (
        value.selectedCountry === 1 &&
        !(UnitedStatesTINRegex.test(value.value) || UnitedStatesEINRegex.test(value.value))
    ) {
        return helpers.error("string.invalid");
    }

    return value;
};

export const JoiPhoneValidator = (value, helpers) => {
    if (!PhoneRegex.test(value)) {
        return helpers.error("string.invalidPhone");
    }

    return value;
};

export const JoiAddressValidator = (value, helpers) => {
    if (!AddressRegex.test(value)) {
        return helpers.error("string.invalidAddress");
    }
};

export const JoiDomainValidator = (value, helpers) => {
    if (!DomainRegex.test(value)) {
        return helpers.error("string.invalidDomain");
    }

    return value;
};

export const JoiMaxLengthValidatorBuilder = (max) => {
    return (value, helpers) => {
        if (value.length > max) {
            return helpers.error(`string.max-${max}`);
        }

        return value;
    };
};

export const JoiMinLengthValidatorBuilder = (min) => {
    return (value, helpers) => {
        if (value.length < min) {
            return helpers.error(`string.min-${min}`);
        }

        return value;
    };
};

export const NOTIFICATION_AREAS = {
    PROJECT: "Project",
    PAYMENT: "Payment",
    REVIEW: "Review",
};

export const NOTIFICATION_TYPES = {
    PAYMENT_VALIDATED: "Validated",
    PAYMENT_REJECTED: "Rejected",
    PROJECT_CREATION: "ProjectCreation",
    PROJECT_REJECTED: "ProjectRejected",
    PROJECT_VALIDATION_HOURS_PENDING: "ProjectValidationHoursPending",
    PROJECT_ALL_CANDIDATES_ALLOCATED: "ProjectAllCandidatesAllocated",
    PROJECT_SOW_SIGNED: "ProjectDocumentSowSigned",
};

export const ProjectHistoryEntryTypes = {
    PROJECT_STATUS: "ProjectStatus",
    PAYMENT: "Payment",
};

export const ProjectHistoryParameterTypes = {
    PAYMENT_ID: "PaymentId",
    PAYMENT_PROOF_NAME: "PaymentProofName",
};

export const STRIPE_STATUS = {
    Processing: "payment_intent.processing",
    Success: "payment_intent.succeeded",
    Canceled: "payment_intent.canceled",
    Failed: "payment_intent.payment_failed",
};
export const STRIPE_STATUS_SELECT_VALUES = [
    {
        label: "Processing",
        value: "payment_intent.processing",
    },
    {
        label: "Success",
        value: "payment_intent.succeeded",
    },
    {
        label: "Canceled",
        value: "payment_intent.canceled",
    },
    {
        label: "Failed",
        value: "payment_intent.payment_failed",
    },
];

export const HOURS_VALIDATION_STATUS = {
    creating: "Creating",
    created: "Created",
    pending: "Pending",
    validated: "Validated",
};

export const ProjectTraceTypes = {
    PROJECT_STATUS: "ProjectStatus",
    PAYMENT: "Payment",
    PAYMENT_COMMENT: "PaymentComment",
    RESOURCE_ASSIGNATION_UPDATE: "ResourceAssignationUpdate",
    PROJECT_ADMINISTRATION_UPDATE: "ProjectAdministrationUpdate",
    PROJECT_REJECTION: "ProjectRejection",
};

export const ProjectTraceParameterTypes = {
    PAYMENT_ID: "PaymentId",
    PAYMENT_PROOF_NAME: "PaymentProofName",
    ACTION_AUTHOR_NAME: "ActionAuthorName",
    ACTION_AUTHOR_ID: "ActionAuthorId",
    RESOURCE_NAME: "ResourceName",
    RESOURCE_ID: "ResourceId",
    AMOUNT_OF_POSITIONS: "AmountOfPositions",
    AMOUNT_OF_RESOURCES: "AmountOfResources",
    PROJECT_CREATOR_NAME: "ProjectCreatorName",
    PROJECT_CREATOR_ID: "ProjectCreatorId",
    PROJECT_REJECTION_CAUSE: "ProjectRejectionCause",
    HIRING_MANAGER_NAME: "HiringManagerName",
    HIRING_MANAGER_ID: "HiringManagerId",
    TAM_NAME: "TamName",
    TAM_ID: "TamId",
};

export const INFINITE_SCROLL_FETCH_SIZE = 10;

export const DEFAULT_PAGING_OPTS = {
    skip: 0,
    take: INFINITE_SCROLL_FETCH_SIZE,
    searchText: "",
};

export const ENGLISH_LEVELS = [
    { id: "A1", name: "A1" },
    { id: "A2", name: "A2" },
    { id: "B1", name: "B1" },
    { id: "B2", name: "B2" },
    { id: "C1", name: "C1" },
    { id: "C2", name: "C2" },
];
