import PropTypes from "prop-types";
import { ModalButtonVariant } from "../../../../base/js/constants";
import style from "./ModalButton.module.scss";

const ModalButton = ({ variant, onClick, children, type = "button" }) => {
    let variantClass;

    switch (variant) {
        case ModalButtonVariant.GREEN:
            variantClass = style.greenVariant;
            break;
        case ModalButtonVariant.WHITE:
            variantClass = style.whiteVariant;
            break;
        case ModalButtonVariant.BLUE:
            variantClass = style.blueVariant;
            break;
        case ModalButtonVariant.RED:
            variantClass = style.redVariant;
            break;
        default:
            throw new Error(`Unrecognized variant "${variant}" for ModalButton`);
    }

    return (
        <button className={`${style.button} ${variantClass} text-uppercase`} onClick={onClick} type={type}>
            {children}
        </button>
    );
};

ModalButton.propTypes = {
    variant: PropTypes.number.isRequired,
};

export default ModalButton;
