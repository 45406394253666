const en_translation = {
    english: "English",
    spanish: "Spanish",
    italian: "Italian",
    german: "German",
    portuguese: "Portuguese",
    french: "French",
    chinese: "Chinese",
    buildYourTeam: "Build your team",
    contactUs: "Contact us",
    meetTheTeam: "Meet <span1>the</span1> <span2>team</span2>",
    whatWeAre: "We are a team of professionals driven by passion to what we do.",
    contactUs2: "Contact Us",
    coreTeam: "Core team",
    jackieSpeech:
        "\"…It doesn't matter how smart you are, and it doesn't matter how brilliant your idea is. If your message is muddled, your idea is dead…\"",
    santiagoBeginnings:
        "Santiago was a restless, curious and creative boy. At a very young age, he was inspired by his parent’s stories of success. Since he was a true problem solver, it’s no surprise he began his career in the field of technology. He quickly realized the large gap between technical and non-technical skills. To combat this gap, he decided to pair their strengths (empathy, active listening, and passion for problem solving) to bring the business world closer to the technical world.",
    readMore: "Read more",
    santiagoChallenges:
        "Acting in the commercial area, he faced the “technical language” a thousand and one times and fought against the business problems that it created. “…the IT industry has a lot of technical slang, and many times I think that people are talking in a different language…. But business language is simpler. I make our customer's lives as simple as possible, day in and day out…” Santiago Fraga – Founder",
    santiagoBusinessVisionP1:
        "Santiago identified problems on both sides of the sales process: While Latin-American software factories have skilled professionals, they have difficulty accessing",
    santiagoBusinessVisionP2:
        "international markets. Customers, on the other hand, struggle with the staffing and recruiting of IT professionals. They’re often unable to attract the right candidates with the right qualifications at the right times.",
    santiagoSpeech:
        "“…It doesn't matter how smart you are, and it doesn't matter how brilliant your idea is. If your message is muddled, your idea is dead…” Santiago Fraga – Founder",
    santiagoMeetsJackie:
        "In 2010, Santiago met Jackie at a job interview. They quickly became a solid team. While they delivered exceptional results for the company, they simultaneously implemented a business transformation and diversification strategy. Even after their careers steered them toward different paths, they always stayed in touch. Therefore, it was only natural for Santiago to bring Jackie into TheDevBlock materialization meetings, and appoint her as CEO. Once Santiago realized the problems he encountered were quite common in the industry, he had a vision. He wanted to create a web platform that could allow customers to build the ideal remote IT team and partners to sell their resources. Santiago knew that this unique solution could make life easier for everyone involved.",
    member1Quote:
        "We believe in the power of business to transform society. And probably the most attractive area for creating high skilled jobs is the IT Industry.",
    member2Quote:
        "We have designed a platform that cares about what you care about. TheDevBlock was thought by software developers who have experienced the pains of hiring firsthand.",
    writeUs: "Write us",
    wantToConnect: "Want to connect?",
    tellUsHelp: "Tell us how we can help. We’ll get in touch shortly.",
    fullName: "Full Name",
    phoneNumber: "Phone Number",
    company: "Company",
    businessEmailAddress: "Business Email Address",
    writeMessage: "Write a message",
    receiveNewsletter: "I want to receive TheDevBlock’s newsletter",
    sendMessage: "Send message",
    address: "Address",
    hiringHasChanged: "Hiring <span1>has</span1> <span2>changed</span2>",
    acquireQualifiedIT:
        "It can be a real challenge to acquire qualified IT talent. Despite the infinite demand for software development skills, there is a shortage of software developers.",
    tdbComesIn: "That’s where TheDevBlock comes in.",
    getAQuote: "Get a quote",
    tellAStory: "Let us tell you <span>our story</span>",
    foundationStory:
        "Founded in 2018 and headquartered in Texas, TheDevBlock is dedicated to solving these issues. By partnering with us, you can receive the IT talent you need to take your business to new heights.",
    ourStaff:
        "Our managerial staff is based in Uruguay, which is the perfect spot nearshoring due to its conditions, location, time zone. It also offers the best business climate in the region. When you make the smart decision to hire through us, you won’t have to worry about HR headaches, legal issues and security risks. Best of all, we speak the same exact business language.",
    wantToLearnMore: "Want to learn more?",
    scheduleMeeting: "Schedule meeting",
    meetTheTeam2: "Meet the team",
    knowTheTeam: "Want to know more about the professionals that work in TheDevBlock?",
    valuesWeStandFor: "The <span>values</span> we stand for",
    speedAndQuality: "Speed and Quality",
    customerService: "Customer Service",
    innovation: "Innovation",
    diversity: "Diversity",
    whatWeProvide: "We provide the IT talent you need quickly, without compromising quality. After all, time is money.",
    ourPriority: "Our customers are our top priority. We work tirelessly to ensure they’re always satisfied.",
    ourMission:
        "While we’re proud of our one-of-a-kind platform, it’s not set in stone. We’re constantly on a mission to innovate and improve it.",
    ourEmployees:
        "We welcome employees from all walks of life. It’s their differences and unique perspectives that make them so valuable.",
    whatClientsSay: "What clients say about us",
    reviewDerek:
        "We were helped out by TDB when we were facing a critical business situation with tight deadlines for product launch, training and customer deliveries. ScienceSoft developers were able to jump in and unfinished project and finalize everything in a highly professional and efficient way, and right on time. We have been very, very satisfied with TDB and not only plan to continue with them but also would like to recommend their services for any other challenging software development project!",
    wantToStart: "Want to start a project?",
    scheduleMeeting2: "Schedule Meeting",
    augmentYourTeam: "Augment your <span>tech talent team</span> within 72 hours",
    tdbDescription:
        "TheDevBlock, the most efficient B2B hiring platform, provides nearshore IT staffing while you focus on your business.",
    isYourCompanyStruggling: "Is your company struggling with these issues?",
    shortageTechResources: "Shortage of technical resources",
    strugglingManageBudget: "Struggling to manage the project budget",
    lackOfTime: "Lack of time to hire professionals for your projects",
    helpIncreaseEfficiency: "Let us help you increase the efficiency of your projects",
    highQualifiedExperts: "High qualified experts available",
    seniorDevelopers: "Senior Developers",
    skillsAvailable: "Skills available",
    whyTdb: "Why TheDevBlock?",
    support360: "360 Support",
    support360Description:
        "A team of professionals support you before, during and after the hiring process. A Hiring Manager and a Technical Account Manager help you to move faster and get better results.",
    preQualifiedTalents: "Pre qualified talents",
    preQualifiedTalentsDescription:
        "We examine our tech experts team through a variety of tests such as screening interviews, technical tests, code reviews, psychological tests and background checks.",
    flexibility: "Flexibility",
    flexibilityDescription: "You can ramp up or down your team on demand and you can cancel the service anytime.",
    adaptability: "Adaptability",
    adaptabilityDescription: "We work across your time zone, timelines and schedules.",
    forgetAboutProblems:
        "Forget about HR pains, legal issues and security risks; you and TheDevBlock speak the same business language.",
    findExperiencedDevelopers: "Find <span>experienced developers</span> in our vetted staff",
    screenForExpertise: "We screen for expertise,<br /> personality and behavior.",
    letsStart: "Let’s start",
    liveScreeningInterview: "Live-screening interview",
    liveScreeningInterviewDescription:
        "We live-screen our engineers and ask them behavioral and technical questions to understand their expertise, drives and dreams.",
    professionalRefChecks: "Professional Reference Checks",
    professionalRefChecksDescription:
        "We talk to at least two professional references that have worked with our developers within the last 2 years to understand their workplace performance.",
    englishInterview: "English Interview",
    englishInterviewDescription:
        "Using a variety of techniques, our developers respond to a series of tests to evaluate and consider their current level.",
    technicalInterview: "Technical Interview",
    technicalInterviewDescription:
        "You probably have in-house technical tests to measure technical proficiency. However, we do have a technical evaluation for our developers if needed!",
    technicalInterviewFootnote: "Upon request",
    aboutTheProject: "About the project",
    teamName: "Team Name",
    investmentExpectation: "What's your project's budget?",
    yourDecisionFlow: "What is your decision flow process for these projects?",
    rolesInYourCompany: "What roles in your company interact during the hiring and confirmation process?",
    estimatedDateToStart: "Estimated start date",
    estimatedDateToEnd: "Estimated end date",
    timeZone: "Time zone",
    expectedDateToConfirm: "Date by which you need the team up and running",
    whatYouNeed: "What you need for your team",
    teamWorkArea: "Area where the team will be working on?",
    typeOfProject: "What type of project is it?",
    additionalComments: "Do you have any additional comments on the project you’d like to share with us?",
    additionalCommentsShort: "Additional comments",
    attachFileAboutProject: "Please attach any file or documentation you’d like to share regarding the project",
    file: "File",
    attachFile: "Attach file",
    continue: "Continue",
    select: "Select",
    noFileChosen: "No file chosen",
    fileSizeLimit: "The file size can be at most 5MB",
    allowedFileTypes: "The allowed file types are",
    mandatoryField: "Mandatory field",
    checkboxRequired: "At least one checkbox should be checked",
    login: "Login",
    assembleYourTeam: "Assemble your team",
    teamInfo: "Team Info",
    resume: "Resume",
    confirmation: "Confirmation",
    search: "Search",
    faq: "Frequently Asked Questions",
    buildYourTeamOfProfessionals: "Build your team of IT <span>professionals</span>",
    buildTeamAccordingNeeds: "Build a team according to your needs and start operating in the shortest possible time.",
    letStart: "Let’s Start",
    home: "Home",
    howToStart: "How to start",
    selectYourTeam: "Select your team",
    projectName: "Project Name",
    selectYourOptions: "Select your options",
    completeAgainInformation: "Please review the roles below and correct the information as needed.",
    partial: "Partial",
    role: "Role",
    seniority: "Seniority",
    jobSpecificSkills: "Skillset",
    jobskills: "Skills",
    count: "Count",
    time: "Time",
    estimatedDateToStartOptional: "Estimated start date",
    estimatedDateToEndOptional: "Estimated end date",
    jobDescriptionLinkOptional: "Job Description Link",
    or: "or",
    errorLinkFormat: "The link should have a correct format. Ex: https://example.com",
    deleteResource: "Delete Resource",
    ok: "Ok",
    addResource: "<span>Add</span> a resource",
    finishFiltering: "<span>Finish</span> filtering",
    decisionMakerRole: "Decision Maker (CTO, CIO, CISO, VP)",
    recommenderRole: "Referral (Director, Senior Manager, Information Security, Cyber Security, IT)",
    userRole: "User (Technical Consultant, Support Engineer, Project Manager, Engineer, Analyst)",
    othersRole: "Others",
    knowledgeRequired: "Knowledge required (languages)",
    dragAndDropHelp: "Drag and drop each item to its corresponding box.",
    mandatorySkills: "Mandatory skills",
    desiredSkills: "Desired skills",
    addCustom: "Add custom",
    dropHere: "Drop here",
    desiredKnowledge: "Desired knowledge (languages)",
    addKnowledge: "Add knowledge",
    requestInterview: "Request Interview",
    moreInformation: "More information",
    markNotSatisfied: "Mark as Not Satisfied",
    hour: "hour",
    pending: "Pending",
    decisionMaker: "Decision Maker (CTO, CIO, CISO, VP)",
    recommender: "Recommender (Director, Senior Manager, Information Security, Cyber Security, IT)",
    user: "User (Technical Consultant, Support Engineer, Project Manager, Engineer, Analyst)",
    others: "Others",
    howToBuildYourTeam: "How to build your team of IT professionals",
    days: "Days",
    hours: "Hours",
    minutes: "Minutes",
    seconds: "Seconds",
    hiringManagerWillContactYou:
        "Your hiring manager will be contacting you shortly to report the status of your application",
    actions: "Actions",
    back: "Back",
    manageTeams: "Manage Teams",
    projectStatus: "Project Status",
    projectPendingConfirmation: "Your project is pending of confirmation",
    summaryResourceFiltering: "Summary of Resources Filtering",
    yourTeamMembers: "Your Team Members",
    startDate: "Start Date",
    endDate: "End Date",
    monthPrice: "Monthly Amount",
    monthPrice2: "Month Price",
    finalPrice: "Total Amount",
    summaryOfResourcesFiltering: "Summary of Resources Filtering",
    selectionModeDescription:
        "If everything checks out, just click on <span>Continue</span> and our experts will allocate your resources within 72 hours. There’s still time to make corrections or adjustments if you need to, to do so just click on <span>Manage Team</span>.",
    manageResources: "<span>Manage</span> team",
    finishSelectionWSpan: "<span>Finish</span> selection",
    finishSelection: "Finish Selection",
    finishSelectionBtnClick:
        "You have clicked the <span>Finish Selection</span> button, our admins will assign the resources in a maximum of <span>72 hours</span> and we will notify you by email.",
    finishSelectionConfirmationDialog:
        "Once you confirm this message, you will not be able to make changes. Do you want to continue?",
    cancel: "Cancel",
    loading: "Loading",
    close: "Close",
    confirmationFinishSelection:
        "<span1>You’re all set!</span1> <span1>Your new team is being assembled!</span1> <span1>Our experts are searching for the perfect match to create your <span2>dream team</span2>!.</span1>",
    remainingTime: "Remaining time to allocate resources",
    prev: "Prev",
    next: "Next",
    filter: "Filter",
    dateFilter: "Date filter",
    show: "Show",
    notifications: "Notifications",
    noNotificationsToShow: "No notifications to show",
    remove: "Remove",
    noDataToShow: "No data to show",
    newReview: "New Review",
    addReview: "Add Review",
    createReviewSuccess: "Review successfully created",
    editReview: "Edit Review",
    editReviewSuccess: "Review successfully updated",
    project: "Project",
    staff: "Staff",
    subject: "Subject",
    review: "Review",
    availableDates: "Available Dates",
    checkAgendaHManager: "Check agenda with the hiring manager",
    startDate2: "Start date",
    endDate2: "End date",
    timeRange: "Time range",
    save: "Save",
    between: "Between",
    to: "to",
    to2: "to",
    interviewModeDescription:
        "You are in Interview mode, the next step will be coordination with the delivery manager. If you have finished selecting the resources, please press <span>Continue</span>.",
    goBack: "Go back",
    downloadCV: "Download CV",
    about: "About",
    unsatisfactoryAllocation: "Unsatisfactory allocation",
    areYouSureAllocationNotSatisfactory: "Are you sure to mark this allocation as not satisfactory?",
    confirm: "Confirm",
    surveyTitle: "Please answer the following questions to make your next experience in our platform even better",
    howRateExperience: "How would you rate your experience during the registration process?",
    howRecommendTheDevBlock: "How likely would you recommend TheDevBlock to a friend or colleague?",
    surveyComments: "Please share any comments on how we could improve your experience",
    finishSurvey: "Finish Survey",
    pleaseInsertCompanyName: "Please insert a Company Name",
    pleaseInsertCompanyPosition: "Please insert a Company Position",
    pleaseInsertBusinessName: "Please insert a Business Name",
    pleaseInsertAddress: "Please insert an Address",
    pleaseInsertWebsite: "Please insert a Website",
    pleaseInsertBusinessSector: "Please select a Business Sector",
    pleaseInsertCountry: "Please select a Country",
    pleaseInsertTaxID: "Please insert a Tax ID",
    companyInfo: "Company Info",
    companyName: "Company Name",
    companyPosition: "Position",
    businessName: "Business Name",
    website: "Website",
    businessSector: "Business Sector",
    countryJurisdiction: "Country/Jurisdiction",
    taxID: "Tax ID Number",
    invalidFormatUrl: "The URL should have a valid format. For example: https://example.com",
    invalidTaxIDFormat: "Invalid Tax ID format",
    teamIsConfirmed: "Your team is <span>CONFIRMED!<span>",
    uploadedDocument: "Document Uploaded Successfully!",
    selectFileToUpload: "Select File to Upload",
    supportedFiles: "Supported files",
    downloadOurCompanyInfo: "Download our company info",
    payWithCard: "Pay with Credit Card",
    dragAndDropAFileToUpload: "Drag and Drop a file to upload",
    yourTeamConfirmedAttachProofOfPayment:
        "Our hiring manager will contact you with the next steps once the checkout is completed, you can do so either by uploading the proof of payment or paying with your credit card below.",
    workTeamWillValidateProofPayment:
        "Our team will validate the payment and your hiring manager will contact you shortly.",
    getYourTeam: "Get your Team",
    getYourNewTeamHere: "Get your <b>New Team Here!</b>",
    tdbIsLogo: "TheDevBlock's logo",
    btnToChangeLang: "Button to change language",
    accept: "Accept",
    addResourcesToDeal: "Add resources to deal",
    addResource2: "Add resource",
    totalResources: "Total resources",
    totalPrice: "Total price",
    totalValue: "Total value",
    dueDate: "Due date",
    acceptTeamAndBuy: "Accept team & buy",
    ifYouNeedATailorMadeProposal: "If you need a tailor made proposal",
    talkToHiringManager: "Talk to Hiring Manager",
    tailorMadeProposal: "Tailor made proposal",
    yourHiringManagerWillContactYouShortly: "Your hiring manager will contact you shortly.",
    creatingNewProject: "Creating a new Project",
    selectingPositions: "Starting / Selection",
    pendingAllocationOfResources: "Pending allocation of resources",
    reselectingResources: "Reselecting resources",
    allResourcesAllocated2: "All resources allocated",
    notAgreeAndReselecting: "Not Agree & Reselecting",
    reselectedAndPendingAllocation: "Reselected & Pending allocation of resources",
    teamConfirmed: "Team Confirmed",
    interviews: "Interviews",
    pendingConfirmation: "Pending confirmation",
    projectInDevelopment: "Project in Development",
    finishedProject: "Finished Project",
    cancelled: "Cancelled",
    pendingOfAllocation: "Pending of allocation",
    projectCreation: "Project creation",
    projectStart: "Start of project",
    projectEnd: "End of project",
    projectCreated: "The Project was Created.",
    selectionFinalized: "The Selection was Finalized.",
    allResourcesAllocated: "All Resources were Allocated.",
    teamConfirmed2: "The Team was Confirmed.",
    proofOfPaymentUploaded: "Proof of Payment uploaded successfully.",
    projectUnderDevelopment: "The Project is under Development.",
    theFile: "The file",
    containsInfoOfProofOfPayment: "contains the information referred to the Proof of Payment.",
    plannedStartDateIs: "The planned date for start is",
    andTheEndDateIs: "and the end date is",
    technicalAccountManagerInformation: "Technical Account Manager Information",
    name: "Name",
    emailAddress: "Email Address",
    phone: "Phone",
    reviews: "Reviews",
    showDevelopmentTimeline: "Show <span>development</span> timeline",
    createReview: "Create Review",
    becauseHiringHasChanged: "Because hiring has changed.",
    giveTDBATry: "Give TheDevBlock a try!",
    navigation: "Navigation",
    aboutUs: "About Us",
    termsAndConditions: "Terms and Conditions",
    password: "Password",
    signUp: "Sign up",
    alreadyHaveAccount: "Already have an account?",
    forgotYourPassword: "Forgot your password?",
    resetYourPassword: "Reset your password",
    resetPasswordSuccess: "We have emailed you a link for resetting your password",
    newPassword: "New password",
    changePassword: "Change password",
    forgotYourPasswordText:
        "That's okay, it happens! Write down your email and click in the button below, we'll send you a recovery link",
    buildWithUs: "Build with us",
    accessToProDevTeams: "You'll get access to professional development teams.",
    readyToStartImmediately: "Ready to start immediately.",
    email: "Email",
    notSatisfied: "Not satisfied",
    upload: "Upload",
    logout: "Logout",
    myProfile: "My Profile",
    managedTeams: "Managed Teams",
    termsAndConditionsSite: "Terms and conditions of use of TheDevBlock's platform",
    hiringManagerEmail: "Hiring Manager email",
    startBuildingITTeam: "Start building your IT dream-team!",
    findPreVettedTailorMatched: "Find pre-vetted & tailor matched, nearshore IT talent that you can count on.",
    newProjectSetup: "New Project Setup",
    teamRequirements: "Team Requirements",
    setup: "Setup",
    team: "Team",
    buildYourDreamTeam: "Build your dream team",
    projectDetails: "Project Details",
    teamFormation: "Team Formation",
    startBuildingANewTeam: "Start building a new team",
    orderSent: "Order Sent! Your project is pending confirmation",
    topMatchingCandidates: "Top Matching Candidates",
    rejected: "Rejected",
    acceptAndPurchase: "<span>Accept</span> & purchase team",
    candidateRejection: "Candidate Rejection",
    areYouSureRejectCandidate: "Are you sure you want to reject this candidate?",
    rejectCandidate: "Reject Candidate",
    checkout: "Checkout",
    industry: "Industry",
    checkoutComplete: "Checkout Complete",
    allCandidatesAllocated: "All Candidates Allocated",
    allCandidatesAllocatedMessage: "New resources have been assigned to your project: {-}",
    searchingAndReselecting: "Searching & Reselecting",
    signUpConfirmation: "SignUp Confirmation",
    accountCreated: "Your new account has been created. Check your email for your activation link.",
    errorRegisteringUser: "Error when trying to register the user",
    failedProjectCreation: "Error when trying to create the project",
    addedNewPositions: "Positions have been successfully saved",
    failedAddingNewPosition: "Error when trying to save positions",
    finishSelectionSuccess: "Selection finished",
    finishSelectionFailed: "Error trying to finish selection",
    requiredFieldIsEmpty: "Required field is empty",
    checkBothLanguages: "Check both languages",
    fieldTooLong: "{{#label}} must not be greater than 255 characters long.",
    fieldTooLong50: "{{#label}} must not be greater than 50 characters long.",
    invalidCharacters:
        "{{#label}} contains invalid characters. Please use only letters and numbers, as we don't allow symbols",
    invalidEmailFormat: "Incorrect format. Please enter your {{#label}} in format: jane@example.org",
    onlyLettersAndSpacesAllowed: "{{#label}} can only contain letters and spaces (not only white spaces)",
    invalidLoginCredentials: "User doesn't exist / Incorrect password",
    userAlreadyExists: "User already exists",
    passwordCharacterLimit: "At least 8 characters",
    passwordSpecialCharacter: "At least one special character",
    passwordNumberCharacter: "At least one number",
    passwordCharacterLower: "At least one lower letter",
    passwordCharacterUpper: "At least one upper letter",
    weakPassword: "Weak password, check the requirements",
    profileDoesNotExist: "The requested profile does not exist",
    unableToFetchProfile: "Unable to fetch the profile",
    unexpectedError: "Unexpected error",
    projectIdNotFound: "Project ID not found in the URL",
    projectIdNotFound2: "Project ID not found",
    projectNotFound: "Project not found",
    projects: "Projects",
    settings: "Settings",
    resources: "Resources",
    resource: "Resource",
    add: "Add",
    adding: "Adding",
    sex: "Sex",
    roles: "Roles",
    cVitae: "C. Vitae",
    active: "Active",
    lastname: "Lastname",
    birthday: "Birthday",
    languages: "Languages",
    technology: "Technology",
    technologies: "Technologies",
    picture: "Picture",
    tags: "Tags",
    description: "Description",
    male: "Male",
    female: "Female",
    other: "Other",
    userHeader: "User",
    dlvmgr: "Delivery Manager",
    status: "Status",
    totRes: "Tot / Res",
    addUserCreator: "Add user to the project",
    insufficientPermissions: "Insufficient permissions to access information",
    insufficientPermissions2: "Insufficient permissions to perform this action",
    admin: "Admin",
    addCreator: "Add creator",
    addCreatorSuccess: "Creator added",
    hiringManager: "Hiring Manager",
    clickToSelect: "Click to Select",
    finishAllocation: "<span>Accept</span> & Allocate Team",
    organization: "Organization",
    positionMustBeSelected: "A position must be selected for adding a resource",
    resourceAddSuccess: "Resource added to position",
    resourceAddFailed: "Failed to add resource",
    positionResourceRemoveSuccess: "Resource removed from position",
    positionResourceRemoveFailed: "Failed to remove resource from position",
    allocateResources: "Allocate resources",
    hiringManagerChangeSuccess: "Hiring manager updated successfully",
    hiringManagerChangeFailed: "Failed updating the hiring manager",
    technicalAccountManagerChangeSuccess: "Technical account manager updated successfully",
    technicalAccountManagerChangeFailed: "Failed updating the technical account manager manager",
    creator: "Creator",
    technicalAccountManager: "Technical Account Manager",
    proofOfPayment: "Proof of payment",
    confirmedAt: "Confirmed at",
    finishedAt: "Finished at",
    dateEnd: "Date end",
    done: "Done",
    technologyCreated: "Technology created",
    alias: "Alias",
    create: "Create",
    technologyUpdated: "Technology updated",
    edit: "Edit",
    update: "Update",
    editing: "Editing",
    editingHours: "Editing Hours",
    technologyDeleted: "Technology deleted",
    areYouSureToDelete: "Are you sure you want to delete",
    delete: "Delete",
    manage: "Manage",
    fantasyName: "Fantasy name",
    number: "Number",
    country: "Country",
    webSite: "Web site",
    users: "Users",
    logoutError: "Error when trying to logout",
    inactive: "Inactive",
    confirmUserStatusChange: "Confirm user status change",
    confirmUserStatusChangeMessage: "Are you sure to perform the action on the user?",
    confirmResourceStatusChange: "Confirm resource status change",
    confirmResourceStatusChangeMessage: "Are you sure to perform the action on the resource?",
    resourceUpdateSuccessfully: "Resource updated successfully",
    send: "Send",
    surveys: "Surveys",
    userName: "User Name",
    completionDateInitialSurvey: "Completion Date",
    registrationProcessRate: "Registration Process Rate",
    recommendationRate: "Recommendation Rate",
    comments: "Comments",
    dateRange: "Date range",
    createdAt: "Created at",
    positionStaffNotFound: "Position/Resource not found",
    confirmProjectDelete: "Confirm project delete",
    confirmProjectDeleteMessage: "Are you sure to perform the action on the project?",
    confirmReviewDelete: "Confirm review delete",
    confirmReviewDeleteMessage: "Are you sure to perform the action on the review?",
    removeProjectSuccess: "Project successfully deleted",
    deleted: "Deleted",
    confirmProjectCancel: "Confirm project cancellation",
    confirmProjectCancelMessage: "Are you sure to perform the action on the project?",
    cancelProjectSuccess: "Project cancelled",
    unableToContinueWithoutAssignations: "Unable to continue without candidates assigned",
    teamConfirmedMessage: "Team confirmed successfully!",
    unableToAutocomplete: "We're unable to autocomplete some fields due to an issue with the server",
    weWillGetInTouch: "We'll get in touch shortly",
    serverError: "It seems that our server is experiencing problems. Please try again soon.",
    userOffline: "Looks like you are offline. Please try again when you recover the connection.",
    organizationUpdated: "Organization updated successfully",
    organizationCreated: "Organization created successfully",
    proofUploadSuccess: "Proof of payment upload successfully",
    proofUploadError: "Unable to upload the proof of payment",
    validatePayment: "Validate Payment",
    validateProject: "Validate Project",
    canNotBeRevertedMessage: "This action can not be reverted",
    reject: "Reject",
    paymentValidationSuccess: "The payment have been validated successfully",
    paymentRejectionSuccess: "The payment have been rejected successfully",
    projectValidationSuccess: "The project have been validated successfully",
    projectRejectionSuccess: "The project have been rejected successfully",
    unexpectedErrorTryingToConfirm: "Unexpected error when trying to confirm the email",
    emailConfirmed: "Email confirmed successfully! You can login now.",
    confirmingEmail: "Confirming email",
    receiveNewsletter2: "Receive newsletter",
    currentPassword: "Current password",
    fieldTooShort8Chars: "This field should have at least 8 characters of long",
    passwordUpdated: "Password updated",
    tariffs: "Tariffs",
    type: "Type",
    rate: "Rate",
    readjustTariff: "Readjust tariff",
    percentage: "Percentage",
    readjust: "Readjust",
    view: "View",
    paymentDeclined: "Payment Rejected",
    paymentDeclinedMessage: "Your payment for: {-} was reject. Please, check with your bank or financial institution",
    projectNotifyCreation: "New project request",
    projectNotifyCreationMessage: "The project: {-} is pending allocation of resource",
    projectStartingSelection: "The Positions have been selected.",
    projectPendingAllocation: "The Positions are pending of being Allocated.",
    projectAllCandidatesAllocated: "All Candidates were Allocated.",
    projectPartialReselecting: "The Team is being Partially Reselected.",
    projectNotAgreeAndReselecting: "All the Candidates have been rejected. We're searching new ones.",
    projectTeamConfirmed: "The Team has been confirmed.",
    projectCancelled: "Project cancelled",
    projectOnDevelopment: "The Project is under Development.",
    projectProofPaymentUploaded: "Proof of Payment uploaded successfully.",
    projectProofPaymentUploadedDescription:
        "The file {fileDownload} contains the information referred to the Proof of Payment.",
    projectPaymentRejected: "The payment of the project has been declined.",
    paymentAccepted: "Payment accepted",
    paymentDeclinedAlt: "Payment declined",
    errorCreatingStripeCustomer: "Error when trying to create the customer on Stripe",
    changeRate: "Change a rate",
    selectRoleAndSeniority: "Select the combination of role and seniority to update the rate of it.",
    newTariff: "New Tariff",
    change: "Change",
    copyTariff: "Copy Tariff",
    projectsToAssignIt: "Projects to assign it",
    organizationsToAssignIt: "Organizations to assign it",
    countriesToAssignIt: "Countries to assign it",
    tariffUpdated: "Tariff successfully updated",
    editTariff: "Edit Tariff",
    payments: "Payments",
    date: "Date",
    amount: "Amount",
    details: "Details",
    viewDetails: "View Details",
    summary: "Summary",
    downloadBasicInformation: "Download Basic Information",
    downloadShortSheet: "Download Short Sheet",
    languageEvaluation: "Language Evaluation",
    interviewVideos: "Interview videos",
    contractFile: "Contract",
    basicInformation: "Basic Information",
    shortSheet: "Short Sheet",
    englishInterviewUrl: "English Interview URL",
    files: "Files",
    addStatement: "Add Statement",
    statement: "Statement",
    statementOfWork: "Statement of work",
    resourceCreated: "Resource created successfully",
    resourceUpdated: "Resource updated successfully",
    showing: "Showing",
    hoursPendingConfirmation: "Hours pending to confirmation",
    viewMore: "View More",
    partTime: "Part Time",
    partTimeAlt: "Part-Time",
    fullTime: "Full Time",
    invoices: "Invoices",
    period: "Period",
    notes: "Notes",
    creating: "Creating",
    validated: "Validated",
    validate: "Validate",
    hoursValidation: "Hours Validations",
    sendToClient: "Send to client",
    validateHours: "Validate hours",
    addNote: "Add note",
    validatingHoursFor: "Validating hours for",
    successfullyValidated: "Successfully validated",
    noteAddedSuccessfully: "Note added successfully",
    sentToClientSuccessfully: "Sent To Client Successfully",
    hoursValidationSuccessfullyCreated: "Hours Validation created successfully",
    hoursValidationSuccessfullyUpdated: "Hours Validation updated successfully",
    document: "Document",
    debit: "Debit",
    credit: "Credit",
    balance: "Balance",
    faqTeamBuildingProcess:
        "<ul>" +
        "<li>Assessing client needs: The first step is to assess the client's needs. This involves understanding the client's goals, the specific skills required for the project, and any other relevant details that can help identify the right team members.</li>" +
        "<li>Recruitment: Once the client's needs have been identified, TheDevBlock will typically begin the recruitment process. This involves sourcing and evaluating candidates who have the required skills and experience to meet the client's needs.</li>" +
        "<li>Skill matching: Once candidates have been identified, TheDevBlock will typically assess their skills and match them to the client's needs. This process may involve interviews, technical assessments, and reference checks.</li>" +
        "<li>Onboarding: After candidates have been selected, TheDevBlock will typically onboard them onto the client's project. This may involve providing orientation and training, as well as introducing team members and establishing communication channels.</li>" +
        "<li>Team building: As the team works together on the project, TheDevBlock will typically focus on building a cohesive and effective team. This may involve providing team-building activities, encouraging communication and collaboration, and monitoring team dynamics to identify and address any issues that arise.</li>" +
        "</ul>",
    faqCandidatesVerification:
        "Some of the ways that TheDevBlock may verify candidates include:" +
        "<ul>" +
        "<li>Resumes and work history: TheDevBlock will typically review the candidate's resume and work history to verify their employment history, experience, and qualifications.</li>" +
        "<li>Technical assessments: Depending on the specific requirements of the project, TheDevBlock may conduct technical assessments to evaluate the candidate's skills in a particular area.</li>" +
        "<li>Reference checks: TheDevBlock may contact references provided by the candidate to verify their work experience and performance.</li>" +
        "<li>Background checks: Depending on the client's requirements, TheDevBlock may conduct background checks to verify the candidate's education, criminal history, and other relevant information.</li>" +
        "<li>Interviews: TheDevBlock will typically conduct one or more interviews with the candidate to evaluate their communication skills, problem-solving abilities, and cultural fit.</li>" +
        "</ul>",
    faqMinimumTermServiceContract:
        "The minimum term service contract refers to the shortest duration of time for which a service provider and a client can agree to engage in a contractual relationship. \n" +
        "Our minimum term service contract is often set at 3 months. This means that the client agrees to engage the services of TheDevBlock for a minimum of 3 months. After this initial period, the contract may be extended or renewed, depending on the needs of the client and the availability of TheDevBlock's resources.",
    faqWhereIsTalentLocated:
        "A growing pool of tech talent is located in Latin America, with several countries standing out as key players in the region. These countries include Uruguay, Argentina, Bolivia, Peru, Colombia, and Mexico, among others." +
        "<ul>" +
        "<li>Uruguay has been recognized as a leading tech hub in the region, with a highly educated workforce and a strong focus on innovation. The country has a high level of English proficiency and a favorable business environment, making it an attractive destination for companies seeking to tap into the Latin American market.</li>" +
        "<li>Argentina is another key player in the tech scene, with a large pool of highly skilled software engineers and a vibrant startup ecosystem. Buenos Aires, the country's capital city, has emerged as a hub for innovation, attracting both local and international companies.</li>" +
        "<li>Bolivia and Peru are also home to growing tech communities, with a focus on software development and IT services. Both countries have invested in developing their technology infrastructure and improving access to education and training programs for tech professionals.</li>" +
        "<li>Colombia has a highly educated and rapidly growing workforce, with a focus on software development, data science, and cybersecurity. The country has a large number of universities and technical schools offering programs in tech-related fields, providing a strong talent pipeline for companies.</li>" +
        "<li>Mexico is the largest tech market in Latin America, with a highly skilled workforce and a focus on software development, IT services, and e-commerce. The country has a favorable business environment, a strong culture of entrepreneurship, and a growing number of accelerators and incubators supporting tech startups.</li>" +
        "</ul>" +
        "While these countries are among the top tech talent hubs in Latin America, there are also other countries in the region with emerging tech ecosystems, such as Chile, Brazil, and Costa Rica, among others. Additionally, many tech professionals in the region are bilingual or multilingual, with proficiency in English, Spanish, and Portuguese, making them attractive candidates for global companies.",
    faqTimeLimitForReceivingProposedTeam:
        "The time limit for receiving a proposed team from TheDevBlock is typically 72 hours or 3 working days. This timeframe may vary depending on the specific company and project requirements.",
    faqSkillNotMentionedOnThePlatform:
        "If you are interested in a particular tech language or skill that is not mentioned on TheDevBlock's platform, it is always worth contacting us directly to inquire about our capabilities.",
    faqCandidateRejection:
        "If you reject a candidate that has been provided by TheDevBlock, the company will typically work with you to understand the reasons for the rejection and identify any specific requirements or preferences you have for the role.\n" +
        "Depending on the terms of the contract between TheDevBlock and the client, there may be provisions for replacing a rejected candidate with a new one, or for providing additional candidates for consideration. TheDevBlock may also work with you to refine the job description and search criteria to better align with your needs.",
    faqMethodsAcceptedQ: "What payment methods are accepted on the platform?",
    faqMethodsAcceptedA: "We accept various payment methods, such as credit cards, debit cards and bank transfers.",
    faqBillFrequencyQ: "How often will I be billed for the staff augmentation services?",
    faqBillFrequencyA: "Monthly basis. The specific billing schedule will be outlined in your service agreement.",
    faqServiceModificationsQ: "Can I change or upgrade the staff augmentation services I receive?",
    faqServiceModificationsA:
        "Yes, you can change or upgrade your staff augmentation services. Please contact your hiring manager to discuss your requirements and make the necessary adjustments.",
    faqRefundPolicyQ: "What is the refund policy for staff augmentation services?",
    faqRefundPolicyA:
        "Our refund policy depends on the terms and conditions outlined in your service agreement. We encourage you to review the agreement for specific details on refunds.",
    faqServiceSupportQ: "What kind of support is available for managing the staff augmentation services?",
    faqServiceSupportA:
        "We offer dedicated technical account managers to help you manage the staff augmentation services and address any concerns or issues you may encounter.",
    faqHiddenFeesQ: "Are there any hidden fees associated with the staff augmentation services?",
    faqHiddenFeesA:
        "We strive to maintain transparency in our pricing. Any applicable fees will be clearly outlined in your service agreement. We encourage you to review the agreement for a comprehensive understanding of the costs.",
    faqDataSecurityQ:
        "How is the security of my data and intellectual property ensured when using staff augmentation services?",
    faqDataSecurityA:
        "We take data security and intellectual property protection very seriously. Our platform implements strict security measures, and all staff members are required to sign non-disclosure agreements (NDAs) to protect your sensitive information.",
    faqHowToRequestChangesQ: "How do I request a change in my current staff augmentation services?",
    faqHowToRequestChangesA:
        "To request a change, please contact your hiring manager or submit a request through our platform. We'll work with you to understand your requirements and make the necessary adjustments.",
    faqProcessForAddingOrRemovingTeamMembersQ:
        "What is the process for adding or removing team members from my augmented staff?",
    faqProcessForAddingOrRemovingTeamMembersA:
        "Please communicate your needs to your technical account manager, who will help you with the process of adding or removing team members. Depending on the terms of your agreement, there may be a notice period required for these changes.",
    faqNoticeINeedQ: "How much notice do I need to give if I want to make changes to my staff augmentation services?",
    faqNoticeINeedA:
        "The required notice period for making changes to your staff augmentation services will be outlined in your service agreement. This may vary depending on the specific terms and conditions.",
    faqCanIPauseServiceQ: "Can I temporarily pause my staff augmentation services if my project requirements change?",
    faqCanIPauseServiceA:
        "Depending on the terms of your service agreement, you may be able to pause your staff augmentation services. Please contact your technical account manager to discuss your situation and determine the best course of action.",
    faqWillChangesAffectCostQ: "Will making changes to my staff augmentation services affect the cost?",
    faqWillChangesAffectCostA:
        "Changes to your staff augmentation services may result in adjustments to the cost. Your technical account manager will provide you with a revised pricing structure based on the changes you request.",
    faqChangeSkillSetOrExpertiseQ: "What if I need to change the skill set or expertise of my augmented staff?",
    faqChangeSkillSetOrExpertiseA:
        "If you require different skills or expertise within your augmented staff, please contact your technical account manager. They will help you identify suitable team members with the desired skill set and facilitate the transition.",
    faqFeesForMakingChangesQ: "Are there any fees associated with making changes to my staff augmentation services?",
    faqFeesForMakingChangesA:
        "Any fees associated with service changes will be outlined in your service agreement. It is important to review the terms and conditions of your agreement to understand any potential fees or charges.",
    faqHowLongTakesImplementingChangesQ:
        "How long does it take to implement changes to my staff augmentation services?",
    faqHowLongTakesImplementingChangesA:
        "The time it takes to implement changes to your staff augmentation services will depend on the complexity and nature of the changes. Your account manager will provide you with an estimated timeline for implementing the requested changes.",
    yes: "yes",
    projectUpdated: "Project updated",
    failedProjectUpdate: "Error when trying to update the project",
    filters: "Filters",
    selectRoleAndSeniorityFilter: "Select the combination of role and seniority to filtering.",
    pendingHoursValidation: "Hours pending for validation",
    pendingHoursValidationMessage: "The project: {-} has new hours pending validation",
    projectStartingSelectionDescription: "Amount of selected positions: {amountOfPositions}",
    hiringManagerAdded: "A new Hiring Manager has been set.",
    hiringManagerAddedDescription: "The new Hiring Manager set is {hiringManager}",
    tamAdded: "A new Technical Account Manager has been set.",
    tamAddedDescription: "The new TAM set is {tam}",
    resourceAssigned: "A new resource has been assigned.",
    resourceAssignedDescription: "Resource name: {resource}",
    resourceUnassigned: "A resource has been unassigned.",
    resourceUnassignedDescription: "Resource name: {resource}",
    resourceRejected: "A resource has been rejected.",
    resourceRejectedDescription: "Resource name: {resource}",
    projectNotAgreeAndReselectingDescription: "Amount of rejected candidates: {amountOfResources}",
    projectTracing: "Project Tracing",
    backToHome: "Back to Home",
    clearFilters: "Clear filters",
    from: "From",
    to3: "To",
    cause: "Cause",
    rejectProject: "Reject Project",
    projectRejected: "Project Rejected",
    projectRejectedMessage: "The team request in project: {-} was rejected",
    projectValidated: "Project Validated",
    projectCreatorAdded: "A new project creator has been added.",
    projectCreatorAddedDescription: "Project creator added: {creator}",
    documentSowSigned: "Document SOW signed",
    documentSowSignedMessage: "Document SOW in project: {-} have been signed",
    descriptionFile: "Description File",
    tdbInformation: "TheDevBlock Information",
    invalidPhoneFormat: "{{#label}} should have a valid format. For example: +14155552671.",
    invalidAddressFormat:
        "{{#label}} should have a valid format. Only letters, numbers, commas, dots and dashes are admitted. For example: 3161 Mt Pleasant St NW, Delaware, United States.",
    fieldTooLong100: "{{#label}} must not be greater than 100 characters long.",
    fieldTooLong15: "{{#label}} must not be greater than 15 characters long.",
    fieldTooShort5: "{{#label}} should be at least 5 characters long",
    fieldTooShort7: "{{#label}} should be at least 7 characters long",
    unableToFetchPreviousData: "Unable to fetch previous data",
    unableToUpdateInformation: "Unable to update information",
    informationUpdated: "Information updated",
    domain: "Domain",
    invalidDomain: "{{#label}} should have a valid format. For example: thedevblock.com.",
    resourceSetup: "Resource Setup",
    owner: "Owner",
    openPositions: "Open Positions",
    projectInformation: "Project Information",
    companyInformation: "Company Information",
    timer: "Timer",
    log: "Log",
    payment: "Payment",
    sow: "Sow",
    taxIDEIN: "Tax ID (EIN)",
    signUpDate: "Sign Up Date",
    msaDate: "MSA Date",
    message: "Message",
    descriptionSkillSet: "Description & Skillset",
    ended: "Ended",
    historicalProjectLog: "Historical Project Log",
    all: "All",
    interview: "Interview",
    confirmed: "Confirmed",
    development: "Development",
    allocated: "Allocated",
    finished: "Finished",
    abandoned: "Abandoned",
    canceled: "Canceled",
    user2: "User",
    finishAt: "Finish At",
    assignedTotal: "Assigned / Total",
    restrictedDomains: "Restricted Domains",
    createdBy: "Created By",
    updatedBy: "Updated By",
    restrictedDomainCreated: "A new domain has been restricted successfully",
    restrictedDomainUpdated: "Restricted domain updated",
    compare: "Compare",
    candidateComparison: "Candidate Comparison",
    englishLevel: "English Level",
    profileInfo: "Profile Info",
    hiringCountry: "Hiring Country",
    summarySkillSet: "Summary & Skillset",
    downloadCv: "Download CV",
    restore: "Restore",
    sessionExpired: "Your session has expired",
    hiringCountries: "Hiring Countries",
    fieldTooLong3000: "{{#label}} must not be greater than 3000 characters long.",
    fieldTooLong5000: "{{#label}} must not be greater than 5000 characters long.",
    fieldTooLong1000: "{{#label}} must not be greater than 1000 characters long.",
    finance: "Finance",
    userPasswordUpdated: "User password updated",
    jobDescriptionTooltip: "Please enter the job description details for this position.",
    timeTooltip: "Part-time: Minimum of 80 hours per month. <br> Full-time: Minimum of 160 hours per month.",
    tamTooltip: "We’re pleased to inform you that TDB has already added a Technical Account Manager (TAM) to your team at no additional cost.",
    developmentRoles: "Development Roles",
    developmentRoleCreated: "Development Role Created",
    developmentRoleUpdated: "Development Role Updated",
    developmentRoleDeleted: "Development Role Deleted",
    linkedinLabel: "LinkedIn Label",
    linkedinUrl: "LinkedIn Url",
    title: "Title",
    subTitle: "Subtitle",
    sections: "Sections",
    content: "Content",
    addSection: "Add Section",
    deleteSection: "Delete Section",
    selectTermsAndConditionsLanguage: "Select language of terms and conditions",
    termsAndConditionsSuccess: "Terms and conditions saved",
};

export default en_translation;
