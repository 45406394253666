import { generateErrorToast } from "../base/js/httpError";
import { API_BASE, request } from "../utils/httpRequest";

export const getUsersByHiringManagerRole = async () => {
    try {
        return await request(`${API_BASE}/api/user/by-hm-role`);
    } catch (error) {
        generateErrorToast(error);
    }
};
