import { Form } from "react-bootstrap";

const Radio = ({ children, checked, onChange, name, id, isValid, value, flexColumn, className = null }) => {
    const radioId = `radio-${id}`;
    return (
        <div
            className={`d-flex align-items-center${className ? " " + className : ""} gap-2 ${
                flexColumn ? "flex-column" : ""
            }`}
        >
            <Form.Check
                type="radio"
                id={radioId}
                name={name}
                value={value}
                checked={checked}
                onChange={onChange}
                isInvalid={!isValid}
            />
            <label htmlFor={radioId}>{children}</label>
        </div>
    );
};

export default Radio;
