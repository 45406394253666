import { format, parseISO } from "date-fns";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { DATE_FORMAT_HOURS_ALTERNATIVE } from "../../../../../base/js/constants";
import styles from "./Note.module.scss";

const Note = ({ note }) => {
    const user = useSelector((state) => state.user);

    return (
        <div
            className={`d-flex flex-column align-items-start ${
                user.email === note.createdByEmail ? "align-items-end" : ""
            }`}
        >
            <div className={`${styles.noteContainer} d-flex flex-column align-items-end`}>
                <div className={`${styles.noteContent} ${user.email === note.createdByEmail ? styles.selfNote : ""}`}>
                    <span className={styles.senderName}>{note.createdByName}:</span>
                    <p className={styles.note}>{note.note}</p>
                </div>
                <span className={styles.noteTimestamp}>
                    {format(parseISO(note.createdAt), DATE_FORMAT_HOURS_ALTERNATIVE)}
                </span>
            </div>
        </div>
    );
};

Note.prototype = {
    note: PropTypes.object.isRequired,
};

export default Note;
