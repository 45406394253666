import { generateErrorToast } from "../base/js/httpError";
import { API_BASE, request } from "../utils/httpRequest";

export const getTariffAsync = async (id) => {
    try {
        return await request(API_BASE + "/api/tariff/" + id);
    } catch (error) {
        generateErrorToast(error);
    }
};

export const getTariffListAsync = async () => {
    try {
        return await request(API_BASE + "/api/tariff", {
            method: "GET",
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const getTariffsWithPagingAsync = async (page, pageSize, searchText = "") => {
    try {
        return await request(
            `${API_BASE}/api/tariff/with-paging?page=${page}&pageSize=${pageSize}${
                searchText ? `&searchText=${searchText}` : ""
            }`
        );
    } catch (error) {
        generateErrorToast(error);
    }
};

export const addTariffAsync = async (data) => {
    try {
        return await request(`${API_BASE}/api/tariff`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const updateTariffAsync = async (id, data) => {
    try {
        return await request(`${API_BASE}/api/tariff/${id}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const setTariffRateAsync = async (id, data) => {
    try {
        return await request(`${API_BASE}/api/tariff/${id}/set-tariff-rate`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const readjustTariffAsync = async (id, percentage) => {
    try {
        return await request(`${API_BASE}/api/tariff/${id}/readjust`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(percentage),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};
