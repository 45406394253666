import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Joi from "joi";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ModalButtonVariant } from "../../../../base/js/constants";
import { FormElementsIds } from "../../../build-your-team/assemble-your-team/second-section/SecondSection";
import Label from "../../../common/forms/label/Label";
import ModalButton from "../../../common/modal/buttons/ModalButton";
import Modal from "../../../common/modal/Modal";
import useHasRole from "../../../hooks/UseHasRole";
import useJoiValidation from "../../../hooks/UseJoiValidation";
import sharedStyle from "../../../shared-styles/FormStyle.module.scss";
import style from "./RejectProjectModal.module.scss";

const BASE_SCHEMA = {
    cause: Joi.string().max(255).required().empty(""),
};

const SCHEMA_ERROR_MESSAGES = {
    "any.required": "requiredFieldIsEmpty",
    "string.empty": "requiredFieldIsEmpty",
    "object.with": "requiredFieldIsEmpty",
    "object.missing": "requiredFieldIsEmpty",
    "string.max": "fieldTooLong",
};

const RejectProjectModal = ({ show, onReject, onClose }) => {
    const { t } = useTranslation();
    const { validateSchema, validateSubSchemaFromEvent, resetErrors, errors } = useJoiValidation();
    const [cause, setCause] = useState("");

    const handleRejectClick = (e) => {
        e.preventDefault();

        const cantErrors = validateSchema(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, { cause: cause });
        if (!!cantErrors) {
            return;
        }

        onReject(cause);
    };

    const handleChangeCause = (e) => {
        e.preventDefault();
        setCause(e.target.value);
        validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, e);
    };

    const handleCloseClick = () => {
        resetErrors();
        onClose();
    };

    return (
        <Modal show={show}>
            <div className={`${style.closeBtn}`} onClick={handleCloseClick}>
                <FontAwesomeIcon icon={faClose} />
            </div>

            <span className={`${style.modalTitle} text-bold d-block text-center`}>{t("rejectProject")}</span>

            <span className="d-block text-center">{t("canNotBeRevertedMessage")}</span>

            <div className="mb-4 align-items-start w-100">
                <Label htmlFor="cause" requiredIndicator>
                    {t("cause")}
                </Label>
                <textarea
                    id="cause"
                    name="cause"
                    value={cause}
                    onChange={handleChangeCause}
                    className={`${sharedStyle.textArea} ${!errors.cause ? "" : sharedStyle.invalidField} px-3 py-2`}
                    rows="4"
                />
                {errors.cause && (
                    <div>
                        <p className={sharedStyle.errorMsg}>{errors.cause.message}</p>
                    </div>
                )}
            </div>

            <div className="d-flex justify-content-center gap-4 mt-4">
                <ModalButton variant={ModalButtonVariant.RED} onClick={handleRejectClick}>
                    {t("reject")}
                </ModalButton>

                <ModalButton variant={ModalButtonVariant.WHITE} onClick={handleCloseClick}>
                    {t("cancel")}
                </ModalButton>
            </div>
        </Modal>
    );
};

RejectProjectModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onReject: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default RejectProjectModal;
