import Joi from "joi";
import cloneDeep from "lodash.clonedeep";
import React, { Suspense, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { JoiTaxIdValidator, JoiWebsiteValidator } from "../../base/js/constants";
import { updatePasswordWhileLoggedAsync } from "../../services/AuthService";
import { getBusinessSectorsAsync } from "../../services/BusinessSectorService";
import { getCountriesAsync } from "../../services/CountryService";
import { addOrganizationAsync, updateUserOrganization } from "../../services/OrganizationService";
import { getUserInformation } from "../../services/UserService";
import useProcessInputs from "../accept-and-buy-company-info/hooks/UseProcessInputs";
import useJoiValidation from "../hooks/UseJoiValidation";
import Loading from "../loading/Loading";
import { FormNames } from "./organization-form/OrganizationForm";
import UpdatePasswordModal from "./update-password-modal/UpdatePasswordModal";
import style from "./UserProfile.module.scss";
const OrganizationForm = React.lazy(() => import("./organization-form/OrganizationForm"));
const UserInformation = React.lazy(() => import("./user-information/UserInformation"));

const BASE_SCHEMA = {
    id: Joi.string().empty(""),
    [FormNames.NAME]: Joi.string().max(50).empty("").required(),
    [FormNames.COMPANY_POSITION]: Joi.string().max(50).empty(""),
    [FormNames.FANTASY_NAME]: Joi.string().max(50).empty(""),
    [FormNames.ADDRESS]: Joi.string().max(50).empty(""),
    [FormNames.WEBSITE]: Joi.string().empty("").custom(JoiWebsiteValidator),
    [FormNames.BUSINESS_SECTOR]: Joi.object({
        value: Joi.number().required(),
        label: Joi.string().required(),
    }).required(),
    [FormNames.COUNTRY]: Joi.object({
        value: Joi.number().empty("").required(),
        label: Joi.string().required(),
    }).required(),
    [FormNames.TAX_ID]: Joi.object({
        value: Joi.string().required(),
        selectedCountry: Joi.any(),
    })
        .custom(JoiTaxIdValidator)
        .required(),
};

const SCHEMA_ERROR_MESSAGES = {
    "any.required": "requiredFieldIsEmpty",
    "any.invalid": "invalidFormatUrl",
    "string.invalid": "invalidTaxIDFormat",
    "string.empty": "requiredFieldIsEmpty",
    "object.missing": "requiredFieldIsEmpty",
    "string.max": "fieldTooLong",
};

const USER_DATA_DEFAULT = {
    data: {
        organization: {
            [FormNames.NAME]: "",
            [FormNames.COMPANY_POSITION]: "",
            [FormNames.FANTASY_NAME]: "",
            [FormNames.ADDRESS]: "",
            [FormNames.WEBSITE]: "",
            [FormNames.BUSINESS_SECTOR]: null,
            [FormNames.COUNTRY]: null,
            [FormNames.TAX_ID]: { value: "", selectedCountry: null },
        },
    },
    loading: true,
};

const UserProfile = () => {
    const { t, i18n } = useTranslation();
    const { validateSchema, validateSubSchemaFromEvent, errors } = useJoiValidation();
    const { processLettersAndNumbersInput, processLettersInput, processNoSpacesInput, processAlphanumeric } =
        useProcessInputs();

    const [countryOptions, setCountryOptions] = useState({ data: [], loading: true });
    const [businessSectorOptions, setBusinessSectorOptions] = useState({ data: [], loading: true });
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const [userData, setUserData] = useState(USER_DATA_DEFAULT);
    const [formDisabled, setFormDisabled] = useState(true);

    const userHasOrganization = useMemo(() => !!userData.data?.organization?.id, [userData.data?.organization]);

    // Load initial data
    useEffect(() => {
        (async () => {
            try {
                const data = await getUserInformation();

                if (data) {
                    data.rawOrganization = cloneDeep(data.organization);

                    data.organization = !data.organization
                        ? {}
                        : {
                              id: data.organization.id,
                              [FormNames.FANTASY_NAME]: data.organization.fantasyName || "",
                              [FormNames.COMPANY_POSITION]: data.organization.companyPosition || "",
                              [FormNames.NAME]: data.organization.name,
                              [FormNames.ADDRESS]: data.organization.address || "",
                              [FormNames.WEBSITE]: data.organization.webSite || "",
                              // The field `selectedCountry` must be filled after fetching countries
                              [FormNames.TAX_ID]: { value: data.organization.taxId, selectedCountry: null },
                          };

                    setUserData({ data, loading: false });
                }
            } catch (error) {
                toast.error(`${t("unexpectedError")}: ${error.message}`);
            } finally {
                if (userData.loading) setUserData((prev) => ({ data: prev.data, loading: false }));
            }
        })();

        (async () => {
            try {
                const countries = await getCountriesAsync();

                if (countries) {
                    const mappedCountries = countries.map((c) => ({ value: c.id, label: c.name }));

                    setCountryOptions({ data: mappedCountries, loading: false });
                }
            } catch (error) {
                toast.error(`${t("unexpectedError")}: ${error.message}`);
            } finally {
                if (countryOptions.loading) setCountryOptions((prev) => ({ data: prev.data, loading: false }));
            }
        })();

        (async () => {
            try {
                const businessSectors = await getBusinessSectorsAsync();

                if (businessSectors) {
                    const mappedBusinessSectors = businessSectors.map((bs) => ({
                        value: bs.id,
                        label: i18n.resolvedLanguage === "en" ? bs.sectorEn : bs.sectorEs,
                    }));

                    setBusinessSectorOptions({ data: mappedBusinessSectors, loading: false });
                }
            } catch (error) {
                toast.error(`${t("unexpectedError")}: ${error.message}`);
            } finally {
                if (businessSectorOptions.loading)
                    setBusinessSectorOptions((prev) => ({ data: prev.data, loading: false }));
            }
        })();
    }, []);

    // Parse country and business sector on user data
    useEffect(() => {
        if (
            userData.loading ||
            countryOptions.loading ||
            businessSectorOptions.loading ||
            !userData.data.organization ||
            userData.data.rawOrganization?.countryId == null ||
            userData.data.rawOrganization?.businessSectorId == null ||
            userData.data.organization?.country != null ||
            userData.data.organization?.businessSector != null
        ) {
            return;
        }

        const country = countryOptions.data.find((c) => c.value === userData.data.rawOrganization.countryId);
        const businessSector = businessSectorOptions.data.find(
            (bs) => bs.value === userData.data.rawOrganization.businessSectorId
        );

        setUserData((prevState) => {
            const newState = {
                data: {
                    ...prevState.data,
                    organization: {
                        ...prevState.data.organization,
                        [FormNames.COUNTRY]: country,
                        [FormNames.BUSINESS_SECTOR]: businessSector,
                        [FormNames.TAX_ID]: {
                            ...prevState.data.organization[FormNames.TAX_ID],
                            selectedCountry: country.value,
                        },
                    },
                },
                loading: false,
            };

            return newState;
        });
    }, [userData, countryOptions, businessSectorOptions]);

    const onChangePasswordBtnClick = () => {
        setShowChangePasswordModal(true);
    };

    const onModalCancelClick = () => {
        setShowChangePasswordModal(false);
    };

    // This callback should return a boolean indicating if the execution was incorrect (true) or not (false).
    const onModalAcceptClick = async (data) => {
        try {
            setLoading(true);

            const response = await updatePasswordWhileLoggedAsync(data);

            if (!response) {
                return true;
            }

            toast.success(t("passwordUpdated"));
        } catch (error) {
            toast.error(`${t("unexpectedError")}: ${error.message}`);
            return true;
        } finally {
            setLoading(false);
        }

        setShowChangePasswordModal(false);

        return false;
    };

    const processInput = (elementName, prevState, event) => {
        if (event.target.value === "") {
            return event.target.value;
        }

        switch (elementName) {
            case FormNames.FANTASY_NAME:
                return processLettersAndNumbersInput(prevState, event);
            case FormNames.COMPANY_POSITION:
                return processLettersInput(prevState, event);
            case FormNames.NAME:
                return processLettersAndNumbersInput(prevState, event);
            case FormNames.ADDRESS:
                return processAlphanumeric(prevState, event);
            case FormNames.WEBSITE:
                return processNoSpacesInput(prevState, event);
            case FormNames.TAX_ID:
                return processNoSpacesInput(prevState, event);
            default:
                throw new Error(`Unexpected element. Name: ${elementName}`);
        }
    };

    const onInputChange = (event) => {
        setUserData((prevState) => {
            let newValue = processInput(event.target.name, prevState.data?.organization[event.target.name], event);

            if (event.target.name !== FormNames.WEBSITE && event.target.name !== FormNames.TAX_ID) {
                validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, event);
            }

            if (event.target.name === FormNames.TAX_ID) {
                newValue = { ...prevState.data?.organization[event.target.name], value: newValue };
            }

            return {
                ...prevState,
                data: {
                    ...prevState.data,
                    organization: {
                        ...prevState.data?.organization,
                        [event.target.name]: newValue,
                    },
                },
            };
        });
    };

    const onBusinessSelectorChange = (selection) => {
        setUserData((prevState) => {
            return {
                ...prevState,
                data: {
                    ...prevState.data,
                    organization: {
                        ...prevState.data.organization,
                        [FormNames.BUSINESS_SECTOR]: selection,
                    },
                },
            };
        });

        // Validate the business sector
        validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, {
            target: {
                name: FormNames.BUSINESS_SECTOR,
                value: selection,
            },
        });
    };

    const onCountrySelectorChange = (selection) => {
        setUserData((prevState) => {
            return {
                ...prevState,
                data: {
                    ...prevState.data,
                    organization: {
                        ...prevState.data?.organization,
                        [FormNames.COUNTRY]: selection,
                        [FormNames.TAX_ID]: {
                            ...prevState.data?.organization[FormNames.TAX_ID],
                            selectedCountry: selection.value,
                        },
                    },
                },
            };
        });

        // Validate the country
        validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, {
            target: {
                name: FormNames.COUNTRY,
                value: selection,
            },
        });

        // Trigger the validation of Tax ID due to the change of the country
        validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, {
            target: {
                name: FormNames.TAX_ID,
                value: { ...userData.data?.organization[FormNames.TAX_ID], selectedCountry: selection.value },
            },
        });
    };

    const validateUrl = (event) => {
        validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, event);
    };

    const checkTaxId = (event) => {
        validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, {
            target: {
                name: event.target.name,
                value: { ...userData.data?.organization[FormNames.TAX_ID], value: event.target.value },
            },
        });
    };

    const onOrganizationFormEditBtnClick = () => {
        setFormDisabled(false);
    };

    const onOrganizationFormSaveBtnClick = async () => {
        const numberOfErrors = validateSchema(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, userData.data.organization);

        if (numberOfErrors > 0) {
            return;
        }

        const organization = userData.data.organization;

        const finalData = {
            id: organization.id || undefined,
            fantasyName: organization[FormNames.FANTASY_NAME],
            companyPosition: organization[FormNames.COMPANY_POSITION],
            name: organization[FormNames.NAME],
            address: organization[FormNames.ADDRESS],
            webSite: organization[FormNames.WEBSITE],
            businessSectorId: organization[FormNames.BUSINESS_SECTOR].value,
            countryId: organization[FormNames.COUNTRY].value,
            taxId: organization[FormNames.TAX_ID].value,
        };

        try {
            setLoading(true);

            if (userHasOrganization) {
                await updateUserOrganization(finalData);
                toast.success(t("organizationUpdated"));
            } else {
                const response = await addOrganizationAsync(finalData);
                response && toast.success(t("organizationCreated"));
            }

            setFormDisabled(true);
        } catch (error) {
            console.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <main className={`${style.container} d-flex flex-column flex-lg-row`}>
                <Suspense fallback={<div className="mx-auto">Loading...</div>}>
                    {!userData.loading && (
                        <>
                            <UserInformation data={userData.data} onPasswordChangeClick={onChangePasswordBtnClick} />

                            <OrganizationForm
                                organizationData={userData.data?.organization}
                                onEditClick={onOrganizationFormEditBtnClick}
                                onSaveClick={onOrganizationFormSaveBtnClick}
                                errors={errors}
                                onInputChange={onInputChange}
                                checkTaxId={checkTaxId}
                                onCountrySelectorChange={onCountrySelectorChange}
                                onBusinessSelectorChange={onBusinessSelectorChange}
                                validateUrl={validateUrl}
                                countryOptions={countryOptions}
                                businessSectorsOptions={businessSectorOptions}
                                disabled={formDisabled}
                                organizationRawData={userData.data?.rawOrganization}
                            />
                        </>
                    )}
                </Suspense>
            </main>

            <UpdatePasswordModal
                show={showChangePasswordModal}
                onCancelClick={onModalCancelClick}
                onAcceptClick={onModalAcceptClick}
            />

            {loading && <Loading />}
        </>
    );
};

export default UserProfile;
