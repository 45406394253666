import { useTranslation } from "react-i18next";

export const TermsAndConditionsContent = ({termsAndConditions}) => {
    const { i18n } = useTranslation();
    
    return (
        <>
            {termsAndConditions && termsAndConditions.sections.map((section, index) => {
                return (
                    <section key={index}>
                        {section.subTitle[i18n.resolvedLanguage] && <h2>{section.subTitle[i18n.resolvedLanguage]}</h2>}
                        <p>{section.content[i18n.resolvedLanguage]}</p>
                    </section>
                )
            })}
        </>
    )
};
