import { useTranslation } from "react-i18next";
import ProjectCard from "../project-card/ProjectCard";
import styles from "./ProjectCardPaginated.module.scss";

const ProjectCardPaginated = ({ projects }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="d-flex flex-wrap gap-5 justify-content-start">
                {projects &&
                    projects.map((project) => {
                        return <ProjectCard key={project.id} project={project} />;
                    })}
                {!projects?.length && <div className={styles.noData}>{t("noDataToShow")}</div>}
            </div>
        </>
    );
};

export default ProjectCardPaginated;
