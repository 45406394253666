import { faCheck, faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import greenAlarmIcon from "../../../assets/icons/alarm-clock-icon.svg";
import Colors from "../../../base/js/colors";
import { PositionStaffStatus, ProjectStatus, ResourceStatus } from "../../../base/js/constants";
import OptionLabel from "./OptionLabel";
import style from "./TeamCard.module.scss";

const selectorStyle = {
    control: (provided, _) => ({
        ...provided,
        borderRadius: "8px",
        height: "20px",
        border: `1px solid ${Colors.GRAY650}`,
        fontSize: "0.875rem",
        lineHeight: "2.187rem",
    }),
    container: (provided, _) => ({
        ...provided,
        width: "60%",
        maxWidth: "60%",
    }),
};

const TeamCard = ({
    role,
    seniority,
    skills,
    rate,
    status = null,
    projectStatus,
    positionId = null,
    onResourceStatusChange = null,
    resource = null,
    greenBg = false,
    positionStaffStatus = null,
}) => {
    const { t } = useTranslation();

    let receivedSkills;

    if (Array.isArray(skills)) {
        receivedSkills = skills.join(", ");
    } else {
        receivedSkills = skills || "";
    }

    const defaultOptions = [
        {
            label: (
                <OptionLabel icon={faCheck} iconColor={Colors.GREEN}>
                    <span>{t("requestInterview")}</span>
                </OptionLabel>
            ),
            value: ResourceStatus.INTERVIEW_NEEDED,
        },
        {
            label: (
                <OptionLabel icon={faPlus}>
                    <span>{t("moreInformation")}</span>
                </OptionLabel>
            ),
            value: ResourceStatus.NEEDS_MORE_INFO,
        },
        {
            label: (
                <OptionLabel icon={faXmark} iconColor={Colors.COLOR_DANGER}>
                    <span style={{ color: "rgb(197, 0, 0)" }}>{t("rejectCandidate")}</span>
                </OptionLabel>
            ),
            value: ResourceStatus.NOT_SATISFIED,
        },
    ];

    const skillsText = `Skills: ${receivedSkills}`;

    const preselectedOption = resource ? defaultOptions.find((opt) => opt.value === status) : null;

    const handleResourceStatusChange = (newState) => {
        if (!onResourceStatusChange || !resource) return;
        onResourceStatusChange(resource, positionId, newState.value);
    };

    return (
        <div
            className={`${style.container} d-flex flex-column justify-content-center ${
                status === ResourceStatus.NOT_SATISFIED || positionStaffStatus === PositionStaffStatus.REJECTED
                    ? style.containerPendingOfAllocation
                    : ""
            } ${greenBg ? style.greenBg : ""}`}
        >
            {resource &&
                (positionStaffStatus === PositionStaffStatus.ASSIGNED ||
                    positionStaffStatus === PositionStaffStatus.REJECTED) && (
                    <span
                        className={`${style.resourceName} ${
                            positionStaffStatus === PositionStaffStatus.ASSIGNED &&
                            projectStatus === ProjectStatus.IN_DEVELOPMENT
                                ? style.resourceNameGreen
                                : ""
                        } ${style.fw700} mb-2 ${
                            status === ResourceStatus.NOT_SATISFIED ||
                            positionStaffStatus === PositionStaffStatus.REJECTED
                                ? style.resourceNamePendingOfAllocation
                                : ""
                        }`}
                        title={`${resource.name} ${resource.lastName[0]}.`}
                    >
                        {`${resource.name} ${resource.lastName[0]}.`}
                    </span>
                )}

            <span
                className={`${style.text} ${
                    status === ResourceStatus.NOT_SATISFIED || positionStaffStatus === PositionStaffStatus.REJECTED
                        ? style.textPendingOfAllocation
                        : ""
                }`}
            >
                <span className={style.fw700}>{role}</span> ({seniority})
            </span>

            <span
                className={`${style.text} ${
                    status === ResourceStatus.NOT_SATISFIED || positionStaffStatus === PositionStaffStatus.REJECTED
                        ? style.textPendingOfAllocation
                        : ""
                }`}
                title={skillsText}
            >
                {skillsText}
            </span>

            <div className="mt-3 d-flex justify-content-between align-items-center">
                <span
                    className={`${style.rateText} ${
                        status === ResourceStatus.NOT_SATISFIED || positionStaffStatus === PositionStaffStatus.REJECTED
                            ? style.textPendingOfAllocation
                            : ""
                    }`}
                >
                    U$D {rate} / {t("hour")}
                </span>
                {projectStatus !== ProjectStatus.IN_DEVELOPMENT &&
                    role !== "Technical Account Manager" &&
                    (resource ? (
                        status === ResourceStatus.NOT_SATISFIED ||
                        positionStaffStatus === PositionStaffStatus.REJECTED ? (
                            <span className={`${style.notSatisfiedIndicator} px-2`}>{t("pendingOfAllocation")}</span>
                        ) : (
                            <Select
                                options={defaultOptions}
                                defaultValue={preselectedOption}
                                isSearchable={false}
                                styles={selectorStyle}
                                onChange={handleResourceStatusChange}
                            />
                        )
                    ) : (
                        <div className={`${style.stateIndicator} d-flex align-items-center px-3 py-2`}>
                            <img src={greenAlarmIcon} className={style.icon} alt="" />
                            <span className="ms-2">{t("pending")}</span>
                        </div>
                    ))}
            </div>
        </div>
    );
};

TeamCard.propTypes = {
    role: PropTypes.string.isRequired,
    seniority: PropTypes.string.isRequired,
    skills: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    rate: PropTypes.number.isRequired,
    status: PropTypes.number,
    projectStatus: PropTypes.string,
    onResourceStatusChange: PropTypes.func,
    resource: PropTypes.object,
    greenBg: PropTypes.bool,
    positionStaffStatus: PropTypes.string,
    positionId: PropTypes.string,
};

export default TeamCard;
