import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FORMATTER } from "../../base/js/constants";
import Routes from "../../base/js/routes";
import { getPositionsForResumeListAsync } from "../../services/PositionService";
import { getInvoiceListAsync } from "../../services/StripeService";
import Button, { variants as btnVariants } from "../common/Button";
import styles from "../manage-teams/ManageTeams.module.scss";
import Table from "../manage-teams/table/Table";
import style from "./InvoiceList.module.scss";

const InvoiceList = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [invoiceList, setInvoiceList] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [tableFilters, setTableFilters] = useState(null);

    useEffect(() => {
        (async () => {
            try {
                const responseData = await getInvoiceListAsync(currentPage, rowsPerPage, tableFilters);

                if (responseData) {
                    setInvoiceList(
                        responseData.invoices.map((invoice) => {
                            const actions = [];
                            if (invoice.hostedInvoiceUrl) {
                                actions.push({
                                    label: "viewDetails",
                                    action: invoice.hostedInvoiceUrl,
                                    color: "green",
                                });
                            }

                            return {
                                projectName: invoice.projectName,
                                period: invoice.period,
                                total: FORMATTER.format(invoice.amount),
                                actions: actions,
                            };
                        })
                    );
                    setTotalRows(responseData.total);
                }
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            }
        })();
    }, [currentPage, rowsPerPage, tableFilters]);

    const handleOnRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setCurrentPage(1);
    };

    const handleOnPageChange = (page) => {
        setCurrentPage(page);
    };

    const handleOnFilterChange = (newFilters) => {
        setTableFilters(newFilters.searchText);
    };

    const handleBack = (e) => {
        e.preventDefault();
        navigate(Routes.HOME);
    };

    return (
        <section className={style.mainContainer}>
            <Table
                headers={["project", "period", "finalPrice", "actions"]}
                onRowsPerPageChange={handleOnRowsPerPageChange}
                onPageChange={handleOnPageChange}
                totalRows={totalRows}
                rows={invoiceList}
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                onFilterChange={handleOnFilterChange}
                showDateFilter={false}
            />
            <div className="d-flex justify-content-center">
                <Button cssClasses={[styles.btnBack]} variant={btnVariants.PRIMARY_INVERSE} onClick={handleBack}>
                    {t("back")}
                </Button>
            </div>
        </section>
    );
};

export default InvoiceList;
