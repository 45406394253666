import { generateErrorToast } from "../base/js/httpError";
import { API_BASE, request } from "../utils/httpRequest";

export const getCountriesAsync = async () => {
    try {
        return await request(API_BASE + "/api/country");
    } catch (error) {
        generateErrorToast(error);
    }
};
