import Joi from "joi";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import {
    DEFAULT_PAGING_OPTS,
    INFINITE_SCROLL_FETCH_SIZE,
    JoiMaxLengthValidatorBuilder,
} from "../../../base/js/constants";
import {
    addHiringCountry,
    deleteHiringCountry,
    getAllDeletedHiringCountries,
    getAllHiringCountries,
    getHiringCountry,
    restoreHiringCountry,
    updateHiringCountryPATCH,
} from "../../../services/HiringCountryService";
import { formatPatchBody } from "../../../utils/patchFormatter";
import Input from "../../common/forms/input/Input";
import Label from "../../common/forms/label/Label";
import InfiniteTable from "../../common/infinite-table/InfiniteTable";
import useFirstRender from "../../hooks/UseFirstRender";
import useJoiValidation from "../../hooks/UseJoiValidation";
import Loading from "../../loading/Loading";
import sharedStyles from "../../shared-styles/FormStyle.module.scss";
import styles from "./AdminHiringCountries.module.scss";
import HiringCountriesForm from "./form/HiringCountriesForm";

const TABLE_HEADERS = ["name", "code", "description", "actions"];

const FormElements = {
    NAME: { id: "name", required: true },
    CODE: { id: "code", required: true },
    DESCRIPTION: { id: "description", required: false },
};

const FormStatus = {
    ADDING: "adding",
    EDITING: "editing",
};

const JOI_ERROR_MESSAGES = {
    "any.required": "requiredFieldIsEmpty",
    "string.empty": "requiredFieldIsEmpty",
    "string.max-2": "fieldTooLong2",
    "string.max-50": "fieldTooLong50",
    "string.max-500": "fieldTooLong500",
};

const JOI_SCHEMA = {
    [FormElements.NAME.id]: Joi.string().custom(JoiMaxLengthValidatorBuilder(50)).required(),
    [FormElements.CODE.id]: Joi.string().custom(JoiMaxLengthValidatorBuilder(2)).required(),
    [FormElements.DESCRIPTION.id]: Joi.string().custom(JoiMaxLengthValidatorBuilder(500)).empty(""),
};

// Use this function to build each field state. This will help us if we need to
// modify the structure of the fields globally, since you'll only modify this part
// of the code (and maybe where the data is being used too, but that's another problem).
const fieldStateBuilder = (data, modified) => ({ data, modified });

const DEFAULT_FORM_STATE = {
    [FormElements.NAME.id]: fieldStateBuilder("", false),
    [FormElements.CODE.id]: fieldStateBuilder("", false),
    [FormElements.DESCRIPTION.id]: fieldStateBuilder("", false),
};

// Delay used to trigger the "search" filter once the user has finished typing.
const SEARCH_FILTER_DELAY = 1000;

const AdminHiringCountries = () => {
    const { t } = useTranslation();
    const { validateSubSchemaFromEvent, validateSchema, errors, resetErrors } = useJoiValidation();
    const firstRender = useFirstRender();

    const [searchText, setSearchText] = useState("");
    const [showDeleted, setShowDeleted] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [formMode, setFormMode] = useState(FormStatus.ADDING);
    const [hiringCountries, setHiringCountries] = useState([]);
    const [totalElements, setTotalElements] = useState(0);
    const [tableLoading, setTableLoading] = useState(false);
    const [currentHiringCountry, setCurrentHiringCountry] = useState(DEFAULT_FORM_STATE);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const timeoutId = setTimeout(
            () => {
                initialDataLoad(firstRender);
            },
            firstRender ? 0 : SEARCH_FILTER_DELAY
        );

        return () => {
            clearTimeout(timeoutId);
        };
    }, [searchText]);

    useEffect(() => {
        if (firstRender) {
            return;
        }

        initialDataLoad();
    }, [showDeleted]);

    // Loads the data for first time, avoiding the skipping of elements.
    const initialDataLoad = async (showLoading = true) => {
        if (showLoading) {
            setLoading(true);
        }

        try {
            const pagingOpts = { ...DEFAULT_PAGING_OPTS, searchText };

            const response = showDeleted
                ? await getAllDeletedHiringCountries(pagingOpts, onRestore)
                : await getAllHiringCountries(pagingOpts, onEdit, onRemove);

            setHiringCountries(response?.hiringCountries || []);
            setTotalElements(response?.total || 0);
        } catch (e) {
            toast.error(e);
        }

        if (showLoading) {
            setLoading(false);
        }
    };

    const onSearchChange = (e) => {
        setSearchText(e.target.value);
    };

    const onSwitchChange = (e) => {
        setShowDeleted(e.target.checked);
    };

    const onFiltersAddBtnClick = () => {
        setShowForm(true);
    };

    const fetchNewElements = async () => {
        try {
            const pagingOpts = {
                ...DEFAULT_PAGING_OPTS,
                skip: hiringCountries.length,
                searchText,
            };

            const response = showDeleted
                ? await getAllDeletedHiringCountries(pagingOpts, onRestore)
                : await getAllHiringCountries(pagingOpts, onEdit, onRemove);

            setTotalElements(response.total);
            setHiringCountries((prevState) => [...prevState, ...response.hiringCountries]);
        } catch (e) {
            toast.error(e.message);
        }
    };

    const onTableLoading = (newValue) => {
        setTableLoading(newValue);
    };

    const onFormSubmit = async (e) => {
        e.preventDefault();

        const validationObj = {
            [FormElements.NAME.id]: currentHiringCountry[FormElements.NAME.id].data,
            [FormElements.CODE.id]: currentHiringCountry[FormElements.CODE.id].data,
            [FormElements.DESCRIPTION.id]: currentHiringCountry[FormElements.DESCRIPTION.id].data,
        };

        const amountOfErrors = validateSchema(JOI_SCHEMA, JOI_ERROR_MESSAGES, validationObj);
        if (amountOfErrors > 0) {
            return;
        }

        let payload;

        if (formMode === FormStatus.ADDING) {
            // If the data is being added, we just send everything.
            payload = validationObj;
        } else {
            // If the data is being modified, then we've to check which fields
            // have been modified. Once we've located them, we'll have
            // to build the payload for the PATCH request.
            payload = formatPatchBody(currentHiringCountry);
        }

        try {
            setLoading(true);

            // ID of the added entry, which should be returned as a response to our POST request.
            const entryId =
                formMode === FormStatus.ADDING
                    ? await addHiringCountry(payload)
                    : await updateHiringCountryPATCH(currentHiringCountry.id.data, payload);

            toast.success(t(formMode === FormStatus.ADDING ? "hiringCountryCreated" : "hiringCountryUpdated"));

            // Add/Update the domain in the list
            if (formMode === FormStatus.ADDING) {
                // Fetch the information of the added entry
                const newEntry = await getHiringCountry(entryId, onEdit, onRemove);

                // Add the new entry to the collection
                setHiringCountries((prevState) => [newEntry, ...prevState]);
                setTotalElements((prevState) => prevState + 1);
            } else {
                const objectToInsert = {};

                // Map back to the object's original structure
                Object.keys(currentHiringCountry).forEach((k) => {
                    objectToInsert[k] = currentHiringCountry[k].data;
                });

                // Update the domain in the collection
                setHiringCountries((prevState) => [
                    objectToInsert,
                    ...prevState.filter((hc) => hc.id !== currentHiringCountry.id.data),
                ]);

                setShowForm(false);
                setFormMode(FormStatus.ADDING);
            }

            clearForm();
        } catch (e) {
            toast.error(e.message);
        } finally {
            setLoading(false);
        }
    };

    const onFormCancel = () => {
        setShowForm(false);
        clearForm();
        setFormMode(FormStatus.ADDING);
    };

    const clearForm = () => {
        setCurrentHiringCountry(DEFAULT_FORM_STATE);
        resetErrors();
    };

    const onRemove = async (id) => {
        try {
            setLoading(true);

            await deleteHiringCountry(id);

            setLoading(false);

            setHiringCountries((prevState) => prevState.filter((hc) => hc.id !== id));
            setTotalElements((prevState) => prevState - 1);
        } catch (e) {
            toast.error(e.message);
        }
    };

    const onEdit = async (id) => {
        try {
            setLoading(true);

            const hc = await getHiringCountry(id, onEdit, onRemove);

            // If there is no HiringCountry then return, since there was an error.
            if (!hc) {
                return;
            }

            // Update each field to add a flag to keep track if it has been
            // modified or not.
            Object.keys(hc).forEach((k) => {
                hc[k] = fieldStateBuilder(hc[k] || "", false);
            });

            setCurrentHiringCountry(hc);
            setFormMode(FormStatus.EDITING);
            setShowForm(true);

            // Make sure that the user is seeing our form
            window.scrollTo(0, 0);
        } catch (e) {
            toast.error(e.message);
        } finally {
            setLoading(false);
        }
    };

    const onRestore = async (id) => {
        try {
            setLoading(true);

            await restoreHiringCountry(id);

            setLoading(false);

            // Since this functionality is being accessed when the deleted
            // hiring countries are being shown, we should remove the restored
            // element from the collection.
            setHiringCountries((prevState) => prevState.filter((hc) => hc.id !== id));
            setTotalElements((prevState) => prevState - 1);
        } catch (e) {
            toast.error(e.message);
        }
    };

    const onChange = (e) => {
        setCurrentHiringCountry((prevState) => ({
            ...prevState,
            [e.target.id]: fieldStateBuilder(e.target.value, true),
        }));
    };

    const onBlur = (e) => {
        validateSubSchemaFromEvent(JOI_SCHEMA, JOI_ERROR_MESSAGES, e);
    };

    return (
        <>
            <div className="d-flex flex-column container py-4">
                <h1 className="h1 text-center mb-4">{t("hiringCountries")}</h1>

                {/* Filters bar */}
                <div
                    className={`d-flex align-items-center justify-content-between px-4 mb-3 ${styles.filtersContainer}`}
                >
                    <div className="d-flex gap-4">
                        <div className="d-flex align-items-center gap-3">
                            <Label htmlFor="search">{t("search")}</Label>
                            <Input
                                id="search"
                                name="search"
                                className={`px-2 ${styles.searchInput}`}
                                value={searchText}
                                onChange={onSearchChange}
                            />
                        </div>

                        <div className="d-flex align-items-center mx-2">
                            <Form.Check
                                id="inactiveFilter"
                                type="switch"
                                checked={showDeleted}
                                className={`${sharedStyles.customSwitch}`}
                                onChange={onSwitchChange}
                                label={<span>{t("deleted")}</span>}
                            />
                        </div>
                    </div>

                    <button
                        className="btn btn-success text-uppercase"
                        disabled={showForm}
                        onClick={onFiltersAddBtnClick}
                    >
                        {showForm ? t(formMode) : t("add")}
                    </button>
                </div>

                {/* Form to add/update a hiring country */}
                {showForm && (
                    <HiringCountriesForm
                        onFormSubmit={onFormSubmit}
                        onFormCancel={onFormCancel}
                        onChange={onChange}
                        onBlur={onBlur}
                        currentHiringCountry={currentHiringCountry}
                        formElements={FormElements}
                        formStatus={FormStatus}
                        formMode={formMode}
                        errors={errors}
                    />
                )}

                {/* Table with infinite scroll */}
                <InfiniteTable
                    headers={TABLE_HEADERS}
                    rows={hiringCountries}
                    onFetchRequest={fetchNewElements}
                    className="mt-3"
                    loading={tableLoading}
                    onLoading={onTableLoading}
                    disableInfiniteScroll={hiringCountries.length >= totalElements}
                />
            </div>

            {loading && <Loading />}
        </>
    );
};

export default AdminHiringCountries;
