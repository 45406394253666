import addBussinessDays from "date-fns/addBusinessDays";
import Countdown, { zeroPad } from "react-countdown";
import { useTranslation } from "react-i18next";

import styles from "./CountDown.module.scss";

const CountDown = ({ startDate, finishDate, lite = false }) => {
    const { t } = useTranslation();
    let date = addBussinessDays(new Date(), 3);

    if (startDate) {
        date = addBussinessDays(startDate, 3);
    } else if (finishDate) {
        date = finishDate;
    }

    const CustomRenderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return (
                <div className={`${styles.countDownContainer} ${styles.countDownFinished} text-center d-inline-flex`}>
                    <span>{t("hiringManagerWillContactYou")}</span>
                </div>
            );
        } else {
            return (
                <div className={`${styles.countDownContainer} ${styles.countDownUnFinished} d-inline-flex`}>
                    <div className="d-flex flex-column align-items-center">
                        <span>{days}</span>
                        <span className={`${styles.countDownLabel} text-uppercase`}>{t("days")}</span>
                    </div>
                    :
                    <div className="d-flex flex-column align-items-center">
                        <span>{zeroPad(hours)}</span>
                        <span className={`${styles.countDownLabel} text-uppercase`}>{t("hours")}</span>
                    </div>
                    :
                    <div className="d-flex flex-column align-items-center">
                        <span>{zeroPad(minutes)}</span>
                        <span className={`${styles.countDownLabel} text-uppercase`}>{t("minutes")}</span>
                    </div>
                    :
                    <div className="d-flex flex-column align-items-center">
                        <span>{zeroPad(seconds)}</span>
                        <span className={`${styles.countDownLabel} text-uppercase`}>{t("seconds")}</span>
                    </div>
                </div>
            );
        }
    };

    const CustomRendererLite = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return (
                <div className={`text-center d-inline-flex`}>
                    <span className={styles.ended}>{t("ended")}</span>
                </div>
            );
        } else {
            return (
                <div className="d-inline-flex">
                    <div className="d-flex flex-column align-items-center">
                        <span>{days}</span>
                    </div>
                    :
                    <div className="d-flex flex-column align-items-center">
                        <span>{zeroPad(hours)}</span>
                    </div>
                    :
                    <div className="d-flex flex-column align-items-center">
                        <span>{zeroPad(minutes)}</span>
                    </div>
                    :
                    <div className="d-flex flex-column align-items-center">
                        <span>{zeroPad(seconds)}</span>
                    </div>
                </div>
            );
        }
    };

    return <Countdown date={date} renderer={lite ? CustomRendererLite : CustomRenderer} />;
};

export default CountDown;
