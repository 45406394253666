import PropTypes from "prop-types";
import BootstrapModal from "react-bootstrap/Modal";
import style from "./Modal.module.scss";

const Modal = ({ show, children, className = "" }) => (
    <BootstrapModal contentClassName={style.modal} dialogClassName={className} show={show} centered>
        <BootstrapModal.Body>{children}</BootstrapModal.Body>
    </BootstrapModal>
);

Modal.propTypes = {
    show: PropTypes.bool.isRequired,
    className: PropTypes.string,
};

export default Modal;
