import { format, parseISO } from "date-fns";
import Joi from "joi";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import Selector from "react-select";
import { DATE_FORMAT_ALTERNATIVE, TABLE_ROWS_LIMITS } from "../../../base/js/constants";
import {
    adminUpdateUserAsync,
    getRoleListAsync,
    getUserAsync,
    getUserListAsync,
    toggleUserStatus,
    updateUserPassword,
} from "../../../services/AdminService";
import { adminUserRegisterAsync } from "../../../services/AuthService";
import { getAllOrganizationListAsync } from "../../../services/OrganizationService";
import Input from "../../common/forms/input/Input";
import Label from "../../common/forms/label/Label";
import Paginator from "../../common/paginator/Paginator";
import useJoiValidation from "../../hooks/UseJoiValidation";
import Loading from "../../loading/Loading";
import { default as sharedStyle, default as sharedStyles } from "../../shared-styles/FormStyle.module.scss";
import { getSelectorStyle } from "../../shared-styles/StylesFunctions";
import AdminTable from "../admin-tables/AdminTable";
import styles from "./AdminUsers.module.scss";
import ChangePasswordModal from "./change-password-modal/ChangePasswordModal";
import ConfirmUserStatusModal from "./confirm-user-status-modal/ConfirmUserStatusModal";

const BASE_SCHEMA = {
    name: Joi.string().max(50).required(),
    email: Joi.string()
        .max(255)
        .email({ tlds: { allow: false } })
        .required(),
    role: Joi.object({
        value: Joi.string().empty("").required(),
        label: Joi.string().empty("").required(),
    }),
};

const SCHEMA_ERROR_MESSAGES = {
    "string.empty": "requiredFieldIsEmpty",
    "string.max": "fieldTooLong",
    "string.min": "****",
    "string.email": "invalidEmailFormat",
};

const USER_ACTIONS = {
    ADD: "adding",
    EDIT: "editing",
};

const AdminUsers = () => {
    const { t } = useTranslation();

    const { validateSchema, validateSubSchemaFromEvent, errors } = useJoiValidation();
    const [addBtnLabel, setAddBtnLabel] = useState(USER_ACTIONS.ADD);
    const [loading, setLoading] = useState(false);
    const [reloadData, setReloadData] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [activePage, setActivePage] = useState(1);
    const [totalPerPage, setTotalPerPage] = useState(5);
    const [usersData, setUsersData] = useState([]);
    const [usersTotal, setUsersTotal] = useState(0);
    const [showForm, setShowForm] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const [roleList, setRoleList] = useState({ roles: [], isLoading: false });
    const [organizationList, setOrganizationList] = useState({ organizations: [], isLoading: false });
    const [filterRole, setFilterRole] = useState(null);
    const [filterOrganization, setFilterOrganization] = useState(null);
    const [filterInactive, setFilterInactive] = useState(false);
    const [userActive, setUserActive] = useState(null);

    const [role, setRole] = useState({ value: "", label: "" });
    const [organization, setOrganization] = useState({ value: "", label: "" });
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");

    useEffect(() => {
        (async () => {
            try {
                const response = await getUserListAsync(
                    activePage,
                    totalPerPage,
                    searchInput,
                    filterRole?.value,
                    filterOrganization?.value,
                    filterInactive
                );

                if (response) {
                    const data = response?.users?.map((user) => {
                        const handleUserEdit = async (e) => {
                            e.preventDefault();

                            setUserActive(user.id);

                            const userData = await getUserAsync(user.id);

                            if (userData) {
                                setName(userData.name);
                                setEmail(userData.email);
                                setPhone(userData.phoneNumber);
                                setRole({
                                    value: userData.role.id,
                                    label: userData.role.name,
                                });
                                setOrganization({
                                    value: userData.organization ? userData.organization.id : "",
                                    label: userData.organization ? userData.organization.name : "",
                                });

                                setAddBtnLabel(USER_ACTIONS.EDIT);
                                setShowForm(true);
                            }
                        };

                        const handleActiveChange = async (e) => {
                            e.preventDefault();
                            setUserActive(user.id);
                            setShowConfirmationModal(true);
                        };

                        return {
                            name: user.name,
                            email: user.email,
                            phoneNumber: user.phoneNumber,
                            organization: user.organization?.name,
                            createdAt: user.createdAt ? format(parseISO(user.createdAt), DATE_FORMAT_ALTERNATIVE) : "",
                            active: { isActive: user.isActive, action: handleActiveChange },
                            actions: [
                                {
                                    label: "edit",
                                    action: handleUserEdit,
                                    color: "",
                                },
                            ],
                        };
                    });

                    setUsersTotal(response?.total);
                    setUsersData(data);
                }
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            }
        })();
    }, [activePage, totalPerPage, searchInput, reloadData, filterRole, filterOrganization, filterInactive, t]);

    useEffect(() => {
        (async () => {
            try {
                setRoleList((prevState) => {
                    return {
                        ...prevState,
                        isLoading: true,
                    };
                });

                const response = await getRoleListAsync();

                if (response) {
                    setRoleList({
                        isLoading: false,
                        roles: response?.map((role) => {
                            return {
                                value: role.id,
                                label: role.name,
                            };
                        }),
                    });
                }
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            } finally {
                setRoleList((prev) => ({
                    isLoading: false,
                    roles: prev.roles,
                }));
            }
        })();
    }, [t]);

    useEffect(() => {
        (async () => {
            try {
                setOrganizationList((prevState) => {
                    return {
                        ...prevState,
                        isLoading: true,
                    };
                });

                const responseData = await getAllOrganizationListAsync();

                if (responseData) {
                    setOrganizationList({
                        isLoading: false,
                        organizations: responseData.map((organization) => {
                            return {
                                value: organization.id,
                                label: organization.name,
                            };
                        }),
                    });
                }
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            } finally {
                setOrganizationList((prev) => ({
                    isLoading: false,
                    organizations: prev.organizations,
                }));
            }
        })();
    }, [t]);

    const handleChangePerPage = (event) => {
        setActivePage(1);
        setTotalPerPage(event.target.value);
    };

    const handleInputChange = (e) => {
        e.preventDefault();

        switch (e.target.id) {
            case "search":
                setSearchInput(e.target.value);
                break;
            case "name":
                setName(e.target.value);
                validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, e);
                break;
            case "email":
                setEmail(e.target.value);
                // We won't be validating the format of the email here to avoid showing an error when the user
                // just started to write it.
                break;
            case "phone":
                setPhone(e.target.value);
                break;
            default:
                throw new Error(`Unable to handle element with ID "${e.target.id}"`);
        }
    };

    const validateEmail = (e) => {
        validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, e);
    };

    const handleRoleChange = (selectedRole) => {
        setRole(selectedRole);
        validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, {
            target: { id: "role", value: selectedRole, name: "role" },
        });
    };

    const handleOrganizationChange = (selectedOrganization) => {
        setOrganization(selectedOrganization);
        console.log("on change: ", organization);
    };

    const handleFilterRoleChange = (selectedRole) => {
        setFilterRole(selectedRole);
    };

    const handleFilterOrganizationChange = (selectedOrganization) => {
        setFilterOrganization(selectedOrganization);
    };

    const handleFilterInactiveChange = (e) => {
        setFilterInactive(!filterInactive);
    };

    const clearForm = () => {
        setName("");
        setEmail("");
        setPhone("");
        setRole({ value: "", label: "" });
        setOrganization({ value: "", label: "" });
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const formData = {
            name: name,
            email: email,
            role: role,
        };

        let cantErrors = validateSchema(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, formData);

        if (!!cantErrors) {
            return;
        }

        switch (addBtnLabel) {
            case USER_ACTIONS.ADD:
                await handleFormSubmitAdd();
                break;
            case USER_ACTIONS.EDIT:
                await handleFormSubmitEdit(userActive);
                break;
            default:
                throw new Error(`Unrecognized button label "${addBtnLabel}"`);
        }
    };

    const handleFormSubmitAdd = async () => {
        try {
            const dataToSend = {
                name: name,
                email: email,
                phoneNumber: phone,
                roleId: role.value,
                organizationId: organization.value,
            };

            setLoading(true);

            const response = await adminUserRegisterAsync(dataToSend);
            if (response) {
                toast.success("User successfully created");

                handleFormCancel();
                setReloadData(!reloadData);
            }
        } catch (e) {
            toast.error("Unexpected error: " + e.message);
        } finally {
            setLoading(false);
        }
    };

    const handleFormSubmitEdit = async (userId) => {
        try {
            const dataToSend = {
                name: name,
                email: email,
                phoneNumber: phone,
                roleId: role.value,
                organizationId: organization.value,
            };

            setLoading(true);

            await adminUpdateUserAsync(userId, dataToSend);

            toast.success("User successfully updated");

            handleFormCancel();
            setReloadData(!reloadData);
        } catch (e) {
            console.error(e.message);
        } finally {
            setLoading(false);
        }
    };

    const handleFormCancel = () => {
        setShowForm(false);
        setUserActive(null);
        setAddBtnLabel(USER_ACTIONS.ADD);
        clearForm();
    };

    const handleClickAdd = () => {
        setShowForm(true);
    };

    const handleAcceptChangeUserStatus = async () => {
        try {
            setLoading(true);

            const response = await toggleUserStatus(userActive);

            if (response) {
                toast.success("User successfully updated");
                setReloadData(!reloadData);
            }
        } catch (e) {
            toast.error("Unexpected error: " + e.message);
        } finally {
            setShowConfirmationModal(false);
            setLoading(false);
        }
    };

    const handleCancelChangeUserStatus = () => {
        setShowConfirmationModal(false);
    };

    const showPasswordChangeModal = () => setShowChangePasswordModal(true);

    const handleAcceptChangePassword = async (e) => {
        try {
            setLoading(true);

            await updateUserPassword(userActive, e);

            toast.success(t("userPasswordUpdated"));
        } catch (error) {
            console.error(error.message);
            return;
        } finally {
            setLoading(false);
        }

        setShowChangePasswordModal(false);
    };

    const handleCancelChangePassword = () => {
        setShowChangePasswordModal(false);
    };

    return (
        <>
            <div className={styles.filtersContainer}>
                <div className={styles.searchContainer}>
                    <div className={styles.search}>
                        {/*<Label htmlFor="search">{t("search")}</Label>*/}
                        <Input
                            id="search"
                            name="search"
                            className="px-2"
                            value={searchInput}
                            onChange={handleInputChange}
                            placeholder={`${t("search")}...`}
                        />
                    </div>
                    <div className={styles.fieldContainer}>
                        <Selector
                            name="filterRole"
                            isLoading={roleList.isLoading}
                            options={roleList.roles}
                            value={filterRole}
                            onChange={handleFilterRoleChange}
                            styles={getSelectorStyle(false)}
                            isClearable
                            placeholder={`${t("select")} ${t("role").toLowerCase()}...`}
                        />
                    </div>
                    <div className={styles.fieldContainer}>
                        <Selector
                            name="filterOrganization"
                            options={organizationList.organizations}
                            value={filterOrganization}
                            onChange={handleFilterOrganizationChange}
                            styles={getSelectorStyle(false)}
                            placeholder={`${t("select")} ${t("organization").toLowerCase()}...`}
                            isClearable
                        />
                    </div>
                    <div className={`${styles.inactiveFilterContainer} d-flex align-items-center gap-2`}>
                        <Form.Check
                            id={`inactiveFilter`}
                            type="switch"
                            checked={filterInactive}
                            className={`${sharedStyles.customSwitch}`}
                            onChange={handleFilterInactiveChange}
                        />
                        <Label htmlFor="inactiveFilter">{t("inactive")}</Label>
                    </div>
                </div>
                <div className="d-flex gap-2 mx-3">
                    <button className={`${styles.btnAdd} btn btn-success`} disabled={showForm} onClick={handleClickAdd}>
                        {showForm ? t(addBtnLabel).toUpperCase() : t("add").toUpperCase()}
                    </button>
                    <div className={styles.totalPerPage}>
                        <select
                            id="select-limit"
                            className="py-1 px-2"
                            defaultValue={totalPerPage}
                            onChange={handleChangePerPage}
                        >
                            {TABLE_ROWS_LIMITS.map((rowLimit, index) => {
                                return (
                                    <option key={index} value={rowLimit}>
                                        {rowLimit}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
            </div>

            <div className={`${styles.contentContainer} mx-auto`}>
                {showForm && (
                    <div className={styles.formContainer}>
                        <form onSubmit={handleFormSubmit} className="py-3">
                            <div className="d-flex m-3 flex-column gap-5">
                                <div className={styles.formRow}>
                                    <div className={styles.fieldContainer}>
                                        <Label htmlFor="name" requiredIndicator>
                                            {t("fullName")}
                                        </Label>
                                        <Input
                                            type="text"
                                            id="name"
                                            name="name"
                                            className="px-3 py-2"
                                            value={name}
                                            onChange={handleInputChange}
                                            invalid={!!errors.name}
                                        />
                                        {errors.name && (
                                            <div className="mt-1">
                                                <p className={sharedStyle.errorMsg}>{errors.name.message}</p>
                                            </div>
                                        )}
                                    </div>

                                    <div className={styles.fieldContainer}>
                                        <Label htmlFor="email" requiredIndicator>
                                            {t("email")}
                                        </Label>
                                        <Input
                                            type="email"
                                            id="email"
                                            name="email"
                                            className="px-3 py-2"
                                            value={email}
                                            onChange={handleInputChange}
                                            onBlur={validateEmail}
                                            invalid={!!errors.email}
                                        />
                                        {errors.email && (
                                            <div className="mt-1">
                                                <p className={sharedStyle.errorMsg}>{errors.email.message}</p>
                                            </div>
                                        )}
                                    </div>

                                    <div className={styles.fieldContainer}>
                                        <Label htmlFor="phone">{t("phoneNumber")}</Label>
                                        <Input
                                            id="phone"
                                            name="phone"
                                            className="px-3 py-2"
                                            value={phone}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>

                                <div className={styles.formRow}>
                                    <div className={styles.fieldContainer}>
                                        <Label htmlFor="role" requiredIndicator>
                                            {t("role")}
                                        </Label>
                                        <Selector
                                            name="role"
                                            isDisabled={!roleList.roles.length}
                                            isLoading={roleList.isLoading}
                                            options={roleList.roles}
                                            value={role}
                                            onChange={handleRoleChange}
                                            styles={getSelectorStyle(!!errors.role)}
                                        />
                                        {errors.role && (
                                            <div className="mt-1">
                                                <p className={sharedStyle.errorMsg}>{errors.role.message}</p>
                                            </div>
                                        )}
                                    </div>

                                    <div className={styles.fieldContainer}>
                                        <Label htmlFor="organization">{t("organization")}</Label>
                                        <Selector
                                            name="organization"
                                            isDisabled={!organizationList.organizations.length}
                                            isLoading={organizationList.isLoading}
                                            options={organizationList.organizations}
                                            value={organization}
                                            onChange={handleOrganizationChange}
                                            styles={getSelectorStyle(!!errors.organization)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex">
                                {addBtnLabel === USER_ACTIONS.EDIT && (
                                    <div className="d-flex mx-4">
                                        <button
                                            type="button"
                                            className="btn btn-link"
                                            onClick={showPasswordChangeModal}
                                        >
                                            {t("changePassword")}
                                        </button>
                                    </div>
                                )}

                                <div className="d-flex m-3 justify-content-end gap-3 flex-grow-1">
                                    <button
                                        type="reset"
                                        className="btn btn-danger text-uppercase"
                                        onClick={handleFormCancel}
                                    >
                                        {t("cancel")}
                                    </button>

                                    <button type="submit" className="btn btn-success text-uppercase">
                                        {t("save")}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                )}

                <AdminTable
                    headers={["name", "email", "phoneNumber", "organization", "createdAt", "active", ""]}
                    data={usersData ? usersData : []}
                />

                <div className={styles.paginatorContainer}>
                    <Paginator
                        setPage={setActivePage}
                        activePage={activePage}
                        lastPage={Math.ceil(usersTotal ? usersTotal / totalPerPage : 1)}
                    />
                </div>
            </div>

            {loading && <Loading />}

            <ConfirmUserStatusModal
                show={showConfirmationModal}
                onAccept={handleAcceptChangeUserStatus}
                onCancel={handleCancelChangeUserStatus}
            />

            <ChangePasswordModal
                show={showChangePasswordModal}
                onAccept={handleAcceptChangePassword}
                onCancel={handleCancelChangePassword}
            />
        </>
    );
};

export default AdminUsers;
