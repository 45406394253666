import Joi from "joi";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Select from "react-select";
import Routes from "../../../base/js/routes";
import { addReviewAsync, editReviewAsync, getReviewAsync } from "../../../services/ReviewService";
import { getStaffAsync, getStaffByProjectIdAsync } from "../../../services/StaffService";
import Label from "../../common/forms/label/Label";
import useJoiValidation from "../../hooks/UseJoiValidation";
import Loading from "../../loading/Loading";
import sharedStyles from "../../shared-styles/FormStyle.module.scss";
import { getSelectorStyle } from "../../shared-styles/StylesFunctions";
import styles from "./FormReview.module.scss";

const BASE_SCHEMA = {
    staff: Joi.object({
        value: Joi.string().empty("").required(),
        label: Joi.string().empty("").required(),
    })
        .empty(null)
        .required(),
    subject: Joi.string().max(50).required(),
    review: Joi.string().max(3000).empty("").required(),
};

const SCHEMA_ERROR_MESSAGES = {
    "string.empty": "requiredFieldIsEmpty",
    "string.max": "fieldTooLong",
    "string.email": "invalidEmailFormat",
    "any.required": "requiredFieldIsEmpty",
};

const CreateReview = ({ create = true }) => {
    const params = useParams();
    const [searchParams] = useSearchParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { validateSchema, validateSubSchemaFromEvent, errors } = useJoiValidation();
    const [staffList, setStaffList] = useState([]);
    const [selectedStaff, setSelectedStaff] = useState(null);
    const [subject, setSubject] = useState("");
    const [review, setReview] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!create) {
            (async () => {
                try {
                    const data = await getReviewAsync(params.reviewId);

                    if (data) {
                        setSelectedStaff({ label: `${data.staff.name} ${data.staff.lastName}`, value: data.staff.id });
                        setSubject(data.subject);
                        setReview(data.comment);
                    }
                } catch (e) {
                    toast.error(t("unexpectedError") + ": " + e.message);
                }
            })();
        }
    }, []);

    useEffect(() => {
        const staffId = searchParams.get("staff");
        if (staffId) {
            (async () => {
                try {
                    const data = await getStaffAsync(staffId);

                    if (data) {
                        setSelectedStaff({ label: `${data.name} ${data.lastName}`, value: data.id });
                    }
                } catch (e) {
                    toast.error(t("unexpectedError") + ": " + e.message);
                }
            })();
        }
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const projectId = params.projectId;
                const data = await getStaffByProjectIdAsync(projectId);

                if (data) {
                    const dataMapped = data.map((staff) => {
                        return {
                            value: staff.id,
                            label: `${staff.name} ${staff.lastName}`,
                        };
                    });
                    setStaffList(dataMapped);
                }
            } catch (error) {
                console.error(error.message);
            }
        })();
    }, []);

    const handleChangeStaff = (staffSelected) => {
        setSelectedStaff(staffSelected);
        validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, {
            target: { id: "staff", value: staffSelected, name: "staff" },
        });
    };

    const handleChangeSubject = (event) => {
        setSubject(event.target.value);
        validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, event);
    };

    const handleChangeReview = (event) => {
        setReview(event.target.value);
        validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, event);
    };

    const handleSubmitForm = async (event) => {
        event.preventDefault();
        const formData = {
            staff: selectedStaff,
            subject: subject,
            review: review,
        };

        let cantErrors = validateSchema(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, formData);

        if (!!cantErrors) {
            return;
        }

        try {
            const dataToSend = {
                staffId: selectedStaff.value,
                projectId: params.projectId,
                subject: subject,
                comment: review,
            };

            if (!create) {
                dataToSend.id = params.reviewId;
            }

            setIsLoading(true);

            const response = create ? await addReviewAsync(dataToSend) : await editReviewAsync(dataToSend);
            if (response) {
                toast.success(create ? t("createReviewSuccess") : t("editReviewSuccess"));
                navigate(Routes.buildReviewsPath(params.projectId));
            }
        } catch (e) {
            toast.error("Unexpected error: " + e.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = (e) => {
        e.preventDefault();
        const staffId = searchParams.get("staff");
        if (staffId) {
            navigate(Routes.HOME);
        } else {
            navigate(Routes.buildReviewsPath(params.projectId));
        }
    };

    return (
        <main className={`${styles.mainContainer} mx-auto`}>
            <div className={styles.titleContainer}>
                <h1 className={styles.title}>{create ? t("newReview") : t("editReview")}</h1>
            </div>
            <form onSubmit={handleSubmitForm}>
                <div className="d-flex flex-column gap-5">
                    <div className={styles.formRow}>
                        <div className={styles.fieldContainer}>
                            <Label htmlFor="staff" requiredIndicator boldLabel={false}>
                                {t("staff")}:
                            </Label>
                            <Select
                                id="staff"
                                options={staffList}
                                styles={getSelectorStyle(!!errors.staff)}
                                value={selectedStaff}
                                onChange={handleChangeStaff}
                                placeholder={`${t("select")}...`}
                            />
                            {errors.staff && (
                                <div className="mt-1">
                                    <p className={sharedStyles.errorMsg}>{errors.staff.message}</p>
                                </div>
                            )}
                        </div>
                        <div className={styles.fieldContainer}>
                            <Label htmlFor="subject" requiredIndicator boldLabel={false}>
                                {t("subject")}:
                            </Label>
                            <input
                                id="subject"
                                name="subject"
                                type="text"
                                className={`${sharedStyles.inputText} p-2 ${
                                    !!errors.subject ? sharedStyles.invalidField : ""
                                }`}
                                value={subject}
                                onChange={handleChangeSubject}
                            ></input>
                            {errors.subject && (
                                <div className="mt-1">
                                    <p className={sharedStyles.errorMsg}>{errors.subject.message}</p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={styles.formRow}>
                        <div className={styles.fieldContainer}>
                            <Label boldLabel={false} requiredIndicator>
                                {t("review")}:
                            </Label>
                            <textarea
                                id="review"
                                name="review"
                                rows={5}
                                maxLength={3000}
                                className={`${sharedStyles.inputText} ${styles.textareaInput} p-2 ${
                                    !!errors.review ? sharedStyles.invalidField : ""
                                }`}
                                onChange={handleChangeReview}
                                value={review}
                            ></textarea>
                            {errors.review && (
                                <div className="mt-1">
                                    <p className={sharedStyles.errorMsg}>{errors.review.message}</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-end gap-3 mt-5 mb-4">
                    <div className={styles.btnContainer}>
                        <button className={`${styles.btn} ${styles.btnCancel} text-uppercase`} onClick={handleCancel}>
                            {t("cancel")}
                        </button>
                        <button type="submit" className={`${styles.btn} ${styles.btnSave} text-uppercase`}>
                            {t("save")}
                        </button>
                    </div>
                </div>
            </form>
            {isLoading && <Loading />}
        </main>
    );
};

export default CreateReview;
