import {
    LettersAndNumbersRegex,
    LettersNumbersAndSpecials,
    LettersRegex,
    NoWhitespaceRegex,
    UnitedStatesEINRegex,
    UnitedStatesTINRegex,
    UrlRegex,
} from "../../../utils/formValidation";

const useProcessInputs = () => {
    const processWithRegex = (regex, event, previousState) => {
        return regex.test(event.target.value) ? event.target.value : previousState;
    };

    const processLettersAndNumbersInput = (previousState, event) => {
        return processWithRegex(LettersAndNumbersRegex, event, previousState);
    };

    const processLettersInput = (previousState, event) => {
        return processWithRegex(LettersRegex, event, previousState);
    };

    const processUrlInput = (previousState, event) => {
        return processWithRegex(NoWhitespaceRegex, event, previousState);
    };

    const processNoSpacesInput = (previousState, event) => {
        return processWithRegex(NoWhitespaceRegex, event, previousState);
    };

    const processAlphanumeric = (previousState, event) => {
        return processWithRegex(LettersNumbersAndSpecials, event, previousState);
    };

    return {
        processLettersAndNumbersInput,
        processLettersInput,
        processUrlInput,
        processNoSpacesInput,
        processAlphanumeric,
    };
};

export default useProcessInputs;
