import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SURVEY_RATES } from "../../base/js/constants";
import Routes from "../../base/js/routes";
import { postSurvey } from "../../services/SurveyService";
import { setPendingSurvey } from "../../store/slices/userSlice";
import Label from "../common/forms/label/Label";
import Radio from "../common/Radio";
import Loading from "../loading/Loading";
import sharedStyles from "../shared-styles/FormStyle.module.scss";
import styles from "./Survey.module.scss";

const initialState = {
    value: null,
    isValid: true,
};

const Survey = () => {
    const { t } = useTranslation();
    const [experienceRate, setExperienceRate] = useState(initialState);
    const [recommendRate, setRecommendRate] = useState(initialState);
    const [comments, setComments] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleChangeExperienceRate = (event) => {
        setExperienceRate({ value: event.target.value, isValid: true });
    };

    const handleChangeRecommendRate = (event) => {
        setRecommendRate({ value: event.target.value, isValid: true });
    };

    const handleChangeComments = (event) => {
        setComments(event.target.value);
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        if (!experienceRate.value) setExperienceRate({ ...experienceRate, isValid: false });
        if (!recommendRate.value) setRecommendRate({ ...recommendRate, isValid: false });
        if (!experienceRate.value || !recommendRate.value) return;

        try {
            setIsLoading(true);

            const requestBody = {
                completionDateInitialSurvey: new Date(),
                registrationProcessRate: experienceRate.value,
                recommendationRate: recommendRate.value,
                comment: comments.trim(),
            };

            const response = await postSurvey(requestBody);

            if (response) {
                // If the survey has been submitted correctly, set the pending survey redux state to false
                dispatch(setPendingSurvey(false));

                // Finally, redirect to view Let's Start
                navigate(Routes.LETS_START);
            }
        } catch (error) {
            console.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <main className={`${styles.mainContainer} d-flex justify-content-center align-items-center min-vh-100`}>
                <div className={`${styles.formContainer} m-auto`}>
                    <form onSubmit={handleFormSubmit}>
                        <h1 className={styles.title}>{t("surveyTitle")}</h1>

                        <div className="mt-5">
                            <Label requiredIndicator>{t("howRateExperience")}</Label>
                            <div className="d-flex">
                                {SURVEY_RATES.map((rate) => (
                                    <div key={rate} className="d-flex flex-column-reverse gap-1 mx-2 mx-md-3 mx-lg-3">
                                        <Radio
                                            isValid={experienceRate.isValid}
                                            id={`experience-rate-${rate}`}
                                            name="experience_rate"
                                            value={rate}
                                            onChange={handleChangeExperienceRate}
                                            flexColumn
                                        >
                                            {rate}
                                        </Radio>
                                    </div>
                                ))}
                            </div>
                            <div className={!experienceRate.isValid ? "mt-1" : ""}>
                                {!experienceRate.isValid && (
                                    <p className={sharedStyles.errorMsg}>{t("mandatoryField")}</p>
                                )}
                            </div>
                        </div>

                        <div className="mt-5">
                            <Label requiredIndicator>{t("howRecommendTheDevBlock")}</Label>
                            <div className="d-flex">
                                {SURVEY_RATES.map((rate) => (
                                    <div key={rate} className="d-flex flex-column-reverse gap-1 mx-2 mx-md-3 mx-lg-3">
                                        <Radio
                                            isValid={recommendRate.isValid}
                                            id={`recommend-rate-${rate}`}
                                            name="recommend_rate"
                                            value={rate}
                                            onChange={handleChangeRecommendRate}
                                            flexColumn
                                        >
                                            {rate}
                                        </Radio>
                                    </div>
                                ))}
                            </div>
                            <div className={!recommendRate.isValid ? "mt-1" : ""}>
                                {!recommendRate.isValid && (
                                    <p className={sharedStyles.errorMsg}>{t("mandatoryField")}</p>
                                )}
                            </div>
                        </div>

                        <div className="mt-5">
                            <Label>{t("surveyComments")}</Label>
                            <textarea
                                rows={5}
                                value={comments}
                                onChange={handleChangeComments}
                                className={styles.textarea}
                            ></textarea>
                        </div>

                        <button className={`${styles.btnFinish} text-uppercase my-5 d-block ms-auto mx-lg-auto`}>
                            {t("send")}
                        </button>
                    </form>
                </div>
            </main>

            {isLoading && <Loading />}
        </>
    );
};

export default Survey;
