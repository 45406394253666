import Joi from "joi";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import Selector from "react-select";
import { ModalButtonVariant } from "../../../../base/js/constants";
import { getProjectsBasicByOrganization } from "../../../../services/ProjectService";
import {
    ALLOWED_MIMETYPES,
    ALLOWED_TYPE_NAMES,
    MAX_ATTACHMENT_FILESIZE,
} from "../../../build-your-team/assemble-your-team/constants";
import InputFile from "../../../common/custom-input-file/CustomInputFile";
import Label from "../../../common/forms/label/Label";
import ModalButton from "../../../common/modal/buttons/ModalButton";
import Modal from "../../../common/modal/Modal";
import useJoiValidation from "../../../hooks/UseJoiValidation";
import sharedStyle from "../../../shared-styles/FormStyle.module.scss";
import { getSelectorStyle } from "../../../shared-styles/StylesFunctions";
import styles from "../AdminOrganization.module.scss";
import style from "./AddStatementModal.module.scss";

const BASE_SCHEMA = {
    project: Joi.object({
        value: Joi.string().empty("").required(),
        label: Joi.string().required(),
    })
        .empty(null)
        .required(),
};

const SCHEMA_ERROR_MESSAGES = {
    "any.required": "requiredFieldIsEmpty",
    "any.invalid": "invalidFormatUrl",
    "string.invalid": "invalidTaxIDFormat",
    "string.empty": "requiredFieldIsEmpty",
    "object.missing": "requiredFieldIsEmpty",
    "string.max": "fieldTooLong",
};

const AddStatementModal = ({ show, organizationId = null, onAccept, onCancel }) => {
    const { t } = useTranslation();

    const { validateSchema, resetErrors, errors } = useJoiValidation();
    const [projectList, setProjectList] = useState({ data: [], isLoading: false });
    const [projectSelected, setProjectSelected] = useState(null);
    const [statementFile, setStatementFile] = useState({
        fileName: "",
        isLoading: false,
        errors: [],
        isValid: true,
    });
    const [file, setFile] = useState(null);

    useEffect(() => {
        (async () => {
            try {
                if (organizationId) {
                    setProjectList({ ...projectList, isLoading: true });

                    const data = await getProjectsBasicByOrganization(organizationId);

                    if (data) {
                        setProjectList({
                            data: data?.map((project) => {
                                return {
                                    value: project.id,
                                    label: project.name,
                                };
                            }),
                            isLoading: false,
                        });
                    }
                }
            } catch (error) {
                console.error(error.message);
            } finally {
                setProjectList((prev) => ({
                    data: prev.data,
                    isLoading: false,
                }));
            }
        })();
    }, [organizationId]);

    const handleProjectChange = (projectSelected) => {
        setProjectSelected(projectSelected);
    };

    const handleStatementChange = async (event) => {
        if (event.target.id !== "statementFile") {
            throw new Error(`Unrecongized element (ID "${event.target.id}") to handle attachment`);
        }

        const fileSize = event.target.files[0].size;
        const fileType = event.target.files[0].type;

        const errors = [];

        if (fileSize > MAX_ATTACHMENT_FILESIZE) {
            errors.push("fileSizeLimit");
            return;
        } else if (!ALLOWED_MIMETYPES.includes(fileType)) {
            errors.push("allowedFileTypes");
            return;
        }

        let finalObj;

        if (errors.length) {
            finalObj = {
                fileName: "",
                isValid: false,
                isLoading: false,
                errors,
            };
        } else {
            finalObj = {
                fileName: event.target.files[0]?.name,
                isValid: true,
                isLoading: false,
            };
        }

        setStatementFile(finalObj);
        setFile(event.target.files[0]);
    };

    const handleAcceptClick = (e) => {
        e.preventDefault();

        const formData = {
            project: projectSelected,
        };

        let cantErrors = validateSchema(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, formData);

        if (!file) {
            setStatementFile({
                ...statementFile,
                isValid: false,
                errors: [...statementFile.errors, "requiredFieldIsEmpty"],
            });
        }

        if (!!cantErrors || !statementFile.isValid) {
            return;
        }

        const dataToSend = { project: projectSelected, file: file };
        onAccept(dataToSend);
        setFile(null);
        setProjectSelected(null);
    };

    const handleCancelClick = (e) => {
        e.preventDefault();
        setFile(null);
        setProjectSelected(null);
        setStatementFile({
            fileName: "",
            isLoading: false,
            errors: [],
            isValid: true,
        });
        resetErrors();
        onCancel(e);
    };

    return (
        <Modal show={show} className={style.modalWidth}>
            <span className={`${style.modalTitle} text-bold d-block text-center`}>{t("addStatement")}</span>

            <form>
                <div className="d-flex m-3 flex-column gap-2">
                    <div className={styles.fieldContainer}>
                        <Label htmlFor={"project"} requiredIndicator>
                            {t("project")}:
                        </Label>
                        <Selector
                            id="project"
                            name="project"
                            styles={getSelectorStyle(!!errors.project)}
                            options={projectList.data}
                            isLoading={projectList.isLoading}
                            onChange={handleProjectChange}
                            value={projectSelected}
                        />
                        {errors.project && (
                            <div className="mt-1">
                                <p className={sharedStyle.errorMsg}>{errors.project.message}</p>
                            </div>
                        )}
                    </div>
                    <div className={styles.fieldContainer}>
                        <Label htmlFor="statementFile" requiredIndicator>
                            {t("statement")}
                        </Label>
                        <InputFile
                            id="statementFile"
                            inputFileChange={handleStatementChange}
                            invalid={!statementFile.isValid}
                            isLoading={statementFile.isLoading}
                            disabled={statementFile.isLoading}
                        />
                        <div className={!statementFile.isValid ? "mt-1" : ""}>
                            {!statementFile.isValid &&
                                statementFile.errors.map((error, i) => (
                                    <p key={i} className={sharedStyle.errorMsg}>
                                        {error === "allowedFileTypes"
                                            ? t(error) + " " + ALLOWED_TYPE_NAMES.join(", ")
                                            : t(error)}
                                    </p>
                                ))}
                        </div>
                    </div>
                </div>
            </form>

            <div className="d-flex justify-content-center gap-4 mt-4">
                <ModalButton variant={ModalButtonVariant.GREEN} onClick={handleAcceptClick}>
                    {t("accept")}
                </ModalButton>

                <ModalButton variant={ModalButtonVariant.WHITE} onClick={handleCancelClick}>
                    {t("cancel")}
                </ModalButton>
            </div>
        </Modal>
    );
};

AddStatementModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onAccept: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default AddStatementModal;
