import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import linkedinIcon from "../../../assets/linkedin.svg";
import messageIcon from "../../../assets/message.svg";
import locationIcon from "../../../assets/pin-location.svg";
import whatsappIcon from "../../../assets/whatsapp.svg";
import Routes from "../../../base/js/routes";
import styles from "./SocialsSection.module.scss";

const SocialsSection = () => {
    const { t } = useTranslation();
    const tdbInformation = useSelector((state) => state.tdbInformation.info);

    return (
        <div className="d-flex flex-column gap-3 col-lg-2">
            <span className="fw-bold mb-2">{t("contactUs2")}</span>

            <a href={tdbInformation.linkedinUrl.data} 
               className="d-flex gap-2 align-items-start" 
               target="_blank"
               rel="noreferrer"
            >
                <img src={linkedinIcon} alt="" className={styles.icon} />
                <span>{tdbInformation.linkedinLabel.data}</span>
            </a>

            {tdbInformation.phone?.data && (
                <span className="d-flex gap-2 align-items-start">
                    <img src={whatsappIcon} alt="" className={styles.icon} />
                    {tdbInformation.phone.data}
                </span>
            )}

            {tdbInformation.email?.data && (
                <a href={`mailto:${tdbInformation.email.data}`} className="d-flex gap-2 align-items-start">
                    <img src={messageIcon} alt="" className={styles.icon} style={{ marginTop: "5px" }} />
                    <span>{tdbInformation.email.data}</span>
                </a>
            )}

            {tdbInformation.address?.data && (
                <span className="d-flex gap-2 align-items-start">
                    <img src={locationIcon} alt="" className={styles.icon} />
                    {tdbInformation.address.data}
                </span>
            )}

            <Link to={Routes.TERMS_AND_CONDITIONS} className="d-none d-lg-block fw-bold mt-2">
                {t("termsAndConditions")}
            </Link>
        </div>
    );
};

export default SocialsSection;
