import PropTypes from "prop-types";
import sharedStyles from "../../../shared-styles/FormStyle.module.scss";
import styles from "./Input.module.scss";

const Input = ({
    value,
    type = "text",
    name = undefined,
    id = undefined,
    className = undefined,
    onChange = undefined,
    onBlur = undefined,
    disabled = false,
    invalid = false,
    readOnly = false,
    autoComplete = undefined,
    placeholder = undefined,
    min = undefined,
    max = undefined,
}) => {
    let cssClasses = styles.input;

    if (className) {
        cssClasses += " " + className;
    }

    if (invalid) {
        cssClasses += " " + sharedStyles.invalidField;
    }

    return (
        <input
            id={id}
            name={name}
            type={type}
            className={cssClasses}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
            readOnly={readOnly}
            autoComplete={autoComplete}
            placeholder={placeholder}
            min={min}
            max={max}
        />
    );
};

Input.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
    className: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,
    invalid: PropTypes.bool,
    readOnly: PropTypes.bool,
    autoComplete: PropTypes.string,
    placeholder: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
};

export default Input;
