import style from "./LangSelector.module.scss";

const LangSelector = ({ langs, currentLang, onLangChange }) => {
    const langsOptions = Object.keys(langs).map((lang) => {
        let cssClasses = `${style.btn} text-uppercase ${currentLang === lang ? style.btnActive : ""}`;

        const onOptionClick = (event) => {
            event.preventDefault();
            event.stopPropagation();
            onLangChange(lang);
        };

        return (
            <button key={lang} className={cssClasses} onClick={onOptionClick}>
                {lang}
            </button>
        );
    });

    return <div className={`${style.container} d-flex`}>{langsOptions}</div>;
};

export default LangSelector;
