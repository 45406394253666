import { generateErrorToast } from "../base/js/httpError";
import { API_BASE, request } from "../utils/httpRequest";

export const getProjectTraces = async (projectId) => {
    try {
        return await request(`${API_BASE}/api/projectTrace/${projectId}`);
    } catch (error) {
        generateErrorToast(error);
    }
};
