import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ModalButtonVariant } from "../../../base/js/constants";
import ModalButton from "../../common/modal/buttons/ModalButton";
import Modal from "../../common/modal/Modal";
import sharedStyles from "../../shared-styles/FormStyle.module.scss";
import Label from "../forms/label/Label";
import style from "./ReviewViewModal.module.scss";

const ReviewViewModal = ({ show, onClose, review }) => {
    const { t } = useTranslation();

    return (
        <Modal show={show} className={style.modalXL}>
            <div className="d-flex flex-column gap-5">
                <div className={style.formRow}>
                    <div className={style.fieldContainer}>
                        <Label htmlFor="staff" requiredIndicator boldLabel={false}>
                            {t("staff")}:
                        </Label>
                        <span
                            className={`${sharedStyles.inputText} ${style.disabled} p-1`}
                        >{`${review?.staff.name} ${review?.staff.lastName}`}</span>
                    </div>
                    <div className={style.fieldContainer}>
                        <Label htmlFor="subject" requiredIndicator boldLabel={false}>
                            {t("subject")}:
                        </Label>
                        <span className={`${sharedStyles.inputText} p-1 ${style.disabled}`}>{review?.subject}</span>
                    </div>
                </div>
                <div className={style.formRow}>
                    <div className={style.fieldContainer}>
                        <Label boldLabel={false} requiredIndicator>
                            {t("review")}:
                        </Label>
                        <span className={`${sharedStyles.inputText} ${style.textareaInput} p-2 ${style.disabled}`}>
                            {review?.comment}
                        </span>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center gap-4 mt-4">
                <ModalButton variant={ModalButtonVariant.WHITE} onClick={onClose}>
                    {t("close")}
                </ModalButton>
            </div>
        </Modal>
    );
};

ReviewViewModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default ReviewViewModal;
