import PropTypes from "prop-types";
import { createElement, useEffect, useState } from "react";
import Loading from "../../loading/Loading";
import styles from "./InfiniteScroll.module.scss";

// Percentage to trigger the update event
const TRIGGER_UPDATE_AT_PERCENTAGE = 98;

const InfiniteScroll = ({
    children,
    onBottomReached,
    onLoading,
    loading,
    className = undefined,
    disabled = false,
    as = "div",
}) => {
    const [scrollAtBottom, setScrollAtBottom] = useState(false);

    useEffect(() => {
        if (!scrollAtBottom || loading || disabled) {
            return;
        }

        (async () => {
            try {
                onLoading(true);
                await onBottomReached();
            } catch (e) {
                console.error(e.message);
            } finally {
                onLoading(false);
            }
        })();
    }, [scrollAtBottom]);

    const onScroll = (e) => {
        const scrollAt = e.target.scrollTop + e.target.clientHeight;
        const maxScroll = e.target.scrollHeight;

        // Calculate the % of the entire available scroll
        const computedMaxScroll = (maxScroll * TRIGGER_UPDATE_AT_PERCENTAGE) / 100;

        // Trigger the event when the scroll is at least at the 98%
        const scrollEnd = scrollAt >= computedMaxScroll;

        setScrollAtBottom(scrollEnd);
    };

    return createElement(
        as,
        {
            className: `${styles.container}${className ? " " + className : ""}`,
            onScroll,
        },
        children
    );
};

InfiniteScroll.propTypes = {
    onBottomReached: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    onLoading: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};

export default InfiniteScroll;
