import { generateErrorToast } from "../base/js/httpError";
import { API_BASE, request } from "../utils/httpRequest";

export const addBusinessSectorAsync = async (data) => {
    try {
        return await request(API_BASE + "/api/businesssector", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const getBusinessSectorsAsync = async () => {
    try {
        return await request(API_BASE + "/api/businesssector");
    } catch (error) {
        generateErrorToast(error);
    }
};
