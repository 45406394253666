import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useHeaderHeight from "../hooks/UseHeaderHeight";
import { TermsAndConditionsContent } from "./Content";
import { getTermsAndConditions } from "../../services/TermsAndConditionsService";
import Loading from "../loading/Loading";
import style from "./TermsAndConditions.module.scss";

const TermsAndConditions = () => {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [termsAndConditions, setTermsAndConditions] = useState();
    const marginTop = useHeaderHeight();    

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const data = await getTermsAndConditions();
                if (data.length > 0) {
                    const { titleEn, titleEs } = data[0];
                    const sections = data.map(({ subTitleEn, subTitleEs, contentEn, contentEs }) => ({
                        subTitle: {
                            en: subTitleEn,
                            es: subTitleEs,
                            error: ""
                        },
                        content: {
                            en: contentEn,
                            es: contentEs,
                            error: ""
                        }
                    }));

                    setTermsAndConditions({
                        title: { en: titleEn, es: titleEs, error: "" },
                        sections
                    });
                }
            } catch (error) {
                console.error(error.message);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    return (
        <main className={style.container} style={{ marginTop }}>
            <div
                className={`${style.containerTitle} d-flex align-items-center justify-content-center justify-content-md-start text-center text-md-start`}
            >
                <h1 className={style.title}>{termsAndConditions ? termsAndConditions.title[i18n.resolvedLanguage] : t("termsAndConditionsSite")}</h1>
            </div>
            <div className={style.containerContent}>
                <TermsAndConditionsContent termsAndConditions={termsAndConditions} />
            </div>
            {loading && <Loading />}
        </main>
    );
};

export default TermsAndConditions;
