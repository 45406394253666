import { generateErrorToast } from "../base/js/httpError";
import { API_BASE, request } from "../utils/httpRequest";

export const getLanguageAsync = async (id) => {
    try {
        return await request(API_BASE + "/api/language/" + id);
    } catch (error) {
        generateErrorToast(error);
    }
};

export const getLanguageListAsync = async () => {
    try {
        return await request(API_BASE + "/api/language", {
            method: "GET",
        });
    } catch (error) {
        generateErrorToast(error);
    }
};
