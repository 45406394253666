import { forwardRef } from "react";
import { DropdownButton, Form } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import "react-datepicker/dist/react-datepicker.css";
import sharedStyles from "../../shared-styles/FormStyle.module.scss";
import styles from "../../shared-styles/TableStyle.module.scss";

const AdminTable = ({ headers, data }) => {
    const { t } = useTranslation();

    return (
        <div className="col-12">
            <table className="text-left col-12">
                <thead>
                    <tr>
                        {headers?.map((head, index) => {
                            return <th key={index}>{t(head)}</th>;
                        })}
                    </tr>
                </thead>
                <tbody>
                    {data?.map((columns, index) => {
                        const rowContent = [];
                        for (const key in columns) {
                            if (key === "actions") {
                                rowContent.push(
                                    <td key={uuidv4()} className={`${styles.actions} text-center`}>
                                        <DropdownButton
                                            id="dropdown-basic-button"
                                            title={t("actions")}
                                            size="sm"
                                            variant="secondary"
                                            disabled={columns[key].length === 0}
                                        >
                                            {columns[key].map((action) => {
                                                const CustomDropdownItem = forwardRef(({ children, onClick }, ref) => {
                                                    if (typeof action.action === "function") {
                                                        return (
                                                            <a
                                                                className={`px-3 dropdown-item-${action.color}`}
                                                                ref={ref}
                                                                href={"#"}
                                                                onClick={action.action}
                                                            >
                                                                {t(action.label)}
                                                            </a>
                                                        );
                                                    } else {
                                                        return (
                                                            <a target="_blank" href={action.action}>
                                                                {t(action.label)}
                                                            </a>
                                                        );
                                                    }
                                                });

                                                return (
                                                    <Dropdown.Item
                                                        as={CustomDropdownItem}
                                                        key={uuidv4()}
                                                        href="#"
                                                    ></Dropdown.Item>
                                                );
                                            })}
                                        </DropdownButton>
                                    </td>
                                );
                            } else if (key === "active") {
                                rowContent.push(
                                    <td key={uuidv4()}>
                                        <Form.Check
                                            id={`active-${uuidv4()}`}
                                            type="switch"
                                            checked={columns[key].isActive}
                                            className={`${sharedStyles.customSwitch}`}
                                            onChange={columns[key].action}
                                        />
                                    </td>
                                );
                            } else {
                                rowContent.push(<td key={uuidv4()}>{columns[key]}</td>);
                            }
                        }

                        return <tr key={index}>{rowContent}</tr>;
                    })}
                    {!data.length && (
                        <tr>
                            <td colSpan={headers.length} className={styles.noDataContent}>
                                {t("noDataToShow")}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default AdminTable;
