import { format, parse, parseISO } from "date-fns";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { v4 as uuidV4 } from "uuid";
import { FORMATTER, TAM_LABEL } from "../../../base/js/constants";
import Routes from "../../../base/js/routes";
import { getProjectAsync } from "../../../services/ProjectService";
import { restartState } from "../../../store/slices/assembleYourTeamSlice";
import BtnUp from "../../common/btn-up/BtnUp";
import Button, { variants as btnVariants } from "../../common/Button";
import TeamCard from "../../common/team-card/TeamCard";
import Loading from "../../loading/Loading";
import { DATE_FORMAT } from "../assemble-your-team/constants";
import CountDown from "../count-down/CountDown";
import styles from "./Confirmation.module.scss";

const TAM_POSITION = {
    role: { label: TAM_LABEL },
    seniority: { label: "Senior" },
    jobSkills: "",
    total: 1,
    rate: 0,
};

const Confirmation = ({ resetPhase }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();

    const teamName = useSelector((state) => state.assembleYourTeam.teamName);
    const startDate = useSelector((state) => state.assembleYourTeam.startDate);
    const endDate = useSelector((state) => state.assembleYourTeam.endDate);
    const positions = useSelector((state) => state.assembleYourTeam.positions);

    const [project, setProject] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            const from = searchParams.get("from");
            if (!from) {
                const total = positions.reduce((total, position) => {
                    if (position?.rate) {
                        return total + position.rate * (position.time ? 80 : 160);
                    }
                    return total;
                }, 0);

                // TODO Fetch from API
                const projectAPI = {
                    teamName,
                    startDate,
                    endDate,
                    monthPrice: FORMATTER.format(total),
                    finalPrice: FORMATTER.format(total),
                    team: positions.map((position) => ({
                        ...position,
                        id: uuidV4(),
                        rate: position?.rate ? position?.rate : 0,
                    })), // TODO Get information about rate
                    createdAt: format(new Date(), DATE_FORMAT),
                };

                setProject(projectAPI);
                return;
            }

            try {
                setLoading(true);

                const data = await getProjectAsync(from, true);

                if (data) {
                    const fetchedProject = {
                        id: data.id,
                        name: data.name,
                        status: data.projectStatus[0].status.value,
                        startDate: format(parseISO(data.dateStart), DATE_FORMAT),
                        endDate: format(parseISO(data.dateEnd), DATE_FORMAT),
                        monthPrice: FORMATTER.format(data.monthPrice),
                        finalPrice: FORMATTER.format(data.finalPrice),
                        createdAt: project.createdAt,
                        team: data.positions.map((p) => {
                            // Convert each tag object to a string
                            p.tags = p.tags?.map((t) => t.value) || [];

                            // Parse the data from the API to make it work with the structure
                            // of the view
                            return {
                                ...p,
                                role: { label: p.developmentRol.name },
                                seniority: { label: p.seniority.name },
                                jobSkills: p.positionCustomValues.map((cv) => cv.customValue.value).join(", "),
                                rate: 0,
                            };
                        }),
                    };

                    // Set the TAM position manually
                    fetchedProject.team = [TAM_POSITION, ...fetchedProject.team];

                    setProject(fetchedProject);
                }
            } catch (error) {
                console.error(error.message);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    const handleClose = () => {
        navigate(Routes.HOME);
        // Note: the status on Redux will be automatically cleaned, so we don't have to do it manually.
    };

    const onNewProjectClick = () => {
        searchParams.delete("from");
        resetPhase();
        dispatch(restartState());
    };

    return (
        <>
            <section className="col-12 mb-5">
                <h1 className={`${styles.title} mb-5`}>{t("orderSent")}</h1>

                <section className={`${styles.firstSectionContainer} flex-wrap`}>
                    <div className="d-flex flex-column-reverse flex-md-row flex-lg-row">
                        <div
                            className={`${styles.datePriceContainer} col-lg-4 col-md-6 d-flex flex-column justify-content-between`}
                        >
                            <div className={`${styles.priceContainer} d-flex gap-5`}>
                                <div className="d-flex flex-column">
                                    <label>{t("monthPrice")}:</label>
                                    <span>{project.monthPrice}</span>
                                </div>

                                {/*<div className="d-flex flex-column">*/}
                                {/*    <label>{t("finalPrice")}:</label>*/}
                                {/*    <span>{project.finalPrice}</span>*/}
                                {/*</div>*/}
                            </div>
                        </div>

                        <div className="col-lg-8 col-md-6 d-flex flex-column-reverse flex-md-column flex-lg-column justify-content-start align-items-center">
                            <div>
                                <CountDown
                                    startDate={
                                        project.createdAt ? parse(project.createdAt, DATE_FORMAT, new Date()) : null
                                    }
                                />
                            </div>
                            <span className={`${styles.countdownLabel} mt-4 mt-md-0 mt-lg-0`}>
                                {t("remainingTime")}
                            </span>
                        </div>
                    </div>
                    <h2 className={`${styles.title} mt-5`}>{t("teamFormation")}</h2>
                </section>

                <section className={`${styles.cardSection} d-flex flex-column flex-md-row flex-wrap gap-3 mb-5`}>
                    {project.team?.map((position) => {
                        const cards = [];

                        for (let i = 0; i < position.total; i++) {
                            cards.push(
                                <div key={`${uuidV4()}_${position.id}`}>
                                    <TeamCard
                                        role={position.role?.label}
                                        seniority={position.seniority?.label}
                                        skills={position.jobSkills}
                                        rate={position.rate}
                                        greenBg
                                    />
                                </div>
                            );
                        }

                        return cards;
                    })}
                </section>

                <section className="d-flex justify-content-center flex-column align-items-center">
                    <span className={`${styles.noteContainer} text-center justify-content-center mb-5`}>
                        <Trans
                            components={{ span1: <span className="d-block" />, span2: <span className="fw-bold" /> }}
                        >
                            confirmationFinishSelection
                        </Trans>
                    </span>

                    <div className="d-flex flex-column flex-md-row gap-3 justify-content-center position-relative w-100">
                        <Button
                            cssClasses={[styles.btnClose]}
                            variant={btnVariants.PRIMARY_INVERSE}
                            onClick={handleClose}
                        >
                            {t("close")}
                        </Button>
                        <Button cssClasses={[styles.btnNewTeam, "d-none", "d-md-block"]} onClick={onNewProjectClick}>
                            {t("startBuildingANewTeam")}
                        </Button>
                    </div>
                </section>

                <BtnUp />
            </section>

            {loading && <Loading />}
        </>
    );
};

Confirmation.propTypes = {
    resetPhase: PropTypes.func.isRequired,
};

export default Confirmation;
