import { addMonths, parse } from "date-fns";
import { forwardRef, useContext } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { getTimezones } from "../../../../services/TimezoneService";
import { parseTimezones } from "../../../../utils/timezonesParsing";
import Checkbox from "../../../common/Checkbox";
import Label from "../../../common/forms/label/Label";
import sharedStyle from "../../../shared-styles/FormStyle.module.scss";
import { getSelectorStyle } from "../../../shared-styles/StylesFunctions";
import BYTContext from "../../BYTContext";
import { DATE_FORMAT, ProjectTimeLimits } from "../constants";

export const RoleCheckboxNames = {
    DECISION_MAKER: "decisionMaker",
    RECOMMENDER: "recommender",
    USER: "user",
    OTHERS: "others",
};

export const FormElementsIds = {
    TEAM_NAME: "input-team-name",
    INVESTMENT_EXPECTATION: "input-investment-expectation",
    DECISION_FLOW: "textarea-decision-flow",
    checkboxes: {
        DECISION_MAKER: "checkbox-" + RoleCheckboxNames.DECISION_MAKER,
        RECOMMENDER: "checkbox-" + RoleCheckboxNames.RECOMMENDER,
        USER: "checkbox-" + RoleCheckboxNames.USER,
        OTHERS: "checkbox-" + RoleCheckboxNames.OTHERS,
        ROLES_OTHERS: "input-roles-others",
    },
    START_DATE: "datepicker-start-date",
    END_DATE: "datepicker-end-date",
    TIMEZONE: "input-timezone",
    MIN_CONFIRMATION_DATE: "input-date-to-confirm",
};

const StartDateInput = forwardRef(({ value, onClick, startDate, isValid }, ref) => (
    <input
        ref={ref}
        id="input-start-date"
        type="text"
        className={`${sharedStyle.inputDate} ${isValid ? "" : sharedStyle.invalidField} px-3 py-2`}
        value={value}
        onClick={onClick}
        readOnly
    />
));

const EndDateInput = forwardRef(({ value, onClick, startDate, endDate, isValid }, ref) => (
    <input
        ref={ref}
        id="input-end-date"
        type="text"
        className={`${sharedStyle.inputDate} ${isValid ? "" : sharedStyle.invalidField} px-3 py-2`}
        value={value}
        onClick={onClick}
        disabled={!startDate}
        readOnly
    />
));

const ExpectedDateToConfirmInput = forwardRef(({ value, onClick, expectedDateState, isValid }, ref) => (
    <input
        ref={ref}
        id="input-date-to-confirm"
        type="text"
        className={`${sharedStyle.inputDate} ${isValid ? "" : sharedStyle.invalidField} px-3 py-2`}
        value={value}
        onClick={onClick}
        readOnly
    />
));

const FirstSection = ({
    teamNameState,
    investmentExpectationState,
    decisionFlowState,
    timezoneState,
    selectedCheckboxesState,
    othersCheckboxInputState,
    startDateState,
    endDateState,
    estimatedConfDateState,
    onChange,
    errors,
}) => {
    const { t } = useTranslation();
    const { timezones } = useContext(BYTContext);

    const onExpectedDateToConfirmChange = (date) => {
        onChange({ target: { id: FormElementsIds.MIN_CONFIRMATION_DATE, value: date, name: "expectedDateToConfirm" } });
    };

    const onTimezoneChange = (value) => {
        onChange({ target: { id: FormElementsIds.TIMEZONE, value, name: "timeZone" } });
    };

    const onEndDateChange = (date) => {
        onChange({ target: { id: FormElementsIds.END_DATE, value: date, name: "estimatedDateToEnd" } });
    };

    const onStartDateChange = (date) => {
        onChange({ target: { id: FormElementsIds.START_DATE, value: date, name: "estimatedDateToStart" } });
    };

    return (
        <div className={sharedStyle.section}>
            <h2 className={sharedStyle.title}>{t("newProjectSetup")}</h2>
            <div className={`${sharedStyle.container} p-4`}>
                <div className="col-11 d-flex flex-column">
                    <Label
                        htmlFor={FormElementsIds.TEAM_NAME}
                        informationAboutField="Maecenas sodales dapibus lectus, id pulvinar tortor iaculis non."
                        informationIndicator
                        requiredIndicator
                    >
                        {t("teamName")}
                    </Label>
                    <div className="mb-4">
                        <input
                            type="text"
                            id={FormElementsIds.TEAM_NAME}
                            name="teamName"
                            className={`${sharedStyle.inputText} ${
                                !errors.teamName ? "" : sharedStyle.invalidField
                            } px-3 py-2`}
                            value={teamNameState}
                            onChange={onChange}
                        />
                        {errors.teamName && (
                            <div className="mt-1">
                                <p className={sharedStyle.errorMsg}>{errors.teamName.message}</p>
                            </div>
                        )}
                    </div>

                    <Label htmlFor={FormElementsIds.INVESTMENT_EXPECTATION}>{t("investmentExpectation")}</Label>
                    <div className="mb-4">
                        <input
                            type="number"
                            name="investmentExpectation"
                            id={FormElementsIds.INVESTMENT_EXPECTATION}
                            className={`${sharedStyle.inputText} ${
                                !errors.investmentExpectation ? "" : sharedStyle.invalidField
                            } px-3 py-2`}
                            value={investmentExpectationState}
                            onChange={onChange}
                        />
                        {errors.investmentExpectation && (
                            <div className="mt-1">
                                <p className={sharedStyle.errorMsg}>{errors.investmentExpectation.message}</p>
                            </div>
                        )}
                    </div>

                    <Label
                        requiredIndicator={true}
                        htmlFor={FormElementsIds.DECISION_FLOW}
                        informationAboutField="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque porttitor euismod auctor."
                        informationIndicator
                    >
                        {t("yourDecisionFlow")}
                    </Label>
                    <div className="mb-4">
                        <textarea
                            id={FormElementsIds.DECISION_FLOW}
                            name="yourDecisionFlow"
                            className={`${sharedStyle.textArea} ${
                                !errors.yourDecisionFlow ? "" : sharedStyle.invalidField
                            } px-3 py-2`}
                            rows="4"
                            value={decisionFlowState}
                            onChange={onChange}
                        />
                        {errors.yourDecisionFlow && (
                            <div className="mt-1">
                                <p className={sharedStyle.errorMsg}>{errors.yourDecisionFlow.message}</p>
                            </div>
                        )}
                    </div>

                    <Label requiredIndicator>{t("rolesInYourCompany")}</Label>
                    <div className={`ms-4 mt-2 ${selectedCheckboxesState[RoleCheckboxNames.OTHERS] ? "" : "mb-4"}`}>
                        <Checkbox
                            checked={selectedCheckboxesState[RoleCheckboxNames.DECISION_MAKER]}
                            onChange={onChange}
                            name={RoleCheckboxNames.DECISION_MAKER}
                            className="mb-3"
                            isValid={!errors.rolesInYourCompany}
                        >
                            {t("decisionMakerRole")}
                        </Checkbox>

                        <Checkbox
                            checked={selectedCheckboxesState[RoleCheckboxNames.RECOMMENDER]}
                            onChange={onChange}
                            name={RoleCheckboxNames.RECOMMENDER}
                            className="mb-3"
                            isValid={!errors.rolesInYourCompany}
                        >
                            {t("recommenderRole")}
                        </Checkbox>

                        <Checkbox
                            checked={selectedCheckboxesState[RoleCheckboxNames.USER]}
                            onChange={onChange}
                            name={RoleCheckboxNames.USER}
                            className="mb-3"
                            isValid={!errors.rolesInYourCompany}
                        >
                            {t("userRole")}
                        </Checkbox>

                        <Checkbox
                            checked={selectedCheckboxesState[RoleCheckboxNames.OTHERS]}
                            onChange={onChange}
                            name={RoleCheckboxNames.OTHERS}
                            isValid={!errors.rolesInYourCompany}
                        >
                            {t("othersRole")}
                            {selectedCheckboxesState[RoleCheckboxNames.OTHERS] && ":"}
                        </Checkbox>

                        {!selectedCheckboxesState[RoleCheckboxNames.OTHERS] && errors.rolesInYourCompany && (
                            <div className="mt-1">
                                <p className={sharedStyle.errorMsg}>{errors.rolesInYourCompany.message}</p>
                            </div>
                        )}
                    </div>
                    <div className={`col-lg-5 ${selectedCheckboxesState[RoleCheckboxNames.OTHERS] ? "mb-4" : ""}`}>
                        {selectedCheckboxesState[RoleCheckboxNames.OTHERS] && (
                            <input
                                id={FormElementsIds.checkboxes.ROLES_OTHERS}
                                name="otherRolesInput"
                                type="text"
                                className={`${sharedStyle.inputText} ${
                                    selectedCheckboxesState[RoleCheckboxNames.OTHERS] && errors.rolesInYourCompany
                                        ? sharedStyle.invalidField
                                        : ""
                                } px-3 py-2 mt-2`}
                                value={othersCheckboxInputState}
                                onChange={onChange}
                            />
                        )}

                        {selectedCheckboxesState[RoleCheckboxNames.OTHERS] && errors.rolesInYourCompany && (
                            <div className="mt-1">
                                <p className={sharedStyle.errorMsg}>{errors.rolesInYourCompany.message}</p>
                            </div>
                        )}
                    </div>

                    <div className={`d-flex flex-column flex-lg-row mb-4 justify-content-between`}>
                        <div className="d-flex flex-column col-lg-5">
                            <Label requiredIndicator={true} htmlFor={FormElementsIds.START_DATE}>
                                {t("estimatedDateToStart")}:
                            </Label>

                            <div>
                                <DatePicker
                                    id={FormElementsIds.START_DATE}
                                    selected={startDateState ? parse(startDateState, DATE_FORMAT, new Date()) : null}
                                    onChange={onStartDateChange}
                                    customInput={
                                        <StartDateInput
                                            startDate={startDateState}
                                            isValid={!errors.estimatedDateToStart}
                                        />
                                    }
                                    dateFormat={DATE_FORMAT}
                                    minDate={ProjectTimeLimits.MIN_START}
                                    showPopperArrow={false}
                                />
                                {errors.estimatedDateToStart && (
                                    <div className="mt-1">
                                        <p className={sharedStyle.errorMsg}>{errors.estimatedDateToStart.message}</p>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="d-flex flex-column mt-4 mt-lg-0 col-lg-5">
                            <Label requiredIndicator={true} htmlFor={FormElementsIds.END_DATE}>
                                {t("estimatedDateToEnd")}:
                            </Label>

                            <div>
                                <DatePicker
                                    id={FormElementsIds.END_DATE}
                                    selected={endDateState ? parse(endDateState, DATE_FORMAT, new Date()) : null}
                                    onChange={onEndDateChange}
                                    customInput={
                                        <EndDateInput
                                            startDate={startDateState}
                                            endDate={endDateState}
                                            isValid={!errors.estimatedDateToEnd}
                                        />
                                    }
                                    dateFormat={DATE_FORMAT}
                                    minDate={addMonths(parse(startDateState, DATE_FORMAT, new Date()), 1)}
                                    maxDate={ProjectTimeLimits.MAX_END}
                                    showPopperArrow={false}
                                />
                                {errors.estimatedDateToEnd && (
                                    <div className="mt-1">
                                        <p className={sharedStyle.errorMsg}>{errors.estimatedDateToEnd.message}</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="mb-4">
                        <Label requiredIndicator={true} htmlFor={FormElementsIds.TIMEZONE}>
                            {t("timeZone")}:
                        </Label>

                        <div>
                            <Select
                                id={FormElementsIds.TIMEZONE}
                                styles={getSelectorStyle(!!errors.timeZone)}
                                options={timezones.data}
                                onChange={onTimezoneChange}
                                value={timezoneState}
                                placeholder={`${t("select")}...`}
                                isLoading={!timezones.loaded}
                            />
                            {errors.timeZone && (
                                <div className="mt-1">
                                    <p className={sharedStyle.errorMsg}>{errors.timeZone.message}</p>
                                </div>
                            )}
                        </div>
                    </div>

                    <Label requiredIndicator={true} htmlFor={FormElementsIds.MIN_CONFIRMATION_DATE}>
                        {t("expectedDateToConfirm")}:
                    </Label>
                    <div className="mb-4">
                        <DatePicker
                            id={FormElementsIds.MIN_CONFIRMATION_DATE}
                            selected={
                                estimatedConfDateState ? parse(estimatedConfDateState, DATE_FORMAT, new Date()) : null
                            }
                            onChange={onExpectedDateToConfirmChange}
                            customInput={
                                <ExpectedDateToConfirmInput
                                    expectedDateState={estimatedConfDateState}
                                    isValid={!errors.expectedDateToConfirm}
                                />
                            }
                            dateFormat={DATE_FORMAT}
                            minDate={ProjectTimeLimits.MIN_CONFIRMATION_DATE}
                            showPopperArrow={false}
                        />
                        {errors.expectedDateToConfirm && (
                            <div className="mt-1">
                                <p className={sharedStyle.errorMsg}>{errors.expectedDateToConfirm.message}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FirstSection;
