import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ModalButtonVariant } from "../../../../base/js/constants";
import ModalButton from "../../../common/modal/buttons/ModalButton";
import Modal from "../../../common/modal/Modal";
import ResourceProfileInfo from "../resource-profile-info/ResourceProfileInfo";
import styles from "./ResourceProfileModal.module.scss";

const ResourceProfileModal = ({ show, onClose, resourceId }) => {
    const { t } = useTranslation();

    return (
        <Modal show={show} className={styles.customModal}>
            <span className={`${styles.modalTitle} text-bold d-block text-center`}>{t("profileInfo")}</span>

            <div className="d-flex gap-4 mt-4">
                <ResourceProfileInfo resourceId={resourceId} />
            </div>

            <div className="d-flex justify-content-center gap-4 mt-4">
                <ModalButton variant={ModalButtonVariant.WHITE} onClick={onClose}>
                    {t("close")}
                </ModalButton>
            </div>
        </Modal>
    );
};

ResourceProfileModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    resourceId: PropTypes.string.isRequired,
};

export default ResourceProfileModal;
