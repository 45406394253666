import { generateErrorToast } from "../base/js/httpError";
import { API_BASE, request } from "../utils/httpRequest";

export const getUserContextualInfo = async () => {
    try {
        return await request(`${API_BASE}/api/user/contextual-info`);
    } catch (e) {
        generateErrorToast(e);
    }
};

export const getUserInformation = async () => {
    try {
        return await request(`${API_BASE}/api/user/information`);
    } catch (error) {
        generateErrorToast(error);
    }
};

export const getNotifications = async (lastUpdate = null) => {
    try {
        return await request(`${API_BASE}/api/user/notifications${lastUpdate ? "?lastUpdate=" + lastUpdate : ""}`);
    } catch (error) {
        generateErrorToast(error);
    }
};

export const markNotificationAsRead = async () => {
    try {
        return await request(`${API_BASE}/api/user/mark-notifications-as-read`, {
            method: "POST",
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const deleteNotificationAsync = async (notificationId) => {
    try {
        return await request(`${API_BASE}/api/user/notification/${notificationId}`, {
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const updateNewsletterPrefs = async (wantsNewsletter) => {
    try {
        return await request(`${API_BASE}/api/user/newsletter-prefs`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ wantsNewsletter }),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};
