import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import styles from "./ResourceSummarySkillset.module.scss";

const ResourceSummarySkillset = ({ skillset, summary }) => {
    const { t } = useTranslation();

    return (
        <div>
            <span className={`${styles.title} d-inline-block`}>{t("summarySkillSet")}</span>
            <div className={styles.container}>
                <span className={`${styles.skillset} d-flex justify-content-end`}>{skillset.join(", ")}</span>
                <p>{summary}</p>
            </div>
        </div>
    );
};

ResourceSummarySkillset.prototype = {
    skillset: PropTypes.array.isRequired,
    summary: PropTypes.string.isRequired,
};

export default ResourceSummarySkillset;
