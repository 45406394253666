import { generateErrorToast } from "../base/js/httpError";
import { API_BASE, request } from "../utils/httpRequest";

export const addReviewAsync = async (data) => {
    try {
        return await request(API_BASE + "/api/review", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const editReviewAsync = async (data) => {
    try {
        return await request(API_BASE + "/api/review", {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const getReviewAsync = async (reviewId) => {
    try {
        return await request(`${API_BASE}/api/review/${reviewId}`);
    } catch (error) {
        generateErrorToast(error);
    }
};

export const getReviewListAsync = async (page = 1, pageSize = 5, searchText = null) => {
    try {
        return await request(
            `${API_BASE}/api/review?page=${page}&pageSize=${pageSize}${searchText ? "&searchText=" + searchText : ""}`
        );
    } catch (error) {
        generateErrorToast(error);
    }
};

export const getReviewListByProjectAsync = async (projectId, page = 1, pageSize = 5, searchText = null) => {
    try {
        return await request(
            `${API_BASE}/api/review/${projectId}/get-review-by-project-id?page=${page}&pageSize=${pageSize}${
                searchText ? "&searchText=" + searchText : ""
            }`
        );
    } catch (error) {
        generateErrorToast(error);
    }
};

export const removeReview = async (id) => {
    try {
        return await request(`${API_BASE}/api/review/${id}`, {
            method: "DELETE",
        });
    } catch (error) {
        generateErrorToast(error);
    }
};
