import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { addProjectCreatorAsync } from "../../../../services/ProjectService";
import sharedStyle from "../../../shared-styles/FormStyle.module.scss";
import Note from "./note/Note";
import styles from "./OrganizationNotes.module.scss";

const OrganizationNotes = ({ notes = [], onAddNote }) => {
    const { t } = useTranslation();
    const [newNote, setNewNote] = useState("");
    const divRef = useRef(null);

    useEffect(() => {
        divRef.current.scrollTop = divRef.current.scrollHeight;
    }, [notes]);

    const handleNewNoteChange = (e) => {
        e.preventDefault();
        setNewNote(e.target.value);
    };

    const addNote = async () => {
        if (newNote && newNote.trim() !== "") {
            onAddNote(newNote);
            setNewNote("");
        }
    };

    return (
        <div className="d-flex flex-column gap-0">
            <span className={styles.title}>{t("notes")}</span>
            <div className="d-flex flex-column gap-2">
                <div ref={divRef} className={styles.notesContainer}>
                    {notes.map((note) => {
                        return <Note key={note.id} note={note} />;
                    })}
                </div>
                <div className="d-flex align-items-center position-relative">
                    <input
                        name="newNote"
                        type="text"
                        value={newNote}
                        className={`${sharedStyle.inputText} ${styles.newNoteInput}`}
                        onChange={handleNewNoteChange}
                        autoComplete="off"
                        placeholder={t("message")}
                    />
                    <FontAwesomeIcon
                        icon={faPaperPlane}
                        className={`${styles.sendIcon} ${newNote.trim().length > 0 ? styles.sendIconActive : ""}`}
                        onClick={addNote}
                    />
                </div>
            </div>
        </div>
    );
};

OrganizationNotes.prototype = {
    notes: PropTypes.array.isRequired,
    onAddNote: PropTypes.func.isRequired,
};

export default OrganizationNotes;
