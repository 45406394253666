import { faFileExcel, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format, formatISO, parseISO } from "date-fns";
import { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import Selector from "react-select";
import {
    DATE_FORMAT_ALTERNATIVE,
    SURVEYS_EXPORT_FILE_TYPES,
    SURVEYS_TYPES,
    TABLE_ROWS_LIMITS,
} from "../../../base/js/constants";
import { getAllOrganizationListAsync } from "../../../services/OrganizationService";
import { generateExportSurveyUrl, getSurveyListAsync } from "../../../services/SurveyService";
import Paginator from "../../common/paginator/Paginator";
import Loading from "../../loading/Loading";
import sharedStyle from "../../shared-styles/FormStyle.module.scss";
import { getSelectorStyle } from "../../shared-styles/StylesFunctions";
import AdminTable from "../admin-tables/AdminTable";
import styles from "./AdminSurveys.module.scss";

const DateInput = forwardRef(({ value, onClick, startDate }, ref) => {
    const { t } = useTranslation();
    return (
        <div className="d-flex">
            <input
                ref={ref}
                id="input-date-filter"
                type="text"
                className={`${sharedStyle.inputText} ${styles.datepicker}`}
                value={value}
                onClick={onClick}
                placeholder={`${t("select")} ${t("dateRange").toLowerCase()}...`}
                readOnly
            />
        </div>
    );
});

const AdminUsers = () => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [totalPerPage, setTotalPerPage] = useState(5);
    const [surveysData, setSurveysData] = useState([]);
    const [surveysTotal, setSurveysTotal] = useState(0);
    const [surveysTypeList] = useState([SURVEYS_TYPES.INITIAL, SURVEYS_TYPES.SECONDARY]);
    const [organizationList, setOrganizationList] = useState({ organizations: [], isLoading: false });
    const [filterSurveysType, setFilterSurveysType] = useState(null);
    const [filterOrganization, setFilterOrganization] = useState(null);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [filterStartDate, setFilterStartDate] = useState(null);
    const [filterEndDate, setFilterEndDate] = useState(null);

    useEffect(() => {
        (async () => {
            try {
                const responseData = await getSurveyListAsync(
                    activePage,
                    totalPerPage,
                    filterSurveysType?.value,
                    filterOrganization?.value,
                    filterStartDate,
                    filterEndDate
                );

                if (responseData) {
                    const data = responseData.surveys.map((survey) => {
                        return {
                            username: survey.userName,
                            organization: survey.organizationName,
                            completionDateInitialSurvey: format(
                                parseISO(survey.completionDateInitialSurvey),
                                DATE_FORMAT_ALTERNATIVE
                            ),
                            registrationProcessRate: survey.registrationProcessRate,
                            recommendationRate: survey.recommendationRate,
                            comments: survey.comment,
                        };
                    });
                    setSurveysTotal(responseData.total);
                    setSurveysData(data);
                }
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            }
        })();
    }, [activePage, totalPerPage, filterOrganization, startDate, endDate, filterSurveysType]);

    useEffect(() => {
        (async () => {
            try {
                setOrganizationList((prevState) => {
                    return {
                        ...prevState,
                        isLoading: true,
                    };
                });
                const responseData = await getAllOrganizationListAsync();

                if (responseData) {
                    setOrganizationList({
                        isLoading: false,
                        organizations: responseData.map((organization) => {
                            return {
                                value: organization.id,
                                label: organization.name,
                            };
                        }),
                    });
                }
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            } finally {
                setOrganizationList((prev) => ({
                    isLoading: false,
                    organizations: prev.organizations,
                }));
            }
        })();
    }, []);

    const handleChangePerPage = (event) => {
        setTotalPerPage(event.target.value);
    };

    const handleFilterSurveyTypeChange = (selectedSurveyType) => {
        setFilterSurveysType(selectedSurveyType);
    };

    const handleFilterOrganizationChange = (selectedOrganization) => {
        setFilterOrganization(selectedOrganization);
    };

    const handleDateRangeFilterChange = (newRange) => {
        const start = newRange[0] ? formatISO(newRange[0]) : null;
        const end = newRange[1] ? formatISO(newRange[1]) : null;
        setDateRange(newRange);
        setFilterStartDate(start);
        setFilterEndDate(end);
    };

    return (
        <>
            <div className={styles.filtersContainer}>
                <div className={styles.searchContainer}>
                    <div className={styles.fieldContainer}>
                        <Selector
                            name="filterType"
                            options={surveysTypeList}
                            value={filterSurveysType}
                            onChange={handleFilterSurveyTypeChange}
                            styles={getSelectorStyle(false)}
                            isClearable
                            placeholder={`${t("select")} ${t("survey").toLowerCase()}...`}
                        />
                    </div>
                    <div className={styles.fieldContainer}>
                        <Selector
                            name="filterOrganization"
                            options={organizationList.organizations}
                            value={filterOrganization}
                            onChange={handleFilterOrganizationChange}
                            styles={getSelectorStyle(false)}
                            placeholder={`${t("select")} ${t("organization").toLowerCase()}...`}
                            isClearable
                        />
                    </div>
                    <div className={`${styles.fieldContainer} ${styles.datepickerContainer}`}>
                        <DatePicker
                            startDate={startDate}
                            endDate={endDate}
                            onChange={handleDateRangeFilterChange}
                            customInput={<DateInput startDate={startDate} />}
                            className="flex-grow-1"
                            selectsRange
                            isClearable
                        />
                    </div>
                </div>
                <div className="d-flex gap-2 mx-3">
                    {surveysTotal > 0 ? (
                        <a
                            href={generateExportSurveyUrl(
                                SURVEYS_EXPORT_FILE_TYPES.EXCEL,
                                filterSurveysType?.value,
                                filterOrganization?.value,
                                filterStartDate,
                                filterEndDate
                            )}
                            className={styles.downloadBtnContainer}
                            role="link"
                            download
                        >
                            <FontAwesomeIcon icon={faFileExcel} />
                        </a>
                    ) : null}
                    {surveysTotal > 0 ? (
                        <a
                            href={generateExportSurveyUrl(
                                SURVEYS_EXPORT_FILE_TYPES.PDF,
                                filterSurveysType?.value,
                                filterOrganization?.value,
                                filterStartDate,
                                filterEndDate
                            )}
                            className={styles.downloadBtnContainer}
                            role="link"
                            download
                        >
                            <FontAwesomeIcon icon={faFilePdf} />
                        </a>
                    ) : null}
                    <div className={styles.totalPerPage}>
                        <select
                            id="select-limit"
                            className="py-1 px-2"
                            defaultValue={totalPerPage}
                            onChange={handleChangePerPage}
                        >
                            {TABLE_ROWS_LIMITS.map((rowLimit, index) => {
                                return (
                                    <option key={index} value={rowLimit}>
                                        {rowLimit}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
            </div>

            <div className={`${styles.contentContainer} mx-auto`}>
                <AdminTable
                    headers={[
                        "userName",
                        "organization",
                        "completionDateInitialSurvey",
                        "registrationProcessRate",
                        "recommendationRate",
                        "comments",
                    ]}
                    data={surveysData}
                />
                <div className={styles.paginatorContainer}>
                    <Paginator
                        setPage={setActivePage}
                        activePage={activePage}
                        lastPage={Math.ceil(surveysTotal ? surveysTotal / totalPerPage : 1)}
                    />
                </div>
            </div>

            {loading && <Loading />}
        </>
    );
};

export default AdminUsers;
