import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import assembleYourTeamReducer from "./slices/assembleYourTeamSlice";
import tdbInformationReducer from "./slices/tdbInformationSlice";
import userSlice from "./slices/userSlice";

const persistConfig = {
    key: "root",
    storage,
};

const persistedUser = persistReducer(persistConfig, userSlice);

export const store = configureStore({
    reducer: {
        assembleYourTeam: assembleYourTeamReducer,
        user: persistedUser,
        tdbInformation: tdbInformationReducer,
    },
    middleware: [thunk],
});

export const persistor = persistStore(store);
