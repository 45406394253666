import { Trans, useTranslation } from "react-i18next";
import arrowRightIcon from "../../../assets/arrow-right.svg";
import personas from "../../../assets/meet-the-team/meet-the-team-personas.png";
import Button from "../../common/Button";
import style from "./FirstSection.module.scss";

const FirstSection = () => {
    const contactUsBtnStyle = [style.leftSectionContact, "d-block", "d-lg-none"];
    const { t } = useTranslation();

    return (
        <div className={`${style.section} d-flex`}>
            <div className={style.leftSection}>
                <h1 className={style.leftSectionTitle}>
                    <Trans components={{ span1: <span className="d-block" />, span2: <span /> }}>meetTheTeam</Trans>
                </h1>

                <p className={`${style.leftSectionDescription} text-italic my-4 my-lg-5`}>{t("whatWeAre")}</p>

                <div className={`${style.leftSectionContact} d-none d-lg-flex`}>
                    <span className="me-3">{t("contactUs2")}</span>
                    <img src={arrowRightIcon} alt="" />
                </div>

                <Button cssClasses={contactUsBtnStyle}>{t("contactUs2")}</Button>
            </div>

            <div className={`${style.rightSection} position-relative d-none d-lg-block`}>
                <img src={personas} alt="" className="position-absolute bottom-0" />
            </div>
        </div>
    );
};

export default FirstSection;
