import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { v4 as uuidV4 } from "uuid";
import { DATE_FORMAT, FORMATTER, ModalButtonVariant, ProjectStatus, TAM_LABEL } from "../../../base/js/constants";
import { sendDocumentSowToSignAsync } from "../../../services/DocumentToSignService";
import { getPositionsByProject } from "../../../services/PositionService";
import { finishProjectCreationAsync } from "../../../services/ProjectService";
import { setPositions as setPositionsAction } from "../../../store/slices/assembleYourTeamSlice";
import Button, { variants as btnVariants } from "../../common/Button";
import ModalButton from "../../common/modal/buttons/ModalButton";
import Modal from "../../common/modal/Modal";
import ProjectInfoTemplate from "../../common/project-info-template/ProjectInfoTemplate";
import TeamCard from "../../common/team-card/TeamCard";
import Loading from "../../loading/Loading";
import styles from "../team-info/TeamInfo.module.scss";
import style from "./Resume.module.scss";

const Resume = ({ firstPhase, previousPhase, nextPhase }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const projectId = useSelector((state) => state.assembleYourTeam.id);
    const teamName = useSelector((state) => state.assembleYourTeam.teamName);
    const startDate = useSelector((state) => state.assembleYourTeam.startDate);
    const endDate = useSelector((state) => state.assembleYourTeam.endDate);
    const positions = useSelector((state) => state.assembleYourTeam.positions);

    const [projectData, setProjectData] = useState({
        name: teamName,
        status: ProjectStatus.STARTING_SELECTION,
        startDate,
        endDate,
        monthPrice: "$",
        finalPrice: "$",
        team: positions.map((position) => ({ ...position })),
    });
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const positionWithRate = await getPositionsByProject(projectId);

                if (positionWithRate) {
                    const newFilteredPositionsWithRate = positionWithRate.map((position) => {
                        return {
                            id: position.id,
                            role: { label: position.developmentRol?.name, value: position.developmentRolId },
                            seniority: { label: position.seniority?.name, value: position.seniorityId },
                            jobSkills: position.positionCustomValues?.map((cv) => cv.customValue?.value),
                            total: 1,
                            time: position.partTime,
                            estimatedStartDate: position.estimatedStartDate
                                ? format(parseISO(position.estimatedStartDate), DATE_FORMAT)
                                : null,
                            estimatedEndDate: position.estimatedEndDate
                                ? format(parseISO(position.estimatedEndDate), DATE_FORMAT)
                                : null,
                            jobDescription: {
                                isLink: !position.jobDescriptionFile,
                                isValid: true,
                                link: position.jobDescriptionLink,
                                file: {
                                    name: position.jobDescriptionFile,
                                    blobName: position.jobDescriptionBlob,
                                },
                            },
                            rate: position.rate,
                        };
                    });

                    dispatch(
                        setPositionsAction([
                            {
                                role: {
                                    value: 14,
                                    label: "Technical Account Manager",
                                },
                                seniority: {
                                    value: 4,
                                    label: "Senior",
                                },
                                jobSkills: [],
                                total: 1,
                                time: false,
                                estimatedStartDate: projectData.startDate,
                                estimatedEndDate: projectData.endDate,
                                jobDescription: {
                                    isLink: true,
                                    isValid: true,
                                    link: "",
                                    file: {
                                        name: "",
                                        url: null,
                                    },
                                },
                                id: "tam_position",
                            },
                            ...newFilteredPositionsWithRate,
                        ])
                    );
                }
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            }
        })();
    }, []);

    useEffect(() => {
        setProjectData((prevState) => {
            return {
                ...prevState,
                monthPrice: FORMATTER.format(
                    positions.reduce((total, next) => {
                        return next.id !== "tam_position" ? total + next.rate * (next.time ? 80 : 160) : 0;
                    }, 0)
                ),
                finalPrice: FORMATTER.format(
                    positions.reduce((total, next) => {
                        return next.id !== "tam_position" ? total + next.rate * (next.time ? 80 : 160) : 0;
                    }, 0)
                ),
                team: positions.map((position) => ({ ...position })),
            };
        });
    }, [positions]);

    const toggleModal = () => {
        setShowModal((prevState) => !prevState);
    };

    const onContinueBtnClick = async () => {
        try {
            setLoading(true);

            await finishProjectCreationAsync(projectId);

            toast.success(t("finishSelectionSuccess"));
            nextPhase();
        } catch (e) {
            toast.error(t("unexpectedError") + ": " + e.message);
        } finally {
            setLoading(false);
        }
    };

    const onManageResourcesClick = () => {
        previousPhase();
    };

    const handleBack = () => {
        setSearchParams({ back: true });
        firstPhase();
    };

    return (
        <>
            <div className="mt-5">
                <ProjectInfoTemplate projectData={projectData} sectionTitle={t("projectDetails")} />

                <div className="d-flex flex-column px-3 px-lg-0">
                    <h1 className={`${style.title} flex-grow-1`}>{t("teamFormation")}</h1>

                    <div
                        className={`${style.cardsMainContainer} d-flex flex-column flex-md-row flex-wrap gap-3 mt-3 justify-content-center justify-content-md-start`}
                    >
                        {projectData.team?.map((position) => {
                            const cards = [];
                            for (let i = 0; i < position.total; i++) {
                                cards.push(
                                    <div key={`${uuidV4()}_${position.id}`}>
                                        <TeamCard
                                            role={position.role ? position.role.label : ""}
                                            seniority={position.seniority ? position.seniority.label : ""}
                                            skills={position.jobSkills}
                                            rate={
                                                position.role?.label === TAM_LABEL
                                                    ? 0
                                                    : position.rate
                                                    ? position.rate
                                                    : 0
                                            }
                                        />
                                    </div>
                                );
                            }

                            return cards;
                        })}
                    </div>

                    <p className={`${style.explanation} text-center mx-auto mt-5`}>
                        <Trans components={{ span: <span className="fw-bold" /> }}>selectionModeDescription</Trans>
                    </p>

                    <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center mt-5 position-relative gap-3">
                        <Button
                            type="button"
                            cssClasses={[styles.btn, styles.btnDefaultOutline, styles.btnBack]}
                            variant={btnVariants.PRIMARY_INVERSE}
                            onClick={handleBack}
                        >
                            {t("back")}
                        </Button>

                        <Button
                            type="button"
                            cssClasses={[style.btnManage]}
                            onClick={onManageResourcesClick}
                            variant={btnVariants.PRIMARY_INVERSE}
                        >
                            <Trans components={{ span: <span className="fw-bold" /> }}>manageResources</Trans>
                        </Button>

                        <Button type="button" cssClasses={[style.btnFinish]} onClick={toggleModal}>
                            {t("continue")}
                        </Button>
                    </div>
                </div>
            </div>

            <Modal show={showModal}>
                <span className={style.fw600}>{t("finishSelection")}</span>

                <p className={`${style.modalText} mt-3 mb-0`}>
                    <Trans components={{ span: <span className={style.fw600} /> }}>finishSelectionBtnClick</Trans>
                </p>
                <p className={`${style.fw600} mb-0`}>{t("finishSelectionConfirmationDialog")}</p>

                <div className={`${style.modalBtnContainer} d-flex justify-content-center gap-4 mt-4`}>
                    <ModalButton variant={ModalButtonVariant.WHITE} onClick={toggleModal}>
                        {t("cancel")}
                    </ModalButton>

                    <ModalButton variant={ModalButtonVariant.GREEN} onClick={onContinueBtnClick}>
                        {t("continue")}
                    </ModalButton>
                </div>
            </Modal>

            {loading && <Loading />}
        </>
    );
};

export default Resume;
