import { generateErrorToast } from "../base/js/httpError";
import { API_BASE, request } from "../utils/httpRequest";

export const getRoleAsync = async (id) => {
    try {
        return await request(API_BASE + "/api/developmentrol/" + id);
    } catch (error) {
        generateErrorToast(error);
    }
};

export const getRoleListAsync = async () => {
    try {
        return await request(API_BASE + `/api/developmentrol`, {
            method: "GET",
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const getRolesWithPagingAsync = async (page, pageSize, searchText = "") => {
    try {
        return await request(
            `${API_BASE}/api/developmentrol/with-paging?page=${page}&pageSize=${pageSize}${
                searchText ? `&searchText=${searchText}` : ""
            }`
        );
    } catch (error) {
        generateErrorToast(error);
    }
};

export const updateDevelopmentRole = async (id, updatedDevelopmentRole) => {
    try {
        return await request(`${API_BASE}/api/developmentrol/${id}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(updatedDevelopmentRole),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const createDevelopmentRole = async (developmentRole) => {
    try {
        return await request(`${API_BASE}/api/developmentrol`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(developmentRole),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const removeDevelopmentRole = async (id) => {
    try {
        return await request(`${API_BASE}/api/developmentrol/${id}`, {
            method: "DELETE",
        });
    } catch (error) {
        generateErrorToast(error);
    }
};
