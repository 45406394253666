import { faRotateLeft, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useContext, useEffect, useRef, useState } from "react";
import { useDrag } from "react-dnd";
import { useTranslation } from "react-i18next";
import { generateLinkForVisualizePhoto } from "../../../../../services/FileService";
import useClickPreventionOnDoubleClick from "../../../../hooks/UseClickPreventionOnDoubleClick";
import { PositionActiveContext } from "../../AllocationResource";
import ResourceProfileModal from "../../resource-profile-modal/ResourceProfileModal";
import styles from "./ResourceCard.module.scss";

const ContentClickable = ({ onClick, onDoubleClick, children }) => {
    const [handleClick, handleDoubleClick] = useClickPreventionOnDoubleClick(onClick, onDoubleClick);

    return (
        <div className="d-flex gap-3 align-items-center" onClick={handleClick} onDoubleClick={handleDoubleClick}>
            {children}
        </div>
    );
};

const ResourceCard = ({
    resource,
    resourceActive = false,
    onCardClick,
    deleteOption = false,
    onDeleteClick = null,
    cursorPointer = true,
}) => {
    const { t } = useTranslation();
    const positionActive = useContext(PositionActiveContext);
    const [showTimeIndicator, setShowTimeIndicator] = useState(false);
    const sendDeleteRef = useRef(true);
    const [sendDelete, setSendDelete] = useState(true);
    const [timeOutId, setTimeOutId] = useState(null);
    const [showResourceProfileModal, setShowResourceProfileModal] = useState(false);

    const [{ isDragging }, drag] = useDrag(() => ({
        type: "resource",
        item: resource,
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));

    useEffect(() => {
        sendDeleteRef.current = sendDelete;
    }, [sendDelete]);

    const handleCardClick = () => {
        onCardClick(resource.id);
    };

    const handleDoubleClick = () => {
        setShowResourceProfileModal(true);
    };

    const handleUndoClick = (e) => {
        e.preventDefault();
        setSendDelete(false);
        setShowTimeIndicator(false);
        if (timeOutId) {
            clearTimeout(timeOutId);
        }
    };

    const sendDeleteFunction = () => {
        if (onDeleteClick && sendDeleteRef.current) {
            onDeleteClick(resource.id);
        }
    };

    const handleDeleteClick = (e) => {
        e.preventDefault();
        setSendDelete(true);
        setShowTimeIndicator(true);
        const timeoutId = setTimeout(sendDeleteFunction, 5500, sendDelete);
        setTimeOutId(timeoutId);
    };

    const handleProfileClose = () => {
        setShowResourceProfileModal(false);
    };

    return (
        <div
            ref={drag}
            className={`${styles.mainContent} ${cursorPointer ? styles.cursorPointer : ""} ${
                positionActive ? styles.borderActive : ""
            } ${
                !positionActive && resourceActive ? styles.resourceActive : ""
            } position-relative d-flex align-items-center`}
            style={{ transform: "translate(0, 0)" }}
        >
            <ContentClickable onClick={handleCardClick} onDoubleClick={handleDoubleClick}>
                {showTimeIndicator && (
                    <>
                        <div className={`${styles.timeIndicator} ${styles.scaleOutHorRight} position-absolute`}></div>
                        <div
                            className={`${styles.btnUndo} ${styles.cursorPointer} d-flex justify-content-center align-items-center`}
                            onClick={handleUndoClick}
                        >
                            <FontAwesomeIcon icon={faRotateLeft} className={`${styles.icon} ${styles.iconLg}`} />
                        </div>
                    </>
                )}

                {deleteOption && (
                    <div
                        className={`${styles.btnDelete} ${styles.cursorPointer} d-flex justify-content-center align-items-center position-absolute`}
                        onClick={handleDeleteClick}
                    >
                        <FontAwesomeIcon icon={faX} className={styles.icon} />
                    </div>
                )}
                <div className={styles.photoSection}>
                    {resource.pictureBlobName && (
                        <img
                            className={styles.photoSection}
                            src={generateLinkForVisualizePhoto(resource.pictureBlobName)}
                            alt={resource.pictureBlobName}
                        />
                    )}
                </div>
                <div className="d-flex flex-column">
                    <span className={styles.item}>
                        {t("name")}: <span className={styles.itemText}>{resource.name}</span>
                    </span>
                    <span className={styles.item}>
                        {t("roles")}:{" "}
                        <span className={styles.itemText}>
                            {resource.developmentRols?.map((role) => role.name).join(", ")}
                        </span>
                    </span>
                    <span className={styles.item}>
                        {t("seniority")}: <span className={styles.itemText}>{resource.seniority.name}</span>
                    </span>
                    <span className={styles.item}>
                        {t("technologies")}:{" "}
                        <span className={styles.itemText}>
                            {resource?.technologies?.map((technology) => technology.name).join(", ")}
                            {resource?.staffCustomValues.length > 0 ? ", " : ""}
                            {resource?.staffCustomValues
                                ?.map((staffCustomValue) => staffCustomValue.customValue.value)
                                .join(", ")}
                        </span>
                    </span>
                </div>
            </ContentClickable>
            <ResourceProfileModal
                onClose={handleProfileClose}
                resourceId={resource.id}
                show={showResourceProfileModal}
            />
        </div>
    );
};

ResourceCard.prototype = {
    resource: PropTypes.object.isRequired,
    resourceActive: PropTypes.bool,
    onCardClick: PropTypes.func.isRequired,
    deleteOption: PropTypes.bool,
    onDeleteClick: PropTypes.func,
    cursorPointer: PropTypes.bool,
};

export default ResourceCard;
