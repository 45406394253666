import { generateErrorToast } from "../base/js/httpError";
import { API_BASE, request } from "../utils/httpRequest";

export const addHoursValidationAsync = async (data) => {
    try {
        return await request(`${API_BASE}/api/validationhour`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const sendToClientAsync = async (data) => {
    try {
        return await request(`${API_BASE}/api/validationhour/send-to-client`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const validateHoursValidationAsync = async (id) => {
    try {
        return await request(`${API_BASE}/api/validationhour/${id}/validate`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const updateHoursValidationAsync = async (data) => {
    try {
        return await request(`${API_BASE}/api/validationhour`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const getHoursValidationOverviewAsync = async (page = 1, pageSize = 5, searchText = null) => {
    try {
        return await request(
            API_BASE +
                `/api/validationhour/projects-overview?page=${page}&pageSize=${pageSize}${
                    searchText ? "&searchText=" + searchText : ""
                }`,
            {
                method: "GET",
            }
        );
    } catch (error) {
        generateErrorToast(error);
    }
};

export const getHoursValidationByProjectAndPeriodAsync = async (projectId, period) => {
    try {
        return await request(API_BASE + `/api/validationhour?projectId=${projectId}&period=${period}`, {
            method: "GET",
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const getHoursValidationPendingByProjectAsync = async (projectId) => {
    try {
        return await request(API_BASE + `/api/validationhour/pending-by-project?projectId=${projectId}`, {
            method: "GET",
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const addNoteToHourValidation = async (data) => {
    try {
        return await request(`${API_BASE}/api/validationhournote`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};
