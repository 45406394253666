import Joi from "joi";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { JoiPasswordStrengthValidator, ModalButtonVariant, PasswordRules } from "../../../../base/js/constants";
import Input from "../../../common/forms/input/Input";
import Label from "../../../common/forms/label/Label";
import ModalButton from "../../../common/modal/buttons/ModalButton";
import Modal from "../../../common/modal/Modal";
import useJoiValidation from "../../../hooks/UseJoiValidation";
import PasswordStrengthIndicator from "../../../login-signup/login-form/password-strength-indicator/PasswordStrengthIndicator";
import sharedStyles from "../../../shared-styles/FormStyle.module.scss";

const FormNames = {
    NEW_PASSWORD: "newPassword",
};

const DEFAULT_STATE = {
    [FormNames.NEW_PASSWORD]: "",
};

const JOI_SCHEMA = {
    [FormNames.NEW_PASSWORD]: Joi.string().min(8).max(255).required().custom(JoiPasswordStrengthValidator),
};

const SCHEMA_ERROR_MESSAGES = {
    "string.empty": "requiredFieldIsEmpty",
    "string.max": "fieldTooLong",
    "string.min": "fieldTooShort8Chars",
    "string.email": "invalidEmailFormat",
    "any.required": "weakPassword",
};

const ChangePasswordModal = ({ show, onAccept, onCancel }) => {
    const { t } = useTranslation();
    const { validateSubSchemaFromEvent, validateSchema, errors, resetErrors } = useJoiValidation();
    const [formState, setFormState] = useState(DEFAULT_STATE);

    const onChange = (e) => {
        setFormState((prevState) => ({
            ...prevState,
            [e.target.id]: e.target.value,
        }));
    };

    const onBlur = (e) => {
        validateSubSchemaFromEvent(JOI_SCHEMA, SCHEMA_ERROR_MESSAGES, e);
    };

    const handleChangeUserPassword = (e) => {
        e.preventDefault();

        const amountOfErrors = validateSchema(JOI_SCHEMA, SCHEMA_ERROR_MESSAGES, formState);
        if (amountOfErrors > 0) {
            return;
        }

        onAccept(formState);
        resetState();
    };

    const handleCancel = () => {
        onCancel();
        resetState();
    };

    const resetState = () => {
        setFormState(DEFAULT_STATE);
        resetErrors();
    };

    return (
        <Modal show={show}>
            <form onSubmit={handleChangeUserPassword} className="d-flex flex-column gap-3">
                <h3 className="text-center">{t("changePassword")}</h3>

                <div className="d-flex flex-column">
                    <Label htmlFor={FormNames.NEW_PASSWORD} requiredIndicator>
                        {t("newPassword")}
                    </Label>

                    <Input
                        id={FormNames.NEW_PASSWORD}
                        name={FormNames.NEW_PASSWORD}
                        type="password"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={formState[FormNames.NEW_PASSWORD]}
                        invalid={!!errors[FormNames.NEW_PASSWORD]}
                    />

                    {errors[FormNames.NEW_PASSWORD] && (
                        <p className={sharedStyles.errorMsg}>{errors[FormNames.NEW_PASSWORD].message}</p>
                    )}
                </div>

                <PasswordStrengthIndicator
                    password={formState[FormNames.NEW_PASSWORD]}
                    rules={PasswordRules}
                    translateLabels
                />

                <div className="d-flex justify-content-center gap-3 mt-4">
                    <ModalButton variant={ModalButtonVariant.RED} onClick={handleCancel} type="reset">
                        {t("cancel")}
                    </ModalButton>

                    <ModalButton variant={ModalButtonVariant.BLUE} type="submit">
                        {t("update")}
                    </ModalButton>
                </div>
            </form>
        </Modal>
    );
};

export default ChangePasswordModal;
