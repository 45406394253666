import Label from "../../common/forms/label/Label";
import Input from "../../common/forms/input/Input";
import {useEffect, useMemo, useState} from "react";
import LangSelector from "../../common/lang-selector/LangSelector";
import {useTranslation} from "react-i18next";
import Button, {variants} from "../../common/Button";
import {addTermsAndConditions, getTermsAndConditions} from "../../../services/TermsAndConditionsService";
import toast from "react-hot-toast";
import Loading from "../../loading/Loading";
import styles from './AdminTermsAndConditions.module.scss';
import sharedStyle from "../../shared-styles/FormStyle.module.scss";

const INITIAL_STATE = {
    title: {
        es: "",
        en: "",
        error: ""
    },
    sections: [{
        subTitle: {
            es: "",
            en: "",
            error: ""
        },
        content: {
            es: "",
            en: "",
            error: ""
        }
    }]
};

const AdminTermsAndConditions = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [formState, setFormState] = useState(INITIAL_STATE);
    const [currentLanguage, setCurrentLanguage] = useState('en');

    const languages = {
        en: {
            name: t("english"),
        },
        es: {
            name: t("spanish"),
        },
    };
    
    useEffect(() => {
        (async () => {
                setLoading(true);
            try {
                const data = await getTermsAndConditions();
                if (data.length > 0) {
                    const { titleEn, titleEs } = data[0];
                    const sections = data.map(({ subTitleEn, subTitleEs, contentEn, contentEs }) => ({
                        subTitle: {
                            en: subTitleEn,
                            es: subTitleEs,
                            error: ""
                        },
                        content: {
                            en: contentEn,
                            es: contentEs,
                            error: ""
                        }
                    }));

                    setFormState({
                        title: { en: titleEn, es: titleEs, error: "" },
                        sections
                    });
                }
            } catch (error) {
                console.error(error.message);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    const handleTitleChange = (lang) => {
        return (e) => {
            setFormState(prev => ({
                ...prev,
                title: {
                    ...prev.title,
                    [lang]: e.target.value
                }
            }));
        };
    };

    const handleSectionChange = (index, field, lang) => {
        return (e) => {
            setFormState(prev => {
                const updatedSections = [...prev.sections];
                updatedSections[index][field][lang] = e.target.value;

                return {
                    ...prev,
                    sections: updatedSections
                };
            });
        };
    };

    const addSection = () => {
        setFormState(prev => ({
            ...prev,
            sections: [
                ...prev.sections,
                {
                    subTitle: { es: "", en: "" },
                    content: { es: "", en: "" }
                }
            ]
        }));
    };

    const removeSection = (indexToRemove) => {
        if (formState.sections.length > 1) {
            setFormState(prev => ({
                ...prev,
                sections: prev.sections.filter((_, index) => index !== indexToRemove)
            }));
        }
    };
    
    const validateForm = () => {
        let isValid = true;
        
        if(formState.title.en.trim() === "" || formState.title.es.trim() === "") {
            setFormState({...formState, title: {...formState.title, error: "requiredFieldIsEmpty"}});
            isValid = false;
        } else {
            if(formState.title.en.trim().length > 100 || formState.title.es.trim().length > 100) {
                setFormState({...formState, title: {...formState.title, error: "fieldTooLong100"}});
                isValid = false;
            } else {
                setFormState({...formState, title: {...formState.title, error: ""}});
            }
        }

        const sections = [...formState.sections];

        for (const section of sections) {
            if (section.subTitle.en.trim().length > 100 || section.subTitle.es.trim().length > 100) {
                section.subTitle.error = "fieldTooLong100";
                isValid = false;
            } else {
                section.subTitle.error = "";
            }

            if (section.content.en.trim() === "" || section.content.es.trim() === "") {
                section.content.error = "requiredFieldIsEmpty";
                isValid = false;
            } else {
                if (section.content.en.trim().length > 5000 || section.content.es.trim().length > 5000) {
                    section.content.error = "fieldTooLong5000";
                    isValid = false;
                } else {
                    section.content.error = "";
                }
            }
        }
        
        setFormState(prev => ({...prev, sections}));
        
        return isValid;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        const isFormValid = validateForm();
        
        if(!isFormValid) {
            return;
        }

        try {
            setLoading(true);

            const response = await addTermsAndConditions(formState);

            toast.success(t("termsAndConditionsSuccess"));
        } catch (error) {
            console.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    const sectionInputs = useMemo(() => {
        return formState.sections.map((section, index) => ({
            subTitle: section.subTitle[currentLanguage],
            content: section.content[currentLanguage],
            subTitleError: section.subTitle.error,
            contentError: section.content.error,
            index
        }));
    }, [formState.sections, currentLanguage]);
    
    const handleLangChange = (lang) => {
        setCurrentLanguage(lang);
    };
    
    return (<div className={styles.mainContainer}>
        <form onSubmit={handleSubmit}>
            <div className="flex justify-between align-items-center gap-4 mb-4">
                <Label>{t("selectTermsAndConditionsLanguage")}</Label> <LangSelector langs={languages} currentLang={currentLanguage} onLangChange={handleLangChange} />
            </div>

            <div className="flex flex-column mb-4">
                <Label requiredIndicator htmlFor="title">{t("title")}</Label>
                <Input
                    id="title"
                    name="title"
                    value={formState.title[currentLanguage]}
                    onChange={handleTitleChange(currentLanguage)}
                    invalid={!!formState.title.error}
                />
                {formState.title.error && (
                    <div className="mt-1">
                        <p className={sharedStyle.errorMsg}>{`${t(formState.title.error).replace("{{#label}}", t("title"))} - ${t("checkBothLanguages")}`}</p>
                    </div>
                )}
            </div>

            <div>
                <h3>{t("sections")}</h3>
                {sectionInputs.map(({subTitle, content, subTitleError, contentError, index}) => (
                    <div key={index} className={`${styles.sectionContainer} mb-4`}>
                        {formState.sections.length > 1 && (
                            <div className="flex justify-content-end">
                                <button
                                    type="button"
                                    onClick={() => removeSection(index)}
                                    className="btn btn-danger text-uppercase"
                                    title="Eliminar sección"
                                >
                                    {t("deleteSection")}
                                </button>
                            </div>
                        )}
                        <div>
                            <div className="flex flex-column mb-4">
                                <Label htmlFor={`subtitle-${index}`}>
                                    {t("subTitle")}
                                </Label>
                                <Input
                                    id={`subtitle-${index}`}
                                    name={`subtitle-${index}`}
                                    className="px-3 py-2"
                                    value={subTitle}
                                    onChange={handleSectionChange(index, 'subTitle', currentLanguage)}
                                    invalid={!!subTitleError}
                                />
                                {subTitleError && (
                                    <div className="mt-1">
                                        <p className={sharedStyle.errorMsg}>{`${t(subTitleError).replace("{{#label}}", t("subTitle"))} - ${t("checkBothLanguages")}`}</p>
                                    </div>
                                )}
                            </div>

                            <div className="flex flex-column mb-4">
                                <Label requiredIndicator>
                                    {t("content")}
                                </Label>
                                <textarea
                                    value={content}
                                    onChange={handleSectionChange(index, 'content', currentLanguage)}
                                    rows={4}
                                    className={sharedStyle.textArea}
                                />
                                {contentError && (
                                    <div className="mt-1">
                                        <p className={sharedStyle.errorMsg}>{`${t(contentError).replace("{{#label}}", t("content"))} - ${t("checkBothLanguages")}`}</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className="flex justify-content-center gap-4 mb-4">
                <Button type="button" variant={variants.PRIMARY_INVERSE} onClick={addSection}>
                    {t("addSection")}
                </Button>
                <Button type="submit">
                    {t("save")}
                </Button>
            </div>
        </form>
        {loading && <Loading />}
    </div>)
}

export default AdminTermsAndConditions;
