import { forwardRef, useRef } from "react";
import colors from "../../../base/js/colors";
import { UrlRegex } from "../../../utils/formValidation";
import { TeamInfoFormIds, TotalMinMaxLimits } from "./TeamInfoConstant";

import sharedStyle from "../../shared-styles/FormStyle.module.scss";

export const useTeamInfoFunctions = (
    resourceInfo,
    onChange,
    deleteResource,
    confirmationControlVisible,
    setConfirmationControlVisible,
    schema,
    schemaErrorMessages,
    validateSchema,
    validateSubSchemaFromEvent,
    firstRunFlag,
    seniorOnlyRoles,
    seniorityTypes
) => {
    const joiSchema = useRef(schema);
    const errorMessages = useRef(schemaErrorMessages);

    const onChangeHook = (event) => {
        firstRunFlag.current = false;
        onChange(event);

        validateSubSchemaFromEvent(joiSchema.current, errorMessages.current, event);
    };

    const StartDateInput = forwardRef(({ value, onClick, disabled = false }, ref) => (
        <input
            ref={ref}
            id="input-start-date"
            type="text"
            className={`${sharedStyle.inputDate} ${disabled ? sharedStyle.disabledInput : ""} px-3 py-2`}
            value={value}
            onClick={onClick}
            disabled={disabled}
            readOnly
        />
    ));

    const EndDateInput = forwardRef(({ value, onClick, disabled }, ref) => (
        <input
            ref={ref}
            id="input-start-date"
            type="text"
            className={`${sharedStyle.inputDate} ${disabled ? sharedStyle.disabledInput : ""} px-3 py-2`}
            value={value}
            onClick={onClick}
            disabled={disabled}
            readOnly
        />
    ));

    const getSelectorStyle = (invalid = false) => ({
        control: (provided, state) => ({
            ...provided,
            borderRadius: "8px",
            height: "40px",
            boxShadow: "none",
            border: `${invalid || state.isFocused ? 2 : 1}px solid ${
                invalid ? colors.ERROR : state.isFocused ? colors.GRAY550 : colors.GRAY350
            }`,
            ":hover": {
                borderColor: invalid ? colors.ERROR : colors.GRAY550,
            },
        }),
    });

    const handleChangeRole = (value) => {
        onChangeHook({ target: { name: "role", id: TeamInfoFormIds.ROLE, resourceId: resourceInfo.id, value } });

        // If the role only admits seniority "senior", call the seniority handler to update it
        if (seniorOnlyRoles.includes(value.value)) {
            const senior = seniorityTypes.find((seniority) => seniority.label === "Senior");
            handleChangeSeniority(senior);
        }
    };

    const handleChangeSeniority = (value) => {
        onChangeHook({
            target: { name: "seniority", id: TeamInfoFormIds.SENIORITY, resourceId: resourceInfo.id, value },
        });
    };

    const handleChangeJobSkills = (event) => {
        let tagsList = event.detail.tagify.getCleanValue().map((tag) => {
            return tag.value;
        });

        onChangeHook({
            target: {
                name: "jobSpecificSkills",
                id: TeamInfoFormIds.JOB_SKILLS,
                resourceId: resourceInfo.id,
                value: tagsList,
            },
        });
    };

    const handleChangeTotal = (event) => {
        onChangeHook({
            ...event,
            target: { ...event.target, name: "count", id: TeamInfoFormIds.TOTAL, resourceId: resourceInfo.id },
        });
    };

    const handleChangeTime = (event) => {
        onChangeHook({
            ...event,
            target: { ...event.target, name: "time", id: TeamInfoFormIds.TIME, resourceId: resourceInfo.id },
        });
    };

    const handleChangeStartDate = (date) => {
        onChangeHook({
            target: {
                name: "estimatedDateToStartOptional",
                id: TeamInfoFormIds.ESTIMATED_START_DATE,
                resourceId: resourceInfo.id,
                value: date,
            },
        });
    };

    const handleChangeEndDate = (date) => {
        onChangeHook({
            target: {
                name: "estimatedDateToEndOptional",
                id: TeamInfoFormIds.ESTIMATED_END_DATE,
                resourceId: resourceInfo.id,
                value: date,
            },
        });
    };

    const handleChangeJobDescription = (event) => {
        onChange({
            ...event,
            target: {
                ...event.target,
                id: TeamInfoFormIds.JOB_DESCRIPTION_LINK,
                name: "jobDescriptionLinkOptional",
                isLink: true,
                isValid: true,
                resourceId: resourceInfo.id,
            },
        });
    };

    const changeUploadJobDescription = (event) => {
        onChange({
            ...event,
            target: {
                ...event.target,
                id: TeamInfoFormIds.JOB_DESCRIPTION_LINK,
                name: "jobDescriptionLinkOptional",
                isLink: false,
                isValid: true,
                resourceId: resourceInfo.id,
                files: { ...event.target.files },
            },
        });
    };

    const deleteJobDescriptionFile = (event) => {
        onChange({
            ...event,
            target: {
                ...event.target,
                id: TeamInfoFormIds.JOB_DESCRIPTION_LINK,
                name: "jobDescriptionLinkOptional",
                isLink: true,
                isValid: true,
                resourceId: resourceInfo.id,
                value: "",
            },
        });
    };

    const validateTotalFieldValue = (event) => {
        if (Number(event.target.value) > TotalMinMaxLimits.MAX) {
            onChangeHook({
                ...event,
                target: {
                    ...event.target,
                    id: TeamInfoFormIds.TOTAL,
                    name: "count",
                    resourceId: resourceInfo.id,
                    value: TotalMinMaxLimits.MAX,
                },
            });
        }
        if (event.target.value) return;
        onChangeHook({
            ...event,
            target: {
                ...event.target,
                id: TeamInfoFormIds.TOTAL,
                name: "count",
                resourceId: resourceInfo.id,
                value: TotalMinMaxLimits.MIN,
            },
        });
    };

    const validateJobDescriptionValue = (event) => {
        if (!event.target.value.match(UrlRegex) && event.target.value) {
            onChange({
                ...event,
                target: {
                    ...event.target,
                    id: TeamInfoFormIds.JOB_DESCRIPTION_LINK,
                    name: "jobDescriptionLinkOptional",
                    isLink: true,
                    isValid: false,
                    resourceId: resourceInfo.id,
                },
            });
        }
    };

    const handleDeleteResource = () => {
        deleteResource(resourceInfo.id);
    };

    const toggleConfirmationControls = () => {
        setConfirmationControlVisible(!confirmationControlVisible);
    };

    return {
        StartDateInput,
        EndDateInput,
        getSelectorStyle,
        handleChangeRole,
        handleChangeSeniority,
        handleChangeJobSkills,
        handleChangeTotal,
        handleChangeTime,
        handleChangeStartDate,
        handleChangeEndDate,
        handleChangeJobDescription,
        changeUploadJobDescription,
        deleteJobDescriptionFile,
        validateTotalFieldValue,
        validateJobDescriptionValue,
        handleDeleteResource,
        toggleConfirmationControls,
    };
};
