import Joi from "joi";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { TABLE_ROWS_LIMITS } from "../../../base/js/constants";
import { getCountriesAsync } from "../../../services/CountryService";
import { getAllOrganizationListAsync } from "../../../services/OrganizationService";
import { getSenioritiesAsync } from "../../../services/PositionService";
import { getAllProjectsBasic } from "../../../services/ProjectService";
import { getRoleListAsync } from "../../../services/RoleService";
import {
    addTariffAsync,
    getTariffAsync,
    getTariffsWithPagingAsync,
    readjustTariffAsync,
    updateTariffAsync,
} from "../../../services/TariffService";
import Input from "../../common/forms/input/Input";
import Label from "../../common/forms/label/Label";
import Paginator from "../../common/paginator/Paginator";
import useJoiValidation from "../../hooks/UseJoiValidation";
import useOnBeforeUnload from "../../hooks/UseOnBeforeUnload";
import Loading from "../../loading/Loading";
import sharedStyle from "../../shared-styles/FormStyle.module.scss";
import { getSelectorStyle } from "../../shared-styles/StylesFunctions";
import AdminTable from "../admin-tables/AdminTable";
import styles from "./AdminTariffs.module.scss";
import ConfirmTariffReadjustModal from "./confirm-tariff-readjust-modal/ConfirmTariffReadjustModal";
import TariffDetails from "./tariff-details/TariffDetails";

const TARIFF_SCHEMA = {
    name: Joi.string().max(50).empty("").required(),
};

const RATE_CHANGE_SCHEMA = {
    role: Joi.object({
        label: Joi.string().required(),
        value: Joi.number().required(),
    })
        .empty(null)
        .required(),
    seniority: Joi.object({
        label: Joi.string().required(),
        value: Joi.number().required(),
    })
        .empty(null)
        .required(),
    rate: Joi.number().empty("").required(),
};

const SCHEMA_ERROR_MESSAGES = {
    "any.required": "requiredFieldIsEmpty",
    "string.empty": "requiredFieldIsEmpty",
    "object.missing": "requiredFieldIsEmpty",
    "string.max": "fieldTooLong",
};

const TARIFF_ACTIONS = {
    ADD: "adding",
    EDIT: "editing",
    VIEW: "viewing",
};

const FormTitles = {
    ADDING_TARIFF: "newTariff",
    EDIT_TARIFF: "editTariff",
};

const generateTariff = (
    roles,
    seniorities,
    data = null,
    restoreAssignations = false,
    restoreIdNameType = false,
    restoreRatesFromData = false
) => {
    let rates = [];

    for (let i = 0; i < roles.length; i++) {
        for (let j = 0; j < seniorities.length; j++) {
            const previousRate = data
                ? data.rates?.find(
                      (rate) => rate.developmentRoleId == roles[i].value && rate.seniorityId == seniorities[j].value
                  )
                : null;

            rates.push({
                developmentRole: roles[i].label,
                developmentRoleId: roles[i].value,
                rate: previousRate ? previousRate.rate : 0,
                seniority: seniorities[j].label,
                seniorityId: seniorities[j].value,
            });
        }
    }

    return {
        id: restoreIdNameType ? data.id : null,
        name: restoreIdNameType ? data.name : "",
        type: restoreIdNameType ? data.type : "",
        rates: restoreRatesFromData ? data.rates : rates,
        organizations: !restoreAssignations
            ? []
            : data.organizations?.map((organization) => {
                  return {
                      label: organization.name,
                      value: organization.id,
                  };
              }),
        projects: !restoreAssignations
            ? []
            : data.projects?.map((project) => {
                  return {
                      label: project.name,
                      value: project.id,
                  };
              }),
        countries: !restoreAssignations
            ? []
            : data.countries?.map((country) => {
                  return {
                      label: country.name,
                      value: country.id,
                  };
              }),
    };
};

const AdminTariffs = () => {
    const { t } = useTranslation();
    const { validateSchema, validateSubSchemaFromEvent, errors, resetErrors } = useJoiValidation();
    const [loading, setLoading] = useState(false);
    const [btnLabel, setBtnLabel] = useState(TARIFF_ACTIONS.ADD);
    const [formTitle, setFormTitle] = useState(FormTitles.ADDING_TARIFF);
    const [activeTariffId, setActiveTariffId] = useState(null);
    const [activeTariff, setActiveTariff] = useState(null);
    const [reloadData, setReloadData] = useState(false);
    const [reloadTariff, setReloadTariff] = useState(false);
    const [seniorities, setSeniorities] = useState([]);
    const [roles, setRoles] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [totalPerPage, setTotalPerPage] = useState(5);
    const [tariffsData, setTariffsData] = useState([]);
    const [tariffsDataTotal, setTariffsDataTotal] = useState(0);
    const [showForm, setShowForm] = useState(false);
    const [showReadjustModal, setShowReadjustModal] = useState(false);
    const [search, setSearch] = useState("");
    const [contentEdited, setContentEdited] = useState(false);

    const [projects, setProjects] = useState({ data: [], loading: true });
    const [organizations, setOrganizations] = useState({ data: [], loading: true });
    const [countries, setCountries] = useState({ data: [], loading: true });

    const [name, setName] = useState("");
    const [senioritySelected, setSenioritySelected] = useState(null);
    const [roleSelected, setRoleSelected] = useState(null);
    const [seniorityFilterSelected, setSeniorityFilterSelected] = useState([]);
    const [roleFilterSelected, setRoleFilterSelected] = useState([]);
    const [rate, setRate] = useState("");
    const [projectsAssigned, setProjectsAssigned] = useState(null);
    const [organizationsAssigned, setOrganizationsAssigned] = useState(null);
    const [countriesAssigned, setCountriesAssigned] = useState(null);

    useEffect(() => {
        (async () => {
            try {
                const responseData = await getSenioritiesAsync();

                if (responseData) {
                    setSeniorities(
                        responseData.map((seniority) => {
                            return { label: seniority.name, value: seniority.id };
                        })
                    );
                }
            } catch (error) {
                console.error(error.message);
            }
        })();

        (async () => {
            try {
                const responseData = await getRoleListAsync();

                if (responseData) {
                    setRoles(
                        responseData.map((role) => {
                            return { label: role.name, value: role.id };
                        })
                    );
                }
            } catch (error) {
                console.error(error.message);
            }
        })();

        (async () => {
            try {
                const parsedResponse = await getAllProjectsBasic();

                if (parsedResponse) {
                    const mappedProjects = parsedResponse?.map((p) => ({ value: p.id, label: p.name }));

                    setProjects({ data: mappedProjects, loading: false });
                }
            } catch (error) {
                console.error(error.message);
            }
        })();

        (async () => {
            try {
                const parsedResponse = await getAllOrganizationListAsync();

                if (parsedResponse) {
                    const mappedOrganizations = parsedResponse?.map((o) => ({ value: o.id, label: o.name }));

                    setOrganizations({ data: mappedOrganizations, loading: false });
                }
            } catch (error) {
                console.error(error.message);
            }
        })();

        (async () => {
            try {
                const parsedResponse = await getCountriesAsync();

                if (parsedResponse) {
                    const mappedCountries = parsedResponse?.map((c) => ({ value: c.id, label: c.name }));

                    setCountries({ data: mappedCountries, loading: false });
                }
            } catch (error) {
                console.error(error.message);
            }
        })();
    }, []);

    useEffect(() => {
        if (activeTariffId)
            (async () => {
                const data = await getTariffAsync(activeTariffId);

                if (data) {
                    setBtnLabel(TARIFF_ACTIONS.EDIT);
                    setRoleSelected(null);
                    setSenioritySelected(null);
                    setRate("");

                    const tariffTemp = generateTariff(roles, seniorities, data, true, true, true);

                    setActiveTariff(tariffTemp);

                    // Set the states of selectors
                    setName(tariffTemp.name);
                    setOrganizationsAssigned(tariffTemp.organizations);
                    setProjectsAssigned(tariffTemp.projects);
                    setCountriesAssigned(tariffTemp.countries);
                }
            })();
    }, [reloadTariff]);

    useEffect(() => {
        (async () => {
            let responseData;

            try {
                setLoading(true);

                const responseData = await getTariffsWithPagingAsync(activePage, totalPerPage, search);

                if (responseData) {
                    responseData.tariffs = responseData.tariffs.map((tariff) => {
                        const handleReadjustTariffClick = () => {
                            setActiveTariffId(tariff.id);
                            setShowReadjustModal(true);
                        };

                        const handleEditTariffClick = async (e) => {
                            e.preventDefault();
                            setActiveTariffId(tariff.id);

                            try {
                                setLoading(true);

                                const data = await getTariffAsync(tariff.id);

                                if (data) {
                                    const tariffTemp = generateTariff(roles, seniorities, data, true, true);

                                    setActiveTariff(tariffTemp);

                                    // Set the states of selectors
                                    setName(tariffTemp ? tariffTemp.name : "");
                                    setOrganizationsAssigned(tariffTemp.organizations);
                                    setProjectsAssigned(tariffTemp.projects);
                                    setCountriesAssigned(tariffTemp.countries);

                                    setBtnLabel(TARIFF_ACTIONS.EDIT);
                                    setFormTitle(FormTitles.EDIT_TARIFF);
                                    setShowForm(true);

                                    // Scroll to the top of the page
                                    window.scrollTo(0, 0);
                                }
                            } catch (error) {
                                toast.error(`${t("unexpectedError")}: ${error.message}`);
                            } finally {
                                setLoading(false);
                            }
                        };

                        const handleCopyTariff = async (e) => {
                            e.preventDefault();
                            setActiveTariffId(null);

                            try {
                                setLoading(true);

                                const data = await getTariffAsync(tariff.id);

                                if (data) {
                                    const tariffTemp = generateTariff(roles, seniorities, data);

                                    setActiveTariff(tariffTemp);

                                    // Set the states of selectors
                                    setOrganizationsAssigned(null);
                                    setProjectsAssigned(null);
                                    setCountriesAssigned(null);

                                    setName("");
                                    setBtnLabel(TARIFF_ACTIONS.ADD);
                                    setShowForm(true);

                                    // Scroll to the top of the page
                                    window.scrollTo(0, 0);
                                }
                            } catch (e) {
                                toast.error(`${t("unexpectedError")}: ${e.message}`);
                            } finally {
                                setLoading(false);
                            }
                        };

                        const handleViewTariff = async (e) => {
                            e.preventDefault();
                            setActiveTariffId(tariff.id);

                            try {
                                setLoading(true);

                                const data = await getTariffAsync(tariff.id);

                                if (data) {
                                    const tariffTemp = generateTariff(roles, seniorities, data);

                                    setActiveTariff(tariffTemp);

                                    // Set the states of selectors
                                    setOrganizationsAssigned(null);
                                    setProjectsAssigned(null);
                                    setCountriesAssigned(null);

                                    setName("");
                                    setBtnLabel(TARIFF_ACTIONS.VIEW);
                                    setShowForm(true);

                                    // Scroll to the top of the page
                                    window.scrollTo(0, 0);
                                }
                            } catch (e) {
                                toast.error(`${t("unexpectedError")}: ${e.message}`);
                            } finally {
                                setLoading(false);
                            }
                        };

                        return {
                            name: tariff.name,
                            type: tariff.type,
                            actions: [
                                {
                                    label: "view",
                                    action: handleViewTariff,
                                    color: "",
                                },
                                {
                                    label: "copyTariff",
                                    action: handleCopyTariff,
                                    color: "",
                                },
                                {
                                    label: "edit",
                                    action: handleEditTariffClick,
                                    color: "",
                                },
                                {
                                    label: "readjust",
                                    action: handleReadjustTariffClick,
                                    color: "",
                                },
                            ],
                        };
                    });

                    setTariffsDataTotal(responseData.total);
                    setTariffsData(responseData.tariffs);
                }
            } catch (e) {
                toast.error("Unexpected error: " + e.message);
            } finally {
                setLoading(false);
            }
        })();
    }, [activePage, totalPerPage, reloadData, seniorities, roles]);

    useEffect(() => {
        (async () => {
            try {
                if (activeTariffId) {
                    const data = await getTariffAsync(activeTariffId);

                    if (data) {
                        const ratesTemp = data.rates.filter(
                            (rate) =>
                                (roleFilterSelected.length === 0 ||
                                    roleFilterSelected.some((filter) => filter.value === rate.developmentRoleId)) &&
                                (seniorityFilterSelected.length === 0 ||
                                    seniorityFilterSelected.some((filter) => filter.value === rate.seniorityId))
                        );

                        setActiveTariff((prev) => ({ ...prev, rates: ratesTemp }));
                    }
                }
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            }
        })();
    }, [roleFilterSelected, seniorityFilterSelected]);

    useOnBeforeUnload((event) => {
        event = event || Window.event;

        // Compatibility with old browsers and Safari
        if (event) {
            event.returnValue = "Are you sure you want to leave the current page?";
        }

        return "Are you sure you want to leave the current page?";
    }, contentEdited);

    const handleSearchChange = (e) => {
        e.preventDefault();
        setSearch(e.target.value);
        setReloadData(!reloadData);
    };

    const handleNameChange = (e) => {
        e.preventDefault();
        setName(e.target.value);
        validateSubSchemaFromEvent(TARIFF_SCHEMA, SCHEMA_ERROR_MESSAGES, e);

        setContentEdited(true);
    };

    const handleRoleChange = (role) => {
        setRoleSelected(role);
        validateSubSchemaFromEvent(RATE_CHANGE_SCHEMA, SCHEMA_ERROR_MESSAGES, {
            target: { name: "role", value: role },
        });
    };

    const handleSeniorityChange = (seniority) => {
        setSenioritySelected(seniority);
        validateSubSchemaFromEvent(RATE_CHANGE_SCHEMA, SCHEMA_ERROR_MESSAGES, {
            target: { name: "seniority", value: seniority },
        });
    };

    const handleRoleFilterChange = (role) => {
        setRoleFilterSelected(role);
    };

    const handleSeniorityFilterChange = (seniority) => {
        setSeniorityFilterSelected(seniority);
    };

    const handleRateChange = (e) => {
        e.preventDefault();

        const rateInt = parseInt(e.target.value);
        setRate(rateInt);

        validateSubSchemaFromEvent(RATE_CHANGE_SCHEMA, SCHEMA_ERROR_MESSAGES, e);
    };

    const handleCancelReadjust = () => {
        setActiveTariffId(null);
        setShowReadjustModal(false);
    };

    const handleAcceptReadjust = async (readjustPercentage) => {
        try {
            setLoading(true);

            const response = await readjustTariffAsync(activeTariffId, readjustPercentage);

            if (response) {
                toast.success("Tariff readjusted successfully");
                setShowReadjustModal(false);
            }
        } catch (e) {
            toast.error("Unexpected error: " + e.message);
        } finally {
            setLoading(false);
        }
    };

    const handleChangePerPage = (event) => {
        setTotalPerPage(event.target.value);
    };

    const clearForm = () => {
        setName("");
        setProjectsAssigned(null);
        setOrganizationsAssigned(null);
        setCountriesAssigned(null);

        setRate("");
        setRoleSelected(null);
        setSenioritySelected(null);

        resetErrors();

        setBtnLabel(TARIFF_ACTIONS.ADD);
        setFormTitle(FormTitles.ADDING_TARIFF);
        setContentEdited(false);
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        const formData = {
            name: name,
        };

        let cantErrors = validateSchema(TARIFF_SCHEMA, SCHEMA_ERROR_MESSAGES, formData);

        if (!!cantErrors) {
            return;
        }

        switch (btnLabel) {
            case TARIFF_ACTIONS.ADD:
                await handleFormSubmitAdd();
                break;
            case TARIFF_ACTIONS.EDIT:
                await handleFormSubmitEdit();
                break;
        }

        setContentEdited(false);
        setShowForm(false);
    };

    const handleFormSubmitAdd = async () => {
        try {
            const dataToSend = {
                name: name,
                rates: activeTariff?.rates,
                organizations: organizationsAssigned?.map((o) => o.value),
                projects: projectsAssigned?.map((p) => p.value),
                countries: countriesAssigned?.map((c) => c.value),
            };

            setLoading(true);

            const response = await addTariffAsync(dataToSend);

            if (response) {
                toast.success("Tariff rate successfully added");

                setActiveTariffId(response);
                setReloadTariff(!reloadTariff);
                setReloadData(!reloadData);
            }
        } catch (e) {
            toast.error("Unexpected error: " + e.message);
        } finally {
            setLoading(false);
        }
    };

    const handleFormSubmitEdit = async () => {
        try {
            const dataToSend = {
                name: name,
                rates: activeTariff?.rates,
                organizationsIds: organizationsAssigned?.map((o) => o.value),
                projectsIds: projectsAssigned?.map((p) => p.value),
                countriesIds: countriesAssigned?.map((c) => c.value),
            };

            setLoading(true);

            const response = await updateTariffAsync(activeTariffId, dataToSend);

            if (response) {
                toast.success(t("tariffUpdated"));

                setReloadData(!reloadData);
                setReloadTariff(!reloadTariff);
            }
        } catch (e) {
            toast.error(t("unexpectedError") + ": " + e.message);
        } finally {
            setLoading(false);
        }
    };

    const handleFormCancel = () => {
        setActiveTariffId(null);
        setActiveTariff(null);
        setShowForm(false);
        clearForm();
    };

    const handleClickAdd = () => {
        const tariffTemp = generateTariff(roles, seniorities);

        setActiveTariff(tariffTemp);

        // Set the states of selectors
        setName(tariffTemp.name);
        setOrganizationsAssigned(tariffTemp.organizations);
        setProjectsAssigned(tariffTemp.projects);
        setCountriesAssigned(tariffTemp.countries);

        setFormTitle(FormTitles.ADDING_TARIFF);

        setActiveTariffId(null);
        setShowForm(true);
    };

    const handleSetRoleSelected = (role) => {
        setRoleSelected(role);
        validateSubSchemaFromEvent(RATE_CHANGE_SCHEMA, SCHEMA_ERROR_MESSAGES, {
            target: { name: "role", value: role },
        });
    };

    const handleSetSenioritySelected = (seniority) => {
        setSenioritySelected(seniority);
        validateSubSchemaFromEvent(RATE_CHANGE_SCHEMA, SCHEMA_ERROR_MESSAGES, {
            target: { name: "seniority", value: seniority },
        });
    };

    const handleSetRate = (rate) => {
        setRate(rate);
        validateSubSchemaFromEvent(RATE_CHANGE_SCHEMA, SCHEMA_ERROR_MESSAGES, {
            target: { name: "rate", value: rate },
        });
    };

    const handleChangeRate = (e) => {
        e.preventDefault();

        const data = {
            role: roleSelected,
            seniority: senioritySelected,
            rate,
        };

        const numberOfErrors = validateSchema(RATE_CHANGE_SCHEMA, SCHEMA_ERROR_MESSAGES, data);
        if (numberOfErrors > 0) {
            return;
        }

        const rateIndex = activeTariff?.rates.findIndex(
            (rate) => rate.seniorityId === senioritySelected?.value && rate.developmentRoleId === roleSelected.value
        );

        if (rateIndex >= 0) {
            const prevRates = activeTariff.rates;
            prevRates[rateIndex].rate = rate;
            setActiveTariff((prevState) => {
                return { ...prevState, rates: prevRates };
            });
        }

        setContentEdited(true);
    };

    const handleBackClick = (e) => {
        e.preventDefault();
        setActiveTariff(null);
        setActiveTariffId(null);
        setShowForm(false);
        setRoleFilterSelected([]);
        setSeniorityFilterSelected([]);
        setBtnLabel(TARIFF_ACTIONS.ADD);
    };

    return (
        <>
            <div
                className={`${styles.filtersContainer} ${showForm ? "justify-content-end" : "justify-content-between"}`}
            >
                {!showForm && (
                    <div className={styles.searchContainer}>
                        <div className={styles.search}>
                            <Label htmlFor="search">{t("search")}</Label>
                            <Input id="search" className="px-2" value={search} onChange={handleSearchChange} />
                        </div>
                    </div>
                )}
                <div className="d-flex gap-2 mx-3">
                    <button
                        className={`${styles.btnAdd} btn btn-success text-uppercase`}
                        disabled={showForm}
                        onClick={handleClickAdd}
                    >
                        {showForm ? t(btnLabel) : t("add")}
                    </button>
                    <div className={styles.totalPerPage}>
                        <select
                            id="select-limit"
                            className="py-1 px-2"
                            defaultValue={totalPerPage}
                            onChange={handleChangePerPage}
                        >
                            {TABLE_ROWS_LIMITS.map((rowLimit, index) => {
                                return (
                                    <option key={index} value={rowLimit}>
                                        {rowLimit}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
            </div>

            <div className={`${styles.contentContainer} mx-auto`}>
                {showForm && (
                    <div className={`${styles.formContainer} p-3`}>
                        {btnLabel !== TARIFF_ACTIONS.VIEW && (
                            <form onSubmit={handleFormSubmit} className="py-3">
                                <div className={`${styles.tariffDetailsContainer} d-flex flex-column m-3 p-3`}>
                                    <h3 className="text-center">{t(formTitle)}</h3>

                                    <div className="d-flex gap-4">
                                        <div className={`${styles.fieldContainer}`}>
                                            <Label htmlFor="name" requiredIndicator>
                                                {t("name")}
                                            </Label>
                                            <Input
                                                id="name"
                                                name="name"
                                                className="px-3 py-2"
                                                value={name}
                                                onChange={handleNameChange}
                                                invalid={!!errors.name}
                                            />
                                            {errors.name && (
                                                <div className="mt-1">
                                                    <p className={sharedStyle.errorMsg}>{errors.name.message}</p>
                                                </div>
                                            )}
                                        </div>

                                        <div className={`${styles.fieldContainer}`}>
                                            <Label htmlFor="projects">{t("projectsToAssignIt")}</Label>
                                            <Select
                                                id="projects"
                                                name="projects"
                                                options={projects.data}
                                                isLoading={projects.loading}
                                                styles={getSelectorStyle(!!errors.projects)}
                                                value={projectsAssigned}
                                                onChange={setProjectsAssigned}
                                                isMulti
                                            />
                                            {errors.projects && (
                                                <div className="mt-1">
                                                    <p className={sharedStyle.errorMsg}>{errors.projects.message}</p>
                                                </div>
                                            )}
                                        </div>

                                        <div className={`${styles.fieldContainer}`}>
                                            <Label htmlFor="organizations">{t("organizationsToAssignIt")}</Label>
                                            <Select
                                                id="organizations"
                                                name="organizations"
                                                options={organizations.data}
                                                isLoading={organizations.loading}
                                                styles={getSelectorStyle(!!errors.organizations)}
                                                value={organizationsAssigned}
                                                onChange={setOrganizationsAssigned}
                                                isMulti
                                            />
                                            {errors.organizations && (
                                                <div className="mt-1">
                                                    <p className={sharedStyle.errorMsg}>
                                                        {errors.organizations.message}
                                                    </p>
                                                </div>
                                            )}
                                        </div>

                                        <div className={`${styles.fieldContainer}`}>
                                            <Label htmlFor="countries">{t("countriesToAssignIt")}</Label>
                                            <Select
                                                id="countries"
                                                name="countries"
                                                options={countries.data}
                                                isLoading={countries.loading}
                                                styles={getSelectorStyle(!!errors.countries)}
                                                value={countriesAssigned}
                                                onChange={setCountriesAssigned}
                                                isMulti
                                            />
                                            {errors.countries && (
                                                <div className="mt-1">
                                                    <p className={sharedStyle.errorMsg}>{errors.countries.message}</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className={`${styles.tariffDetailsContainer} d-flex flex-column m-3 p-3`}>
                                    <h4>{t("changeRate")}</h4>
                                    <p className={styles.description}>{t("selectRoleAndSeniority")}</p>

                                    <div className="d-flex justify-content-between gap-5">
                                        <div className={styles.fieldContainer}>
                                            <Label htmlFor="role" requiredIndicator>
                                                {t("role")}
                                            </Label>
                                            <Select
                                                id="role"
                                                styles={getSelectorStyle(!!errors.role)}
                                                options={roles}
                                                onChange={handleRoleChange}
                                                value={roleSelected}
                                                placeholder={`${t("select")}...`}
                                                isClearable
                                            />
                                            {errors.role && (
                                                <div className="mt-1">
                                                    <p className={sharedStyle.errorMsg}>{errors.role.message}</p>
                                                </div>
                                            )}
                                        </div>

                                        <div className={styles.fieldContainer}>
                                            <Label htmlFor="seniority" requiredIndicator>
                                                {t("seniority")}
                                            </Label>
                                            <Select
                                                id="seniority"
                                                styles={getSelectorStyle(!!errors.role)}
                                                options={seniorities}
                                                onChange={handleSeniorityChange}
                                                value={senioritySelected}
                                                placeholder={`${t("select")}...`}
                                                isClearable
                                            />
                                            {errors.seniority && (
                                                <div className="mt-1">
                                                    <p className={sharedStyle.errorMsg}>{errors.seniority.message}</p>
                                                </div>
                                            )}
                                        </div>

                                        <div className={styles.fieldContainer}>
                                            <Label htmlFor="rate" requiredIndicator>
                                                {t("rate")}
                                            </Label>
                                            <Input
                                                type="number"
                                                id="rate"
                                                name="rate"
                                                className="px-3 py-2"
                                                value={rate}
                                                onChange={handleRateChange}
                                                invalid={!!errors.rate}
                                            />
                                            {errors.rate && (
                                                <div className="mt-1">
                                                    <p className={sharedStyle.errorMsg}>{errors.rate.message}</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <button
                                        className="btn btn-success ms-auto mt-4 text-uppercase"
                                        onClick={handleChangeRate}
                                    >
                                        {t("change")}
                                    </button>
                                </div>

                                <div className="d-flex m-3 justify-content-between gap-5"></div>
                                <div className="d-flex m-3 justify-content-between gap-5"></div>

                                <div className="d-flex m-3 justify-content-end gap-3">
                                    <button
                                        type="reset"
                                        className="btn btn-danger text-uppercase"
                                        onClick={handleFormCancel}
                                    >
                                        {t("close")}
                                    </button>
                                    <button type="submit" className="btn btn-success text-uppercase">
                                        {t("save")}
                                    </button>
                                </div>
                            </form>
                        )}
                        {btnLabel === TARIFF_ACTIONS.VIEW && (
                            <div className={`${styles.tariffDetailsContainer} d-flex flex-column m-3 p-3`}>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <h4>{t("filters")}</h4>
                                        <p className={styles.description}>{t("selectRoleAndSeniorityFilter")}</p>
                                    </div>
                                    <div>
                                        <button
                                            className={`${styles.backBtn} btn text-uppercase`}
                                            onClick={handleBackClick}
                                        >
                                            {t("back")}
                                        </button>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-between gap-5">
                                    <div className={styles.fieldContainer}>
                                        <Label htmlFor="role">{t("role")}</Label>
                                        <Select
                                            id="role"
                                            styles={getSelectorStyle(false)}
                                            options={roles}
                                            isMulti
                                            onChange={handleRoleFilterChange}
                                            value={roleFilterSelected}
                                            placeholder={`${t("select")}...`}
                                            isClearable
                                        />
                                    </div>

                                    <div className={styles.fieldContainer}>
                                        <Label htmlFor="seniority">{t("seniority")}</Label>
                                        <Select
                                            id="seniority"
                                            styles={getSelectorStyle(false)}
                                            options={seniorities}
                                            isMulti
                                            onChange={handleSeniorityFilterChange}
                                            value={seniorityFilterSelected}
                                            placeholder={`${t("select")}...`}
                                            isClearable
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="d-flex">
                            <TariffDetails
                                roles={
                                    roleFilterSelected.length > 0
                                        ? roleFilterSelected
                                        : roles.filter((role) => {
                                              return roleSelected ? role.value === roleSelected.value : true;
                                          })
                                }
                                seniorities={seniorityFilterSelected.length > 0 ? seniorityFilterSelected : seniorities}
                                tariff={activeTariff?.rates ? activeTariff?.rates : []}
                                setRole={handleSetRoleSelected}
                                setSeniority={handleSetSenioritySelected}
                                setRate={handleSetRate}
                                justView={btnLabel === TARIFF_ACTIONS.VIEW}
                            />
                        </div>
                    </div>
                )}

                <AdminTable headers={["name", "type", ""]} data={tariffsData} />

                <div className={styles.paginatorContainer}>
                    <Paginator
                        setPage={setActivePage}
                        activePage={activePage}
                        lastPage={Math.ceil(tariffsDataTotal ? tariffsDataTotal / totalPerPage : 1)}
                    />
                </div>

                <ConfirmTariffReadjustModal
                    onCancel={handleCancelReadjust}
                    show={showReadjustModal}
                    onAccept={handleAcceptReadjust}
                />
            </div>

            {loading && <Loading />}
        </>
    );
};

export default AdminTariffs;
