import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { DATE_FORMAT, TABLE_ROWS_LIMITS } from "../../../base/js/constants";
import { getReviewListAsync } from "../../../services/ReviewService";
import Input from "../../common/forms/input/Input";
import Label from "../../common/forms/label/Label";
import Paginator from "../../common/paginator/Paginator";
import ReviewViewModal from "../../common/review-view-modal/ReviewViewModal";
import Loading from "../../loading/Loading";
import sharedStyle from "../../shared-styles/FormStyle.module.scss";
import AdminTable from "../admin-tables/AdminTable";
import styles from "./AdminReviews.module.scss";

const AdminTariffs = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [totalPerPage, setTotalPerPage] = useState(5);
    const [reviewList, setReviewList] = useState([]);
    const [reviewActive, setReviewActive] = useState(null);
    const [showReviewViewModal, setShowReviewViewModal] = useState(false);
    const [reviewListTotal, setReviewListTotal] = useState(0);
    const [search, setSearch] = useState("");

    useEffect(() => {
        (async () => {
            const responseData = await getReviewListAsync(activePage, totalPerPage, search);

            if (responseData) {
                responseData.reviews = responseData.reviews.map((review) => {
                    const handleReviewView = (e) => {
                        e.preventDefault();
                        setReviewActive(review);
                        setShowReviewViewModal(true);
                    };

                    return {
                        createdAt: format(parseISO(review.createdAt), DATE_FORMAT),
                        project: review.project.name,
                        staff: `${review.staff.name} ${review.staff.lastName}`,
                        subject: review.subject,
                        review: `${review.comment.slice(0, 50)}...`,
                        actions: [
                            {
                                label: "view",
                                action: handleReviewView,
                                color: "",
                            },
                        ],
                    };
                });
                setReviewListTotal(responseData.total);
                setReviewList(responseData.reviews);
            }
        })();
    }, [activePage, totalPerPage, search]);

    const handleSearchChange = (e) => {
        e.preventDefault();
        setSearch(e.target.value);
    };

    const handleChangePerPage = (event) => {
        setTotalPerPage(event.target.value);
    };

    const handleCloseReviewViewModal = () => {
        setReviewActive(null);
        setShowReviewViewModal(false);
    };

    return (
        <>
            <div className={`${styles.filtersContainer} justify-content-between`}>
                <div className={styles.searchContainer}>
                    <div className={styles.search}>
                        <Label htmlFor="search">{t("search")}</Label>
                        <Input id="search" className="px-2" value={search} onChange={handleSearchChange} />
                    </div>
                </div>

                <div className="d-flex gap-2 mx-3">
                    <div className={styles.totalPerPage}>
                        <select
                            id="select-limit"
                            className="py-1 px-2"
                            defaultValue={totalPerPage}
                            onChange={handleChangePerPage}
                        >
                            {TABLE_ROWS_LIMITS.map((rowLimit, index) => {
                                return (
                                    <option key={index} value={rowLimit}>
                                        {rowLimit}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
            </div>

            <div className={`${styles.contentContainer} mx-auto`}>
                <AdminTable
                    headers={["createdAt", "project", "staff", "subject", "review", "actions"]}
                    data={reviewList}
                />
                <div className={styles.paginatorContainer}>
                    <Paginator
                        setPage={setActivePage}
                        activePage={activePage}
                        lastPage={Math.ceil(reviewListTotal ? reviewListTotal / totalPerPage : 1)}
                    />
                </div>
            </div>

            <ReviewViewModal onClose={handleCloseReviewViewModal} show={showReviewViewModal} review={reviewActive} />

            {loading && <Loading />}
        </>
    );
};

export default AdminTariffs;
