import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import en_translation from "./assets/i18n/en";
import es_translation from "./assets/i18n/es";


i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: false,
        fallbackLng: "en",
        interpolation: {
            escapeValue: false,
        },
        resources: {
            en: {
                translation: en_translation,
            },
            es: {
                translation: es_translation,
            },
        },
    });

const parseCurrentLanguage = (value) => {
    if (value.includes("en")) {
        return "en";
    }
    if (value.includes("es")) {
        return "es";
    }
    return "en";
};

export const getLocalizedMessage = (value) => {
    const currentLanguage = parseCurrentLanguage(i18n.language);

    return i18n.getResource(currentLanguage, "translation", value);
};


export default i18n;
