export const TotalMinMaxLimits = {
    MIN: 1,
    MAX: 99,
};

export const TeamInfoFormIds = {
    ROLE: "role",
    SENIORITY: "seniority",
    TOTAL: "total",
    TIME: "time",
    JOB_SKILLS: "jobSkills",
    ESTIMATED_START_DATE: "estimatedStartDate",
    ESTIMATED_END_DATE: "estimatedEndDate",
    JOB_DESCRIPTION_LINK: "jobDescriptionLink",
};
