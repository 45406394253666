const es_translation = {
    english: "Inglés",
    spanish: "Español",
    italian: "Italiano",
    german: "Alemán",
    portuguese: "Portugués",
    french: "Francés",
    chinese: "Mandarín",
    buildYourTeam: "Construye tu equipo",
    contactUs: "Contacto",
    meetTheTeam: "Conoce <span1>el</span1> <span2>equipo</span2>",
    whatWeAre: "Somos un equipo de profesionales impulsados por la pasión por lo que hacemos.",
    contactUs2: "Contáctanos",
    coreTeam: "Equipo principal",
    jackieSpeech:
        '"... No importa cuán inteligente seas, y no importa cuán brillante sea tu idea. Si tu mensaje es confuso, tu idea está muerta..."',
    santiagoBeginnings:
        "Santiago era un niño inquieto, curioso y creativo. A una edad muy temprana, se inspiró en las historias de éxito de sus padres. Dado que era un verdadero solucionador de problemas, no sorprende que comenzara su carrera en el campo de la tecnología. Rápidamente se dio cuenta de la gran brecha entre las habilidades técnicas y no técnicas. Para combatir esta brecha, decidió unir sus fortalezas (empatía, escucha activa y pasión por la resolución de problemas) para acercar el mundo empresarial al mundo técnico.",
    readMore: "Leer más",
    santiagoChallenges:
        "Actuando en el área comercial, enfrentó mil y una veces el “lenguaje técnico” y luchó contra los problemas empresariales que éste generaba. “…la industria de TI tiene mucha jerga técnica, y muchas veces pienso que la gente está hablando en un idioma diferente…. Pero el lenguaje comercial es más simple. Hago la vida de nuestros clientes lo más simple posible, día tras día…” Santiago Fraga – Fundador",
    santiagoBusinessVisionP1:
        "Santiago identificó problemas en ambos lados del proceso de ventas: si bien las fábricas de software latinoamericanas cuentan con profesionales calificados, tienen dificultades para acceder",
    santiagoBusinessVisionP2:
        "a mercados internacionales. Los clientes, por otro lado, luchan con la dotación de personal y la contratación de profesionales de TI. A menudo, no pueden atraer a los candidatos adecuados con las cualificaciones adecuadas en el momento adecuado.",
    santiagoSpeech:
        "“…No importa cuán inteligente seas, y no importa cuán brillante sea tu idea. Si tu mensaje está confuso, tu idea está muerta…” Santiago Fraga – Fundador",
    santiagoMeetsJackie:
        "En 2010, Santiago conoció a Jackie en una entrevista de trabajo. Rápidamente se convirtieron en un equipo sólido. Si bien entregaron resultados excepcionales para la empresa, implementaron simultáneamente una estrategia de transformación y diversificación comercial. Incluso después de que sus carreras los condujeran por caminos diferentes, siempre se mantuvieron en contacto. Por lo tanto, era natural que Santiago trajera a Jackie a las reuniones de materialización de TheDevBlock y la nombrara directora ejecutiva. Una vez que Santiago se dio cuenta de que los problemas que encontraba eran bastante comunes en la industria, tuvo una visión. Quería crear una plataforma web que pudiera permitir a los clientes crear el equipo de TI y los socios remotos ideales para vender sus recursos. Santiago sabía que esta solución única podría facilitar la vida de todos los involucrados.",
    member1Quote:
        "Creemos en el poder de las empresas para transformar la sociedad. Y probablemente el área más atractiva para la creación de puestos de trabajo altamente cualificados es la industria de TI.",
    member2Quote:
        "Hemos diseñado una plataforma que se preocupa por lo que te importa. TheDevBlock fue pensado por desarrolladores de software que han experimentado los dolores de la contratación de primera mano.",
    writeUs: "Escríbenos",
    wantToConnect: "¿Quieres contactarnos?",
    tellUsHelp: "Dinos cómo podemos ayudarte. Nos pondremos en contacto a la brevedad.",
    fullName: "Nombre Completo",
    phoneNumber: "Número de teléfono",
    company: "Empresa",
    businessEmailAddress: "Email Empresarial",
    writeMessage: "Escribe un mensaje",
    receiveNewsletter: "Quiero recibir el boletín de noticias de TheDevBlock",
    sendMessage: "Enviar mensaje",
    address: "Dirección",
    hiringHasChanged: "La forma de contratar <span1>ha</span1> <span2>cambiado</span2>",
    acquireQualifiedIT:
        "Puede ser un verdadero desafío adquirir talento de TI calificado. A pesar de la demanda infinita de habilidades de desarrollo de software, hay escasez de desarrolladores de software.",
    tdbComesIn: "Ahí es donde entra TheDevBlock.",
    getAQuote: "Cotice aquí",
    tellAStory: "Permítenos contarte <span>nuestra historia</span>",
    foundationStory:
        "Fundada en 2018 y con sede en Texas, TheDevBlock se dedica a resolver estos problemas. Al asociarse con nosotros, puede recibir el talento de TI que necesita para llevar su negocio a nuevas alturas.",
    ourStaff:
        "Nuestro personal gerencial tiene su sede en Uruguay, que es el lugar perfecto para el nearshoring debido a sus condiciones, ubicación y zona horaria. También ofrece el mejor clima de negocios de la región. Cuando tome la decisión inteligente de contratar a través de nosotros, no tendrá que preocuparse por los dolores de cabeza de recursos humanos, los problemas legales y los riesgos de seguridad. Lo mejor de todo es que hablamos exactamente el mismo idioma comercial.",
    wantToLearnMore: "¿Quieres conocer más?",
    scheduleMeeting: "Programa una reunión",
    meetTheTeam2: "Conoce al equipo",
    knowTheTeam: "¿Quieres saber más sobre los profesionales que trabajan en TheDevBlock?",
    valuesWeStandFor: "Los <span>valores</span> que defendemos",
    speedAndQuality: "Velocidad y Calidad",
    customerService: "Servicio al Cliente",
    innovation: "Innovación",
    diversity: "Diversidad",
    whatWeProvide:
        "Brindamos el talento de TI que necesita rápidamente, sin comprometer la calidad. Después de todo, el tiempo es dinero.",
    ourPriority:
        "Nuestros clientes son nuestra principal prioridad. Trabajamos incansablemente para asegurarnos de que siempre estén satisfechos.",
    ourMission:
        "Si bien estamos orgullosos de nuestra plataforma única, no está grabada en piedra. Estamos constantemente en una misión para innovar y mejorarlo.",
    ourEmployees:
        "Damos la bienvenida a los empleados de todos los ámbitos de la vida. Son sus diferencias y perspectivas únicas lo que los hace tan valiosos.",
    whatClientsSay: "Lo que los clientes dicen sobre nosotros",
    reviewDerek:
        "TDB nos ayudó cuando nos enfrentamos a una situación empresarial crítica con plazos muy ajustados para el lanzamiento del producto, la formación y las entregas a los clientes. Los desarrolladores de ScienceSoft fueron capaces de intervenir en un proyecto inacabado y finalizarlo todo de forma muy profesional y eficiente, y justo a tiempo. Estamos muy, muy satisfechos con TDB y no sólo planeamos continuar con ellos, sino que también nos gustaría recomendar sus servicios para cualquier otro proyecto de desarrollo de software desafiante.",
    wantToStart: "¿Quieres iniciar un proyecto?",
    scheduleMeeting2: "Programa una Reunión",
    augmentYourTeam: "Aumente su <span>equipo de talentos tecnológicos</span> en 72 horas",
    tdbDescription:
        "TheDevBlock, la plataforma de contratación B2B más eficiente, proporciona personal de TI cercano mientras usted se enfoca en su negocio.",
    isYourCompanyStruggling: "¿Su empresa está luchando con estos problemas?",
    shortageTechResources: "Escasez de recursos técnicos",
    strugglingManageBudget: "Luchando para administrar el presupuesto del proyecto",
    lackOfTime: "Falta de tiempo para contratar profesionales para sus proyectos",
    helpIncreaseEfficiency: "Permítanos ayudarlo a aumentar la eficiencia de sus proyectos",
    highQualifiedExperts: "Expertos altamente calificados disponibles",
    seniorDevelopers: "Desarrolladores Senior",
    skillsAvailable: "Habilidades disponibles",
    whyTdb: "¿Por qué TheDevBlock?",
    support360: "Soporte 360",
    support360Description:
        "Un equipo de profesionales te acompaña antes, durante y después del proceso de contratación. Un Gerente de Contratación y un Gerente Técnico de Cuentas lo ayudan a moverse más rápido y obtener mejores resultados.",
    preQualifiedTalents: "Talentos pre calificados",
    preQualifiedTalentsDescription:
        "Examinamos a nuestro equipo de expertos en tecnología a través de una variedad de pruebas, como entrevistas de selección, pruebas técnicas, revisiones de códigos, pruebas psicológicas y verificaciones de antecedentes.",
    flexibility: "Flexibilidad",
    flexibilityDescription:
        "Puede aumentar o disminuir su equipo a pedido y puede cancelar el servicio en cualquier momento.",
    adaptability: "Adaptabilidad",
    adaptabilityDescription: "Trabajamos en su zona horaria, líneas de tiempo y horarios.",
    forgetAboutProblems:
        "Olvídese de los problemas de Recursos Humanos, los problemas legales y los riesgos de seguridad; usted y TheDevBlock hablan el mismo idioma empresarial.",
    findExperiencedDevelopers: "Encuentre <span>desarrolladores experimentados</span> en nuestro personal examinado",
    screenForExpertise: "Examinamos la experiencia,<br /> la personalidad y el comportamiento.",
    letsStart: "Empecemos",
    liveScreeningInterview: "Entrevista de proyección en vivo",
    liveScreeningInterviewDescription:
        "Evaluamos en vivo a nuestros ingenieros y les hacemos preguntas técnicas y de comportamiento para comprender su experiencia, impulsos y sueños.",
    professionalRefChecks: "Chequeos de Referencia Profesional",
    professionalRefChecksDescription:
        "Hablamos con al menos dos referencias profesionales que han trabajado con nuestros desarrolladores en los últimos 2 años para comprender el desempeño de su lugar de trabajo.",
    englishInterview: "Entrevista en Inglés",
    englishInterviewDescription:
        "Usando una variedad de técnicas, nuestros desarrolladores responden a una serie de pruebas para evaluar y considerar su nivel actual.",
    technicalInterview: "Entrevista Técnica",
    technicalInterviewDescription:
        "Probablemente tenga pruebas técnicas internas para medir la competencia técnica. Sin embargo, ¡tenemos una evaluación técnica para nuestros desarrolladores si es necesario!",
    technicalInterviewFootnote: "A pedido",
    aboutTheProject: "Sobre el proyecto",
    teamName: "Nombre del Equipo",
    investmentExpectation: "¿Cuál es el presupuesto de tu proyecto?",
    yourDecisionFlow: "¿Cuál es su proceso de flujo de decisiones para estos proyectos?",
    rolesInYourCompany: "¿Qué roles en su empresa interactúan durante el proceso de contratación y confirmación?",
    estimatedDateToStart: "Fecha de inicio estimada",
    estimatedDateToEnd: "Fecha de finalización estimada",
    timeZone: "Zona horaria",
    expectedDateToConfirm: "Fecha para la que necesita el equipo en funcionamiento",
    whatYouNeed: "Lo que necesitas para tu equipo",
    teamWorkArea: "¿En qué área trabajará el equipo?",
    typeOfProject: "¿Qué tipo de proyecto es?",
    additionalComments: "¿Tiene algún comentario adicional sobre el proyecto que le gustaría compartir con nosotros?",
    additionalCommentsShort: "Comentario adicional",
    attachFileAboutProject: "Adjunte cualquier archivo o documentación que desee compartir sobre el proyecto",
    file: "Archivo",
    attachFile: "Adjuntar archivo",
    continue: "Continuar",
    select: "Seleccionar",
    noFileChosen: "Sin archivo adjunto",
    fileSizeLimit: "EL tamaño del archivo puede ser como máximo de 5MB",
    allowedFileTypes: "Los tipos de archivos admitidos son",
    mandatoryField: "Campo obligatorio",
    checkboxRequired: "Debe marcarse al menos una casilla",
    login: "Inicio de sesión",
    assembleYourTeam: "Construye tu equipo",
    teamInfo: "Información del equipo",
    resume: "Resumen",
    confirmation: "Confirmación",
    search: "Búsqueda",
    faq: "Preguntas frecuentes",
    buildYourTeamOfProfessionals: "Crea tu equipo de TI<span>profesionales</span>",
    buildTeamAccordingNeeds:
        "Arma un equipo de acuerdo a tus necesidades y comienza a operar en el menor tiempo posible.",
    letStart: "Empecemos",
    home: "Inicio",
    howToStart: "¿Cómo comenzar?",
    selectYourTeam: "Selecciona tu equipo",
    selectYourOptions: "Selecciona tus opciones",
    completeAgainInformation: "Revise los roles a continuación y corrija la información según sea necesario.",
    partial: "Parcial",
    role: "Rol",
    seniority: "Seniority",
    jobSpecificSkills: "Conjunto de habilidades",
    jobskills: "Habilidades",
    count: "Total",
    time: "Tiempo",
    estimatedDateToStartOptional: "Fecha de inicio estimada",
    estimatedDateToEndOptional: "Fecha de finalización estimada",
    jobDescriptionLinkOptional: "Enlace de descripción del puesto",
    or: "o",
    errorLinkFormat: "El link debe tener un formato correcto. Ej: https://example.com",
    deleteResource: "Eliminar recurso",
    cancel: "Cancelar",
    ok: "Ok",
    addResource: "<span>Agregar</span> un recurso",
    finishFiltering: "<span>Terminar</span> filtrado",
    decisionMakerRole: "Tomador de Decisiones (CTO, CIO, CISO, VP)",
    recommenderRole: "Referente (Director, Senior Manager, Information Security, Cyber Security, IT)",
    userRole: "Usuario (Technical Consultant, Support Engineer, Project Manager, Engineer, Analyst)",
    othersRole: "Otros",
    knowledgeRequired: "Conocimiento requerido (lenguajes)",
    dragAndDropHelp: "Arrastre y suelte cada elemento en su casilla correspondiente.",
    mandatorySkills: "Habilidades requeridas",
    desiredSkills: "Habilidades deseadas",
    addCustom: "Añadir personalizado",
    dropHere: "Arroje aquí",
    desiredKnowledge: "Conocimiento deseado (lenguajes)",
    addKnowledge: "Agregar conocimiento",
    requestInterview: "Solicitar Entrevista",
    moreInformation: "Más información",
    markNotSatisfied: "Marcar como No Satisfecho",
    hour: "hora",
    pending: "Pendiente",
    decisionMaker: "Tomador de Decisiones (CTO, CIO, CISO, VP)",
    recommender: "Recomendador (Director, Senior Manager, Information Security, Cyber Security, IT)",
    user: "Usuario (Technical Consultant, Support Engineer, Project Manager, Engineer, Analyst)",
    others: "Otros",
    howToBuildYourTeam: "Cómo construir su equipo de profesionales de TI",
    days: "Días",
    hours: "Horas",
    minutes: "Minutos",
    seconds: "Segundos",
    hiringManagerWillContactYou:
        "Su gerente de contratación se comunicará con usted a la brevedad para informarle sobre el estado de su solicitud",
    actions: "Acciones",
    back: "Atrás",
    manageTeams: "Administrar equipos",
    projectStatus: "Estado del Proyecto",
    projectName: "Nombre del Proyecto",
    startDate: "Fecha de Inicio",
    endDate: "Fecha de Finalización",
    monthPrice: "Monto Mensual",
    monthPrice2: "Precio Mensual",
    finalPrice: "Monto Final",
    summaryOfResourcesFiltering: "Resumen de Filtrado de Recursos",
    selectionModeDescription:
        "Si todo sale bien, simplemente haga clic en <span>Continuar</span> y nuestros expertos asignarán sus recursos dentro de las 72 horas. Todavía hay tiempo para hacer correcciones o ajustes si es necesario, para hacerlo simplemente haga clic en <span>Administrar equipo</span>.",
    manageResources: "<span>Administrar</span> equipo",
    finishSelectionWSpan: "<span>Finalizar</span> selección",
    finishSelection: "Finalizar Selección",
    finishSelectionBtnClick:
        "Ha hecho clic en el botón <span>Finalizar selección</span>, nuestros administradores le asignarán los recursos en un máximo de <span>72 horas</span> y le notificaremos por correo electrónico.",
    finishSelectionConfirmationDialog:
        "Una vez que confirme este mensaje, no podrá realizar cambios. ¿Quieres continuar?",
    loading: "Cargando",
    projectPendingConfirmation: "Su proyecto está pendiente de confirmación",
    summaryResourceFiltering: "Resumen de filtrado de recursos",
    yourTeamMembers: "Miembros de su equipo",
    close: "Cerrar",
    confirmationFinishSelection:
        "<span1>¡Estás listo!</span1> <span1>¡Tu nuevo equipo se está armando!</span1> <span1>¡Nuestros expertos están buscando la combinación perfecta para crear el <span2>equipo de sus sueños</span2>!.</span1>",
    remainingTime: "Tiempo restante para asignar recursos",
    prev: "Anterior",
    next: "Próxima",
    filter: "Filtro",
    dateFilter: "Filtrar por fecha",
    show: "Mostrar",
    notifications: "Notificaciones",
    noNotificationsToShow: "No hay notificaciones para mostrar",
    remove: "Eliminar",
    noDataToShow: "Sin datos para mostrar",
    newReview: "Nueva Revisión",
    addReview: "Agregar Revisión",
    createReviewSuccess: "Revisión agregada correctamente",
    editReview: "Editar Revisión",
    editReviewSuccess: "Revisión actualizada correctamente",
    project: "Proyecto",
    staff: "Personal",
    subject: "Asunto",
    review: "Revisión",
    availableDates: "Fechas Disponibles",
    checkAgendaHManager: "Consultar agenda con el responsable de contratación",
    startDate2: "Fecha de inicio",
    endDate2: "Fecha de finalización",
    timeRange: "Intervalo de tiempo",
    save: "Guardar",
    between: "Entre",
    to: "a",
    to2: "de",
    interviewModeDescription:
        "Estás en modo Entrevista, el siguiente paso será la coordinación con el encargado de la entrega. Si ha terminado de seleccionar los recursos, presione <span>Continuar</span>.",
    goBack: "Volver atrás",
    downloadCV: "Descargar CV",
    about: "Sobre",
    unsatisfactoryAllocation: "Asignación insatisfactoria",
    areYouSureAllocationNotSatisfactory: "¿Desea marcar esta asignación como no satisfactoria?",
    confirm: "Confirmar",
    surveyTitle:
        "Responda las siguientes preguntas para que su próxima experiencia en nuestra plataforma sea aún mejor",
    howRateExperience: "¿Cómo calificaría su experiencia durante el proceso de registro?",
    howRecommendTheDevBlock: "¿Con qué probabilidad recomendaría TheDevBlock a un amigo o colega?",
    surveyComments: "Comparta cualquier comentario sobre cómo podríamos mejorar su experiencia",
    finishSurvey: "Terminar encuesta",
    pleaseInsertCompanyName: "Por favor ingrese el Nombre de la Empresa",
    pleaseInsertCompanyPosition: "Por favor ingrese la Posición de la Empresa",
    pleaseInsertBusinessName: "Por favor ingrese el Nombre del Negocio",
    pleaseInsertAddress: "Por favor ingrese la Dirección",
    pleaseInsertWebsite: "Por favor ingrese el Sitio Web",
    pleaseInsertBusinessSector: "Por favor seleccione el Sector del Negocio",
    pleaseInsertCountry: "Por favor seleccione un País",
    pleaseInsertTaxID: "Por favor ingrese la Identificación del Impuesto",
    companyInfo: "Información de la Empresa",
    companyName: "Nombre de la Empresa",
    companyPosition: "Posición de la Empresa",
    businessName: "Nombre del Negocio",
    website: "Sito Web",
    businessSector: "Sector del Negocio",
    countryJurisdiction: "País/Jurisdicción",
    taxID: "Número de Identificación de Impuesto",
    invalidFormatUrl: "La URL debe tener un formato válido. Por ejemplo: https://example.com",
    invalidTaxIDFormat: "Formato de Identificación de Impuesto inválido",
    teamIsConfirmed: "Su equipo está <span>CONFIRMADO!<span>",
    uploadedDocument: "¡Documento subido exitosamente!",
    selectFileToUpload: "Seleccione archivo para cargar",
    supportedFiles: "Archivos soportados",
    downloadOurCompanyInfo: "Descarga la información de nuestra empresa",
    payWithCard: "Pagar con Tarjeta de Crédito",
    dragAndDropAFileToUpload: "Arrastra y suelta un archivo para cargar",
    yourTeamConfirmedAttachProofOfPayment:
        "Su equipo está confirmado, para continuar con el proceso, adjunte el comprobante de pago y una vez comprobado por nuestro Gerente de Entrega nos comunicaremos con usted.",
    workTeamWillValidateProofPayment:
        "Nuestro equipo validará el pago y su gerente de contratación se comunicará con usted en breve.",
    tdbIsLogo: "Logo de TheDevBlock",
    btnToChangeLang: "Botón para cambiar el lenguaje",
    accept: "Aceptar",
    addResourcesToDeal: "Agregar recursos",
    addResource2: "Agregar recurso",
    totalResources: "Total de recursos",
    totalPrice: "Precio total",
    totalValue: "Monto",
    dueDate: "Válido hasta",
    acceptTeamAndBuy: "Aceptar equipo y pagar",
    ifYouNeedATailorMadeProposal: "Si necesitas una propuesta a medida",
    talkToHiringManager: "Hablar con el gerente de contratación",
    tailorMadeProposal: "Propuesta a medida",
    yourHiringManagerWillContactYouShortly: "Su gerente de contratación se comunicará con usted en breve.",
    creatingNewProject: "Crear un nuevo proyecto",
    selectingPositions: "Inicio / Selección",
    pendingAllocationOfResources: "Asignación de recursos pendiente",
    reselectingResources: "Reselección de recursos",
    allResourcesAllocated2: "Todos los recursos asignados",
    notAgreeAndReselecting: "En desacuerdo y volviendo a seleccionar",
    reselectedAndPendingAllocation: "Asignación de recursos reseleccionada y pendiente",
    teamConfirmed: "Equipo confirmado",
    interviews: "Entrevistas",
    pendingConfirmation: "Confirmación pendiente",
    projectInDevelopment: "Proyecto en Desarrollo",
    finishedProject: "Proyecto Finalizado",
    cancelled: "Cancelado",
    pendingOfAllocation: "Pendiente de asignación",
    projectCreation: "Creación del Proyecto",
    projectStart: "Inicio del Proyecto",
    projectEnd: "Fin del Proyecto",
    projectCreated: "El Proyecto fue Creado.",
    selectionFinalized: "La Selección Finalizó.",
    allResourcesAllocated: "Todos los Recursos fueron Asignados.",
    teamConfirmed2: "El Equipo fue Confirmado.",
    proofOfPaymentUploaded: "Comprobante de Pago subido exitosamente.",
    projectUnderDevelopment: "El Proyecto está bajo Desarrollo.",
    theFile: "El archivo",
    containsInfoOfProofOfPayment: "contiene la información referida al Comprobante de Pago.",
    plannedStartDateIs: "La fecha de inicio planeada es",
    andTheEndDateIs: "y la fecha de finalización es",
    technicalAccountManagerInformation: "Información del administrador técnico de cuentas",
    name: "Nombre",
    emailAddress: "Dirección de correo",
    phone: "Teléfono",
    reviews: "Revisiones",
    showDevelopmentTimeline: "Mostrar línea de tiempo de <span>desarrollo</span>",
    createReview: "Crear Revisión",
    becauseHiringHasChanged: "Porque la contratación ha cambiado.",
    giveTDBATry: "¡Prueba TheDevBlock!",
    navigation: "Navegación",
    getYourTeam: "Obtén tu equipo",
    getYourNewTeamHere: "Obtén tu <b>nuevo equipo aquí!</b>",
    aboutUs: "Sobre Nosotros",
    termsAndConditions: "Términos y Condiciones",
    password: "Contraseña",
    signUp: "Registrarse",
    alreadyHaveAccount: "¿Ya tienes una cuenta?",
    forgotYourPassword: "¿Olvidaste tu contraseña?",
    resetYourPassword: "Restablecer su contraseña",
    resetPasswordSuccess: "Le hemos enviado un correo electrónico con un enlace para restablecer su contraseña",
    newPassword: "Nueva contraseña",
    changePassword: "Cambiar contraseña",
    forgotYourPasswordText:
        "¡Está bien, sucede! Escriba su correo electrónico y haga clic en el botón de abajo, le enviaremos un enlace de recuperación",
    buildWithUs: "Construye con nosotros",
    accessToProDevTeams: "Obtendrá acceso a equipos de desarrollo profesional.",
    readyToStartImmediately: "Listo para comenzar de inmediato.",
    email: "Correo",
    notSatisfied: "No satisfecho",
    upload: "Upload",
    logout: "Salir",
    myProfile: "Mi Perfil",
    managedTeams: "Equipos Gestionados",
    termsAndConditionsSite: "Términos y condiciones de la plataforma de TheDevBlock",
    hiringManagerEmail: "Correo electrónico del Gerente de Contratación",
    startBuildingITTeam: "¡Empieza a formar el equipo de TI de tus sueños!",
    findPreVettedTailorMatched:
        "Encuentre talentos de TI cercanos a la costa previamente examinados y adaptados a medida con los que pueda contar.",
    newProjectSetup: "Configuración del Nuevo Proyecto",
    teamRequirements: "Requisitos del Equipo",
    setup: "Configuración",
    team: "Equipo",
    buildYourDreamTeam: "Construye el equipo de tus sueños",
    projectDetails: "Detalles del Proyecto",
    teamFormation: "Formación del Equipo",
    startBuildingANewTeam: "Construir nuevo equipo",
    orderSent: "¡Pedido enviado! Tu proyecto está pendiente de confirmación",
    topMatchingCandidates: "Mejores Candidatos",
    rejected: "Rechazado",
    acceptAndPurchase: "<span>Aceptar</span> & comprar equipo",
    candidateRejection: "Rechazo de Candidato",
    areYouSureRejectCandidate: "¿Está seguro de que desea rechazar a este candidato?",
    rejectCandidate: "Rechazar Candidato",
    checkout: "Checkout",
    industry: "Industria",
    checkoutComplete: "Checkout Completado",
    allCandidatesAllocated: "Todos los Candidatos Asignados",
    allCandidatesAllocatedMessage: "Han sido asignados nuevos recursos a tu proyecto: {-}",
    searchingAndReselecting: "Buscando & Reseleccionando",
    signUpConfirmation: "Confirmación del Registro",
    accountCreated: "Su nueva cuenta ha sido creada. Compruebe su correo electrónico para ver el enlace de activación.",
    errorRegisteringUser: "Error al intentar registrar al usuario",
    failedProjectCreation: "Error al intentar crear el proyecto",
    addedNewPositions: "Las posiciones han sido agregadas satisfactoriamente",
    failedAddingNewPosition: "Error al intentar guardar posiciones",
    finishSelectionSuccess: "Selección finalizada",
    finishSelectionFailed: "Error al intentar finalizar la selección",
    requiredFieldIsEmpty: "Campo obligatorio vacío",
    checkBothLanguages: "Revise ambos idiomas",
    fieldTooLong: "{{#label}} no debe tener más de 255 caracteres.",
    fieldTooLong50: "{{#label}} no debe tener más de 50 caracteres.",
    invalidCharacters:
        "{{#label}} contiene caracteres no válidos. Por favor, utiliza sólo letras y números, ya que no permitimos símbolos",
    invalidEmailFormat: "Formato incorrecto. Por favor, introduzca su {{#label}} en formato: jane@example.org",
    onlyLettersAndSpacesAllowed: "{{#label}} solo puede contener letras y espacios (no solo espacios)",
    invalidLoginCredentials: "El usuario no existe / Contraseña incorrecta",
    userAlreadyExists: "El Usuario ya existe",
    passwordCharacterLimit: "Al menos 8 caracteres",
    passwordSpecialCharacter: "Al menos un caracter especial",
    passwordNumberCharacter: "Al menos un número",
    passwordCharacterLower: "Al menos una letra minúscula",
    passwordCharacterUpper: "Al menos una letra mayúscula",
    weakPassword: "Contraseña débil, verifique los requerimientos",
    profileDoesNotExist: "El perfil solicitado no existe",
    unableToFetchProfile: "No se pudo obtener el perfil",
    unexpectedError: "Error inesperado",
    projectIdNotFound: "No se encontró el ID del proyecto en la URL",
    projectIdNotFound2: "No se encontró el ID del proyecto",
    projectNotFound: "No se encontró el proyecto",
    projects: "Proyectos",
    settings: "Configuración",
    resources: "Recursos",
    resource: "Recurso",
    add: "Agregar",
    adding: "Agregando",
    sex: "Sexo",
    roles: "Roles",
    cVitae: "C. Vitae",
    active: "Activo",
    lastname: "Apellido",
    birthday: "Fecha nacimiento",
    languages: "Idiomas",
    technology: "Tecnología",
    technologies: "Tecnologías",
    picture: "Foto",
    tags: "Etiquetas",
    description: "Descripción",
    male: "Masculino",
    female: "Femenino",
    other: "Otro",
    userHeader: "Usuario",
    dlvmgr: "Gerente",
    status: "Estado",
    totRes: "Tot / Res",
    addUserCreator: "Añadir usuario al proyecto",
    insufficientPermissions: "Permisos insuficientes para acceder a la información",
    insufficientPermissions2: "Permisos insuficientes para realizar esta acción",
    admin: "Administrar",
    addCreator: "Agregar creador",
    addCreatorSuccess: "Creador agregado",
    hiringManager: "Gerente de contratación",
    clickToSelect: "Clic para seleccionar",
    finishAllocation: "<span>Aceptar</span> & Asignar Equipo",
    organization: "Organización",
    resourceAddSuccess: "El recurso ha sido agregado a la posición",
    resourceAddFailed: "No se pudo agregar el recurso",
    positionResourceRemoveSuccess: "El recurso ha sido eliminado de la posición",
    positionResourceRemoveFailed: "No se pudo eliminar el recurso de la posición",
    allocateResources: "Asignar recursos",
    hiringManagerChangeSuccess: "Gerente de contratación actualizado",
    hiringManagerChangeFailed: "No se pudo actualizar el gerente de contratación",
    technicalAccountManagerChangeSuccess: "Técnico gerente de cuenta actualizado correctamente",
    technicalAccountManagerChangeFailed: "Error al actualizar el técnico gerente de cuenta",
    creator: "Creador",
    technicalAccountManager: "Gerente de cuenta",
    proofOfPayment: "Prueba de pago",
    confirmedAt: "Confirmado",
    finishedAt: "Terminado",
    dateEnd: "Finalización",
    done: "Finalizado",
    technologyCreated: "Tecnología creada",
    alias: "Alias",
    create: "Crear",
    technologyUpdated: "Tecnología actualizada",
    edit: "Editar",
    update: "Actualizar",
    editing: "Editando",
    editingHours: "Editando Horas",
    technologyDeleted: "Tecnología eliminada",
    areYouSureToDelete: "Estás seguro que deseas eliminar",
    delete: "Eliminar",
    manage: "Gestionar",
    fantasyName: "Nombre de fantasía",
    number: "Número",
    country: "País",
    webSite: "Sitio web",
    users: "Usuarios",
    logoutError: "Error al intentar desloguearse",
    inactive: "Inactivo",
    confirmUserStatusChange: "Confirmar el cambio de estado del usuario",
    confirmUserStatusChangeMessage: "¿Está seguro de realizar la acción sobre el usuario?",
    confirmResourceStatusChange: "Confirmar el cambio de estado del recurso",
    confirmResourceStatusChangeMessage: "¿Está seguro de realizar la acción sobre el recurso?",
    resourceUpdateSuccessfully: "Recurso actualizado correctamente",
    send: "Enviar",
    surveys: "Encuestas",
    userName: "Nombre de usuario",
    completionDateInitialSurvey: "Fecha de completado",
    registrationProcessRate: "Tasa de proceso de registro",
    recommendationRate: "Tasa de recomendación",
    comments: "Comentarios",
    dateRange: "Rango de fechas",
    createdAt: "Fecha de creación",
    positionStaffNotFound: "No se encontró la/el Posición/Recurso",
    confirmProjectDelete: "Confirmar eliminar proyecto",
    confirmProjectDeleteMessage: "¿Está seguro de realizar la acción sobre el proyecto?",
    confirmReviewDelete: "Confirmar eliminar la reseña",
    confirmReviewDeleteMessage: "¿Está seguro de realizar la acción sobre la reseña?",
    removeProjectSuccess: "Proyecto eliminado con éxito",
    deleted: "Eliminado",
    confirmProjectCancel: "Confirmar cancelar proyecto",
    confirmProjectCancelMessage: "¿Está seguro de realizar la acción sobre el proyecto?",
    cancelProjectSuccess: "Proyecto cancelado",
    unableToContinueWithoutAssignations: "No se puede continuar sin candidatos asignados",
    teamConfirmedMessage: "¡Equipo confirmado exitosamente!",
    unableToAutocomplete: "No es posible autocompletar algunos campos debido a un problema con el servidor",
    weWillGetInTouch: "Nos pondremos en contacto a la brevedad",
    serverError:
        "Parece que nuestro servidor está experimentando problemas. Por favor, inténtelo nuevamente a la brevedad.",
    userOffline:
        "Al parecer, no tienes acceso a internet. Por favor, inténtalo nuevamente cuando recuperes la conexión.",
    organizationUpdated: "Organización actualizada exitosamente",
    organizationCreated: "Organización creada exitosamente",
    proofUploadSuccess: "Comprobante de pago subido con éxito",
    proofUploadError: "No se puede cargar el comprobante de pago",
    validatePayment: "Validar pago",
    validateProject: "Validar Proyecto",
    canNotBeRevertedMessage: "Esta acción no podrá ser revertida",
    reject: "Rechazar",
    paymentValidationSuccess: "El pago fue validado correctamente",
    paymentRejectionSuccess: "El pago ha sido rechazado correctamente",
    unexpectedErrorTryingToConfirm: "Error inesperado al intentar confirmar el email",
    emailConfirmed: "¡Correo electrónico confirmado con éxito! Puedes iniciar sesión cuando lo desees.",
    confirmingEmail: "Confirmando email",
    receiveNewsletter2: "Recibe newsletter",
    currentPassword: "Contraseña actual",
    fieldTooShort8Chars: "Este campo debe tener al menos 8 caracteres",
    passwordUpdated: "Contraseña actualizada",
    tariffs: "Tarifarios",
    type: "Tipo",
    rate: "Tarifa",
    readjustTariff: "Reajustar tarifa",
    percentage: "Porcentaje",
    readjust: "Reajustar",
    view: "Ver",
    paymentDeclined: "Pago Rechazado",
    paymentDeclinedMessage:
        "Su pago por: {-} ha sido rechazado. Por favor, consulte con su banco o institución financiera",
    projectNotifyCreation: "Nueva solicitud de proyecto",
    projectNotifyCreationMessage: "El proyecto: {-} está pendiente de asignación de recursos",
    projectStartingSelection: "Las Posiciones han sido seleccionadas.",
    projectPendingAllocation: "Las Posiciones están pendientes de ser Asignadas.",
    projectAllCandidatesAllocated: "Todos los Candidatos fueron Asignados.",
    projectPartialReselecting: "El Equipo está siendo Parcialmente Reseleccionado.",
    projectNotAgreeAndReselecting: "Todos los Candidatos han sido rechazados. Estamos buscando sus reemplazos.",
    projectTeamConfirmed: "El Equipo ha sido confirmado.",
    projectCancelled: "Proyecto cancelado.",
    projectOnDevelopment: "El Proyecto está bajo Desarrollo.",
    projectProofPaymentUploaded: "Comprobante de Pago subido con éxito.",
    projectProofPaymentUploadedDescription:
        "El archivo {fileDownload} contiene la información referida al Comprobante de Pago.",
    projectPaymentRejected: "El Pago del Proyecto ha sido rechazado.",
    paymentAccepted: "Pago aceptado",
    paymentDeclinedAlt: "Pago rechazado",
    errorCreatingStripeCustomer: "Error al intentar crear el cliente en Stripe",
    changeRate: "Cambiar una tarifa",
    selectRoleAndSeniority: "Selecciona la combinación de rol y seniority para actualizar la tarifa de la misma.",
    newTariff: "Nuevo Tarifario",
    change: "Cambiar",
    copyTariff: "Copiar Tarifario",
    projectsToAssignIt: "Proyectos para asignarlo",
    organizationsToAssignIt: "Organizaciones para asignarlo",
    countriesToAssignIt: "Países para asignarlo",
    tariffUpdated: "Tarifario actualizado exitosamente",
    editTariff: "Editar Tarifado",
    payments: "Pagos",
    date: "Fecha",
    amount: "Monto",
    details: "Detalles",
    viewDetails: "Ver Detalles",
    summary: "Resumen",
    downloadBasicInformation: "Descargar Información Básica",
    downloadShortSheet: "Descargar Short Sheet",
    languageEvaluation: "Evaluación de Idiomas",
    interviewVideos: "Videos de la entrevista",
    contractFile: "Contrato",
    basicInformation: "Información Básica",
    shortSheet: "Short Sheet",
    englishInterviewUrl: "URL de la Entrevista en Inglés",
    files: "Archivos",
    addStatement: "Agregar Declaración",
    statement: "Declaración",
    statementOfWork: "Declaración de trabajo",
    resourceCreated: "Recurso creado exitosamente",
    resourceUpdated: "Recurso actualizado exitosamente",
    showing: "Mostrando",
    hoursPendingConfirmation: "Horas pendiente a confirmar",
    viewMore: "Ver más",
    partTime: "Tiempo Parcial",
    partTimeAlt: "Tiempo-Parcial",
    fullTime: "Tiempo completo",
    invoices: "Facturas",
    period: "Período",
    notes: "Notas",
    creating: "Creando",
    validated: "Validado",
    validate: "Validar",
    hoursValidation: "Validación de horas",
    sendToClient: "Enviar al cliente",
    validateHours: "Validar horas",
    addNote: "Agregar nota",
    validatingHoursFor: "Validando horas para",
    successfullyValidated: "Validado correctamente",
    noteAddedSuccessfully: "Nota agregada correctamente",
    sentToClientSuccessfully: "Enviado al cliente correctamente",
    hoursValidationSuccessfullyCreated: "Validación de horas creada correctamente",
    hoursValidationSuccessfullyUpdated: "Validación de horas actualizada correctamente",
    document: "Documento",
    debit: "Débito",
    credit: "Crédito",
    balance: "Balance",
    faqTeamBuildingProcess:
        "<ul>" +
        "<li>Evaluación de las necesidades del cliente: El primer paso es evaluar las necesidades del cliente. Esto implica comprender los objetivos del cliente, las habilidades específicas requeridas para el proyecto y cualquier otro detalle relevante que pueda ayudar a identificar a los miembros del equipo adecuados.</li>" +
        "<li>Reclutamiento: Una vez que se han identificado las necesidades del cliente, TheDevBlock generalmente comenzará el proceso de reclutamiento. Esto implica buscar y evaluar candidatos que tengan las habilidades y experiencia requeridas para satisfacer las necesidades del client.</li>" +
        "<li>Correspondencia de habilidades: Una vez que se han identificado los candidatos, TheDevBlock generalmente evaluará sus habilidades y las correspondencias con las necesidades del cliente. Este proceso puede incluir entrevistas, evaluaciones técnicas y verificaciones de referencias.</li>" +
        "<li>Integración: Después de que se han seleccionado los candidatos, TheDevBlock generalmente los integrará en el proyecto del cliente. Esto puede implicar la orientación y el entrenamiento de los candidatos, así como la presentación de los miembros del equipo y el establecimiento de canales de comunicación.</li>" +
        "<li>Construcción del equipo: A medida que el equipo trabaja en el proyecto, TheDevBlock generalmente se enfocará en la construcción de un equipo cohesivo y efectivo. Esto puede implicar la realización de actividades para el fortalecimiento del equipo, la promoción de la comunicación y la colaboración, y la supervisión de la dinámica del equipo para identificar y abordar cualquier problema que surja.</li>" +
        "</ul>",
    faqCandidatesVerification:
        "Algunas de las formas en que TheDevBlock puede verificar a los candidatos incluyen:" +
        "<ul>" +
        "<li>Resumes e historial laboral: TheDevBlock suele revisar el currículum y el historial laboral del candidato para verificar su historial de empleo, experiencia y calificaciones.</li>" +
        "<li>Evaluaciones técnicas: Dependiendo de los requisitos específicos del proyecto, TheDevBlock puede realizar evaluaciones técnicas para evaluar las habilidades del candidato en un área particular.</li>" +
        "<li>Verificación de referencias: TheDevBlock puede contactar las referencias proporcionadas por el candidato para verificar su experiencia laboral y desempeño.</li>" +
        "<li>Verificaciones de antecedentes: Dependiendo de los requisitos del cliente, TheDevBlock puede realizar verificaciones de antecedentes para verificar la educación del candidato, historial criminal y otra información relevant.</li>" +
        "<li>Entrevistas: TheDevBlock suele realizar una o varias entrevistas con el candidato para evaluar sus habilidades de comunicación, capacidad para resolver problemas y ajuste cultural.</li>" +
        "</ul>",
    faqMinimumTermServiceContract:
        "El contrato de servicio a plazo mínimo se refiere a la duración más corta de tiempo para la cual un proveedor de servicios y un cliente pueden acordar comprometerse en una relación contractual.\n" +
        "Nuestro contrato de servicio a plazo mínimo suele establecerse en 3 meses. Esto significa que el cliente acepta contratar los servicios de TheDevBlock por un mínimo de 3 meses. Después de este período inicial, el contrato puede ser renovado o extendido, dependiendo de las necesidades del cliente y la disponibilidad de los recursos de TheDevBlock.",
    faqWhereIsTalentLocated:
        "Existe una creciente cantidad de talento tecnológico en América Latina, con varios países destacándose como actores clave en la región. Entre estos países se encuentran Uruguay, Argentina, Bolivia, Perú, Colombia y México, entre otros." +
        "<ul>" +
        "<li>Uruguay ha sido reconocido como un centro tecnológico líder en la región, con una fuerza laboral altamente educada y un fuerte enfoque en la innovación. El país tiene un alto nivel de dominio del inglés y un entorno empresarial favorable, lo que lo convierte en un destino atractivo para empresas que buscan ingresar al mercado latinoamericano.</li>" +
        "<li>Argentina es otro jugador clave en la escena tecnológica, con una gran cantidad de ingenieros de software altamente capacitados y un vibrante ecosistema de startups. Buenos Aires, la capital del país, se ha convertido en un centro de innovación, atrayendo tanto a empresas locales como internacionales.</li>" +
        "<li>Bolivia y Perú también albergan comunidades tecnológicas en crecimiento, con un enfoque en el desarrollo de software y servicios de TI. Ambos países han invertido en el desarrollo de su infraestructura tecnológica y en la mejora del acceso a programas educativos y de capacitación para profesionales tecnológicos.</li>" +
        "<li>Colombia tiene una fuerza laboral altamente educada y en rápido crecimiento, con un enfoque en el desarrollo de software, ciencia de datos y ciberseguridad. El país cuenta con un gran número de universidades y escuelas técnicas que ofrecen programas en campos relacionados con la tecnología, lo que proporciona un fuerte canal de talentos para las empresas.</li>" +
        "<li>México es el mercado tecnológico más grande de América Latina, con una fuerza laboral altamente capacitada y un enfoque en el desarrollo de software, servicios de TI y comercio electrónico. El país tiene un entorno empresarial favorable, una fuerte cultura empresarial y un número creciente de aceleradoras e incubadoras que apoyan a las startups tecnológicas.</li>" +
        "</ul>" +
        "Si bien estos países se encuentran entre los principales centros de talento tecnológico en América Latina, también existen otros países en la región con ecosistemas tecnológicos emergentes, como Chile, Brasil y Costa Rica, entre otros. Además, muchos profesionales tecnológicos en la región son bilingües o multilingües, con habilidades en inglés, español y portugués, lo que los convierte en candidatos atractivos para empresas globales.",
    faqTimeLimitForReceivingProposedTeam:
        "El límite de tiempo para recibir un equipo propuesto de TheDevBlock suele ser de 72 horas o 3 días hábiles. Este plazo puede variar según las necesidades específicas de la empresa y del proyecto.",
    faqSkillNotMentionedOnThePlatform:
        "Si estás interesado en un lenguaje o habilidad tecnológica específica que no se menciona en la plataforma de TheDevBlock, siempre vale la pena contactarnos directamente para preguntar sobre nuestras capacidades.",
    faqCandidateRejection:
        "Si rechazas a un candidato proporcionado por TheDevBlock, la empresa generalmente trabajará contigo para comprender las razones del rechazo e identificar cualquier requisito o preferencia específica que tengas para el puesto.\n" +
        "Dependiendo de los términos del contrato entre TheDevBlock y el cliente, puede haber disposiciones para reemplazar al candidato rechazado por uno nuevo o para proporcionar candidatos adicionales para su consideración. TheDevBlock también puede trabajar contigo para afinar la descripción del trabajo y los criterios de búsqueda para que se ajusten mejor a tus necesidades.",
    faqMethodsAcceptedQ: "¿Qué métodos de pago se aceptan en la plataforma?",
    faqMethodsAcceptedA:
        "Aceptamos varios métodos de pago, como tarjetas de crédito, tarjetas de débito y transferencias bancarias.",
    faqBillFrequencyQ: "¿Con qué frecuencia se me facturará por los servicios de staff augmentation?",
    faqBillFrequencyA:
        "La frecuencia de facturación es mensual. El calendario de facturación específico se detallará en su acuerdo de servicio.",
    faqServiceModificationsQ: "¿Puedo cambiar o mejorar los servicios de staff augmentation que recibo?",
    faqServiceModificationsA:
        "Sí, puede cambiar o mejorar sus servicios de staff augmentation. Comuníquese con su Hiring Manager para discutir sus necesidades y realizar los ajustes necesarios.",
    faqRefundPolicyQ: "¿Cuál es la política de reembolso para los servicios de staff augmentation?",
    faqRefundPolicyA:
        "Nuestra política de reembolso depende de los términos y condiciones establecidos en su acuerdo de servicio. Le recomendamos que revise el acuerdo para obtener detalles específicos sobre los reembolsos.",
    faqServiceSupportQ: "¿Qué tipo de soporte está disponible para administrar los servicios de staff augmentation?",
    faqServiceSupportA:
        "Ofrecemos Technical Account Managers para ayudarlo a administrar los servicios de staff augmentation y abordar cualquier inquietud o problema que pueda encontrar.",
    faqHiddenFeesQ: "¿Hay tarifas ocultas asociadas con los servicios de staff augmentation?",
    faqHiddenFeesA:
        "Nos esforzamos por mantener la transparencia en nuestra política de precios. Las tarifas aplicables se detallarán claramente en su acuerdo de servicio. Le recomendamos revisar el acuerdo para comprender completamente los costos.",
    faqDataSecurityQ:
        "¿Cómo se garantiza la seguridad de mis datos y propiedad intelectual al utilizar servicios de staff augmentation?",
    faqDataSecurityA:
        "Tomamos muy en serio la seguridad de los datos y la protección de la propiedad intelectual. Nuestra plataforma implementa estrictas medidas de seguridad y se requiere que todos los miembros del personal firmen acuerdos de confidencialidad (NDA) para proteger su información confidencial.",
    faqHowToRequestChangesQ: "¿Cómo solicito un cambio en mis servicios actuales de staff augmentation?",
    faqHowToRequestChangesA:
        "Para solicitar un cambio, comuníquese con su technical account manager o envíe una solicitud a través de nuestra plataforma. Trabajaremos con usted para entender sus necesidades y realizar los ajustes necesarios.",
    faqProcessForAddingOrRemovingTeamMembersQ: "¿Cuál es el proceso para agregar o eliminar miembros del equipo?",
    faqProcessForAddingOrRemovingTeamMembersA:
        "Comunique sus necesidades a su technical account manager, quien lo ayudará con el proceso de agregar o eliminar miembros del equipo. Dependiendo de los términos de su acuerdo, puede haber un período de aviso requerido para estos cambios.",
    faqNoticeINeedQ:
        "¿Cuánto tiempo de anticipación necesito dar si quiero realizar cambios en mis servicios de staff augmentation?",
    faqNoticeINeedA:
        "El período de aviso requerido para realizar cambios en sus servicios de staff augmentation se detallará en su acuerdo de servicio. Esto puede variar según los términos y condiciones específicos.",
    faqCanIPauseServiceQ:
        "¿Puedo pausar temporalmente mis servicios de staff augmentation si cambian los requisitos de mi proyecto?",
    faqCanIPauseServiceA:
        "Dependiendo de los términos de su acuerdo de servicio, es posible que pueda pausar sus servicios de staff augmentation. Comuníquese con su technical account manager para discutir su situación y determinar el mejor curso de acción.",
    faqWillChangesAffectCostQ: "¿Los cambios en mis servicios de staff augmentation afectarán el costo?",
    faqWillChangesAffectCostA:
        "Los cambios en sus servicios de staff augmentation pueden resultar en ajustes en el costo. Su technical account manager le proporcionará una estructura de precios revisada según los cambios que solicite.",
    faqChangeSkillSetOrExpertiseQ:
        "¿Qué pasa si necesito cambiar el conjunto de habilidades o la experiencia de mi personal aumentado?",
    faqChangeSkillSetOrExpertiseA:
        "Si necesita habilidades o experiencia diferentes dentro de su personal aumentado, comuníquese con su technical account manager. Le ayudará a identificar a los miembros del equipo adecuados con el conjunto de habilidades deseado y facilitarán la transición.",
    faqFeesForMakingChangesQ:
        "¿Hay tarifas asociadas con la realización de cambios en mis servicios de staff augmentation?",
    faqFeesForMakingChangesA:
        "Las tarifas asociadas con los cambios de servicio se detallarán en su acuerdo de servicio. Es importante revisar los términos y condiciones de su acuerdo para comprender cualquier tarifa o cargo potencial.",
    faqHowLongTakesImplementingChangesQ:
        "¿Cuánto tiempo lleva implementar cambios en mis servicios de staff augmentation?",
    faqHowLongTakesImplementingChangesA:
        "El tiempo que lleva implementar cambios en sus servicios de staff augmentation dependerá de la complejidad y la naturaleza de los cambios. Su technical account manager le proporcionará un plazo estimado para implementar los cambios solicitados.",
    yes: "si",
    projectUpdated: "Proyecto actualizado",
    failedProjectUpdate: "Error al actualizar el proyecto",
    filters: "Filtros",
    selectRoleAndSeniorityFilter: "Selecciona la combinación de rol y seniority para filtrar.",
    pendingHoursValidation: "Horas pendientes de validación",
    pendingHoursValidationMessage: "El proyecto: {-} tiene horas pendientes de validación",
    projectStartingSelectionDescription: "Cantidad de posiciones seleccionadas: {amountOfPositions}",
    hiringManagerAdded: "Un nuevo Hiring Manager ha sido seleccionado.",
    hiringManagerAddedDescription: "El nuevo Hiring Manager seleccionado es {hiringManager}",
    tamAdded: "Un nuevo Technical Account Manager ha sido seleccionado.",
    tamAddedDescription: "El nuevo TAM seleccionado es {tam}",
    resourceAssigned: "Un nuevo recurso ha sido asignado.",
    resourceAssignedDescription: "Nombre del recurso: {resource}",
    resourceUnassigned: "Un recurso ha sido desasignado.",
    resourceUnassignedDescription: "Nombre del recurso: {resource}",
    resourceRejected: "Un recurso ha sido rechazado.",
    resourceRejectedDescription: "Nombre del recurso: {resource}",
    projectNotAgreeAndReselectingDescription: "Cantidad de candidatos rechazados: {amountOfResources}",
    projectTracing: "Seguimiento del Proyecto",
    backToHome: "Regresar a Home",
    clearFilters: "Limpiar filtros",
    from: "Desde",
    to3: "Hasta",
    cause: "Motivo",
    rejectProject: "Rechazar Proyecto",
    projectRejected: "Proyecto Rechazado",
    projectRejectedMessage: "El equipo solicitado en el proyecto: {-} fue rechazado",
    projectValidated: "Proyecto Validado",
    projectCreatorAdded: "Un nuevo creador de proyecto ha sido añadido.",
    projectCreatorAddedDescription: "Creador añadido: {creator}",
    documentSowSigned: "Documento SOW firmado",
    documentSowSignedMessage: "Documento SOW en el proyecto: {-} ha sido firmado",
    descriptionFile: "Archivo de Descripción",
    tdbInformation: "Información de TheDevBlock",
    invalidPhoneFormat: "Formato de {{#label}} incorrecto. Ejemplo del formato admitido: +14155552671.",
    invalidAddressFormat:
        "Formato de {{#label}} incorrecto. Solo se admiten letras, números, comas, puntos y guiones. Ejemplo: 3161 Mt Pleasant St NW, Delaware, United States.",
    fieldTooLong100: "{{#label}} no debe tener más de 100 caracteres.",
    fieldTooLong15: "{{#label}} no debe tener más de 15 caracteres.",
    fieldTooShort5: "{{#label}} debe tener al menos 5 caracteres",
    fieldTooShort7: "{{#label}} debe tener al menos 7 caracteres",
    unableToFetchPreviousData: "No se pudieron recuperar los datos anteriores",
    unableToUpdateInformation: "No se pudo actualizar la información",
    informationUpdated: "Información actualizada",
    domain: "Dominio",
    invalidDomain: "Formato de {{#label}} incorrecto. Ejemplo del formato admitido: thedevblock.com.",
    resourceSetup: "Configuración de recursos",
    owner: "Creador",
    openPositions: "Posiciones abiertas",
    projectInformation: "Información de proyecto",
    companyInformation: "Información de Empresa",
    timer: "Tiempo",
    log: "Registro",
    payment: "Pago",
    sow: "Sow",
    taxIDEIN: "Tax ID (EIN)",
    signUpDate: "Fecha de registro",
    msaDate: "Fecha MSA",
    message: "Mensaje",
    descriptionSkillSet: "Descripción & habilidades",
    ended: "Finalizado",
    historicalProjectLog: "Registro histórico del proyecto",
    all: "Todo",
    interview: "Entrevista",
    confirmed: "Confirmado",
    development: "Desarrollo",
    allocated: "Asignado",
    finished: "Finalizado",
    abandoned: "Abandonado",
    canceled: "Cancelado",
    user2: "Usuario",
    finishAt: "Finalizado",
    assignedTotal: "Asignados / Total",
    restrictedDomains: "Dominios Restringidos",
    createdBy: "Creado por",
    updatedBy: "Actualizado por",
    restrictedDomainCreated: "Nuevo dominio restringido exitosamente",
    restrictedDomainUpdated: "Dominio restringido actualizado",
    restore: "Restaurar",
    hiringCountries: "Paises de Contratación",
    hiringCountryCreated: "Nuevo país de contratación creado exitosamente",
    hiringCountryUpdated: "País de contratación actualizado",
    code: "Código",
    fieldTooLong2: "{{#label}} no debe tener más de 2 caracteres.",
    fieldTooLong500: "{{#label}} no debe tener más de 500 caracteres.",
    compare: "Comparar",
    candidateComparison: "Comparación de candidatos",
    englishLevel: "Nivel de inglés",
    profileInfo: "Info del perfil",
    hiringCountry: "País",
    summarySkillSet: "Resumen & Habilidades",
    downloadCv: "Descargar CV",
    sessionExpired: "Su sesión ha expirado",
    fieldTooLong3000: "{{#label}} no debe tener más de 3000 caracteres.",
    fieldTooLong5000: "{{#label}} no debe tener más de 5000 caracteres.",
    fieldTooLong1000: "{{#label}} no debe tener más de 1000 caracteres.",
    finance: "Finanzas",
    userPasswordUpdated: "Contraseña del usuario actualizada",
    jobDescriptionTooltip: "Por favor ingrese los detalles de la descripción del trabajo para este puesto.",
    timeTooltip: "Tiempo parcial: mínimo 80 horas mensuales. <br> Tiempo completo: Mínimo 160 horas mensuales.",
    tamTooltip: "Nos complace informarle que TDB ya ha añadido un Gerente de Cuenta Técnica (TAM) a su equipo sin costo adicional.",
    developmentRoles: "Roles de desarrollo",
    developmentRoleCreated: "Rol de desarrollo creado",
    developmentRoleUpdated: "Rol de desarrollo actualizado",
    developmentRoleDeleted: "Rol de desarrollo eliminado",
    linkedinLabel: "LinkedIn Label",
    linkedinUrl: "LinkedIn Url",
    title: "Título",
    subTitle: "Subtítulo",
    sections: "Secciones",
    content: "Contenido",
    addSection: "Agregar Sección",
    deleteSection: "Eliminar sección",
    selectTermsAndConditionsLanguage: "Seleccione idioma de los términos y condiciones",
    termsAndConditionsSuccess: "Términos y condiciones guardados",
};

export default es_translation;
