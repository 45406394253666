import { format, formatISO, parseISO } from "date-fns";
import i18n from "i18next";
import { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import Selector from "react-select";
import { DATE_FORMAT, TABLE_ROWS_LIMITS } from "../../../base/js/constants";
import { getAllOrganizationListAsync } from "../../../services/OrganizationService";
import { getPaymentsAsync } from "../../../services/PaymentService";
import { getAllProjectsBasic, getProjectsBasicByOrganization } from "../../../services/ProjectService";
import Paginator from "../../common/paginator/Paginator";
import Loading from "../../loading/Loading";
import { getSelectorStyle } from "../../shared-styles/StylesFunctions";
import AdminTable from "../admin-tables/AdminTable";
import styles from "./AdminPayments.module.scss";

const TABLE_HEADERS = ["date", "document", "number", "comments", "debit", "credit", "balance", "actions"];

const DocumentType = {
    INVOICE: "invoice",
    CREDIT_NOTE: "credit_note",
    PAYMENT: "payment",
};

const AdminPayments = () => {
    const { t } = useTranslation();

    const [lang] = useState(i18n.resolvedLanguage);
    const [loading, setLoading] = useState(false);
    const [reloadData, setReloadData] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [totalPerPage, setTotalPerPage] = useState(5);
    const [paymentsData, setPaymentsData] = useState([]);
    const [paymentsTotal, setPaymentsTotal] = useState(0);
    const [projectList, setProjectList] = useState({ projects: [], isLoading: false });
    const [organizationList, setOrganizationList] = useState({ organizations: [], isLoading: false });
    const [filterProject, setFilterProject] = useState(null);
    const [filterOrganization, setFilterOrganization] = useState(null);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    const DateInput = forwardRef(({ value, onClick, startDate }, ref) => (
        <div className="d-flex">
            <input
                placeholder={`${t("select")} ${t("dateRange")}...`}
                ref={ref}
                id="input-date-filter"
                type="text"
                className={styles.datepickerInput}
                value={value}
                onClick={onClick}
                readOnly
            />
        </div>
    ));

    // Update payments shown based on filters
    useEffect(() => {
        (async () => {
            try {
                setLoading(true);

                const responseData = await getPaymentsAsync(
                    activePage,
                    totalPerPage,
                    filterProject?.value,
                    filterOrganization?.value,
                    startDate ? formatISO(startDate) : null,
                    endDate ? formatISO(endDate) : null
                );

                if (responseData) {
                    const data = responseData.payments.map((payment, index) => {
                        let actions = [];

                        actions.push({
                            label: "Download",
                            action: payment.downloadUrl,
                            color: "",
                        });

                        // Parse the data in the required format to be displayed on the table
                        return {
                            date: format(parseISO(payment.date), DATE_FORMAT),
                            document: payment.documentType || "-",
                            number: payment.documentNumber || "-",
                            comments: payment.comments || "-",
                            // `debit`, `credit` and `balance` should be calculated in the endpoint
                            debit: payment.documentType === DocumentType.INVOICE ? payment.amount || 0 : 0,
                            credit:
                                payment.documentType === DocumentType.PAYMENT ||
                                payment.documentType === DocumentType.CREDIT_NOTE
                                    ? payment.amount || 0
                                    : 0,
                            balance: 0,
                            actions,
                        };
                    });

                    setPaymentsTotal(responseData.total);
                    setPaymentsData(data);
                }
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            } finally {
                setLoading(false);
            }
        })();
    }, [activePage, totalPerPage, reloadData, filterProject, filterOrganization, dateRange, lang]);

    // Update projects' list depending of organization filter's value
    useEffect(() => {
        (async () => {
            try {
                setProjectList((prevState) => {
                    return {
                        ...prevState,
                        isLoading: true,
                    };
                });

                const responseData = filterOrganization
                    ? await getProjectsBasicByOrganization(filterOrganization.value)
                    : await getAllProjectsBasic();

                if (responseData) {
                    // Restore the filter of projects
                    setFilterProject(null);

                    // Update the list of projects available to select
                    setProjectList({
                        isLoading: false,
                        projects: responseData.map((project) => {
                            return {
                                value: project.id,
                                label: project.name,
                            };
                        }),
                    });
                }
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            } finally {
                setProjectList((prev) => ({
                    projects: prev.projects,
                    isLoading: false,
                }));
            }
        })();
    }, [filterOrganization]);

    useEffect(() => {
        (async () => {
            try {
                setOrganizationList((prevState) => {
                    return {
                        ...prevState,
                        isLoading: true,
                    };
                });

                const responseData = await getAllOrganizationListAsync();

                if (responseData) {
                    setOrganizationList({
                        isLoading: false,
                        organizations: responseData.map((organization) => {
                            return {
                                value: organization.id,
                                label: organization.name,
                            };
                        }),
                    });
                }
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            } finally {
                setOrganizationList((prev) => ({
                    organizations: prev.organizations,
                    isLoading: false,
                }));
            }
        })();
    }, []);

    const handleDateRangeFilterChange = (newRange) => {
        setDateRange(newRange);
    };

    const handleChangePerPage = (event) => {
        setTotalPerPage(event.target.value);
    };

    const handleFilterProjectChange = (selectedProject) => {
        setFilterProject(selectedProject);
    };

    const handleFilterOrganizationChange = (selectedOrganization) => {
        setFilterOrganization(selectedOrganization);
    };

    return (
        <>
            <div className={styles.filtersContainer}>
                <div className={styles.searchContainer}>
                    <div className={`${styles.fieldContainer} ps-3`}>
                        <Selector
                            name="filterOrganization"
                            options={organizationList.organizations}
                            value={filterOrganization}
                            onChange={handleFilterOrganizationChange}
                            styles={getSelectorStyle(false)}
                            placeholder={`${t("select")} ${t("organization").toLowerCase()}...`}
                            isClearable
                        />
                    </div>
                    <div className={styles.fieldContainer}>
                        <Selector
                            name="filterProject"
                            isLoading={projectList.isLoading}
                            options={projectList.projects}
                            value={filterProject}
                            onChange={handleFilterProjectChange}
                            styles={getSelectorStyle(false)}
                            isClearable
                            placeholder={`${t("select")} ${t("project").toLowerCase()}...`}
                        />
                    </div>
                    <div className={`${styles.btnDateRangeFilter} w-100`}>
                        <DatePicker
                            startDate={startDate}
                            endDate={endDate}
                            onChange={handleDateRangeFilterChange}
                            customInput={<DateInput startDate={startDate} />}
                            className="flex-grow-1"
                            selectsRange
                            isClearable
                        />
                    </div>
                </div>
                <div className="d-flex gap-2 mx-3">
                    <div className={styles.totalPerPage}>
                        <select
                            id="select-limit"
                            className="py-1 px-2"
                            defaultValue={totalPerPage}
                            onChange={handleChangePerPage}
                        >
                            {TABLE_ROWS_LIMITS.map((rowLimit, index) => {
                                return (
                                    <option key={index} value={rowLimit}>
                                        {rowLimit}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
            </div>

            <div className={`${styles.contentContainer} mx-auto`}>
                <AdminTable headers={TABLE_HEADERS} data={paymentsData ? paymentsData : []} />
                <div className={styles.paginatorContainer}>
                    <Paginator
                        setPage={setActivePage}
                        activePage={activePage}
                        lastPage={Math.ceil(paymentsTotal ? paymentsTotal / totalPerPage : 1)}
                    />
                </div>
            </div>

            {loading && <Loading />}
        </>
    );
};

export default AdminPayments;
