import { forwardRef, useState } from "react";
import { NavLink } from "react-router-dom";
import styles from "../AdminMenu.module.scss";

const CustomToggle = forwardRef(({ children, onClick, settingsOpen }, ref) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const handleLinkStatus = ({ isActive }) => {
        return `${styles.item} ${isActive ? styles.itemActive : ""}`;
    };

    const handleIconStatus = () => {
        return `${styles.icon} ${menuOpen ? styles.iconOpen : ""}`;
    };

    return (
        <NavLink
            to="admin/settings"
            className={handleLinkStatus}
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                setMenuOpen(!menuOpen);
                onClick(e);
            }}
        >
            {children}
        </NavLink>
    );
});

export default CustomToggle;
