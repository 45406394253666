import { faChevronRight, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import tittleImg from "../../assets/tittle-img-faq.svg";
import colors from "../../base/js/colors";
import { BREAKPOINTS } from "../../base/js/constants";
import useWindowWidth from "../hooks/UseWindowWidth";
import styles from "./Faq.module.scss";
import categories from "./faqData";
import QuestionList from "./questions-list/QuestionList";
import Input from "../common/forms/input/Input";

const Faq = () => {
    const { t } = useTranslation();
    const [search, setSearch] = useState("");
    const [currentCategory, setCurrentCategory] = useState({});
    const innerWidth = useWindowWidth();
    const [isMobile, setIsMobile] = useState(innerWidth < BREAKPOINTS.SM);

    useEffect(() => {
        setCurrentCategory(categories[0]);
    }, []);

    useEffect(() => {
        setIsMobile(innerWidth < BREAKPOINTS.SM);
    }, [innerWidth]);

    const handleChangeCurrentCategory = (event, category) => {
        setCurrentCategory(category);
    };

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
    };

    let categoryListHTML = [];
    categories.forEach((category) => {
        const categoryClasses =
            currentCategory?.id === category?.id
                ? `${styles.categoryContainer} ${styles.categoryActive} ${styles.categoryName}`
                : `${styles.categoryContainer} ${styles.categoryName}`;

        categoryListHTML.push(
            <div
                className={categoryClasses}
                key={category.id}
                onClick={(event) => handleChangeCurrentCategory(event, category)}
            >
                {category.name}
                <FontAwesomeIcon icon={faChevronRight} color={colors.PRIMARY_COLOR} />
            </div>
        );

        if (isMobile && currentCategory?.id === category?.id)
            categoryListHTML.push(<QuestionList key={uuidv4()} category={currentCategory} />);
    });

    return (
        <main className={styles.mainContainer}>
            <section className={`${styles.searchContainer} ${styles.sectionContainer} position-relative`}>
                <Input
                    className={styles.searchInput}
                    value={search}
                    onChange={handleSearchChange}
                    placeholder={t("search")}
                />
                <div className={styles.lenIcon}>
                    <FontAwesomeIcon icon={faMagnifyingGlass} color={colors.GRAY700} />
                </div>
            </section>
            <section className={`${styles.sectionContainer} ${styles.titleSectionContainer}`}>
                <div>
                    <h1 className={styles.tittleFaq}>{t("faq")}</h1>
                    <span className={styles.tittleFaqUnderline}></span>
                </div>
                <img src={tittleImg} alt=""></img>
            </section>
            <section className={`${styles.sectionContainer} ${styles.categoriesQuestionContainer}`}>
                <div className={styles.categoriesContainer}>{categoryListHTML}</div>
                {!isMobile && <QuestionList category={currentCategory} />}
            </section>
        </main>
    );
};

export default Faq;
