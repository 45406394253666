import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { MenuLinks, ROLES } from "../../../../base/js/constants";
import Routes from "../../../../base/js/routes";
import { logoutAsync } from "../../../../services/AuthService";
import { setEmail, setPendingSurvey, setRoles, setUsername } from "../../../../store/slices/userSlice";
import useHasRole from "../../../hooks/UseHasRole";
import Loading from "../../../loading/Loading";
import LangSelector from "../../../common/lang-selector/LangSelector";
import MenuItem from "../menu-item/MenuItem";
import styles from "./MenuContainer.module.scss";

const MenuContainer = ({ user, languages, currentLang, onLangChange, isMobile, onItemClick }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [menuItems] = useState(MenuLinks.private);
    const [loading, setLoading] = useState(false);
    const userLogged = useMemo(() => user.username && user.email, [user]);
    const hasEnoughRole = useHasRole([ROLES.ADMIN, ROLES.HIRING_MANAGER]);

    const onLogoutClick = async () => {
        setLoading(true);

        try {
            await logoutAsync();

            dispatch(setUsername(""));
            dispatch(setEmail(""));
            dispatch(setRoles(""));
            dispatch(setPendingSurvey(""));

            navigate(Routes.LOGIN);
        } catch (error) {
            console.error(error.message);
        }
    };

    return (
        <>
            <div className={`${styles.container} position-absolute d-flex flex-column p-2`}>
                <div className="d-flex flex-column text-end gap-2">
                    {userLogged && isMobile && (
                        <Link
                            to={Routes.USER_PROFILE}
                            className={`${styles.userContainer} d-flex flex-column px-4 py-2`}
                            onClick={onItemClick}
                        >
                            <span className="fw-bold">{user?.username}</span>
                            <span>{user?.email}</span>
                        </Link>
                    )}

                    <LangSelector langs={languages} currentLang={currentLang} onLangChange={onLangChange} />

                    {userLogged &&
                        menuItems.map((item) => <MenuItem key={item.id} item={item} onClick={onItemClick} />)}

                    {userLogged && hasEnoughRole && <MenuItem item={MenuLinks.admin[0]} onClick={onItemClick} />}

                    {userLogged && (
                        <button
                            onClick={onLogoutClick}
                            className={`${styles.logoutBtn} px-3 py-2 fw-bold mt-3`}
                            disabled={loading}
                        >
                            {t("logout")}
                        </button>
                    )}
                </div>
            </div>

            {loading && <Loading />}
        </>
    );
};

export default MenuContainer;
