import { format, parseISO } from "date-fns";
import i18n from "i18next";
import { lazy, Suspense, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { DATE_FORMAT, SEX } from "../../../base/js/constants";
import { getHiringCountryListAsync } from "../../../services/HiringCountryService";
import { getLanguageListAsync } from "../../../services/LanguageService";
import { getSenioritiesAsync } from "../../../services/PositionService";
import { getRoleListAsync } from "../../../services/RoleService";
import { getResourcesListAsync } from "../../../services/StaffService";
import { getTechnologyListAsync } from "../../../services/TechnologyService";
import Paginator from "../../common/paginator/Paginator";
import Loading from "../../loading/Loading";
import AdminTable from "../admin-tables/AdminTable";
import styles from "./AdminResources.module.scss";
import ConfirmResourceStatusModal from "./confirm-resource-status-modal/ConfirmResourceStatusModal";
import FiltersBar from "./filters/FiltersBar";
import useAdminResourcesFunctions from "./hooks/UseAdminResourcesFunctions";

const ResourceForm = lazy(() => import("./resource-form/ResourceForm"));

const fileInitialState = {
    fileName: "",
    blobName: "",
    isLoading: false,
    errors: [],
    isValid: true,
};

const AdminResources = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [reloadData, setReloadData] = useState(false);
    const [seniorities, setSeniorities] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [technologies, setTechnologies] = useState([]);
    const [hiringCountries, setHiringCountries] = useState([]);
    const [roles, setRoles] = useState([]);
    const [searchInput, setSearchInput] = useState("");
    const [activePage, setActivePage] = useState(1);
    const [totalPerPage, setTotalPerPage] = useState(5);
    const [resourcesData, setResourcesData] = useState([]);
    const [resourceActiveId, setResourceActiveId] = useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showForm, setShowForm] = useState(false);

    const [resourceId, setResourceId] = useState(null);
    const [resourceName, setResourceName] = useState("");
    const [resourceLastname, setResourceLastname] = useState("");
    const [resourceEmail, setResourceEmail] = useState("");
    const [resourceSex, setResourceSex] = useState(SEX.OTHER);
    const [resourceSeniority, setResourceSeniority] = useState(0);
    const [resourceCvFile, setResourceCvFile] = useState(fileInitialState);
    const [resourcePictureFile, setResourcePictureFile] = useState(fileInitialState);
    const [shortSheetFile, setShortSheetFile] = useState(fileInitialState);
    const [basicInformationFile, setBasicInformationFile] = useState(fileInitialState);
    const [resourceDescription, setResourceDescription] = useState("");
    const [resourceTags, setResourceTags] = useState([]);
    const [resourceBirthday, setResourceBirthday] = useState("");
    const [resourceLanguages, setResourceLanguages] = useState([]);
    const [resourceTechnologies, setResourceTechnologies] = useState([]);
    const [resourceRoles, setResourceRoles] = useState([]);
    const [englishInterviewUrl, setEnglishInterviewUrl] = useState("");
    const [languageEvaluation, setLanguageEvaluation] = useState("");
    const [resourceEnglishLevel, setResourceEnglishLevel] = useState("");
    const [resourceRate, setResourceRate] = useState("");
    const [resourceHiringCountry, setResourceHiringCountry] = useState(null);

    const {
        onTechnologiesChange,
        onRolesChange,
        onTextFieldChange,
        onTextFieldChangeWithoutValidation,
        onTextFieldBlur,
        onAttachmentChange,
        onResourceTagsChange,
        onResourceBirthdayChange,
        onResourceLanguagesChange,
        onHiringCountryChange,
        handleChangePerPage,
        handleFormSubmit,
        handleFormCancel,
        handleClickAdd,
        getResourcesData,
        handleAcceptChangeResourceStatus,
        handleCancelChangeResourceStatus,
        errors,
    } = useAdminResourcesFunctions(
        setSearchInput,
        setResourceName,
        setResourceLastname,
        setResourceEmail,
        setResourceDescription,
        setEnglishInterviewUrl,
        setLanguageEvaluation,
        setResourceRate,
        setResourceCvFile,
        setResourcePictureFile,
        setShortSheetFile,
        setBasicInformationFile,
        setResourceTags,
        setResourceBirthday,
        setResourceLanguages,
        setResourceHiringCountry,
        setTotalPerPage,
        resourceName,
        resourceLastname,
        resourceEmail,
        resourceSex,
        resourceDescription,
        resourceLanguages,
        resourceTechnologies,
        resourceRoles,
        englishInterviewUrl,
        languageEvaluation,
        resourceEnglishLevel,
        resourceHiringCountry,
        resourceRate,
        resourceCvFile,
        resourceSeniority,
        resourceBirthday,
        resourcePictureFile,
        shortSheetFile,
        basicInformationFile,
        resourceTags,
        setLoading,
        resourceId,
        setReloadData,
        reloadData,
        setShowForm,
        resourcesData,
        setResourceActiveId,
        setShowConfirmationModal,
        setResourceId,
        setResourceSex,
        setResourceSeniority,
        fileInitialState,
        setResourceTechnologies,
        setResourceRoles,
        setResourceEnglishLevel,
        resourceActiveId
    );

    useEffect(() => {
        (async () => {
            try {
                const responseData = await getLanguageListAsync();

                if (!responseData) return;

                const languagesTemp = responseData.map(({ id, name }) => ({
                    value: id,
                    label: t(name),
                }));

                const resourceLanguagesTemp = resourceLanguages
                    ? resourceLanguages.map((resLang) => {
                          const langTemp = responseData.find((l) => l.id === resLang.value);
                          if (langTemp) {
                              return { value: langTemp.id, label: t(langTemp.name) };
                          } else {
                              return resLang;
                          }
                      })
                    : [];

                setLanguages(languagesTemp);
                setResourceLanguages(resourceLanguagesTemp);
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            }
        })();
    }, [i18n.resolvedLanguage]);

    useEffect(() => {
        (async () => {
            try {
                const responseData = await getSenioritiesAsync();

                if (responseData) {
                    setSeniorities(responseData);
                    setResourceSeniority(responseData[0]?.id);
                }
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const responseData = await getRoleListAsync();

                if (!responseData) {
                    return;
                }

                const mappedRoles = responseData.map((r) => ({ value: r.id, label: r.name }));

                // Order elements alphabetically
                mappedRoles.sort((a, b) => a.label.localeCompare(b.label));

                setRoles(mappedRoles);
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const responseData = await getTechnologyListAsync();

                if (!responseData) {
                    return;
                }

                const mappedTechnologies = responseData.map((t) => ({ label: t.name, value: t.id }));

                // Order elements alphabetically
                mappedTechnologies.sort((a, b) => a.label.localeCompare(b.label));

                setTechnologies(mappedTechnologies);
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const responseData = await getHiringCountryListAsync();

                if (responseData) {
                    const mappedData = responseData.map((hc) => ({ label: hc.name, value: hc.id }));

                    setHiringCountries(mappedData);
                }
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const responseData = await getResourcesListAsync(activePage, totalPerPage, searchInput);

                if (responseData) {
                    responseData.staffs = responseData.staffs.map((resource) => {
                        const handleEdit = async (e) => {
                            e.preventDefault();

                            setResourceId(resource.id);

                            try {
                                setLoading(true);

                                setResourceName(resource.name);
                                setResourceLastname(resource.lastName);
                                setResourceSex(resource.sex);
                                setResourceEmail(resource.email);
                                setResourceSeniority(resource.seniority?.id);
                                setResourceCvFile({
                                    ...fileInitialState,
                                    fileName: resource.cvFileName,
                                    blobName: resource.cvFileBlobName,
                                });
                                setResourcePictureFile({
                                    ...fileInitialState,
                                    fileName: resource.pictureName,
                                    blobName: resource.pictureBlobName,
                                });
                                setShortSheetFile({
                                    ...fileInitialState,
                                    fileName: resource.shortSheetFileName,
                                    blobName: resource.shortSheetFileBlobName,
                                });
                                setBasicInformationFile({
                                    ...fileInitialState,
                                    fileName: resource.informationFileName,
                                    blobName: resource.informationFileBlobName,
                                });
                                setResourceDescription(resource.summary);
                                setResourceTags(resource.staffCustomValues?.map((cv) => cv.customValue.value)); // TODO Set this
                                setResourceBirthday(
                                    resource.birthday ? format(parseISO(resource.birthday), DATE_FORMAT) : ""
                                );
                                setResourceLanguages(
                                    resource.idioms?.map(({ id, name }) => ({ value: id, label: t(name) }))
                                );
                                setResourceTechnologies(
                                    resource.technologies?.map((t) => ({ value: t.id, label: t.name }))
                                );
                                setResourceRoles(
                                    resource.developmentRols?.map((r) => ({ value: r.id, label: r.name }))
                                );
                                setEnglishInterviewUrl(resource.englishInterviewUrl || "");
                                setLanguageEvaluation(resource.languageEvaluation || "");
                                setResourceHiringCountry(
                                    resource.hiringCountry
                                        ? { value: resource.hiringCountry?.id, label: resource.hiringCountry?.name }
                                        : null
                                );
                                setResourceRate(resource.rate);
                                setResourceEnglishLevel(resource.englishLevel || "");

                                // Scroll to the top of the page
                                window.scrollTo(0, 0);

                                setShowForm(true);
                            } catch (error) {
                                toast.error(`${t("unexpectedError")}: ${error.message}`);
                            } finally {
                                setLoading(false);
                            }
                        };

                        return {
                            ...resource,
                            actions: [{ label: "edit", action: handleEdit, color: "" }],
                        };
                    });

                    setResourcesData(responseData);
                }
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            }
        })();
    }, [activePage, totalPerPage, searchInput, reloadData]);

    const resourceFormParams = {
        handleFormSubmit,
        onTextFieldChange,
        errors,
        resourceName,
        resourceLastname,
        resourceEmail,
        resourceSex,
        resourceRoles,
        resourceBirthday,
        resourceLanguages,
        resourceTechnologies,
        resourceSeniority,
        resourceCvFile,
        resourcePictureFile,
        resourceTags,
        resourceDescription,
        onTextFieldChangeWithoutValidation,
        onTextFieldBlur,
        onResourceBirthdayChange,
        onResourceLanguagesChange,
        onTechnologiesChange,
        setResourceSeniority,
        onRolesChange,
        onAttachmentChange,
        onResourceTagsChange,
        setResourceSex,
        languages,
        technologies,
        roles,
        seniorities,
        basicInformationFile,
        shortSheetFile,
        englishInterviewUrl,
        languageEvaluation,
        handleFormCancel,
        hiringCountries,
        resourceRate,
        resourceHiringCountry,
        resourceEnglishLevel,
        setResourceEnglishLevel,
        onHiringCountryChange,
    };

    return (
        <>
            <div className={styles.filtersContainer}>
                <FiltersBar
                    searchInput={searchInput}
                    handleChangePerPage={handleChangePerPage}
                    handleClickAdd={handleClickAdd}
                    onTextFieldChange={onTextFieldChange}
                    resourceId={resourceId}
                    totalPerPage={totalPerPage}
                    showForm={showForm}
                />
            </div>

            <div className={`${styles.contentContainer} mx-auto`}>
                {showForm && (
                    <div className={styles.formContainer}>
                        <Suspense fallback={<Loading fullWidth={false} withText={false} allScreen={false} />}>
                            <ResourceForm {...resourceFormParams} />
                        </Suspense>
                    </div>
                )}

                <AdminTable
                    headers={["name", "email", "sex", "roles", "cVitae", "rate", "active", ""]}
                    data={getResourcesData()}
                />

                <div className={styles.paginatorContainer}>
                    <Paginator
                        setPage={setActivePage}
                        activePage={activePage}
                        lastPage={Math.ceil(resourcesData.total ? resourcesData.total / totalPerPage : 1)}
                    />
                </div>
            </div>

            {loading && <Loading />}

            <ConfirmResourceStatusModal
                show={showConfirmationModal}
                onAccept={handleAcceptChangeResourceStatus}
                onCancel={handleCancelChangeResourceStatus}
            />
        </>
    );
};

export default AdminResources;
