import { useEffect, useState } from "react";

const useHeaderHeight = () => {
    const [headerHeight, setHeaderHeight] = useState("0px");

    useEffect(() => {
        const updateMargin = () => {
            const header = document.getElementsByTagName("header")[0];
            setHeaderHeight(header.offsetHeight + "px");
        };

        window.addEventListener("resize", updateMargin);
        updateMargin();

        return () => {
            window.removeEventListener("resize", updateMargin);
        };
    }, []);

    return headerHeight;
};

export default useHeaderHeight;
