import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import arrow from "../../assets/icons/white-arrow.png";
import Routes from "../../base/js/routes";
import styles from "./LetStart.module.scss";

const LetStart = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const onButtonClick = () => {
        navigate(Routes.BUILD_YOUR_TEAM);
    };

    return (
        <main>
            <section className={`${styles.container}`}>
                <div className={`${styles.containerLeft} d-flex flex-column align-items-center`}>
                    <h2 className="text-center">{t("startBuildingITTeam")}</h2>

                    <p className={`${styles.textCover} text-center mx-3`}>{t("findPreVettedTailorMatched")}</p>

                    <button className={`${styles.arrowContainer} d-flex align-items-center`} onClick={onButtonClick}>
                        <img src={arrow} alt="" className={`${styles.arrow} position-relative`} />
                        <span>{t("letStart")}</span>
                    </button>
                </div>
            </section>
        </main>
    );
};

export default LetStart;
