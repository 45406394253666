import { faClone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import color from "../../../base/js/colors";

const ToastError = ({ error = null }) => {
    const [copying, setCopying] = useState(false);
    const handleCopyTraceId = () => {
        setCopying(true);
        navigator.clipboard.writeText(error?.traceId).then(() => setTimeout(() => setCopying(false), 300));
    };

    return (
        <div className="d-flex flex-column">
            {error?.title && <span style={{ fontSize: "16px", color: color.GRAY900 }}>{error?.title}</span>}
            {error.message && (
                <span className="mt-1" style={{ fontSize: "14px" }}>
                    {error?.message}
                </span>
            )}
            {error?.traceId && (
                <span className="mt-3" style={{ fontSize: "12px", color: color.GRAY900 }}>
                    Trace id: <span id="traceId">{error?.traceId}</span>
                    <FontAwesomeIcon
                        icon={faClone}
                        style={{
                            cursor: "pointer",
                            paddingLeft: "10px",
                            color: !copying ? color.COLOR_ACTION : color.GRAY900,
                            width: "10px",
                            height: "10px",
                        }}
                        onClick={handleCopyTraceId}
                    />
                </span>
            )}
        </div>
    );
};

export default ToastError;
