import toast from "react-hot-toast";
import { generateErrorToast } from "../base/js/httpError";
import { getLocalizedMessage } from "../i18n";
import { API_BASE, request } from "../utils/httpRequest";
import { onEditAction, onRemoveAction, onRestoreAction } from "../utils/tableActions";

const mapRestrictedDomain = (rd, ...actions) => ({
    id: rd.id,
    domain: rd.domain,
    description: rd.description,
    createdBy: rd.createdBy.name,
    updatedBy: rd.updatedBy.name,
    actions,
});

export const getAllRestrictedDomains = async (
    skip = null,
    take = null,
    searchText = null,
    onRemove = () => {},
    onEdit = () => {}
) => {
    try {
        if (skip === null && take === null) {
            return await request(`${API_BASE}/api/restrictedDomain`);
        }

        const body = await request(
            `${API_BASE}/api/restrictedDomain?skip=${skip}&take=${take}&searchText=${searchText || ""}`
        );

        if (body) {
            const mappedDomains = body.restrictedDomains.map((rd) =>
                mapRestrictedDomain(rd, onEditAction(onEdit, rd.id), onRemoveAction(onRemove, rd.id))
            );

            return { domains: mappedDomains, total: body.total };
        }
    } catch (error) {
        generateErrorToast(error);
    }
};

export const getRestrictedDomain = async (id, onEdit = () => {}, onRemove = () => {}) => {
    try {
        const body = await request(`${API_BASE}/api/restrictedDomain/${id}`);

        if (body) {
            return mapRestrictedDomain(body, onEditAction(onEdit, id), onRemoveAction(onRemove, id));
        }
    } catch (error) {
        generateErrorToast(error);
    }
};

export const addRestrictedDomain = async (restrictedDomain) => {
    try {
        return await request(`${API_BASE}/api/restrictedDomain`, {
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(restrictedDomain),
            method: "POST",
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

const updateRestrictedDomain = async (id, restrictedDomain, method) => {
    try {
        return await request(`${API_BASE}/api/restrictedDomain/${id}`, {
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(restrictedDomain),
            method: method,
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const updateRestrictedDomainPUT = async (id, restrictedDomain) => {
    try {
        return await updateRestrictedDomain(id, restrictedDomain, "PUT");
    } catch (error) {
        generateErrorToast(error);
    }
};

export const updateRestrictedDomainPATCH = async (id, restrictedDomain) => {
    try {
        return await updateRestrictedDomain(id, restrictedDomain, "PATCH");
    } catch (error) {
        generateErrorToast(error);
    }
};

export const deleteRestrictedDomain = async (id) => {
    try {
        return await request(`${API_BASE}/api/restrictedDomain/${id}`, {
            method: "DELETE",
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const getAllDeletedRestrictedDomains = async (skip, take, searchText = null, onRestore = () => {}) => {
    try {
        const body = await request(
            `${API_BASE}/api/restrictedDomain/deleted?skip=${skip}&take=${take}&searchText=${searchText || ""}`
        );

        if (body) {
            const mappedDomains = body.restrictedDomains.map((rd) =>
                mapRestrictedDomain(rd, onRestoreAction(onRestore, rd.id))
            );

            return { domains: mappedDomains, total: body.total };
        }
    } catch (error) {
        generateErrorToast(error);
    }
};

export const restoreRestrictedDomain = async (id, logout = () => {}) => {
    try {
        const response = await request(`${API_BASE}/api/restrictedDomain/${id}/restore`, { method: "POST" });
    } catch (error) {
        generateErrorToast(error);
    }
};
