import { useTranslation } from "react-i18next";
import { TABLE_ROWS_LIMITS } from "../../../../base/js/constants";
import Input from "../../../common/forms/input/Input";
import Label from "../../../common/forms/label/Label";
import styles from "../AdminResources.module.scss";

const FiltersBar = ({
    searchInput,
    onTextFieldChange,
    showForm,
    handleClickAdd,
    resourceId,
    totalPerPage,
    handleChangePerPage,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <div className={styles.searchContainer}>
                <div className={styles.search}>
                    <Label htmlFor="search">{t("search")}</Label>
                    <Input
                        id="search"
                        name="search"
                        className="px-2"
                        value={searchInput}
                        onChange={onTextFieldChange}
                    />
                </div>
            </div>

            <div className="d-flex gap-2 mx-3">
                <button
                    className={`${styles.btnAdd} btn btn-success text-uppercase`}
                    disabled={showForm}
                    onClick={handleClickAdd}
                >
                    {showForm ? (resourceId ? t("editing") : t("adding")) : t("add")}
                </button>
                <div className={styles.totalPerPage}>
                    <select
                        id="select-limit"
                        className="py-1 px-2"
                        defaultValue={totalPerPage}
                        onChange={handleChangePerPage}
                    >
                        {TABLE_ROWS_LIMITS.map((rowLimit, index) => {
                            return (
                                <option key={index} value={rowLimit}>
                                    {rowLimit}
                                </option>
                            );
                        })}
                    </select>
                </div>
            </div>
        </>
    );
};

export default FiltersBar;
