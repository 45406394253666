import style from "./Loading.module.scss";

const SIZES = {
    small: 10,
    medium: 12,
    large: 20,
};

const Loading = ({ size, withText = true, text = "Loading...", allScreen = true, fullWidth = false }) => {
    let sizeBlock;

    switch (size) {
        case "sm":
            sizeBlock = SIZES.small;
            break;
        case "md":
            sizeBlock = SIZES.medium;
            break;
        case "lg":
            sizeBlock = SIZES.large;
            break;
        default:
            sizeBlock = SIZES.small;
            break;
    }

    return (
        <div
            className={`${style.container} ${allScreen ? style.containerAll : style.containerShort} ${
                fullWidth ? style.containerFullWidth : ""
            }`}
        >
            <div
                className={style.loadingContainer}
                style={{ width: `${(sizeBlock - 2) * 3 + 4}px`, height: `${(sizeBlock - 2) * 3 + 4}px` }}
            >
                <div
                    style={{
                        width: `${sizeBlock - 2}px`,
                        height: `${sizeBlock - 2}px`,
                        left: "0",
                        top: "0",
                        animationDelay: "0s",
                    }}
                ></div>
                <div
                    style={{
                        width: `${sizeBlock - 2}px`,
                        height: `${sizeBlock - 2}px`,
                        left: `${sizeBlock}px`,
                        top: "0",
                        animationDelay: "0.125s",
                    }}
                ></div>
                <div
                    style={{
                        width: `${sizeBlock - 2}px`,
                        height: `${sizeBlock - 2}px`,
                        left: `${sizeBlock * 2}px`,
                        top: "0",
                        animationDelay: "0.25s",
                    }}
                ></div>
                <div
                    style={{
                        width: `${sizeBlock - 2}px`,
                        height: `${sizeBlock - 2}px`,
                        left: "0",
                        top: `${sizeBlock}px`,
                        animationDelay: "0.875s",
                    }}
                ></div>
                <div
                    style={{
                        width: `${sizeBlock - 2}px`,
                        height: `${sizeBlock - 2}px`,
                        left: `${sizeBlock * 2}px`,
                        top: `${sizeBlock}px`,
                        animationDelay: "0.375s",
                    }}
                ></div>
                <div
                    style={{
                        width: `${sizeBlock - 2}px`,
                        height: `${sizeBlock - 2}px`,
                        left: "0",
                        top: `${sizeBlock * 2}px`,
                        animationDelay: "0.75s",
                    }}
                ></div>
                <div
                    style={{
                        width: `${sizeBlock - 2}px`,
                        height: `${sizeBlock - 2}px`,
                        left: `${sizeBlock}px`,
                        top: `${sizeBlock * 2}px`,
                        animationDelay: "0.625s",
                    }}
                ></div>
                <div
                    style={{
                        width: `${sizeBlock - 2}px`,
                        height: `${sizeBlock - 2}px`,
                        left: `${sizeBlock * 2}px`,
                        top: `${sizeBlock * 2}px`,
                        animationDelay: "0.5s",
                    }}
                ></div>
            </div>
            {withText && <span>{text}</span>}
        </div>
    );
};

export default Loading;
