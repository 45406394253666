import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import {
    createLinkDownloadStaffCvFile,
    createLinkDownloadStaffInformationFile,
    createLinkDownloadStaffShortSheetFile,
    generateLinkForVisualizePhoto,
} from "../../../../services/FileService";
import { getStaffAsync } from "../../../../services/StaffService";
import Info from "../../admin-opening-info/info/Info";
import ResourceSummarySkillset from "./resource-summary-skillset/ResourceSummarySkillset";
import styles from "./ResourceProfileInfo.module.scss";

const ResourceProfileInfo = ({ resourceId }) => {
    const { t } = useTranslation();

    const [resource, setResource] = useState(null);
    const [resourceSkills, setResourceSkills] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                const profileData = await getStaffAsync(resourceId, true);

                if (profileData) {
                    setResource({
                        ...profileData,
                        cvUrl: createLinkDownloadStaffCvFile(resourceId),
                        shortSheetUrl: profileData?.shortSheetFileBlobName
                            ? createLinkDownloadStaffShortSheetFile(resourceId)
                            : null,
                        basicInformationUrl: profileData?.informationFileBlobName
                            ? createLinkDownloadStaffInformationFile(resourceId)
                            : null,
                    });
                }
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            }
        })();
    }, []);

    useEffect(() => {
        setResourceSkills(() => [
            ...(resource?.technologies?.map((tech) => tech.name) || []),
            ...(resource?.staffCustomValues?.map((customValue) => customValue.customValue?.value) || []),
        ]);
    }, [resource]);

    return (
        <div className={`${styles.mainContainer} py-2 px-3`}>
            <div className="d-flex gap-3">
                <div className="w-50">
                    <span className={styles.resourceName}>{`${resource?.name} ${resource?.lastName}`}</span>
                    <Info label={t("email")} data={resource?.email} />
                    <Info label={t("hiringCountry")} data={resource?.hiringCountry?.name} />
                    <Info label={t("englishLevel")} data={resource?.englishLevel} />
                </div>
                <div className="d-flex justify-content-center align-items-center w-50">
                    {resource?.pictureBlobName ? (
                        <img
                            className={styles.photoSection}
                            src={generateLinkForVisualizePhoto(resource?.pictureBlobName)}
                            alt={resource?.pictureBlobName}
                        />
                    ) : (
                        <div className={styles.photoSection}></div>
                    )}
                </div>
            </div>
            <div className="d-flex gap-3">
                <div className="w-50">
                    <Info label={t("seniority")} data={resource?.seniority?.name} />
                </div>
                <div className="w-50">
                    <Info label={t("rate")} data={resource?.rate?.toString()} />
                </div>
            </div>
            <div className="d-flex gap-3">
                <Info label={t("roles")} data={resource?.developmentRols?.map((role) => role.name).join(", ")} />
            </div>

            <ResourceSummarySkillset summary={resource?.summary} skillset={resourceSkills} />
            <div className={"d-flex justify-content-end mb-2"}>
                {resource?.cvFileName ? (
                    <a
                        href={resource?.cvUrl}
                        className={`${styles.resourceCvLink} d-flex align-items-center justify-content-center text-uppercase`}
                        target="_blank"
                        download
                    >
                        {t("downloadCv")}
                    </a>
                ) : (
                    <div
                        className={`${styles.resourceCvLink} ${styles.resourceCvLinkDisabled} d-flex align-items-center justify-content-center text-uppercase`}
                    >
                        {t("downloadCv")}
                    </div>
                )}
            </div>
        </div>
    );
};

ResourceProfileInfo.propTypes = {
    resourceId: PropTypes.string.isRequired,
};
export default ResourceProfileInfo;
