import PropTypes from "prop-types";
import { forwardRef } from "react";
import { DropdownButton, Form } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import { v4 as uuidV4 } from "uuid";
import Loading from "../../loading/Loading";
import sharedStyles from "../../shared-styles/FormStyle.module.scss";
import InfiniteScroll from "../infinite-scroll/InfiniteScroll";
import styles from "./InfiniteTable.module.scss";

const InfiniteTable = ({
    headers,
    rows,
    onFetchRequest,
    disableInfiniteScroll,
    onLoading,
    loading,
    className = undefined,
}) => {
    const { t } = useTranslation();

    return (
        <div className="position-relative">
            <table className={`text-left w-100${className ? " " + className : ""}`}>
                <thead>
                    <tr>
                        {headers.map((head, index) => {
                            return <th key={index}>{t(head)}</th>;
                        })}
                    </tr>
                </thead>

                <InfiniteScroll
                    onBottomReached={onFetchRequest}
                    disabled={disableInfiniteScroll}
                    as="tbody"
                    className={styles.tbodyLimiter}
                    onLoading={onLoading}
                    loading={loading}
                >
                    {rows?.map((columns, index) => {
                        const rowContent = [];

                        for (const key in columns) {
                            if (key === "actions") {
                                rowContent.push(
                                    <td key={uuidV4()}>
                                        <DropdownButton
                                            id="dropdown-basic-button"
                                            title={t("actions")}
                                            size="sm"
                                            variant="secondary"
                                            disabled={columns[key].length === 0}
                                        >
                                            {columns[key].map((action) => {
                                                const CustomDropdownItem = forwardRef(({ children, onClick }, ref) => {
                                                    if (typeof action.action === "function") {
                                                        return (
                                                            <a
                                                                className={`px-3 dropdown-item-${action.color}`}
                                                                ref={ref}
                                                                href={"#"}
                                                                onClick={action.action}
                                                            >
                                                                {t(action.label)}
                                                            </a>
                                                        );
                                                    } else {
                                                        return (
                                                            <a target="_blank" href={action.action}>
                                                                {t(action.label)}
                                                            </a>
                                                        );
                                                    }
                                                });

                                                return (
                                                    <Dropdown.Item
                                                        as={CustomDropdownItem}
                                                        key={uuidV4()}
                                                        href="#"
                                                    ></Dropdown.Item>
                                                );
                                            })}
                                        </DropdownButton>
                                    </td>
                                );
                            } else if (key === "active") {
                                rowContent.push(
                                    <td key={uuidV4()}>
                                        <Form.Check
                                            id={`active-${uuidV4()}`}
                                            type="switch"
                                            checked={columns[key].isActive}
                                            className={`${sharedStyles.customSwitch}`}
                                            onChange={columns[key].action}
                                        />
                                    </td>
                                );
                            } else if (key === "pendingConfirmation") {
                                rowContent.push(
                                    columns[key] ? (
                                        <td key={uuidV4()} style={{ width: "5px" }}>
                                            <div className="d-flex align-items-center gap-1">
                                                <span className={`${styles.pendingConfirmation} me-2`}></span>
                                            </div>
                                        </td>
                                    ) : (
                                        <td key={uuidV4()}></td>
                                    )
                                );
                            } else if (key.toLowerCase() !== "id") {
                                rowContent.push(<td key={uuidV4()}>{columns[key]}</td>);
                            }
                        }

                        return <tr key={columns.id}>{rowContent}</tr>;
                    })}
                </InfiniteScroll>
            </table>

            {!disableInfiniteScroll && loading && (
                <div className="position-absolute bottom-0 w-100">
                    <Loading withText={false} size="sm" allScreen={false} fullWidth={true} />
                </div>
            )}
        </div>
    );
};

InfiniteTable.propTypes = {
    headers: PropTypes.array.isRequired,
    rows: PropTypes.arrayOf(PropTypes.object).isRequired,
    onFetchRequest: PropTypes.func,
    disableInfiniteScroll: PropTypes.bool,
    className: PropTypes.string,
    onLoading: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};

export default InfiniteTable;
