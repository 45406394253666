import i18n from "i18next";
import toast from "react-hot-toast";
import ToastError from "../../components/common/toast-error/ToastError";

export class HttpError extends Error {
    constructor(status = null, title = null, message, traceId = null) {
        super(message);
        this.status = status;
        this.title = title;
        this.traceId = traceId;
    }
}

export const generateErrorToast = (error) => {
    if (error instanceof HttpError) {
        toast.error(<ToastError error={error} />);
    } else {
        toast.error(<span style={{ fontSize: "16px" }}>{`${i18n.t("unexpectedError")}: ${error.message}`}</span>);
    }

    throw error;
};
