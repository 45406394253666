import Joi from "joi";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
    JoiAddressValidator,
    JoiMaxLengthValidatorBuilder,
    JoiMinLengthValidatorBuilder,
    JoiPhoneValidator, JoiWebsiteValidator,
} from "../../../base/js/constants";
import { getTDBInformation, updateTDBInformation } from "../../../services/TDBInformationService";
import { setInfo } from "../../../store/slices/tdbInformationSlice";
import Button from "../../common/Button";
import ErrorMessages from "../../common/error-messages/ErrorMessages";
import Label from "../../common/forms/label/Label";
import useJoiValidation from "../../hooks/UseJoiValidation";
import Loading from "../../loading/Loading";
import sharedStyles from "../../shared-styles/FormStyle.module.scss";

const FormFields = {
    LINKEDINLABEL: "linkedinLabel",
    LINKEDINURL: "linkedinUrl",
    ADDRESS: "address",
    PHONE: "phone",
    EMAIL: "email",
};

const BASE_SCHEMA = {
    [FormFields.LINKEDINLABEL]: Joi.string()
        .empty("")
        .custom(JoiMinLengthValidatorBuilder(5))
        .custom(JoiMaxLengthValidatorBuilder(15))
        .required(),
    [FormFields.LINKEDINURL]: Joi.string()
        .empty("")
        .custom(JoiMinLengthValidatorBuilder(5))
        .custom(JoiMaxLengthValidatorBuilder(100))
        .custom(JoiWebsiteValidator)
        .required(),
    [FormFields.ADDRESS]: Joi.string()
        .empty("")
        .custom(JoiMinLengthValidatorBuilder(5))
        .custom(JoiMaxLengthValidatorBuilder(100))
        .custom(JoiAddressValidator)
        .required(),
    [FormFields.PHONE]: Joi.string()
        .empty("")
        .custom(JoiMinLengthValidatorBuilder(7))
        .custom(JoiMaxLengthValidatorBuilder(15))
        .custom(JoiPhoneValidator)
        .required(),
    [FormFields.EMAIL]: Joi.string()
        .max(254)
        .email({ tlds: { allow: false } })
        .required(),
};

const SCHEMA_ERROR_MESSAGES = {
    "any.required": "requiredFieldIsEmpty",
    "any.invalid": "invalidFormatUrl",
    "string.invalidPhone": "invalidPhoneFormat",
    "string.empty": "requiredFieldIsEmpty",
    "object.missing": "requiredFieldIsEmpty",
    "string.max-100": "fieldTooLong100",
    "string.max-15": "fieldTooLong15",
    "string.min-5": "fieldTooShort5",
    "string.min-7": "fieldTooShort7",
    "string.email": "invalidEmailFormat",
    "string.invalidAddress": "invalidAddressFormat",
};

const DEFAULT_STATE = {
    [FormFields.LINKEDINLABEL]: {id: undefined, data: ""},
    [FormFields.LINKEDINURL]: {id: undefined, data: ""},
    [FormFields.ADDRESS]: { id: undefined, data: "" },
    [FormFields.PHONE]: { id: undefined, data: "" },
    [FormFields.EMAIL]: { id: undefined, data: "" },
};

const AdminTDBInformation = () => {
    const { t, i18n } = useTranslation();
    const { validateSchema, validateSubSchemaFromEvent, errors, resetErrors } = useJoiValidation();
    const [data, setData] = useState(DEFAULT_STATE);
    const [editing, setEditing] = useState(false);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            setLoading(true);

            try {
                const body = await getTDBInformation();
                if (body) {
                    const composedData = {};

                    body.forEach((entry) => {
                        composedData[entry.type] = { id: entry.id, data: entry.data };
                    });

                    setData((prevState) => ({ ...prevState, ...composedData }));
                }
            } catch (error) {
                toast.error(`${t("unexpectedError")}: ${error.message}`);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    const onChange = (event) => {
        setData((prevState) => ({
            ...prevState,
            [event.target.id]: { ...prevState[event.target.id], data: event.target.value },
        }));
    };

    const onBlur = (event) => {
        if (!editing) {
            return;
        }

        validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, event);
    };

    const onSubmit = async (event) => {
        event.preventDefault();

        if (!editing) {
            setEditing(true);
            return;
        }

        if (Object.keys(errors).length > 0) {
            return;
        }

        try {
            const composedData = Object.keys(data).map((key) => ({
                id: data[key].id,
                data: data[key].data,
                type: key,
            }));

            setLoading(true);

            const response = await updateTDBInformation(composedData);
            
            dispatch(setInfo({ ...data }));
            toast.success(t("informationUpdated"));
            resetErrors();
            setEditing(false);
            
        } catch (error) {
            toast.error(`${t("unexpectedError")}: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <main className="container py-5">
            <h1 className="h1 text-center mb-4">{t("tdbInformation")}</h1>

            <form onSubmit={onSubmit} className="d-flex flex-column gap-4 align-items-center">
                {/* Render form's fields dynamically */}
                {Object.keys(FormFields).map((key) => (
                    <div className="d-flex flex-column w-50" key={key}>
                        <Label htmlFor={FormFields[key]}>{t(FormFields[key])}</Label>
                        <input
                            type="text"
                            className={`${sharedStyles.inputText} ${
                                errors[FormFields[key]] ? sharedStyles.invalidField : ""
                            } ${editing ? "" : sharedStyles.disabledInput}`}
                            id={FormFields[key]}
                            name={FormFields[key]}
                            value={data[FormFields[key]]?.data}
                            disabled={!editing}
                            onChange={onChange}
                            onBlur={onBlur}
                        />

                        {errors[FormFields[key]] && <ErrorMessages errors={[errors[FormFields[key]].message]} />}
                    </div>
                ))}

                <Button type="submit" cssClasses={["mt-4"]} disabled={Object.keys(errors).length > 0}>
                    {t(editing ? "save" : "edit")}
                </Button>
            </form>

            {loading && <Loading />}
        </main>
    );
};

AdminTDBInformation.propTypes = {};

export default AdminTDBInformation;
