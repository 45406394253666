import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { PositionStaffStatus } from "../../../../base/js/constants";
import ResourceCard from "../resource-card-list/resource-card/ResourceCard";
import styles from "./ResourceAssigned.module.scss";

const ResourceAssigned = ({ positionStaff, short = true, onDelete }) => {
    const handleDelete = async (e) => {
        e.preventDefault();
        if (onDelete) onDelete(positionStaff?.staff?.id);
    };

    return (
        <div
            className={`${styles.resourceAssigned} ${!short ? styles.resourceAssignedLong : ""} ${
                positionStaff.status === PositionStaffStatus.REJECTED ? styles.resourceRejected : ""
            } d-flex justify-content-center align-items-center position-relative`}
        >
            <div className={styles.hoverContainer}>
                <ResourceCard resource={positionStaff?.staff} />
            </div>
            <div
                className={`${styles.priorityIndicator} d-flex justify-content-center align-items-center position-absolute`}
            >
                <span>{positionStaff?.priority}</span>
            </div>
            <div className={`${styles.btnDelete} ${styles.cursorPointer}`} onClick={handleDelete}>
                <div className="d-flex justify-content-center align-items-center w-100 h-100">
                    <FontAwesomeIcon icon={faX} className={styles.icon} />
                </div>
            </div>

            <span>{positionStaff ? positionStaff?.staff?.name : ""}</span>
        </div>
    );
};

ResourceAssigned.propTypes = {
    positionStaff: PropTypes.object.isRequired,
    short: PropTypes.bool,
    onDelete: PropTypes.func,
};

export default ResourceAssigned;
