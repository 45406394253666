import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

import { useTranslation } from "react-i18next";
import styles from "./TariffDetails.module.scss";

const TariffDetails = ({ roles, seniorities, tariff, setRole, setSeniority, setRate, justView = true }) => {
    const { t } = useTranslation();

    return (
        <table className={`${styles.tariffDetailsTable} m-3`}>
            <thead>
                <tr>
                    <td>{t("role")}</td>
                    <td>{t("seniority")}</td>
                    <td>{t("rate")}</td>
                </tr>
            </thead>
            <tbody>
                {roles?.map((role) => {
                    return seniorities?.map((seniority, index) => {
                        const tariffExist = tariff.find((t) => {
                            return t.developmentRoleId === role.value && t.seniorityId === seniority.value;
                        });
                        const selectRow = () => {
                            if (!justView) {
                                setRole(role);
                                setSeniority(seniority);
                                if (tariffExist && tariffExist?.rate != null) {
                                    setRate(tariffExist.rate);
                                } else {
                                    setRate("");
                                }
                            }
                        };

                        return index === 0 ? (
                            <tr key={uuidv4()} className={styles.roleDelimeter}>
                                <td rowSpan={seniorities.length} scope="rowgroup" className="text-bold">
                                    {role.label}
                                </td>
                                <td scope="row" className={`${styles.cursorPointer} text-bold`} onClick={selectRow}>
                                    {seniority.label}
                                </td>
                                <td className={styles.cursorPointer} onClick={selectRow}>
                                    {tariffExist ? tariffExist.rate : 0}
                                </td>
                            </tr>
                        ) : (
                            <tr key={uuidv4()}>
                                <td colSpan={1} className={styles.rowspanOdd}></td>
                                <td scope="row" className={`${styles.cursorPointer} text-bold`} onClick={selectRow}>
                                    {seniority.label}
                                </td>
                                <td className={styles.cursorPointer} onClick={selectRow}>
                                    {tariffExist ? tariffExist.rate : 0}
                                </td>
                            </tr>
                        );
                    });
                })}
            </tbody>
        </table>
    );
};

TariffDetails.propTypes = {
    roles: PropTypes.array.isRequired,
    seniorities: PropTypes.array.isRequired,
    tariff: PropTypes.array.isRequired,
    setName: PropTypes.func,
    setRole: PropTypes.func,
    setSeniority: PropTypes.func,
    setRate: PropTypes.func,
    justView: PropTypes.bool,
};
export default TariffDetails;
