import { format, parseISO } from "date-fns";
import { useEffect, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
    DATE_FORMAT,
    FORMATTER,
    ModalButtonVariant,
    PositionStaffStatus,
    ProjectStatus,
    ResourceStatus,
} from "../../base/js/constants";
import Routes from "../../base/js/routes";
import { updatePositionStaff } from "../../services/PositionService";
import { getProjectAsync, rejectAllCandidates } from "../../services/ProjectService";
import Button, { variants as btnVariants } from "../common/Button";
import ModalButton from "../common/modal/buttons/ModalButton";
import Modal from "../common/modal/Modal";
import ProjectInfoTemplate from "../common/project-info-template/ProjectInfoTemplate";
import TeamCard from "../common/team-card/TeamCard";
import Loading from "../loading/Loading";
import style from "./TeamInfoResume.module.scss";

const TeamInfoResume = () => {
    const params = useParams();
    const [projectData, setProjectData] = useState({});
    const [showRejectModal, setShowRejectModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const resourceIdToReject = useRef(null);

    const navigate = useNavigate();

    const { t } = useTranslation();

    const redirectToConfirmation = () => {
        // Redirect specifically to BYT > Confirmation
        navigate(`${Routes.BUILD_YOUR_TEAM}?from=${projectData.id}`);
    };

    const isStaffAllocated = useMemo(() => {
        let staffAllocated = 0;

        projectData.team?.forEach((position) => {
            position.positionStaffs?.forEach((ps) => {
                if (ps.status === PositionStaffStatus.ASSIGNED) {
                    staffAllocated += 1;
                }
            });
        });

        return staffAllocated >= 1;
    }, [projectData]);

    // Fetch project info from API
    useEffect(() => {
        (async () => {
            try {
                setLoading(true);

                const projectId = params.projectId;

                const data = await getProjectAsync(projectId, true);

                if (data) {
                    const project = {
                        id: data.id,
                        name: data.name,
                        status: data.projectStatus[0].status.value,
                        startDate: format(parseISO(data.dateStart), DATE_FORMAT),
                        endDate: format(parseISO(data.dateEnd), DATE_FORMAT),
                        monthPrice: FORMATTER.format(data.monthPrice),
                        finalPrice: FORMATTER.format(data.finalPrice),
                        team: data.positions.map((p) => {
                            // Convert each tag object to a string
                            p.tags = p.tags?.map((t) => t.value) || [];

                            return p;
                        }),
                    };

                    setProjectData(project);
                }
            } catch (error) {
                console.error(error.message);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        if (isStaffAllocated || Object.keys(projectData).length === 0) return;

        redirectToConfirmation();
    }, [isStaffAllocated]);

    const onModalOk = async () => {
        try {
            setLoading(true);

            const response = await updatePositionStaff(
                resourceIdToReject.current.resourceId,
                resourceIdToReject.current.positionId,
                PositionStaffStatus.REJECTED
            );

            if (response) {
                setProjectData((prevState) => {
                    const newStatus = { ...prevState };
                    let staffAllocated = 0;

                    newStatus.team = newStatus.team.map((position) => {
                        const positionStaffs = position.positionStaffs.map((ps) => {
                            if (ps.status === PositionStaffStatus.ASSIGNED) {
                                staffAllocated++;
                            }

                            if (
                                ps.staff.id === resourceIdToReject.current.resourceId &&
                                position.id === resourceIdToReject.current.positionId
                            ) {
                                // Decrease by 1 to cancel the previous increase
                                staffAllocated--;
                                // Return it with the status updated
                                return { ...ps, status: PositionStaffStatus.REJECTED };
                            } else {
                                return ps;
                            }
                        });

                        return { ...position, positionStaffs };
                    });

                    newStatus.status = staffAllocated >= 1 ? "Partial reselecting" : "Not Agree & Reselecting";

                    return newStatus;
                });

                resourceIdToReject.current = null;
                setShowRejectModal(false);
            }
        } catch (error) {
            console.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    const onModalCancel = () => {
        resourceIdToReject.current = "";
        setShowRejectModal(false);
    };

    const onResourceStatusChange = async (resource, positionId, newState) => {
        if (newState === ResourceStatus.NOT_SATISFIED) {
            resourceIdToReject.current = { resourceId: resource.id, positionId: positionId };
            setShowRejectModal(true);

            return;
        }

        switch (newState) {
            case ResourceStatus.NEEDS_MORE_INFO: {
                navigate(`${Routes.profile(resource.id)}?from=${projectData.id}`);

                break;
            }

            case ResourceStatus.INTERVIEW_NEEDED: {
                // TODO Functionality to implement
                // navigate(Routes.buildRequestInterviewPath(projectData.id));

                break;
            }

            default:
                break;
        }
    };

    const onAcceptAndBuyClick = () => {
        navigate(Routes.buildAcceptAndBuyPath(projectData.id));
    };

    const onInterviewBtnClick = () => {
        // navigate(Routes.REQUEST_INTERVIEW);
    };

    const onBackBtnClick = () => {
        navigate(Routes.MANAGE_TEAMS);
    };

    const onRejectAllClick = async () => {
        try {
            setLoading(true);

            await rejectAllCandidates(projectData.id);

            redirectToConfirmation();
        } catch (error) {
            console.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <main className={style.container}>
                <div className="mt-5">
                    <ProjectInfoTemplate projectData={projectData} sectionTitle={t("teamInfo")} />

                    <div className="d-flex flex-column px-3 px-lg-0">
                        <h1 className={style.title}>{t("topMatchingCandidates")}</h1>

                        <div
                            className={`${style.cardsMainContainer} d-flex flex-wrap gap-3 mt-4 mb-2 justify-content-center justify-content-lg-start`}
                        >
                            {projectData.team?.map((position) =>
                                position.positionStaffs.length ? (
                                    position.positionStaffs?.map((ps) => (
                                        /* Map the resource (+ position) if there is one assigned */
                                        <div key={ps.staff.id} className={style.cardContainer}>
                                            <TeamCard
                                                role={position.developmentRol?.name}
                                                seniority={position.seniority?.name}
                                                skills={ps.staff.technologies?.map((t) => t.name)}
                                                rate={position.rate || 0}
                                                resource={ps.staff}
                                                positionStaffStatus={ps.status}
                                                onResourceStatusChange={onResourceStatusChange}
                                                positionId={position.id}
                                            />
                                        </div>
                                    ))
                                ) : (
                                    /* If there is no resource assigned, map just the position */
                                    <div key={position.id} className={style.cardContainer}>
                                        <TeamCard
                                            role={position.developmentRol?.name}
                                            seniority={position.seniority?.name}
                                            skills={position.positionCustomValues?.map((cv) => cv.customValue?.value)}
                                            rate={position.rate || 0}
                                            onResourceStatusChange={onResourceStatusChange}
                                            positionId={position.id}
                                        />
                                    </div>
                                )
                            )}
                        </div>

                        <div className="d-flex flex-column flex-lg-row justify-content-between align-items-center gap-4 mt-5">
                            <Button
                                type="button"
                                cssClasses={[style.btnManage]}
                                variant={btnVariants.PRIMARY_INVERSE}
                                onClick={onBackBtnClick}
                            >
                                {t("back")}
                            </Button>

                            {isStaffAllocated && (
                                <div className="d-flex gap-4">
                                    <Button
                                        type="button"
                                        cssClasses={[style.btnManage]}
                                        variant={btnVariants.PRIMARY_INVERSE}
                                        onClick={onRejectAllClick}
                                    >
                                        {t("rejected")}
                                    </Button>

                                    <Button
                                        type="button"
                                        cssClasses={[style.btnManage]}
                                        onClick={onInterviewBtnClick}
                                        variant={btnVariants.PRIMARY_INVERSE}
                                    >
                                        {t("interviews")}
                                    </Button>
                                </div>
                            )}

                            <Button
                                type="button"
                                cssClasses={[style.btnFinish]}
                                onClick={onAcceptAndBuyClick}
                                disabled={!isStaffAllocated}
                            >
                                <Trans components={{ span: <span className="fw-bold" /> }}>acceptAndPurchase</Trans>
                            </Button>
                        </div>
                    </div>
                </div>
            </main>

            <Modal show={showRejectModal}>
                <span className={`${style.modalTitle} d-block mb-2`}>{t("candidateRejection")}</span>

                <p className={style.modalContent}>{t("areYouSureRejectCandidate")}</p>

                <div className="d-flex justify-content-evenly mt-4">
                    <ModalButton variant={ModalButtonVariant.WHITE} onClick={onModalCancel}>
                        {t("cancel")}
                    </ModalButton>
                    <ModalButton variant={ModalButtonVariant.BLUE} onClick={onModalOk}>
                        {t("ok")}
                    </ModalButton>
                </div>
            </Modal>

            {loading && <Loading />}
        </>
    );
};

export default TeamInfoResume;
