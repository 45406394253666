import { useState } from "react";
import RoutesPath from "../../../base/js/routes";

const UseGetMenuItems = () => {
    const SETTINGS_PATH = `${RoutesPath.ADMIN}/${RoutesPath.ADMIN_SETTINGS}`;
    const [links, setLinks] = useState([
        {
            to: `${RoutesPath.ADMIN}/${RoutesPath.ADMIN_INTERVIEWS}`,
            label: "interviews",
        },
        {
            to: `${RoutesPath.ADMIN}/${RoutesPath.ADMIN_PROJECTS}`,
            label: "projects",
        },
    ]);
    const [dropdownSettings, setDropdownSettings] = useState({
        label: "settings",
        to: SETTINGS_PATH,
        dropdownLinks: [
            {
                id: 1,
                to: `${SETTINGS_PATH}/${RoutesPath.ADMIN_SETTINGS_USERS}`,
                label: "users",
            },
            {
                id: 2,
                to: `${SETTINGS_PATH}/${RoutesPath.ADMIN_SETTINGS_RESOURCES}`,
                label: "resources",
            },
            {
                id: 3,
                to: `${SETTINGS_PATH}/${RoutesPath.ADMIN_SETTINGS_TECHNOLOGIES}`,
                label: "technologies",
            },
            {
                id: 4,
                to: `${SETTINGS_PATH}/${RoutesPath.ADMIN_SETTINGS_DEVELOPMENT_ROLES}`,
                label: "developmentRoles",
            },
            {
                id: 5,
                to: `${SETTINGS_PATH}/${RoutesPath.ADMIN_SETTINGS_ORGANIZATION}`,
                label: "organization",
            },
            {
                id: 6,
                to: `${SETTINGS_PATH}/${RoutesPath.ADMIN_SETTINGS_TARIFFS}`,
                label: "tariffs",
            },
            {
                id: 7,
                to: `${SETTINGS_PATH}/${RoutesPath.ADMIN_SETTINGS_REVIEWS}`,
                label: "reviews",
            },
            {
                id: 8,
                to: `${SETTINGS_PATH}/${RoutesPath.ADMIN_SETTINGS_SURVEYS}`,
                label: "surveys",
            },
            {
                id: 9,
                label: "finance",
                dropdownLinks: [
                    {
                        to: `${SETTINGS_PATH}/${RoutesPath.ADMIN_SETTINGS_PAYMENTS}`,
                        label: "payments",
                    },
                    {
                        to: `${SETTINGS_PATH}/${RoutesPath.ADMIN_SETTINGS_HOURS_VALIDATION}`,
                        label: "hoursValidation",
                    },
                ],
                active: false,
            },
            {
                id: 10,
                to: `${SETTINGS_PATH}/${RoutesPath.ADMIN_SETTINGS_TDB_INFORMATION}`,
                label: "tdbInformation",
            },
            {
                id: 11,
                to: `${SETTINGS_PATH}/${RoutesPath.ADMIN_SETTINGS_TERMS_AND_CONDITIONS}`,
                label: "termsAndConditions",
            },
            {
                id: 12,
                to: `${SETTINGS_PATH}/${RoutesPath.ADMIN_SETTINGS_RESTRICTED_DOMAINS}`,
                label: "restrictedDomains",
            },
            {
                id: 13,
                to: `${SETTINGS_PATH}/${RoutesPath.ADMIN_SETTINGS_HIRING_COUNTRIES}`,
                label: "hiringCountries",
            },
        ],
    });

    return { links, setLinks, dropdownSettings, setDropdownSettings };
};

export default UseGetMenuItems;
