import i18n from "i18next";
import toast from "react-hot-toast";
import { HttpError } from "../base/js/httpError";
import { setEmail, setPendingSurvey, setRoles, setUsername } from "../store/slices/userSlice";
import { store } from "../store/store";

export const API_BASE = "https://tdbapiprod.azurewebsites.net";

export const request = async (url, options) => {
    const commonOptions = {
        ...options,
        credentials: "include",
    };

    const response = await fetch(url, commonOptions);

    if (response.ok && response.status !== 204) {
        return await response.json();
    }

    if (response.status === 204) return;

    if (response.status !== 401) {
        const data = await response.json();
        throw new HttpError(
            response.status,
            data.title ? data.title : null,
            data.message ? data.message : null,
            data.traceId ? data.traceId : null
        );
    }

    const refreshResponse = await fetch(API_BASE + "/api/authentication/refresh-token", {
        method: "POST",
        credentials: "include",
    });

    if (!refreshResponse.ok) {
        store.dispatch(setUsername(""));
        store.dispatch(setEmail(""));
        store.dispatch(setRoles(""));
        store.dispatch(setPendingSurvey(""));

        toast.remove();
        toast.error(i18n.t("sessionExpired"));
        return;
    }

    const refreshedResponse = await fetch(url, commonOptions);

    if (refreshedResponse.ok && refreshedResponse.status !== 204) {
        return await refreshedResponse.json();
    }

    if (response.status === 204) return;

    const data = await response.json();
    throw new HttpError(
        response.status,
        data.title ? data.title : null,
        data.message ? data.message : null,
        data.traceId ? data.traceId : null
    );
};
