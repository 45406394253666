import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TABLE_ROWS_LIMITS } from "../../../base/js/constants";
import { getInterviewRequestListAsync } from "../../../services/InterviewRequestService";
import Input from "../../common/forms/input/Input";
import Label from "../../common/forms/label/Label";
import Paginator from "../../common/paginator/Paginator";
import sharedStyle from "../../shared-styles/FormStyle.module.scss";
import AdminTable from "../admin-tables/AdminTable";
import styles from "./AdminInterview.module.scss";

const AdminInterviews = () => {
    const { t } = useTranslation();
    const [searchInput, setSearchInput] = useState("");
    const [activePage, setActivePage] = useState(1);
    const [totalPerPage, setTotalPerPage] = useState(5);
    const [interviewsData, setInterviewsData] = useState([]);

    useEffect(() => {
        (async () => {
            const responseData = await getInterviewRequestListAsync(activePage, totalPerPage, searchInput);
            responseData.interviewRequest = responseData.interviewRequest.map((interview) => {
                return {
                    ...interview,
                    actions: [],
                };
            });
            setInterviewsData(responseData);
        })();
    }, [activePage, totalPerPage, searchInput]);

    const handleSearchInputChange = (event) => {
        event.preventDefault();
        setSearchInput(event.target.value);
    };

    const handleChangePerPage = (event) => {
        setTotalPerPage(event.target.value);
    };

    const getInterviewsData = () => {
        if (interviewsData.interviewRequest) {
            return interviewsData?.interviewRequest.map((interview) => {
                return {
                    name: interview.staff.name,
                    user: interview.user.name,
                    dlvmgr: "",
                    status: "",
                    totres: "",
                    actions: interview.actions,
                };
            });
        } else {
            return [];
        }
    };

    return (
        <>
            <div className={styles.filtersContainer}>
                <div className={styles.searchContainer}>
                    <div className={styles.search}>
                        <Label htmlFor="search">{t("search")}</Label>
                        <Input id="search" className="px-2" value={searchInput} onChange={handleSearchInputChange} />
                    </div>
                </div>
                <div className="d-flex gap-2 mx-3">
                    <div className={styles.totalPerPage}>
                        <select
                            id="select-limit"
                            className="py-1 px-2"
                            defaultValue={totalPerPage}
                            onChange={handleChangePerPage}
                        >
                            {TABLE_ROWS_LIMITS.map((rowLimit, index) => {
                                return (
                                    <option key={index} value={rowLimit}>
                                        {rowLimit}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
            </div>

            <div className={`${styles.contentContainer} mx-auto`}>
                <AdminTable
                    headers={["name", "userHeader", "dlvmgr", "status", "totRes", ""]}
                    data={getInterviewsData()}
                />
                <div className={styles.paginatorContainer}>
                    <Paginator
                        setPage={setActivePage}
                        activePage={activePage}
                        lastPage={Math.ceil(interviewsData.total ? interviewsData.total / totalPerPage : 1)}
                    />
                </div>
            </div>
        </>
    );
};

export default AdminInterviews;
