import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/logos/tdb-logo.png";
import Routes from "../../../base/js/routes";
import Button, { variants as btnVariants } from "../../common/Button";
import style from "./HelpSection.module.scss";

const HelpSection = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const onContactUsClick = () => {
        navigate(Routes.CONTACT);
    };

    return (
        <div
            className={`${style.container} d-flex flex-column justify-content-center align-items-center align-items-lg-start col-lg-2`}
        >
            <img src={logo} alt="TheDevBlock's logo" className={style.logo} />

            <p className={`${style.text} text-center text-lg-start`}>
                <span className="d-block">{t("becauseHiringHasChanged")}</span>
                <span className="d-block">{t("giveTDBATry")}</span>
            </p>

            <Button variant={btnVariants.PRIMARY} cssClasses={[style.contactUsBtn]} onClick={onContactUsClick}>
                {t("contactUs")}
            </Button>
        </div>
    );
};

export default HelpSection;
