import PropTypes from "prop-types";
import style from "./Button.module.scss";

export const variants = {
    PRIMARY: "filled-btn",
    PRIMARY_INVERSE: "inverse-btn",
    SECONDARY: "filled-gray-btn",
    LIGHT_BROWN: "filled-brown-btn",
};

export const textVariants = {
    LIGHT: "tdb-text-light",
    BROWN: "primary_color",
    DARK: "tdb-text-dark",
};

const Button = ({
    id = null,
    type = "button",
    children,
    variant = variants.PRIMARY,
    textVariant = textVariants.LIGHT,
    cssClasses = [],
    className = null,
    onClick = null,
    disabled = false,
}) => {
    // Check if the button variant is an expected one
    if (!Object.values(variants).includes(variant)) {
        throw new Error(`Unrecognized button variant '${variant}'`);
    }

    // Check if the text variant is an expected one
    if (!Object.values(textVariants).includes(textVariant)) {
        throw new Error(`Unrecognized text variant '${textVariant}'`);
    }

    const disabledStyle = disabled ? style.disabled : "";

    const onBtnClick = (event) => {
        if (onClick == null) return;
        onClick(event);
    };

    return (
        <button
            id={id}
            className={[...cssClasses, variant, textVariant, disabledStyle, className || ""].join(" ")}
            type={type}
            onClick={onBtnClick}
            disabled={disabled}
        >
            {children}
        </button>
    );
};

Button.propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    variant: PropTypes.string,
    textVariant: PropTypes.string,
    cssClasses: PropTypes.array,
    className: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
};

export default Button;
