import Image from "../../../common/Image";
import styles from "../SecondSection.module.scss";

const MemberQuote = ({ children, name, position, image, style }) => {
    return (
        <div className={styles.columnQuote} style={style}>
            <Image src={image} alt={name} cssClass={styles.columnQuoteImg} />
            <p className={`${styles.columnQuoteName} d-lg-none text-center mt-1`}>
                {name} - {position}
            </p>
            <p className={`${styles.columnQuoteSpeech} my-3`}>{children}</p>
            <p className={`${styles.columnQuoteName} mt-0 d-none d-lg-block`}>
                {name} - {position}
            </p>
        </div>
    );
};

export default MemberQuote;
