import { generateErrorToast } from "../base/js/httpError";
import { API_BASE, request } from "../utils/httpRequest";

export const addStaffAsync = async (data) => {
    try {
        return await request(API_BASE + "/api/staff", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const updateStaffAsync = async (staffId, data) => {
    try {
        return await request(`${API_BASE}/api/staff/${staffId}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const getStaffAsync = async (id, complete = false) => {
    try {
        return await request(`${API_BASE}/api/staff/${id}?complete=${complete}`);
    } catch (error) {
        generateErrorToast(error);
    }
};

export const getStaffByProjectIdAsync = async (projectId) => {
    try {
        return await request(`${API_BASE}/api/staff/by-project/${projectId}`);
    } catch (error) {
        generateErrorToast(error);
    }
};

export const getResourcesListAsync = async (page = 1, pageSize = 5, searchText = null) => {
    try {
        return await request(
            API_BASE + `/api/staff?page=${page}&pageSize=${pageSize}${searchText ? "&searchText=" + searchText : ""}`,
            {
                method: "GET",
            }
        );
    } catch (error) {
        generateErrorToast(error);
    }
};

export const getResourcesAvailableListAsync = async (
    page = 1,
    pageSize = 5,
    roleId = null,
    seniorityId = null,
    technology = null,
    searchText = null
) => {
    try {
        return await request(
            API_BASE +
                `/api/staff/available?page=${page}&pageSize=${pageSize}${roleId ? "&roleId=" + roleId : ""}${
                    seniorityId ? "&seniorityId=" + seniorityId : ""
                }${technology ? "&technology=" + technology : ""}${searchText ? "&searchText=" + searchText : ""}`,
            {
                method: "GET",
            }
        );
    } catch (error) {
        generateErrorToast(error);
    }
};
