const Routes = {
    HOME: "/",
    ABOUT: "/about",
    MEET_THE_TEAM: "/meet-the-team",
    CONTACT: "/contact",
    LETS_START: "/let-start",
    TERMS_AND_CONDITIONS: "/terms-and-conditions",
    BUILD_YOUR_TEAM: "/build-your-team",
    LOGIN: "/login",
    SIGNUP: "/signup",
    RESET_PASSWORD: "/reset-password",
    CHANGE_PASSWORD: "/change-password",
    FAQ: "/faq",
    HOW_TO_START: "/how-to-start",
    MANAGE_TEAMS: "/manage-teams",
    TEAM_INFO_RESUME: "/manage-teams/:projectId/resume",
    REQUEST_INTERVIEW: "/manage-teams/:projectId/request-interview",
    SURVEY: "/survey",
    ACCEPT_TEAM_AND_BUY: "/manage-teams/:projectId/buy",
    IN_DEVELOPMENT: "/manage-teams/:projectId/in-development",
    REVIEWS: "/manage-teams/:projectId/reviews",
    CREATE_REVIEW: "/manage-teams/:projectId/create-review",
    EDIT_REVIEW: "/manage-teams/:projectId/edit-review/:reviewId",
    CHECKOUT: "/manage-teams/:projectId/checkout",
    PAYMENT_PROOF_UPLOADER: "/manage-teams/:projectId/payment-proof-uploader",
    PROJECT_TIMELINE: "/manage-teams/:projectId/timeline",
    USER_PROFILE: "/user",
    STAFF_LIST: "/staff-list",
    INVOICE_LIST: "/invoice-list",
    VALIDATE_HOURS: "/manage-teams/:projectId/validate-hours",
    ADMIN: "/admin",
    ADMIN_INTERVIEWS: "interviews",
    ADMIN_PROJECTS: "projects",
    ADMIN_SETTINGS: "settings",
    ADMIN_SETTINGS_RESOURCES: "resources",
    ADMIN_SETTINGS_TECHNOLOGIES: "technologies",
    ADMIN_SETTINGS_DEVELOPMENT_ROLES: "development-roles",
    ADMIN_SETTINGS_USERS: "users",
    ADMIN_SETTINGS_ORGANIZATION: "organization",
    ADMIN_SETTINGS_SURVEYS: "surveys",
    ADMIN_SETTINGS_TARIFFS: "tariffs",
    ADMIN_SETTINGS_REVIEWS: "reviews",
    ADMIN_SETTINGS_PAYMENTS: "payments",
    ADMIN_SETTINGS_HOURS_VALIDATION: "hours-validation",
    ADMIN_SETTINGS_TDB_INFORMATION: "tdb-information",
    ADMIN_SETTINGS_TERMS_AND_CONDITIONS: "terms-and-conditions",
    ADMIN_SETTINGS_RESTRICTED_DOMAINS: "restricted-domains",
    ADMIN_SETTINGS_HIRING_COUNTRIES: "hiring-countries",
    ADMIN_PROJECT_ALLOCATION_RESOURCES: "project/:id/allocation-resources",
    ADMIN_PROJECT_TRACING: "project/:id/tracing",
    ADMIN_PROJECT_OPENING_INFO: "project/:id/opening-info",
    buildProjectAllocationResourcePath(projectId) {
        return `${this.ADMIN}/project/${projectId}/allocation-resources`;
    },
    buildProjectTracingPath(projectId) {
        return `${this.ADMIN}/project/${projectId}/tracing`;
    },
    buildProjectOpeningInfoPath(projectId) {
        return `${this.ADMIN}/project/${projectId}/opening-info`;
    },
    profile(id) {
        return `/profile/${id}`;
    },
    buildTeamInfoResumePath(projectId) {
        return `${this.MANAGE_TEAMS}/${projectId}/resume`;
    },
    buildRequestInterviewPath(projectId) {
        return `${this.MANAGE_TEAMS}/${projectId}/request-interview`;
    },
    buildAcceptAndBuyPath(projectId) {
        return `${this.MANAGE_TEAMS}/${projectId}/buy`;
    },
    buildCheckout(projectId) {
        return `${this.MANAGE_TEAMS}/${projectId}/checkout`;
    },
    buildPaymentProofUploader(projectId) {
        return `${this.MANAGE_TEAMS}/${projectId}/payment-proof-uploader`;
    },
    buildInDevelopmentPath(projectId) {
        return `${this.MANAGE_TEAMS}/${projectId}/in-development`;
    },
    buildReviewsPath(projectId) {
        return `${this.MANAGE_TEAMS}/${projectId}/reviews`;
    },
    buildCreateReviewPath(projectId) {
        return `${this.MANAGE_TEAMS}/${projectId}/create-review`;
    },
    buildCreateReviewPathWithStaff(projectId, staffId) {
        return `${this.MANAGE_TEAMS}/${projectId}/create-review?staff=${staffId}`;
    },
    buildEditReviewPath(projectId, reviewId) {
        return `${this.MANAGE_TEAMS}/${projectId}/edit-review/${reviewId}`;
    },
    buildProjectTimeline(projectId) {
        return `${this.MANAGE_TEAMS}/${projectId}/timeline`;
    },
    buildValidateHoursPath(projectId) {
        return `${this.MANAGE_TEAMS}/${projectId}/validate-hours`;
    },
};

export default Routes;
