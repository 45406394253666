import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format, parseISO } from "date-fns";
import Joi from "joi";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { v4 as uuidV4 } from "uuid";
import { DATE_FORMAT_HOURS, ModalButtonVariant } from "../../../../base/js/constants";
import Label from "../../../common/forms/label/Label";
import ModalButton from "../../../common/modal/buttons/ModalButton";
import Modal from "../../../common/modal/Modal";
import useJoiValidation from "../../../hooks/UseJoiValidation";
import sharedStyle from "../../../shared-styles/FormStyle.module.scss";
import style from "./NotesValidateHoursModal.module.scss";

const BASE_SCHEMA = {
    newNote: Joi.string().max(250).empty("").required(),
};

const SCHEMA_ERROR_MESSAGES = {
    "any.required": "requiredFieldIsEmpty",
    "any.invalid": "invalidFormatUrl",
    "string.invalid": "invalidTaxIDFormat",
    "string.empty": "requiredFieldIsEmpty",
    "object.missing": "requiredFieldIsEmpty",
    "string.max": "fieldTooLong",
};

const NotesValidateHoursModal = ({ show, validationHourId, notes = [], onSubmitNote, onClose }) => {
    const { t } = useTranslation();
    const user = useSelector((state) => state.user);
    const { validateSchema, validateSubSchemaFromEvent, resetErrors, errors } = useJoiValidation();
    const [noteList, setNoteList] = useState([]);
    const [newNote, setNewNote] = useState("");
    const containerRef = useRef(null);

    useEffect(() => {
        setNoteList(notes);
    }, [notes]);

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    }, [noteList, noteList.length]);

    const onNoteChange = (e) => {
        e.preventDefault();
        setNewNote(e.target.value);
        validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, e);
    };

    const handleOnClose = (e) => {
        e.preventDefault();
        setNewNote("");
        resetErrors();
        onClose();
    };

    const handleSubmitNote = (e) => {
        e.preventDefault();

        const formData = {
            newNote: newNote,
        };

        let cantErrors = validateSchema(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, formData);

        if (!!cantErrors) {
            return;
        }

        const newNoteToSend = newNote;
        setNewNote("");
        noteList.push({
            createdAt: new Date().toISOString(),
            id: uuidV4(),
            isAdmin: true,
            note: newNoteToSend,
            userName: user.username,
            validationHourId: validationHourId,
        });
        onSubmitNote(validationHourId, newNoteToSend);
    };

    return (
        <Modal show={show}>
            <span className={`${style.modalTitle} text-bold d-block text-center`}>{t("notes")}</span>
            <hr />
            <div ref={containerRef} className={style.notesContainer}>
                {noteList.map((note) => {
                    return (
                        <div
                            key={uuidV4()}
                            className={`${style.note} ${note.isAdmin ? style.adminNote : style.clientNote}`}
                        >
                            <span>{note.userName}</span>
                            <p>{note.note}</p>
                            <span className={style.noteDate}>
                                {format(parseISO(note.createdAt), DATE_FORMAT_HOURS)}
                            </span>
                        </div>
                    );
                })}
            </div>
            <hr />
            <div className={style.formContainer}>
                <Label htmlFor="newNote" requiredIndicator>
                    New note:
                </Label>
                <div className="position-relative d-flex align-items-center">
                    <textarea
                        id="newNote"
                        name="newNote"
                        rows="3"
                        style={{ resize: "none" }}
                        className={`${sharedStyle.textArea} ${
                            !errors.newNote ? "" : sharedStyle.invalidField
                        } ps-3 pe-5 py-2`}
                        value={newNote}
                        onChange={onNoteChange}
                        autoComplete="off"
                    />
                    <button
                        onClick={handleSubmitNote}
                        className={`${style.btnSend} ${newNote.length === 0 ? style.btnDisabled : ""}`}
                        disabled={newNote.length === 0}
                    >
                        <FontAwesomeIcon icon={faPaperPlane} className={style.iconSend} />
                    </button>
                </div>
                {errors.newNote && (
                    <div className="mt-1">
                        <p className={sharedStyle.errorMsg}>{errors.newNote.message}</p>
                    </div>
                )}
            </div>
            <div className="d-flex justify-content-center gap-4 mt-4">
                <ModalButton variant={ModalButtonVariant.WHITE} onClick={handleOnClose}>
                    {t("close")}
                </ModalButton>
            </div>
        </Modal>
    );
};

NotesValidateHoursModal.propTypes = {
    show: PropTypes.bool.isRequired,
    notes: PropTypes.array,
    onSubmitNote: PropTypes.func,
    validationHourId: PropTypes.string,
    onClose: PropTypes.func.isRequired,
};

export default NotesValidateHoursModal;
