import Joi from "joi";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { JoiMaxLengthValidatorBuilder, JoiWebsiteValidator, SEX } from "../../../../base/js/constants";
import { toggleResourceStatus } from "../../../../services/AdminService";
import { createLinkDownloadStaffCvFile } from "../../../../services/FileService";
import { addStaffAsync, updateStaffAsync } from "../../../../services/StaffService";
import useJoiValidation from "../../../hooks/UseJoiValidation";
import styles from "../AdminResources.module.scss";
import {
    handleAttachmentChange,
    handleResourceBirthdayChange,
    handleResourceHiringCountryChange,
    handleResourceLanguagesChange,
    handleResourceTagsChange,
    handleRolesChange,
    handleTechnologiesChange,
    handleTextFieldChange,
} from "./UseFormChangeFunctions";

const validateArrayEmpty = (value, helpers) => {
    return value.length > 0 ? value : helpers.error("any.required");
};

const BASE_SCHEMA = {
    search: Joi.string(),
    name: Joi.string().custom(JoiMaxLengthValidatorBuilder(50)).empty("").required(),
    lastname: Joi.string().custom(JoiMaxLengthValidatorBuilder(50)).empty("").required(),
    email: Joi.string()
        .email({ tlds: { allow: false } })
        .empty("")
        .required(),
    resourceSex: Joi.string().max(1),
    summary: Joi.string().custom(JoiMaxLengthValidatorBuilder(1000)).empty("").required(),
    resourceLanguages: Joi.array()
        .items(
            Joi.object({
                value: Joi.number().required(),
                label: Joi.string().required(),
            })
        )
        .custom(validateArrayEmpty)
        .required(),
    resourceTechnologies: Joi.array()
        .items(Joi.object({ value: Joi.string(), label: Joi.string() }))
        .custom(validateArrayEmpty)
        .required(),
    resourceRoles: Joi.array()
        .items(Joi.object({ value: Joi.number(), label: Joi.string() }))
        .custom(validateArrayEmpty)
        .required(),
    englishInterviewUrl: Joi.string().empty("").custom(JoiWebsiteValidator),
    languageEvaluation: Joi.string().empty("").custom(JoiMaxLengthValidatorBuilder(3000)),
    englishLevel: Joi.string().empty("").max(2),
    rate: Joi.number().min(0).empty("").required(),
    hiringCountry: Joi.object({
        value: Joi.number().required(),
        label: Joi.string().required(),
    })
        .empty(null)
        .required(),
};

const SCHEMA_ERROR_MESSAGES = {
    "any.required": "requiredFieldIsEmpty",
    "string.empty": "requiredFieldIsEmpty",
    "string.email": "invalidEmailFormat",
    "object.with": "requiredFieldIsEmpty",
    "object.missing": "requiredFieldIsEmpty",
    "string.max": "fieldTooLong",
    "any.invalid": "invalidFormatUrl",
    "string.max-3000": "fieldTooLong3000",
    "string.max-1000": "fieldTooLong1000",
    "string.max-50": "fieldTooLong50",
};

const useAdminResourcesFunctions = (
    setSearchInput,
    setResourceName,
    setResourceLastname,
    setResourceEmail,
    setResourceDescription,
    setEnglishInterviewUrl,
    setLanguageEvaluation,
    setResourceRate,
    setResourceCvFile,
    setResourcePictureFile,
    setShortSheetFile,
    setBasicInformationFile,
    setResourceTags,
    setResourceBirthday,
    setResourceLanguages,
    setResourceHiringCountry,
    setTotalPerPage,
    resourceName,
    resourceLastname,
    resourceEmail,
    resourceSex,
    resourceDescription,
    resourceLanguages,
    resourceTechnologies,
    resourceRoles,
    englishInterviewUrl,
    languageEvaluation,
    resourceEnglishLevel,
    resourceHiringCountry,
    resourceRate,
    resourceCvFile,
    resourceSeniority,
    resourceBirthday,
    resourcePictureFile,
    shortSheetFile,
    basicInformationFile,
    resourceTags,
    setLoading,
    resourceId,
    setReloadData,
    reloadData,
    setShowForm,
    resourcesData,
    setResourceActiveId,
    setShowConfirmationModal,
    setResourceId,
    setResourceSex,
    setResourceSeniority,
    fileInitialState,
    setResourceTechnologies,
    setResourceRoles,
    setResourceEnglishLevel,
    resourceActiveId
) => {
    const { t } = useTranslation();
    const { validateSchema, validateSubSchemaFromEvent, errors, resetErrors } = useJoiValidation();

    const clearForm = () => {
        setResourceId(null);
        setResourceName("");
        setResourceLastname("");
        setResourceEmail("");
        setResourceSex(SEX.OTHER);
        setResourceSeniority(0);
        setResourceCvFile(fileInitialState);
        setResourcePictureFile(fileInitialState);
        setShortSheetFile(fileInitialState);
        setBasicInformationFile(fileInitialState);
        setResourceDescription("");
        setResourceTags([]);
        setResourceBirthday("");
        setResourceLanguages([]);
        setResourceTechnologies([]);
        setResourceRoles([]);
        setEnglishInterviewUrl("");
        setLanguageEvaluation("");
        setResourceRate("");
        setResourceHiringCountry(null);
        setResourceEnglishLevel("");

        // Reset errors managed by the hook useJoiValidation
        resetErrors();
    };

    const onTechnologiesChange = (event) => {
        const recreatedEvent = handleTechnologiesChange(event, setResourceTechnologies);
        validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, recreatedEvent);
    };

    const onRolesChange = (event) => {
        const recreatedEvent = handleRolesChange(event, setResourceRoles);
        validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, recreatedEvent);
    };

    const onTextFieldChange = (event) => {
        event.preventDefault();
        handleTextFieldChange(
            event,
            setSearchInput,
            setResourceName,
            setResourceLastname,
            setResourceEmail,
            setResourceDescription,
            setEnglishInterviewUrl,
            setLanguageEvaluation,
            setResourceRate
        );
        validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, event);
    };

    const onTextFieldChangeWithoutValidation = (event) => {
        event.preventDefault();

        handleTextFieldChange(
            event,
            setSearchInput,
            setResourceName,
            setResourceLastname,
            setResourceEmail,
            setResourceDescription,
            setEnglishInterviewUrl,
            setLanguageEvaluation
        );
    };

    const onTextFieldBlur = (event) => {
        event.preventDefault();
        validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, event);
    };

    const onAttachmentChange = async (event) => {
        await handleAttachmentChange(
            event,
            setResourceCvFile,
            setResourcePictureFile,
            setShortSheetFile,
            setBasicInformationFile
        );
    };

    const onResourceTagsChange = (event) => {
        handleResourceTagsChange(event, setResourceTags);
    };

    const onResourceBirthdayChange = (date) => {
        handleResourceBirthdayChange(date, setResourceBirthday);
    };

    const onResourceLanguagesChange = (languages) => {
        handleResourceLanguagesChange(languages, setResourceLanguages);
        validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, {
            target: { id: "resourceLanguages", value: languages, name: "resourceLanguages" },
        });
    };

    const onHiringCountryChange = (hiringCountry) => {
        handleResourceHiringCountryChange(hiringCountry, setResourceHiringCountry);
        validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, {
            target: { id: "hiringCountry", value: hiringCountry, name: "hiringCountry" },
        });
    };

    const handleChangePerPage = (event) => {
        setTotalPerPage(event.target.value);
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        const formData = {
            name: resourceName,
            lastname: resourceLastname,
            email: resourceEmail,
            resourceSex: resourceSex,
            summary: resourceDescription,
            resourceLanguages: resourceLanguages,
            resourceTechnologies: resourceTechnologies,
            resourceRoles: resourceRoles,
            englishInterviewUrl,
            languageEvaluation,
            englishLevel: resourceEnglishLevel,
            hiringCountry: resourceHiringCountry,
            rate: resourceRate,
        };

        let cantErrors = validateSchema(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, formData);

        if (!resourceCvFile.fileName || !resourceCvFile.blobName) {
            setResourceCvFile((prevState) => {
                const newErrors = [...prevState.errors, "requiredFieldIsEmpty"];
                return { ...prevState, isValid: false, errors: newErrors };
            });
        } else {
            setResourceCvFile((prevState) => {
                return { ...prevState, isValid: true, errors: [] };
            });
        }

        if (!!cantErrors || !!resourceCvFile.errors.length > 0) {
            return;
        }

        try {
            const dataToSend = {
                seniorityId: resourceSeniority,
                name: resourceName,
                lastName: resourceLastname,
                sex: resourceSex,
                birthday: resourceBirthday,
                pictureName: resourcePictureFile.fileName,
                pictureBlobName: resourcePictureFile.blobName,
                summary: resourceDescription,
                email: resourceEmail,
                cvFileName: resourceCvFile.fileName,
                cvFileBlobName: resourceCvFile.blobName,
                shortSheetFileName: shortSheetFile.fileName,
                shortSheetFileBlobName: shortSheetFile.blobName,
                informationFileName: basicInformationFile.fileName,
                informationFileBlobName: basicInformationFile.blobName,
                idiomsIds: resourceLanguages.map((lang) => lang.value),
                developmentsRolsIds: resourceRoles.map((r) => r.value),
                technologiesIds: resourceTechnologies.map((t) => t.value),
                customValues: resourceTags?.map((tag) => ({
                    value: tag,
                })),
                englishInterviewUrl,
                languageEvaluation,
                englishLevel: resourceEnglishLevel,
                hiringCountryId: resourceHiringCountry.value,
                rate: resourceRate,
            };

            setLoading(true);

            if (resourceId) {
                await updateStaffAsync(resourceId, dataToSend);
            } else {
                await addStaffAsync(dataToSend);
            }

            toast.success(t(resourceId ? "resourceUpdated" : "resourceCreated"));

            handleFormCancel();
            setReloadData(!reloadData);
        } catch (e) {
            toast.error("Unexpected error: " + e.message);
        } finally {
            setLoading(false);
        }
    };

    const handleFormCancel = () => {
        setShowForm(false);
        clearForm();
    };

    const handleClickAdd = () => {
        setShowForm(true);
    };

    const getResourcesData = () => {
        if (resourcesData.staffs) {
            return resourcesData?.staffs.map((resource) => {
                const handleActiveChange = async (e) => {
                    e.preventDefault();
                    setResourceActiveId(resource.id);
                    setShowConfirmationModal(true);
                };

                return {
                    name: resource.name,
                    email: resource.email,
                    sex: resource.sex === SEX.MALE ? "MALE" : resource.sex === SEX.FEMALE ? "FEMALE" : "",
                    roles: resource.developmentRols.map((rol) => rol.name).join(", "),
                    cvitae: resource.cvFileBlobName ? (
                        <a className={styles.downloadLink} href={createLinkDownloadStaffCvFile(resource.id)} download>
                            {resource.cvFileName}
                        </a>
                    ) : null,
                    rate: resource.rate,
                    active: { isActive: resource.active, action: handleActiveChange },
                    actions: resource.actions,
                };
            });
        } else {
            return [];
        }
    };

    const handleAcceptChangeResourceStatus = async () => {
        try {
            setLoading(true);

            const response = await toggleResourceStatus(resourceActiveId);

            if (response) {
                toast.success(t("resourceUpdateSuccessfully"));
                setReloadData(!reloadData);
            }
        } catch (e) {
            toast.error(t("unexpectedError") + ": " + e.message);
        } finally {
            setShowConfirmationModal(false);
            setLoading(false);
        }
    };

    const handleCancelChangeResourceStatus = () => {
        setShowConfirmationModal(false);
    };

    return {
        onTechnologiesChange,
        onRolesChange,
        onTextFieldChange,
        onTextFieldChangeWithoutValidation,
        onTextFieldBlur,
        onAttachmentChange,
        onResourceTagsChange,
        onResourceBirthdayChange,
        onResourceLanguagesChange,
        onHiringCountryChange,
        handleChangePerPage,
        handleFormSubmit,
        handleFormCancel,
        handleClickAdd,
        getResourcesData,
        handleAcceptChangeResourceStatus,
        handleCancelChangeResourceStatus,
        errors,
    };
};

export default useAdminResourcesFunctions;
