import { generateErrorToast } from "../base/js/httpError";
import { API_BASE, request } from "../utils/httpRequest";

export const getTechnologyAsync = async (id) => {
    try {
        return await request(API_BASE + "/api/technology/" + id);
    } catch (error) {
        generateErrorToast(error);
    }
};

export const getTechnologyListAsync = async () => {
    try {
        return await request(API_BASE + "/api/technology", {
            method: "GET",
        });
    } catch (e) {
        generateErrorToast(e);
    }
};

export const getTechnologiesWithPagingAsync = async (page, pageSize, searchText = "") => {
    try {
        return await request(
            `${API_BASE}/api/technology/with-paging?page=${page}&pageSize=${pageSize}${
                searchText ? `&searchText=${searchText}` : ""
            }`
        );
    } catch (error) {
        generateErrorToast(error);
    }
};

export const removeTechnology = async (id) => {
    try {
        return await request(`${API_BASE}/api/technology/${id}`, {
            method: "DELETE",
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const updateTechnology = async (id, updatedTechnology) => {
    try {
        return await request(`${API_BASE}/api/technology/${id}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(updatedTechnology),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const createTechnology = async (technology) => {
    try {
        return await request(`${API_BASE}/api/technology`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(technology),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};
