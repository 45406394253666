/*
 * Formats the body of a PATCH request.
 *
 * Each field of the target should have an object with the following shape:
 * `{ [dataField]: "", [verificationField]: true/false }`
 *
 * @param {Object} target - Object used to parse all the PATCH operations.
 * @param {string} operation - PATCH operation used on all the PATCH operations.
 * @param {string} verificationField - Subfield used to verify if the field's data (under the subfield {@link dataField}) should be included on the PATCH operation.
 * @param {string} dataField - Subfield where the data is stored.
 *
 * @returns {Array} Array containing all the PATCH operations.
 * */
export const formatPatchBody = (target, operation = "replace", verificationField = "modified", dataField = "data") => {
    // We'll store each PATCH operation here.
    let payload = [];

    Object.keys(target).forEach((k) => {
        /*
         * Each field of the target should have an object with the following shape:
         * { [dataField]: "", [verificationField]: true/false }
         *
         * By checking the @verificationField} field we'll know if we should include
         * the data stored under the @dataField */

        // Check if the @verificationField it's true. If it is, we include the @dataField
        // to the array of operations.
        if (!target[k][verificationField]) {
            return;
        }

        // Once we detect that a field should be included in the PATCH operation, we'll push
        // an object with the structure needed to update/add/remove the attribute
        // of the entity by using the PATCH request.
        payload.push({
            op: operation,
            path: `/${k}`,
            value: target[k][dataField],
        });
    });

    return payload;
};
