import Tags from "@yaireo/tagify/dist/react.tagify";
import "@yaireo/tagify/dist/tagify.css";
import { addMonths, parse } from "date-fns";
import PropTypes from "prop-types";
import { useEffect, useMemo, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import {Trans, useTranslation} from "react-i18next";
import Select from "react-select";
import { DATE_FORMAT, TAM_LABEL } from "../../../../base/js/constants";
import Checkbox from "../../../common/Checkbox";
import InputFile from "../../../common/custom-input-file/CustomInputFile";
import ErrorMessages from "../../../common/error-messages/ErrorMessages";
import Input from "../../../common/forms/input/Input";
import Label from "../../../common/forms/label/Label";
import useJoiValidation from "../../../hooks/UseJoiValidation";
import sharedStyle from "../../../shared-styles/FormStyle.module.scss";
import { getSelectorStyle } from "../../../shared-styles/StylesFunctions";
import { TeamInfoFormIds, TotalMinMaxLimits } from "../TeamInfoConstant";
import { useTeamInfoFunctions } from "../useTeamInfoFunctions";
import styles from "./Resource.module.scss";

const CURRENT_DATE = new Date();

const Resource = ({
    roleTypes,
    seniorityTypes,
    totalPositions,
    positionInfo,
    projectInfo,
    onChange,
    deletePosition,
    onValidationChange,
    forceGlobalValidationFlag,
    cardSchema,
    schemaErrorMessages,
    inputFileIsLoading = false,
    disableFileInput = false,
    seniorOnlyRoles = [],
    disabled = false,
}) => {
    const { t } = useTranslation();
    const [confirmationControlVisible, setConfirmationControlVisible] = useState(false);
    const { validateSchema, validateSubSchemaFromEvent, errors } = useJoiValidation();
    const firstRunFlag = useRef(true);

    const {
        StartDateInput,
        EndDateInput,
        handleChangeRole,
        handleChangeSeniority,
        handleChangeJobSkills,
        handleChangeTotal,
        handleChangeTime,
        handleChangeStartDate,
        handleChangeEndDate,
        handleChangeJobDescription,
        changeUploadJobDescription,
        deleteJobDescriptionFile,
        validateTotalFieldValue,
        validateJobDescriptionValue,
        handleDeleteResource,
        toggleConfirmationControls,
    } = useTeamInfoFunctions(
        positionInfo,
        onChange,
        deletePosition,
        confirmationControlVisible,
        setConfirmationControlVisible,
        cardSchema,
        schemaErrorMessages,
        validateSchema,
        validateSubSchemaFromEvent,
        firstRunFlag,
        seniorOnlyRoles,
        seniorityTypes
    );

    const isTam = useMemo(() => positionInfo.role?.label === TAM_LABEL, [positionInfo.role?.label]);
    const availableRoles = useMemo(() => roleTypes.filter((r) => r.label !== TAM_LABEL), [roleTypes]);

    const componentState = {
        role: positionInfo.role,
        seniority: positionInfo.seniority,
        jobSpecificSkills: positionInfo.jobSkills,
        count: positionInfo.total,
        time: positionInfo.time,
        estimatedDateToStartOptional: projectInfo.startDate,
        estimatedDateToEndOptional: projectInfo.endDate,
    };

    // Report to the parent the validity of the form
    useEffect(() => {
        // If it's the first run of the component, do not execute the validation callback
        if (firstRunFlag.current) {
            return;
        }

        onValidationChange({
            id: positionInfo.id,
            valid: Object.keys(errors).length === 0 && positionInfo.jobDescription.isValid,
        });
    }, [errors]);

    // Execute a global validation when forceGlobalValidationFlag changes its value
    useEffect(() => {
        if (forceGlobalValidationFlag === 0) {
            return;
        }

        validateSchema(cardSchema, schemaErrorMessages, componentState);
    }, [forceGlobalValidationFlag]);

    return (
        <div className={`${sharedStyle.container} position-relative p-4 mt-4`}>
            {totalPositions > 2 &&
                positionInfo.role?.label !== TAM_LABEL &&
                !disabled &&
                (confirmationControlVisible ? (
                    <div className={`${styles.confirmationIconsContainer} position-relative`}>
                        <span
                            className={`${styles.confirmationIcons} ${styles.cancelIcon}`}
                            onClick={toggleConfirmationControls}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM175 208.1L222.1 255.1L175 303C165.7 312.4 165.7 327.6 175 336.1C184.4 346.3 199.6 346.3 208.1 336.1L255.1 289.9L303 336.1C312.4 346.3 327.6 346.3 336.1 336.1C346.3 327.6 346.3 312.4 336.1 303L289.9 255.1L336.1 208.1C346.3 199.6 346.3 184.4 336.1 175C327.6 165.7 312.4 165.7 303 175L255.1 222.1L208.1 175C199.6 165.7 184.4 165.7 175 175C165.7 184.4 165.7 199.6 175 208.1V208.1z" />
                            </svg>
                        </span>
                        <span
                            className={`${styles.confirmationIcons} ${styles.acceptIcon}`}
                            onClick={handleDeleteResource}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z" />
                            </svg>
                        </span>
                    </div>
                ) : (
                    <span className={styles.btnDeleteResource}>
                        <svg
                            onClick={toggleConfirmationControls}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                        >
                            <path d="M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM394.8 466.1C393.2 492.3 372.3 512 346.9 512H101.1C75.75 512 54.77 492.3 53.19 466.1L31.1 128H416L394.8 466.1z" />
                        </svg>
                    </span>
                ))}
            <div className="row">
                <div className={`${styles.formElementContainer} col-2`}>
                    <Label htmlFor={TeamInfoFormIds.ROLE} 
                           requiredIndicator 
                           informationIndicator={positionInfo.role?.label === TAM_LABEL } 
                           informationAboutField={t("tamTooltip")}>
                        {t("role")}
                    </Label>
                    <Select
                        id={TeamInfoFormIds.ROLE}
                        styles={getSelectorStyle(!!errors.role)}
                        options={availableRoles}
                        onChange={handleChangeRole}
                        value={positionInfo.role}
                        placeholder={`${t("select")}...`}
                        isDisabled={isTam || disabled}
                    />
                    {errors.role && <ErrorMessages errors={[errors.role.message]} />}
                </div>

                <div className={`${styles.formElementContainer} col-2`}>
                    <Label htmlFor={TeamInfoFormIds.SENIORITY} requiredIndicator>
                        {t("seniority")}
                    </Label>
                    <Select
                        id={TeamInfoFormIds.SENIORITY}
                        styles={getSelectorStyle(!!errors.seniority)}
                        options={seniorityTypes}
                        onChange={handleChangeSeniority}
                        value={positionInfo.seniority}
                        placeholder={`${t("select")}...`}
                        isDisabled={seniorOnlyRoles.includes(positionInfo.role?.value) || isTam || disabled}
                    />

                    {errors.seniority && <ErrorMessages errors={[errors.seniority.message]} />}
                </div>

                <div className={`${styles.formElementContainer} col-3`}>
                    <Label htmlFor={TeamInfoFormIds.JOB_SKILLS}>{t("jobSpecificSkills")}</Label>
                    <Tags
                        onChange={handleChangeJobSkills}
                        defaultValue={positionInfo.jobSkills}
                        className="w-100"
                        disabled={isTam || disabled}
                    />
                </div>

                <div className={`${styles.formElementContainer} col-1`}>
                    <Label htmlFor={TeamInfoFormIds.TOTAL} requiredIndicator>
                        {t("count")}
                    </Label>

                    <Input
                        id={TeamInfoFormIds.TOTAL}
                        type="number"
                        min={TotalMinMaxLimits.MIN}
                        max={TotalMinMaxLimits.MAX}
                        className="px-2"
                        value={positionInfo.total}
                        onChange={handleChangeTotal}
                        onBlur={validateTotalFieldValue}
                        disabled={isTam || disabled}
                        invalid={!!errors.count}
                    />

                    {errors.count && <ErrorMessages errors={[errors.count.message]} />}
                </div>

                <div className={`${styles.formElementContainer} col-2`}>
                    <Label htmlFor={TeamInfoFormIds.TIME} 
                           informationIndicator 
                           informationAboutField={<Trans components={{ br: <br/> }}>timeTooltip</Trans>}
                    >{t("time")}</Label>
                    <Checkbox
                        checked={positionInfo.time}
                        onChange={handleChangeTime}
                        name={TeamInfoFormIds.TIME}
                        className={`mb-3 ${styles.inputMinHeight}`}
                        isValid={true}
                        disabled={isTam || disabled}
                    >
                        {t("partTimeAlt")}
                    </Checkbox>
                </div>
            </div>

            <div className="row pt-4">
                {positionInfo.role?.label !== TAM_LABEL && (
                    <div className={`${styles.formElementContainer} col-3`}>
                        <Label htmlFor={TeamInfoFormIds.ESTIMATED_START_DATE}>
                            {t("estimatedDateToStartOptional")}
                        </Label>

                        <DatePicker
                            id={TeamInfoFormIds.ESTIMATED_START_DATE}
                            selected={
                                positionInfo[TeamInfoFormIds.ESTIMATED_START_DATE]
                                    ? parse(positionInfo[TeamInfoFormIds.ESTIMATED_START_DATE], DATE_FORMAT, new Date())
                                    : null
                            }
                            onChange={handleChangeStartDate}
                            customInput={<StartDateInput disabled={isTam || disabled} />}
                            minDate={CURRENT_DATE}
                            maxDate={parse(projectInfo.endDate, DATE_FORMAT, new Date())}
                            dateFormat={DATE_FORMAT}
                            showPopperArrow={false}
                            disabled={isTam || disabled}
                        />
                    </div>
                )}

                {positionInfo.role?.label !== TAM_LABEL && (
                    <div className={`${styles.formElementContainer} col-3`}>
                        <Label htmlFor={TeamInfoFormIds.ESTIMATED_END_DATE}>{t("estimatedDateToEndOptional")}</Label>
                        <DatePicker
                            id={TeamInfoFormIds.ESTIMATED_END_DATE}
                            selected={
                                positionInfo[TeamInfoFormIds.ESTIMATED_END_DATE]
                                    ? parse(positionInfo[TeamInfoFormIds.ESTIMATED_END_DATE], DATE_FORMAT, new Date())
                                    : null
                            }
                            onChange={handleChangeEndDate}
                            customInput={
                                <EndDateInput
                                    disabled={!positionInfo[TeamInfoFormIds.ESTIMATED_START_DATE] || isTam || disabled}
                                />
                            }
                            minDate={addMonths(
                                parse(positionInfo[TeamInfoFormIds.ESTIMATED_START_DATE], DATE_FORMAT, new Date()),
                                3
                            )}
                            // maxDate={parse(projectInfo.endDate, DATE_FORMAT, new Date())}
                            dateFormat={DATE_FORMAT}
                            disabled={!positionInfo[TeamInfoFormIds.ESTIMATED_START_DATE] || isTam || disabled}
                            showPopperArrow={false}
                        />
                    </div>
                )}

                {positionInfo.role?.label !== TAM_LABEL && (
                    <div className={`${styles.formElementContainer} col-4`}>
                        <Label
                            htmlFor={TeamInfoFormIds.JOB_DESCRIPTION_LINK}
                            informationAboutField={t("jobDescriptionTooltip")}
                            informationIndicator
                        >
                            {t("jobDescriptionLinkOptional")}
                        </Label>

                        <div className="d-flex align-content-center">
                            <Input
                                id={TeamInfoFormIds.JOB_DESCRIPTION_LINK}
                                className={`${styles.jobDescriptionLink} ps-2`}
                                value={
                                    positionInfo.jobDescription?.isLink
                                        ? positionInfo.jobDescription.link
                                        : positionInfo.jobDescription?.file.name
                                }
                                onChange={handleChangeJobDescription}
                                onBlur={validateJobDescriptionValue}
                                disabled={!positionInfo.jobDescription?.isLink || isTam || disabled}
                            />
                            <span
                                className={`${styles.btnCloseCircle} ${
                                    positionInfo.jobDescription?.isLink ? "d-none" : "d-flex"
                                } position-relative`}
                                onClick={deleteJobDescriptionFile}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM175 208.1L222.1 255.1L175 303C165.7 312.4 165.7 327.6 175 336.1C184.4 346.3 199.6 346.3 208.1 336.1L255.1 289.9L303 336.1C312.4 346.3 327.6 346.3 336.1 336.1C346.3 327.6 346.3 312.4 336.1 303L289.9 255.1L336.1 208.1C346.3 199.6 346.3 184.4 336.1 175C327.6 165.7 312.4 165.7 303 175L255.1 222.1L208.1 175C199.6 165.7 184.4 165.7 175 175C165.7 184.4 165.7 199.6 175 208.1V208.1z" />
                                </svg>
                            </span>
                            <span className="p-1 d-flex align-items-center">{t("or")}</span>
                            <InputFile
                                inputFileChange={changeUploadJobDescription}
                                isLoading={inputFileIsLoading}
                                disabled={disableFileInput || isTam || disabled}
                                short
                            />
                        </div>

                        {!positionInfo.jobDescription?.isValid && (
                            <div className="d-flex">
                                <span className={sharedStyle.errorMsg}>{t("errorLinkFormat")}</span>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

Resource.propTypes = {
    positionInfo: PropTypes.object,
    projectInfo: PropTypes.object,
    roleTypes: PropTypes.array,
    seniorityTypes: PropTypes.array,
    totalPositions: PropTypes.number,
    deletePosition: PropTypes.func,
    onValidationChange: PropTypes.func,
    forceGlobalValidationFlag: PropTypes.number,
    cardSchema: PropTypes.object,
    schemaErrorMessages: PropTypes.object,
    inputFileIsLoading: PropTypes.bool,
    disableFileInput: PropTypes.bool,
    seniorOnlyRoles: PropTypes.array,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
};

export default Resource;
