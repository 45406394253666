import Joi from "joi";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Routes from "../../../base/js/routes";
import { confirmEmailAsync, loginAsync } from "../../../services/AuthService";
import { setEmail, setRoles, setUsername } from "../../../store/slices/userSlice";
import Button, { textVariants, variants as btnVariants } from "../../common/Button";
import useJoiValidation from "../../hooks/UseJoiValidation";
import Loading from "../../loading/Loading";
import sharedStyle from "../../shared-styles/FormStyle.module.scss";
import style from "../LoginSignup.module.scss";

// (Joi) Definition of the base schema
const baseSchema = {
    email: Joi.string()
        .max(255)
        .email({ tlds: { allow: false } })
        .required(),
    password: Joi.string().min(8).max(255).required(),
};

// (Joi) Object containing errors' messages by its type
const schemaErrorMessages = {
    "string.empty": "requiredFieldIsEmpty",
    "string.max": "fieldTooLong",
    "string.min": "****",
    "string.email": "invalidEmailFormat",
};

const LoginForm = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [loadingEmailConfirmation, setLoadingEmailConfirmation] = useState(false);
    const { validateSchema, validateSubSchemaFromEvent, errors } = useJoiValidation();
    const [searchParams, setSearchParams] = useSearchParams();

    const [data, setData] = useState({
        email: "",
        password: "",
    });

    useEffect(() => {
        (async () => {
            const userId = searchParams.get("userId");
            const code = searchParams.get("code");

            // If there is no userId or code, the activation can't proceed
            if (!userId || !code) {
                return;
            }

            setLoadingEmailConfirmation(true);

            try {
                await confirmEmailAsync(userId, code);

                toast.success(t("emailConfirmed"));

                // Remove URL query parameters
                setSearchParams("");
            } catch (error) {
                console.error(error.message);
            } finally {
                setLoadingEmailConfirmation(false);
            }
        })();
    }, []);

    const handleOnChange = (event) => {
        const { name, value } = event.target;

        setData((prevState) => {
            return { ...prevState, [name]: value };
        });
    };

    const handleOnBlur = (event) => {
        validateSubSchemaFromEvent(baseSchema, schemaErrorMessages, event);
    };

    const handleLoginSubmit = async (event) => {
        event.preventDefault();

        // Validate the schema and update the state that contains all the errors
        const numberOfErrors = validateSchema(baseSchema, schemaErrorMessages, data);

        // If there are errors, abort the upload of the info
        if (numberOfErrors > 0) {
            return;
        }

        setLoading(true);

        try {
            const dataToSend = {
                userName: data.email,
                password: data.password,
            };

            const responseData = await loginAsync(dataToSend);

            if (responseData) {
                dispatch(setUsername(responseData?.name));
                dispatch(setEmail(responseData?.email));
                dispatch(setRoles(responseData?.roles));
            }
        } catch (e) {
            toast.error("Unexpected error: " + e.message);
        } finally {
            setLoading(false);
        }
    };

    const onSignUpClick = (event) => {
        event.preventDefault();

        navigate(Routes.SIGNUP);
    };

    return (
        <>
            <h1>{t("login")}</h1>
            <form onSubmit={handleLoginSubmit} className="position-relative d-flex flex-column">
                <label className="mt-2 mb-1">{t("email")}</label>
                <input
                    name="email"
                    className={`${errors.email ? sharedStyle.invalidField : ""} ${style.input} border-0`}
                    type="email"
                    value={data.email}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                />
                {errors.email && (
                    <div className="mt-1">
                        <p className={sharedStyle.errorMsg}>{errors.email.message}</p>
                    </div>
                )}

                <label className="mt-2 mb-1">{t("password")}</label>
                <input
                    name="password"
                    className={`${errors.password ? sharedStyle.invalidField : ""} ${style.input} border-0`}
                    type="password"
                    value={data.password}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    autoComplete="current-password"
                />
                {errors.password && (
                    <div className="mt-1">
                        <p className={sharedStyle.errorMsg}>{errors.password.message}</p>
                    </div>
                )}

                <Link className="text-end mt-3" to={Routes.RESET_PASSWORD}>
                    {t("forgotYourPassword")}
                </Link>

                <div className="position-relative d-flex flex-column mt-4">
                    <Button
                        variant={btnVariants.PRIMARY}
                        cssClasses={[style.loginBtn]}
                        type="submit"
                        disabled={loading}
                    >
                        {t("login")}
                    </Button>

                    <span className={`${style.spacing} text-center text-uppercase my-2`}>{t("or")}</span>

                    <Button
                        variant={btnVariants.PRIMARY_INVERSE}
                        textVariant={textVariants.BROWN}
                        cssClasses={[style.loginBtn]}
                        onClick={onSignUpClick}
                    >
                        {t("signUp")}
                    </Button>
                </div>
            </form>

            {loading ||
                (loadingEmailConfirmation && (
                    <Loading withText={loadingEmailConfirmation} text={`${t("confirmingEmail")}...`} />
                ))}
        </>
    );
};

export default LoginForm;
