import Joi from "joi";
import { lazy, Suspense, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { INFINITE_SCROLL_FETCH_SIZE, JoiDomainValidator } from "../../../base/js/constants";
import {
    addRestrictedDomain,
    deleteRestrictedDomain,
    getAllDeletedRestrictedDomains,
    getAllRestrictedDomains,
    getRestrictedDomain,
    restoreRestrictedDomain,
    updateRestrictedDomainPATCH,
} from "../../../services/RestrictedDomainService";
import Input from "../../common/forms/input/Input";
import Label from "../../common/forms/label/Label";
import InfiniteTable from "../../common/infinite-table/InfiniteTable";
import useFirstRender from "../../hooks/UseFirstRender";
import useJoiValidation from "../../hooks/UseJoiValidation";
import Loading from "../../loading/Loading";
import sharedStyles from "../../shared-styles/FormStyle.module.scss";
import styles from "./AdminRestrictedDomains.module.scss";

const RestrictedDomainForm = lazy(() => import("./form/RestrictedDomainForm"));

const TABLE_HEADERS = ["domain", "description", "createdBy", "updatedBy", "actions"];

const FormElements = {
    DOMAIN: "domain",
    DESCRIPTION: "description",
};

const FormStatus = {
    ADDING: "adding",
    EDITING: "editing",
};

const JOI_ERROR_MESSAGES = {
    "any.required": "requiredFieldIsEmpty",
    "string.empty": "requiredFieldIsEmpty",
    "string.max": "fieldTooLong",
    "string.invalidDomain": "invalidDomain",
};

const JOI_SCHEMA = {
    [FormElements.DOMAIN]: Joi.string().max(253).custom(JoiDomainValidator).required(),
    [FormElements.DESCRIPTION]: Joi.string().max(180).required(),
};

// Use this function to build each field state. This will help us if we need to
// modify the structure of the fields globally, since you'll only modify this part
// of the code (and maybe where the data is being used too, but that's another problem).
const fieldStateBuilder = (data, modified) => ({ data, modified });

const DEFAULT_FORM_STATE = {
    [FormElements.DOMAIN]: fieldStateBuilder("", false),
    [FormElements.DESCRIPTION]: fieldStateBuilder("", false),
};

const AdminRestrictedDomains = () => {
    const { t } = useTranslation();
    const [restrictedDomains, setRestrictedDomains] = useState({ data: [], loading: false });
    const [totalElements, setTotalElements] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [loading, setLoading] = useState(false);
    const { validateSchema, validateSubSchemaFromEvent, errors, resetErrors } = useJoiValidation();
    const [currentRestrictedDomain, setCurrentRestrictedDomain] = useState(DEFAULT_FORM_STATE);
    const [formMode, setFormMode] = useState(FormStatus.ADDING);
    const [showForm, setShowForm] = useState(false);
    const firstRender = useFirstRender();
    const [tableLoading, setTableLoading] = useState(false);
    const [showDeleted, setShowDeleted] = useState(false);

    const onRemove = async (id) => {
        try {
            setLoading(true);

            await deleteRestrictedDomain(id);

            setRestrictedDomains((prevState) => ({
                data: prevState.data.filter((rd) => rd.id !== id),
                loading: false,
            }));

            setTotalElements((prevState) => prevState - 1);
        } catch (e) {
            console.error(e.message);
        } finally {
            setLoading(false);
        }
    };

    const onEdit = async (id) => {
        try {
            setLoading(true);

            const rd = await getRestrictedDomain(id, onEdit, onRemove);

            if (rd) {
                // Update each field to add a flag to keep track if it has been
                // modified or not.
                Object.keys(rd).forEach((k) => {
                    rd[k] = fieldStateBuilder(rd[k], false);
                });

                setCurrentRestrictedDomain(rd);
                setFormMode(FormStatus.EDITING);
                setShowForm(true);

                // Make sure that the user is seeing our form
                window.scrollTo(0, 0);
            }
        } catch (e) {
            console.error(e.message);
        } finally {
            setLoading(false);
        }
    };

    const onRestore = async (id) => {
        try {
            setLoading(true);

            await restoreRestrictedDomain(id);

            // Since this functionality is being accessed when the deleted
            // restricted domains are being shown, we should remove the restored
            // element from the collection.
            setRestrictedDomains((prevState) => ({
                data: prevState.data.filter((rd) => rd.id !== id),
                loading: false,
            }));
            setTotalElements((prevState) => prevState - 1);
        } catch (e) {
            console.error(e.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const timeoutId = setTimeout(
            () => {
                initialDataLoad(firstRender);
            },
            firstRender ? 0 : 1000
        );

        return () => {
            clearTimeout(timeoutId);
        };
    }, [searchText]);

    useEffect(() => {
        if (firstRender) {
            return;
        }

        initialDataLoad();
    }, [showDeleted]);

    // Loads the data for first time, avoiding the skipping of elements.
    const initialDataLoad = async (showLoading = true) => {
        try {
            if (showLoading) {
                setLoading(true);
            }

            const response = showDeleted
                ? await getAllDeletedRestrictedDomains(0, INFINITE_SCROLL_FETCH_SIZE, searchText, onRestore)
                : await getAllRestrictedDomains(0, INFINITE_SCROLL_FETCH_SIZE, searchText, onRemove, onEdit);

            if (response) {
                setRestrictedDomains({ data: response?.domains || [], loading: false });
                setTotalElements(response?.total || 0);
            }

            if (showLoading) {
                setLoading(false);
            }
        } catch (e) {
            toast.error(t("unexpectedError") + ": " + e.message);
        }
    };

    const fetchNewElements = async () => {
        try {
            const response = showDeleted
                ? await getAllDeletedRestrictedDomains(
                      restrictedDomains.data.length,
                      INFINITE_SCROLL_FETCH_SIZE,
                      searchText,
                      onRestore
                  )
                : await getAllRestrictedDomains(
                      restrictedDomains.data.length,
                      INFINITE_SCROLL_FETCH_SIZE,
                      searchText,
                      onRemove,
                      onEdit
                  );

            if (response) {
                setTotalElements(response.total);
                if (response.domains.length) {
                    setRestrictedDomains((prevState) => {
                        return { data: [...prevState.data, ...response.domains], loading: false };
                    });
                }
            }
        } catch (e) {
            console.error(e.message);
        }
    };

    const onChange = (e) => {
        setCurrentRestrictedDomain((prevState) => ({
            ...prevState,
            [e.target.id]: fieldStateBuilder(e.target.value, true),
        }));
    };

    const onBlur = (e) => {
        validateSubSchemaFromEvent(JOI_SCHEMA, JOI_ERROR_MESSAGES, e);
    };

    const onFormSubmit = async (e) => {
        e.preventDefault();

        const validationObj = {
            [FormElements.DOMAIN]: currentRestrictedDomain[FormElements.DOMAIN].data,
            [FormElements.DESCRIPTION]: currentRestrictedDomain[FormElements.DESCRIPTION].data,
        };

        const amountOfErrors = validateSchema(JOI_SCHEMA, JOI_ERROR_MESSAGES, validationObj);
        if (amountOfErrors > 0) {
            return;
        }

        let payload;

        if (formMode === FormStatus.ADDING) {
            // If the data is being added, we just send everything.
            payload = validationObj;
        } else {
            // If the data is being modified, then we've to check which fields
            // have been modified. Once we've located them, we'll have
            // to build the payload for the PATCH request.

            // The payload needs to be an array, so we can push each PATCH
            // operation here.
            payload = [];

            Object.keys(currentRestrictedDomain).forEach((k) => {
                if (!currentRestrictedDomain[k].modified) {
                    return;
                }

                // Once we detect that a field has been modified, we'll push
                // an object with the structure needed to update the attribute
                // of the entity by using the PATCH request.
                payload.push({
                    op: "replace",
                    path: `/${k}`,
                    value: currentRestrictedDomain[k].data,
                });
            });
        }

        try {
            setLoading(true);

            const response =
                formMode === FormStatus.ADDING
                    ? await addRestrictedDomain(payload)
                    : await updateRestrictedDomainPATCH(currentRestrictedDomain.id.data, payload);

            if (response) {
                toast.success(
                    t(formMode === FormStatus.ADDING ? "restrictedDomainCreated" : "restrictedDomainUpdated")
                );

                // Add/Update the domain in the list
                if (formMode === FormStatus.ADDING) {
                    // Get the ID of the added entry, which should be returned as a response to our POST request
                    // Fetch the information of the added entry
                    const newEntry = await getRestrictedDomain(response, onEdit, onRemove);

                    if (newEntry) {
                        // Add the new entry to the collection
                        setRestrictedDomains((prevState) => ({
                            data: [newEntry, ...prevState.data],
                            loading: false,
                        }));
                        setTotalElements((prevState) => prevState + 1);
                    }
                } else {
                    const objectToInsert = {};

                    // Map back to the object's original structure
                    Object.keys(currentRestrictedDomain).forEach((k) => {
                        objectToInsert[k] = currentRestrictedDomain[k].data;
                    });

                    // Update the domain in the collection
                    setRestrictedDomains((prevState) => ({
                        data: [
                            objectToInsert,
                            ...prevState.data.filter((rd) => rd.id !== currentRestrictedDomain.id.data),
                        ],
                        loading: false,
                    }));

                    setShowForm(false);
                    setFormMode(FormStatus.ADDING);
                }

                clearForm();
            }
        } catch (e) {
            toast.error(`${t("unexpectedError")}: ${e.message}`);
        } finally {
            setLoading(false);
        }
    };

    const clearForm = () => {
        setCurrentRestrictedDomain(DEFAULT_FORM_STATE);
        resetErrors();
    };

    const onSearchChange = (e) => {
        setSearchText(e.target.value);
    };

    const onFormCancel = () => {
        setShowForm(false);
        setFormMode(FormStatus.ADDING);
        clearForm();
    };

    const onFiltersAddBtnClick = () => {
        setShowForm(true);
    };

    const onTableLoading = (newValue) => {
        setTableLoading(newValue);
    };

    const onSwitchChange = (e) => {
        setShowDeleted(e.target.checked);
    };

    return (
        <>
            <div className="d-flex flex-column container py-4">
                <h1 className="h1 text-center mb-4">{t("restrictedDomains")}</h1>

                {/* Filters bar */}
                <div
                    className={`d-flex align-items-center justify-content-between px-4 mb-3 ${styles.filtersContainer}`}
                >
                    <div className="d-flex gap-4">
                        <div className="d-flex align-items-center gap-3">
                            <Label htmlFor="search">{t("search")}</Label>
                            <Input
                                id="search"
                                name="search"
                                className={`px-2 ${styles.searchInput}`}
                                value={searchText}
                                onChange={onSearchChange}
                            />
                        </div>

                        <div className="d-flex align-items-center mx-2">
                            <Form.Check
                                id={`inactiveFilter`}
                                type="switch"
                                checked={showDeleted}
                                className={`${sharedStyles.customSwitch}`}
                                onChange={onSwitchChange}
                                label={<span>{t("deleted")}</span>}
                            />
                        </div>
                    </div>

                    <button
                        className="btn btn-success text-uppercase"
                        disabled={showForm}
                        onClick={onFiltersAddBtnClick}
                    >
                        {showForm ? (formMode === FormStatus.ADDING ? t("adding") : t("editing")) : t("add")}
                    </button>
                </div>

                {/* Form to add/update a restricted domain */}
                {showForm && (
                    <Suspense fallback={<Loading fullWidth={false} withText={false} allScreen={false} />}>
                        <RestrictedDomainForm
                            onFormSubmit={onFormSubmit}
                            onFormCancel={onFormCancel}
                            onChange={onChange}
                            onBlur={onBlur}
                            currentRestrictedDomain={currentRestrictedDomain}
                            formElements={FormElements}
                            formStatus={FormStatus}
                            formMode={formMode}
                            errors={errors}
                        />
                    </Suspense>
                )}

                {/* Infinite scroll with all the restricted domains */}
                <InfiniteTable
                    headers={TABLE_HEADERS}
                    rows={restrictedDomains.data}
                    onFetchRequest={fetchNewElements}
                    disableInfiniteScroll={restrictedDomains.data.length >= totalElements}
                    className="mt-3"
                    onLoading={onTableLoading}
                    loading={tableLoading}
                />
            </div>

            {loading && <Loading />}
        </>
    );
};

export default AdminRestrictedDomains;
