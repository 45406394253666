import { generateErrorToast } from "../base/js/httpError";
import { API_BASE, request } from "../utils/httpRequest";

export const postPositionsAsync = async (positions, initialRange = true) => {
    try {
        return await request(`${API_BASE}/api/position/add-range?initialRange=${initialRange}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(positions),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const addPositionStaffAsync = async (positionId, resourceId) => {
    try {
        return await request(API_BASE + "/api/positionstaff/add", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ positionId: positionId, staffId: resourceId }),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const removePositionStaffAsync = async (positionId, resourceId) => {
    try {
        return await request(API_BASE + "/api/positionstaff/remove", {
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ positionId: positionId, staffId: resourceId }),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const getPositionsByProject = async (projectId) => {
    try {
        return await request(`${API_BASE}/api/position/positions-by-project/${projectId}`);
    } catch (error) {
        generateErrorToast(error);
    }
};

export const getPositionsForResumeListAsync = async (page = 1, pageSize = 5, searchText = null) => {
    try {
        return await request(
            API_BASE +
                `/api/position/resume-list?page=${page}&pageSize=${pageSize}${
                    searchText ? "&searchText=" + searchText : ""
                }`,
            {
                method: "GET",
            }
        );
    } catch (error) {
        generateErrorToast(error);
    }
};

export const getSenioritiesAsync = async () => {
    try {
        return await request(API_BASE + "/api/seniority");
    } catch (error) {
        generateErrorToast(error);
    }
};

export const getRolesAsync = async () => {
    try {
        return await request(API_BASE + "/api/developmentRol");
    } catch (error) {
        generateErrorToast(error);
    }
};

export const updatePositionStaff = async (resourceId, positionId, newStatus) => {
    try {
        return await request(`${API_BASE}/api/position/update-position-staff`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ positionId: positionId, staffId: resourceId, status: newStatus }),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};
