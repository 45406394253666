import { forwardRef, useState } from "react";
import { NavLink } from "react-router-dom";
import styles from "../AdminMenu.module.scss";

const CustomSubToggle = forwardRef(({ children, onClick, settingsOpen }, ref) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const handleLinkStatus = ({ isActive }) => {
        return `${styles.dropdownItem} px-4`;
    };

    const handleOnClick = (event) => {
        event.preventDefault();
        setMenuOpen(!menuOpen);
        onClick(event);
    };

    return (
        <NavLink to="admin/settings" className={handleLinkStatus} ref={ref} onClick={handleOnClick}>
            {children}
        </NavLink>
    );
});

export default CustomSubToggle;
