import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ModalButtonVariant } from "../../../base/js/constants";
import ModalButton from "../../common/modal/buttons/ModalButton";
import Modal from "../../common/modal/Modal";
import style from "./ConfirmReviewDeleteModal.module.scss";

const ConfirmReviewDeleteModal = ({ show, onAccept, onCancel }) => {
    const { t } = useTranslation();

    return (
        <Modal show={show}>
            <span className={`${style.modalTitle} text-bold d-block text-center`}>{t("confirmReviewDelete")}</span>

            <span>{t("confirmReviewDeleteMessage")}</span>

            <div className="d-flex justify-content-center gap-4 mt-4">
                <ModalButton variant={ModalButtonVariant.GREEN} onClick={onAccept}>
                    {t("accept")}
                </ModalButton>

                <ModalButton variant={ModalButtonVariant.WHITE} onClick={onCancel}>
                    {t("cancel")}
                </ModalButton>
            </div>
        </Modal>
    );
};

ConfirmReviewDeleteModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onAccept: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default ConfirmReviewDeleteModal;
