import { generateErrorToast } from "../base/js/httpError";
import { API_BASE, request } from "../utils/httpRequest";

export const addOrganizationAsync = async (data) => {
    try {
        return await request(`${API_BASE}/api/organization`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const editOrganizationAsync = async (id, data) => {
    try {
        return await request(`${API_BASE}/api/organization/${id}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const getOrganizationAsync = async (id) => {
    try {
        return await request(API_BASE + "/api/organization/" + id);
    } catch (error) {
        generateErrorToast(error);
    }
};

export const getOrganizationListAsync = async (page = 1, pageSize = 5, searchText = null) => {
    try {
        return await request(
            API_BASE +
                `/api/organization?page=${page}&pageSize=${pageSize}${searchText ? "&searchText=" + searchText : ""}`,
            {
                method: "GET",
            }
        );
    } catch (error) {
        generateErrorToast(error);
    }
};

export const getAllOrganizationListAsync = async () => {
    try {
        return await request(API_BASE + `/api/organization/all`, {
            method: "GET",
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const getUserOrganization = async () => {
    try {
        return await request(`${API_BASE}/api/organization/user-organization`);
    } catch (error) {
        generateErrorToast(error);
    }
};

export const updateUserOrganization = async (newOrganizationData) => {
    try {
        return await request(`${API_BASE}/api/organization/user-organization`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(newOrganizationData),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const addNoteToOrganization = async (data) => {
    try {
        return await request(`${API_BASE}/api/organization-note`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};
