import { generateErrorToast } from "../base/js/httpError";
import { API_BASE, request } from "../utils/httpRequest";

export const createCustomer = async () => {
    try {
        return await request(`${API_BASE}/api/stripe/create-customer`, {
            method: "POST",
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const createCheckoutSessionAsync = async (projectId) => {
    try {
        return await request(`${API_BASE}/api/stripe/${projectId}/create-checkout-session`, {
            method: "POST",
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const getInvoiceListAsync = async (page = 1, pageSize = 5, searchText = null) => {
    try {
        return await request(
            API_BASE + `/api/stripe?page=${page}&pageSize=${pageSize}${searchText ? "&searchText=" + searchText : ""}`,
            {
                method: "GET",
            }
        );
    } catch (error) {
        generateErrorToast(error);
    }
};
