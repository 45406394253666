import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidV4 } from "uuid";
import styles from "./ProjectDescriptionSkillset.module.scss";

const ProjectDescriptionSkillset = ({ skillset, description }) => {
    const { t } = useTranslation();
    const [skills, setSkills] = useState([]);

    useEffect(() => {
        setSkills(() => {
            const skills = [];

            for (let i = 0; i < skillset.length; i++) {
                skills.push(skillset[i]);
                if (i !== skillset.length - 1) skills.push(<span key={uuidV4()}>|</span>);
            }

            return skills;
        });
    }, [skillset]);

    return (
        <div>
            <span className={`${styles.title} d-inline-block`}>{t("descriptionSkillSet")}</span>
            <div className={styles.container}>
                <span className={`${styles.skillset} d-flex justify-content-end`}>
                    {skills.map((skill) => {
                        return skill;
                    })}
                </span>
                <p>{description}</p>
            </div>
        </div>
    );
};

ProjectDescriptionSkillset.prototype = {
    skillset: PropTypes.array.isRequired,
    description: PropTypes.string.isRequired,
};

export default ProjectDescriptionSkillset;
