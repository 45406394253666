import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import phoneImg from "../../assets/how-to-start/phone.svg";
import jackieImg from "../../assets/meet-the-team/jackie.jpg";
import personImg from "../../assets/testimonials/persona1.jpg";
import { PlayerSettings, VIDEO_URL } from "./constants";
import Guide from "./guide/Guide";
import style from "./HowToStart.module.scss";

const defaultGuideItems = [
    {
        title: "Login / Signup",
        content: {
            text:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed scelerisque porta turpis, sit amet ornare " +
                "tellus dapibus in. Etiam eros massa, dignissim at laoreet sed, elementum ac tortor. Vestibulum vehicula massa" +
                " nec blandit euismod. Donec molestie porta pretium. Suspendisse odio urna, tincidunt eget molestie sed, euismod" +
                " quis nisl. Fusce eu enim feugiat, egestas lorem vel, posuere mi. Aliquam feugiat ultrices nisi, vel facilisis " +
                "nulla elementum sed. Etiam tincidunt finibus posuere.",
            images: [{ src: phoneImg, alt: "", id: uuidv4() }],
        },
    },
    {
        title: "Start up",
        content: {
            text:
                "Cras nibh elit, tincidunt eu mi at, commodo venenatis velit. Suspendisse ornare urna ut erat venenatis volutpat. " +
                "Cras vehicula tellus vitae dui facilisis, sed molestie purus commodo.",
            images: [
                { src: jackieImg, alt: "", id: uuidv4() },
                { src: personImg, alt: "", id: uuidv4() },
            ],
        },
    },
    {
        title: "Assemble your team",
        content: {
            text:
                "Quisque feugiat laoreet ipsum, at sodales eros rhoncus ac. Vivamus lectus odio, lobortis pretium est eu, scelerisque" +
                " consectetur dolor. Aenean tincidunt nibh bibendum eros maximus ultrices. Donec ut faucibus lectus. Etiam pellentesque sit" +
                " amet libero vitae efficitur. Morbi hendrerit nibh non venenatis laoreet. Nulla eget elementum mauris, non pellentesque" +
                " nibh. Maecenas non pulvinar ipsum. Aliquam eget mollis nisi. Morbi gravida neque ac ultricies facilisis.",
            images: [
                { src: personImg, alt: "", id: uuidv4() },
                { src: phoneImg, alt: "", id: uuidv4() },
            ],
        },
    },
    {
        title: "Confirm the service",
        content: {
            text:
                "Praesent porta condimentum tortor, eget aliquam dolor sagittis in. Donec lobortis risus ligula, vitae tristique sapien " +
                "aliquam a. Nulla ipsum ligula, congue lobortis dolor non, faucibus lacinia est. Proin ullamcorper nec lacus ut ullamcorper." +
                " Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
            images: [{ src: phoneImg, alt: "", id: uuidv4() }],
        },
    },
    {
        title: "Final step",
        content: {
            text:
                "Aenean eget turpis lacus. Curabitur commodo, sem eget mattis vehicula, arcu nisi luctus leo, non consequat justo eros non" +
                " purus. Etiam venenatis ipsum at gravida tempor. Vivamus vel auctor massa. Ut fringilla porta bibendum. Ut rutrum elementum" +
                " lectus non mattis. Ut convallis quam at arcu fermentum, semper consequat tortor tempor. ",
            images: [{ src: phoneImg, alt: "", id: uuidv4() }],
        },
    },
    {
        title: "Satisfaction survey",
        content: {
            text:
                "In eu nisi non sapien aliquet posuere. Integer auctor, risus sit amet maximus facilisis, eros diam congue sapien, ac " +
                "bibendum tellus augue vitae dui. Cras velit purus, iaculis id rutrum eu, varius nec sem. Ut eu magna accumsan lorem consectetur" +
                " varius in ut libero. Phasellus non dui ac felis gravida viverra et ac augue. Curabitur ut mauris ex. Duis quis massa ut neque" +
                " viverra mollis. Mauris sapien dolor, ultrices quis ante in, tincidunt laoreet turpis. Ut nibh magna, ultricies at sem finibus," +
                " sollicitudin volutpat dolor.",
            images: [],
        },
    },
];

const HowToStart = () => {
    const { t } = useTranslation();
    const [menuItems, setMenuItems] = useState(null);

    useEffect(() => {
        // TODO Fetch the data from the API.
        setMenuItems(defaultGuideItems);
    }, []);

    return (
        <main className={`${style.container} d-flex flex-column align-items-center`}>
            <h1 className={`${style.title} text-center`}>{t("howToBuildYourTeam")}</h1>
            <div className="d-flex my-4">
                <iframe
                    className={style.ytPlayer}
                    src={`${VIDEO_URL}?fs=${PlayerSettings.ALLOW_FULLSCREEN}&modestbranding=${PlayerSettings.NO_YT_LOGO}&rel=${PlayerSettings.SHOW_RELATED_CONTENT}`}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            </div>
            {menuItems && <Guide items={menuItems} />}
        </main>
    );
};

export default HowToStart;
