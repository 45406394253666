import { useEffect, useState } from "react";

const useWindowWidth = () => {
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleChangeSize = () => {
            setInnerWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleChangeSize);

        return () => {
            window.removeEventListener("resize", handleChangeSize);
        };
    }, []);

    return innerWidth;
};

export default useWindowWidth;
