import { useTranslation } from "react-i18next";
import rightArrowUnfilledIcon from "../../../assets/icons/right-arrow-unfilled.svg";
import style from "./ThirdSection.module.scss";

const ThirdSection = () => {
    const { t } = useTranslation();

    return (
        <div className={style.section}>
            <div className={`${style.innerContainer} d-flex flex-column flex-lg-row`}>
                <h1 className={`${style.title} mb-3 mb-lg-0`}>{t("wantToConnect")}</h1>

                <div className={`${style.writeUs} d-flex justify-content-between px-4`}>
                    <img src={rightArrowUnfilledIcon} alt="" className="my-auto" />
                    <span className="my-auto">{t("writeUs")}</span>
                </div>
            </div>
        </div>
    );
};

export default ThirdSection;
