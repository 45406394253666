import Tags from "@yaireo/tagify/dist/react.tagify";
import PropTypes from "prop-types";
import { useContext, useEffect, useRef, useState } from "react";
import { useDrop } from "react-dnd";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import { getSenioritiesAsync } from "../../../../services/PositionService";
import { getRoleListAsync } from "../../../../services/RoleService";
import { getResourcesAvailableListAsync } from "../../../../services/StaffService";
import { getTechnologyListAsync } from "../../../../services/TechnologyService";
import Button from "../../../common/Button";
import Paginator from "../../../common/paginator/Paginator";
import Loading from "../../../loading/Loading";
import { getSelectorStyle } from "../../../shared-styles/StylesFunctions";
import { PositionActiveContext } from "../AllocationResource";
import ResourceComparisonModal from "../resource-comparison-modal/ResourceComparisonModal";
import ResourceCard from "./resource-card/ResourceCard";
import styles from "./ResourceCardList.module.scss";

const PAGE_SIZE = 5;
const INITIAL_PAGE = 1;
const MAX_RESOURCE_TO_COMPARE = 2;

const ResourceCardList = ({ onResourceClick, preselectedFilters }) => {
    const { t } = useTranslation();
    const tagRef = useRef(null);
    const positionActive = useContext(PositionActiveContext);
    const [roleList, setRoleList] = useState([]);
    const [resourceList, setResourceList] = useState([]);
    const [resourcesSelectedId, setResourcesSelectedId] = useState([]);
    const [page, setPage] = useState(INITIAL_PAGE);
    const [role, setRole] = useState(null);
    const [seniorityList, setSeniorityList] = useState([]);
    const [seniority, setSeniority] = useState(null);
    const [technologiesList, setTechnologiesList] = useState([]);
    const [technology, setTechnology] = useState([]);
    const [showCompare, setShowCompare] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showComparisonModal, setShowComparisonModal] = useState(false);

    const [{ isOver }, drop] = useDrop(
        () => ({
            accept: "resource",
            collect: (monitor) => ({
                isOver: !!monitor.isOver(),
            }),
        }),
        [resourceList]
    );
    const onDrop = () => {};

    const tagifySettings = {
        placeholder: t("search"),
        dropdown: {
            enabled: 1,
            classname: styles.tagifyDropdown,
        },
    };

    // Fetch available resources
    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                const responseData = await getResourcesAvailableListAsync(
                    page,
                    PAGE_SIZE,
                    role?.value,
                    seniority?.value,
                    technology,
                    null
                );

                if (responseData) {
                    setResourceList(responseData);
                }
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [page, role, seniority, technology, t]);

    // Fetch roles
    useEffect(() => {
        (async () => {
            try {
                const responseData = await getRoleListAsync();

                if (responseData) {
                    const rolesMapped = responseData.map((role) => {
                        return {
                            value: role.id,
                            label: role.name,
                        };
                    });
                    setRoleList(rolesMapped);
                }
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            }
        })();
    }, [t]);

    // Fetch seniorities
    useEffect(() => {
        (async () => {
            try {
                const responseData = await getSenioritiesAsync();

                if (responseData) {
                    const senioritiesMapped = responseData.map((seniority) => {
                        return {
                            value: seniority.id,
                            label: seniority.name,
                        };
                    });
                    setSeniorityList(senioritiesMapped);
                }
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            }
        })();
    }, [t]);

    // Fetch technologies
    useEffect(() => {
        (async () => {
            const data = await getTechnologyListAsync();

            const mappedData = data.map((t) => t.name);

            setTechnologiesList(mappedData);
        })();
    }, []);

    // Update preselected filters
    useEffect(() => {
        if (preselectedFilters.technologies) {
            const mappedTechnologies = [];

            preselectedFilters.technologies.forEach((t) => {
                const tech = technologiesList.find((t2) => t2.toLowerCase() === t.toLowerCase());

                if (!tech) {
                    mappedTechnologies.push(t);
                } else {
                    mappedTechnologies.push(tech);
                }
            });

            tagRef.current.addTags(mappedTechnologies);
        }

        if (preselectedFilters.seniority) {
            const selectedSeniority = seniorityList.find((s) => s.label === preselectedFilters.seniority.name);
            setSeniority(selectedSeniority);
        }

        if (preselectedFilters.role) {
            const selectedRole = roleList.find((r) => r.label === preselectedFilters.role.name);
            setRole(selectedRole);
        }
    }, [preselectedFilters]);

    useEffect(() => {
        setShowCompare(!(resourcesSelectedId.length < MAX_RESOURCE_TO_COMPARE));
    }, [resourcesSelectedId.length]);

    const handleRoleChange = (roleSelected) => {
        setRole(roleSelected);
    };

    const handleSeniorityChange = (senioritySelected) => {
        setSeniority(senioritySelected);
    };

    const handleTechnologyChange = (e) => {
        let tagsList = e.detail.tagify.getCleanValue().map((tag) => {
            return tag.value;
        });

        setTechnology(tagsList);
    };

    const handleShowComparisonModal = () => {
        setShowComparisonModal(true);
    };

    const handleCloseComparisonModal = () => {
        setResourcesSelectedId([]);
        setShowComparisonModal(false);
    };

    const handleResourceCardClick = async (resourceId) => {
        if (positionActive) {
            setResourcesSelectedId([]);
        } else {
            if (resourcesSelectedId.includes(resourceId)) {
                setResourcesSelectedId((prev) => prev.filter((id) => id !== resourceId));
            } else {
                setResourcesSelectedId((prev) =>
                    resourcesSelectedId.length < MAX_RESOURCE_TO_COMPARE ? [...prev, resourceId] : [resourceId]
                );
            }
        }

        onResourceClick(resourceId);
    };

    return (
        <>
            <div className={`${styles.mainContainer} d-flex position-relative flex-column justify-content-between`}>
                {isLoading && (
                    <div
                        className={`${styles.loadingContainer} d-flex justify-content-center align-items-center position-absolute`}
                    >
                        <Loading allScreen={false} />
                    </div>
                )}
                <div className="d-flex flex-wrap justify-content-between">
                    <span className={styles.title}>{t("resources")}</span>
                    <div className={`${styles.filterItem} d-flex flex-column w-100 mt-2 mb-3`}>
                        {showCompare ? (
                            <Button className={styles.btnCompare} onClick={handleShowComparisonModal}>
                                {t("compare")}
                            </Button>
                        ) : (
                            <Tags
                                id="technology"
                                tagifyRef={tagRef}
                                value={technology}
                                onChange={handleTechnologyChange}
                                whitelist={technologiesList}
                                settings={tagifySettings}
                            />
                        )}
                    </div>
                    <div className={styles.filterItem}>
                        <Select
                            id="role"
                            name="role"
                            options={roleList}
                            styles={getSelectorStyle()}
                            value={role}
                            onChange={handleRoleChange}
                            placeholder={t("role")}
                            isClearable={true}
                            isDisabled={showCompare}
                        />
                    </div>
                    <div className={styles.filterItem}>
                        <Select
                            id="seniority"
                            name="seniority"
                            options={seniorityList}
                            styles={getSelectorStyle()}
                            value={seniority}
                            onChange={handleSeniorityChange}
                            placeholder={t("seniority")}
                            isClearable={true}
                            isDisabled={showCompare}
                        />
                    </div>
                </div>
                <div ref={drop} className="d-flex flex-wrap gap-4">
                    {resourceList.staffs?.map((resource) => {
                        return (
                            <ResourceCard
                                onCardClick={handleResourceCardClick}
                                key={uuidv4()}
                                resource={resource}
                                resourceActive={resourcesSelectedId.includes(resource.id)}
                            />
                        );
                    })}
                </div>
                <div className={`${styles.paginatorSection} w-100 position-relative`}>
                    <Paginator
                        activePage={page}
                        lastPage={resourceList.total ? Math.ceil(resourceList.total / PAGE_SIZE) : INITIAL_PAGE}
                        setPage={setPage}
                    />
                </div>
            </div>
            {resourcesSelectedId.length === 2 && (
                <ResourceComparisonModal
                    onClose={handleCloseComparisonModal}
                    show={showComparisonModal}
                    firstResourceId={resourcesSelectedId[0]}
                    secondResourceId={resourcesSelectedId[1]}
                />
            )}
        </>
    );
};

ResourceCardList.propTypes = {
    onResourceClick: PropTypes.func.isRequired,
    preselectedFilters: PropTypes.object,
};

export default ResourceCardList;
