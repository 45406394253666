import Joi from "joi";
import { createContext, useEffect, useMemo, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import toast from "react-hot-toast";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import Routes from "../../../base/js/routes";
import {
    createLinkDownloadPositionDescriptionFile,
    createLinkDownloadProjectDescriptionFile,
} from "../../../services/FileService";
import { getUsersByHiringManagerRole } from "../../../services/HiringManagerService";
import { addPositionStaffAsync } from "../../../services/PositionService";
import {
    getProjectAsync,
    setAllResourcesAllocated,
    setHiringManagerAsync,
    setTechnicalAccountManagerAsync,
} from "../../../services/ProjectService";
import { getUsersByTechnicalAccountManagerRole } from "../../../services/TechnicalAccountManagerService";
import Button, { variants } from "../../common/Button";
import Label from "../../common/forms/label/Label";
import useJoiValidation from "../../hooks/UseJoiValidation";
import Loading from "../../loading/Loading";
import { getSelectorStyle } from "../../shared-styles/StylesFunctions";
import styles from "./AllocationResource.module.scss";
import PositionCard from "./position-card/PositionCard";
import ResourceCardList from "./resource-card-list/ResourceCardList";

const SCHEMA_ERROR_MESSAGES = {
    "any.required": "requiredFieldIsEmpty",
    "string.empty": "requiredFieldIsEmpty",
    "object.missing": "requiredFieldIsEmpty",
};

export const PositionActiveContext = createContext(null);

const AllocationResource = () => {
    const [loading, setLoading] = useState(false);

    const { validateSchema, validateSubSchemaFromEvent, errors } = useJoiValidation();
    const params = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [refreshPositions, setRefreshPositions] = useState(false);
    const [project, setProject] = useState(null);
    const [positionActive, setPositionActive] = useState(null);
    const [hiringManagerList, setHiringManagerList] = useState([]);
    const [hiringManager, setHiringManager] = useState(null);
    const [technicalAccountManagerList, setTechnicalAccountManagerList] = useState([]);
    const [technicalAccountManager, setTechnicalAccountManager] = useState(null);
    const [positionActiveInfo, setPositionActiveInfo] = useState(null);

    // Fetch project information
    useEffect(() => {
        (async () => {
            try {
                const responseData = await getProjectAsync(params.id);

                if (responseData) {
                    // Check if there is a description file for the project. If it is, generate the URL of download
                    // for it.
                    if (responseData.projectBlobDoc) {
                        responseData.descriptionFile = createLinkDownloadProjectDescriptionFile(responseData.id);
                    }

                    // Check if there is a description file for each position. If there is one, generate the URL of
                    // download for it.
                    responseData.positions.forEach((p) => {
                        if (p.jobDescriptionBlob) {
                            p.descriptionFile = createLinkDownloadPositionDescriptionFile(p.id);
                        }
                    });

                    setProject(responseData);

                    if (responseData && responseData.hiringManager) {
                        setHiringManager({
                            label: responseData.hiringManager.name,
                            value: responseData.hiringManager.id,
                        });
                    }

                    if (responseData && responseData.tam) {
                        setTechnicalAccountManager({ label: responseData.tam.name, value: responseData.tam.id });
                    }
                }
            } catch (e) {
                console.error(e.message);
            }
        })();
    }, [refreshPositions]);

    // Fetch users with "Hiring Manager" role
    useEffect(() => {
        (async () => {
            try {
                const responseData = await getUsersByHiringManagerRole();

                if (responseData) {
                    setHiringManagerList(
                        responseData.map((hiringManager) => {
                            return {
                                label: hiringManager.name,
                                value: hiringManager.id,
                            };
                        })
                    );
                }
            } catch (e) {
                console.error(e.message);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const responseData = await getUsersByTechnicalAccountManagerRole();

                if (responseData) {
                    setTechnicalAccountManagerList(
                        responseData.map((technical) => {
                            return {
                                label: technical.name,
                                value: technical.id,
                            };
                        })
                    );
                }
            } catch (e) {
                console.error(e.message);
            }
        })();
    }, []);

    const BASE_SCHEMA = {
        hiringManager: Joi.object({
            label: Joi.string(),
            value: Joi.string(),
        })
            .empty(null)
            .required(),
        technicalAccountManager: Joi.object({
            label: Joi.string(),
            value: Joi.string(),
        })
            .empty(null)
            .required(),
        positionResource: true,
    };

    const handleGoBackClick = (e) => {
        e.preventDefault();
        navigate(`${Routes.ADMIN}/${Routes.ADMIN_PROJECTS}`);
    };

    const handleFinishAllocationClick = async (e) => {
        e.preventDefault();

        let isValid = true;
        project?.positions?.forEach((position) => {
            if (isValid) isValid = position?.positionStaffs?.length > 0;
        });

        const formData = {
            hiringManager: hiringManager,
            technicalAccountManager: technicalAccountManager,
            positionResource: isValid,
        };

        let cantErrors = validateSchema(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, formData);
        if (!!cantErrors) {
            return;
        }

        try {
            setLoading(true);

            await setAllResourcesAllocated(project.id);

            toast.success("All resources allocated");
            navigate(`${Routes.ADMIN}/${Routes.ADMIN_PROJECTS}`);
        } catch (e) {
            console.error("Unexpected error: " + e.message);
        } finally {
            setLoading(false);
        }
    };

    const handleHiringManagerChange = async (hiringManagerSelected) => {
        validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, {
            target: { id: "hiringManager", value: hiringManagerSelected, name: "hiringManager" },
        });

        try {
            setLoading(true);

            await setHiringManagerAsync(project.id, hiringManagerSelected.value);

            setHiringManager(hiringManagerSelected);

            toast.success(t("hiringManagerChangeSuccess"));
        } catch (e) {
            console.error(e.message);
        } finally {
            setLoading(false);
        }
    };

    const handleTechnicalAccountManagerChange = async (technicalAccountManagerSelected) => {
        validateSubSchemaFromEvent(BASE_SCHEMA, SCHEMA_ERROR_MESSAGES, {
            target: {
                id: "technicalAccountManager",
                value: technicalAccountManagerSelected,
                name: "technicalAccountManager",
            },
        });

        try {
            setLoading(true);

            await setTechnicalAccountManagerAsync(project.id, technicalAccountManagerSelected.value);

            setTechnicalAccountManager(technicalAccountManagerSelected);

            toast.success(t("technicalAccountManagerChangeSuccess"));
        } catch (e) {
            console.error(e.message);
        } finally {
            setLoading(false);
        }
    };

    const handleResourceClick = async (resourceId) => {
        if (positionActive) {
            try {
                setLoading(true);

                await addPositionStaffAsync(positionActive, resourceId);

                setRefreshPositions(!refreshPositions);
                setPositionActive(null);

                toast.success(t("resourceAddSuccess"));
            } catch (e) {
                console.error(e.message);
            } finally {
                setLoading(false);
            }
        }
    };

    const handlePositionActiveChange = async (position) => {
        setPositionActive(position);

        if (!position) {
            setPositionActiveInfo(null);
            return;
        }

        const positionId = position.split("_")[0];
        const selectedPosition = project.positions?.find((p) => p.id === positionId);

        setPositionActiveInfo(selectedPosition);
    };

    const preselectedFilters = useMemo(() => {
        return {
            role: positionActiveInfo?.developmentRol,
            seniority: positionActiveInfo?.seniority,
            technologies: positionActiveInfo?.positionCustomValues?.map((cv) => cv.customValue.value),
        };
    }, [positionActiveInfo]);

    const navigateToOpeningInfo = () => {
        navigate(Routes.buildProjectOpeningInfoPath(params.id));
    };

    return (
        <>
            <PositionActiveContext.Provider value={positionActive}>
                {loading && <Loading />}
                <DndProvider backend={HTML5Backend}>
                    <div className={`${styles.mainContainer} d-flex flex-column justify-content-center`}>
                        <h1>{t("resourceSetup")}</h1>
                        <div className="d-flex gap-4">
                            <div className={styles.section}>
                                <div>
                                    <div>
                                        <div className={`${styles.leftSectionHeader} d-flex justify-content-between`}>
                                            <div onClick={navigateToOpeningInfo}>
                                                <span>{t("owner")}</span>
                                                <span>
                                                    {
                                                        project?.userProjectCreators[
                                                            project.userProjectCreators?.length - 1
                                                        ]?.creator.name
                                                    }
                                                </span>
                                            </div>
                                            <div onClick={navigateToOpeningInfo}>
                                                <span>{t("company")}</span>
                                                <span>
                                                    {
                                                        project?.userProjectCreators[
                                                            project.userProjectCreators?.length - 1
                                                        ]?.creator.organization?.name
                                                    }
                                                </span>
                                            </div>
                                            <div onClick={navigateToOpeningInfo}>
                                                <span>{t("project")}</span>
                                                <span>{project?.name}</span>
                                            </div>
                                        </div>
                                        <div className={styles.sectionContent}>
                                            <h2 className={styles.subTitle}>{t("openPositions")}</h2>
                                            <div className={`${styles.openPositionsContainer} flex flex-column gap-3`}>
                                                {project?.positions?.map((position) => {
                                                    let positionList = [];

                                                    for (let i = 0; i < position.total; i++) {
                                                        positionList.push(
                                                            <div key={`${position.id}-${i}`}>
                                                                <PositionCard
                                                                    position={position}
                                                                    setActive={handlePositionActiveChange}
                                                                    active={positionActive === position.id}
                                                                    refreshPositions={refreshPositions}
                                                                    setRefreshPositions={setRefreshPositions}
                                                                    isValid={
                                                                        position.positionStaffs.length === 0 &&
                                                                        !!errors.positionResource
                                                                    }
                                                                />
                                                            </div>
                                                        );
                                                    }

                                                    return positionList;
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.section}>
                                <div
                                    className={`${styles.rightSectionHeader} d-flex flex-wrap justify-content-between`}
                                >
                                    <div>
                                        <Label htmlFor="hiringManager" requiredIndicator>
                                            {t("hiringManager")}
                                        </Label>
                                        <Select
                                            id="hiringManager"
                                            name="hiringManager"
                                            options={hiringManagerList}
                                            styles={getSelectorStyle(!!errors.hiringManager)}
                                            value={hiringManager}
                                            onChange={handleHiringManagerChange}
                                            placeholder={`${t("select")}...`}
                                        />
                                    </div>
                                    <div>
                                        <Label htmlFor="technicalAccountManager" requiredIndicator>
                                            {t("technicalAccountManager")}
                                        </Label>
                                        <Select
                                            id="technicalAccountManager"
                                            name="technicalAccountManager"
                                            options={technicalAccountManagerList}
                                            styles={getSelectorStyle(!!errors.technicalAccountManager)}
                                            value={technicalAccountManager}
                                            onChange={handleTechnicalAccountManagerChange}
                                            placeholder={`${t("select")}...`}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={`${styles.sectionContent} ${
                                        positionActive ? styles.positionActive : ""
                                    }`}
                                >
                                    <ResourceCardList
                                        onResourceClick={handleResourceClick}
                                        preselectedFilters={preselectedFilters}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={styles.buttonContainer}>
                            <Button
                                variant={variants.PRIMARY_INVERSE}
                                cssClasses={[styles.btn]}
                                onClick={handleGoBackClick}
                            >
                                {t("back")}
                            </Button>
                            <Button cssClasses={[styles.btn]} onClick={handleFinishAllocationClick}>
                                <Trans components={{ span: <span className="text-bold" /> }}>finishAllocation</Trans>
                            </Button>
                        </div>
                    </div>
                </DndProvider>
            </PositionActiveContext.Provider>
        </>
    );
};

export default AllocationResource;
