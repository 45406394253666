import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { ROLES } from "../../base/js/constants";
import Routes from "../../base/js/routes";
import useHasRole from "../hooks/UseHasRole";

const SurveyChecker = ({ targetRoles = [ROLES.USER, ROLES.HIRING_MANAGER, ROLES.ADMIN] }) => {
    const requiredRole = useHasRole(targetRoles);
    const user = useSelector((state) => state.user);
    const location = useLocation();

    const redirectToSurvey = useMemo(() => {
        const userLogged = !!user.username && !!user.email;
        const isSurveyPath = location.pathname === Routes.SURVEY;

        return userLogged ? requiredRole && user.pendingSurvey && !isSurveyPath : false;
    }, [user, requiredRole, location]);

    return redirectToSurvey ? <Navigate to={Routes.SURVEY} /> : <Outlet />;
};

export default SurveyChecker;
