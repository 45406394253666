import { generateErrorToast } from "../base/js/httpError";
import { API_BASE, request } from "../utils/httpRequest";

export const loginAsync = async (credentials) => {
    try {
        return await request(API_BASE + "/api/authentication/authenticate", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(credentials),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const signUpAsync = async (data) => {
    try {
        return await request(API_BASE + "/api/authentication/register", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const resetPasswordAsync = async (email) => {
    try {
        return await request(API_BASE + "/api/authentication/reset-password", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(email),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const changePasswordAsync = async (data) => {
    try {
        return await request(API_BASE + "/api/authentication/change-password", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const updatePasswordWhileLoggedAsync = async (data) => {
    try {
        return await request(API_BASE + "/api/authentication/update-password", {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const adminUserRegisterAsync = async (data) => {
    try {
        return await request(`${API_BASE}/api/authentication/admin-register`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const logoutAsync = async () => {
    try {
        return await request(API_BASE + "/api/authentication/logout", {
            method: "POST",
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const confirmEmailAsync = async (userId, code) => {
    try {
        return await request(`${API_BASE}/api/authentication/confirm-email?userId=${userId}&code=${code}`);
    } catch (error) {
        generateErrorToast(error);
    }
};
