import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import styles from "./AdminMenu.module.scss";
import CustomMenu from "./DropdownCustom/CustomMenu";
import CustomSubToggle from "./DropdownCustom/CustomSubToggle";
import CustomToggle from "./DropdownCustom/CustomToggle";
import useGetMenuItems from "./UseGetMenuItems";

const AdminMenu = () => {
    const { t } = useTranslation();
    const [activeLink, setActiveLink] = useState("");
    const { links, setLinks, dropdownSettings, setDropdownSettings } = useGetMenuItems();
    const [settingsOpen, setSettingOpen] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const findActiveLink = (links) => {
            for (const link of links) {
                if (link.dropdownLinks) {
                    const activeDropdownLink = link.dropdownLinks.find((item) => item.to === location.pathname);
                    if (activeDropdownLink) {
                        return activeDropdownLink;
                    }
                } else if (link.to === location.pathname) {
                    return link;
                }
            }
            return null;
        };

        const activeLink = findActiveLink(dropdownSettings.dropdownLinks);

        setActiveLink(activeLink ? activeLink.label : "");
    }, [location, dropdownSettings.dropdownLinks]);

    const handleLinkStatus = ({ isActive }) => {
        return `${styles.item} ${isActive ? styles.itemActive : undefined}`;
    };

    const handleDropdownItemStatus = ({ isActive }) => {
        return `${styles.dropdownItem} ${isActive ? styles.itemActive : ""} px-4`;
    };

    const handleDropdownToggle = (show) => {
        setSettingOpen(show);
    };

    const handleSubMenuToggle = (id) => () => {
        setDropdownSettings((prevSettings) => {
            const subMenuItemIndex = prevSettings.dropdownLinks.findIndex((item) => item.id === id);

            if (subMenuItemIndex === -1) {
                return prevSettings;
            }

            const updatedDropdownLinks = prevSettings.dropdownLinks.map((item, index) => {
                if (index === subMenuItemIndex) {
                    return { ...item, active: !item.active };
                }
                return item;
            });

            return { ...prevSettings, dropdownLinks: updatedDropdownLinks };
        });
    };

    return (
        <>
            <div className={styles.mainContainer}>
                {links.length &&
                    links.map((link) => {
                        return (
                            <div className={styles.container} key={uuidv4()}>
                                <NavLink className={handleLinkStatus} to={link.to}>
                                    {t(link.label)}
                                </NavLink>
                            </div>
                        );
                    })}
                <Dropdown className={styles.container} onToggle={handleDropdownToggle}>
                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                        {t(dropdownSettings.label)} {activeLink ? ` / ${t(activeLink)}` : ""}
                        {
                            <FontAwesomeIcon
                                icon={faChevronRight}
                                className={`${styles.icon} ${settingsOpen ? styles.iconOpen : ""}`}
                            />
                        }
                    </Dropdown.Toggle>
                    <Dropdown.Menu as={CustomMenu}>
                        {dropdownSettings.dropdownLinks.length &&
                            dropdownSettings.dropdownLinks.map((link, index) => {
                                if (!Object.keys(link).find((key) => key === "dropdownLinks")) {
                                    return (
                                        <Dropdown.Item key={uuidv4()} as="li">
                                            <NavLink className={handleDropdownItemStatus} to={link.to}>
                                                {t(link.label)}
                                            </NavLink>
                                        </Dropdown.Item>
                                    );
                                } else {
                                    return (
                                        <Dropdown
                                            className={`${styles.subMenuContainer} ${
                                                link.active ? styles.subMenuContainerActive : ""
                                            }`}
                                            key={index}
                                            onToggle={handleSubMenuToggle(link.id)}
                                            drop="end"
                                        >
                                            <Dropdown.Toggle as={CustomSubToggle} id="dropdown-custom-components">
                                                {t(link.label)}
                                                {<FontAwesomeIcon icon={faChevronRight} className={styles.icon} />}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu as={CustomMenu}>
                                                {link.dropdownLinks.length &&
                                                    link.dropdownLinks.map((subLink) => {
                                                        return (
                                                            <Dropdown.Item key={uuidv4()} as="li">
                                                                <NavLink
                                                                    className={handleDropdownItemStatus}
                                                                    to={subLink.to}
                                                                >
                                                                    {t(subLink.label)}
                                                                </NavLink>
                                                            </Dropdown.Item>
                                                        );
                                                    })}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    );
                                }
                            })}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    );
};

export default AdminMenu;
