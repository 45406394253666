import { generateErrorToast } from "../base/js/httpError";
import { API_BASE, request } from "../utils/httpRequest";

export const getUsersByUserRole = async () => {
    try {
        return await request(`${API_BASE}/api/user/by-user-role`);
    } catch (error) {
        generateErrorToast(error);
    }
};

export const getUserAsync = async (userId) => {
    try {
        return await request(`${API_BASE}/api/user/${userId}`);
    } catch (error) {
        generateErrorToast(error);
    }
};

export const getUserListAsync = async (
    page = 1,
    pageSize = 5,
    searchText = null,
    filterRole = null,
    filterOrganization = null,
    filterInactive = false
) => {
    try {
        return await request(
            `${API_BASE}/api/user?page=${page}&pageSize=${pageSize}${searchText ? "&searchText=" + searchText : ""}
        ${filterRole ? "&role=" + filterRole : ""}${filterOrganization ? "&organization=" + filterOrganization : ""}
        ${filterInactive ? "&inactive=" + filterInactive : ""}`,
            {
                method: "GET",
            }
        );
    } catch (error) {
        generateErrorToast(error);
    }
};

export const adminUpdateUserAsync = async (id, data) => {
    try {
        return await request(`${API_BASE}/api/user/${id}/admin-update`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const getRoleListAsync = async () => {
    try {
        return await request(`${API_BASE}/api/role`);
    } catch (error) {
        generateErrorToast(error);
    }
};

export const toggleUserStatus = async (userId) => {
    try {
        return await request(`${API_BASE}/api/user/${userId}/toggle-status`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const toggleResourceStatus = async (resourceId) => {
    try {
        return await request(`${API_BASE}/api/staff/${resourceId}/toggle-status`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const updateUserPassword = async (userId, payload) => {
    try {
        return await request(`${API_BASE}/api/user/${userId}/admin-update-password`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(payload),
        });
    } catch (error) {
        generateErrorToast(error);
    }
};
