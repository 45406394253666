import { generateErrorToast } from "../base/js/httpError";
import { API_BASE, request } from "../utils/httpRequest";

export const getTimezones = async () => {
    try {
        return await request(`${API_BASE}/api/timezone`);
    } catch (error) {
        generateErrorToast(error);
    }
};
