import { format, parseISO } from "date-fns";
import { lazy, Suspense, useEffect, useState } from "react";
import toast from "react-hot-toast";
import {Trans, useTranslation} from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DATE_FORMAT_ALTERNATIVE, FORMATTER, ProjectStatusIds } from "../../base/js/constants";
import Routes from "../../base/js/routes";
import { getPositionsForResumeListAsync } from "../../services/PositionService";
import { getProjectsByUserAsync, removeProject } from "../../services/ProjectService";
import { getInvoiceListAsync } from "../../services/StripeService";
import { getNotifications } from "../../services/UserService";
import { setNotifications } from "../../store/slices/userSlice";
import ConfirmProjectDeleteModal from "../common/confirm-project-delete-modal/ConfirmProjectDeleteModal";
import Loading from "../loading/Loading";
import style from "./HomeResume.module.scss";
import Button, {variants as btnVariants} from "../common/Button";

const TableResume = lazy(() => import("./table-resume/TableResume"));

const HomeResume = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [projectList, setProjectList] = useState([]);
    const [projectTotal, setProjectTotal] = useState(0);
    const [projectListIsLoading, setProjectListIsLoading] = useState(false);
    const [staffList, setStaffList] = useState([]);
    const [staffTotal, setStaffTotal] = useState(0);
    const [staffListIsLoading, setStaffListIsLoading] = useState(false);
    const [invoiceList, setInvoiceList] = useState([]);
    const [invoiceTotal, setInvoiceTotal] = useState(0);
    const [invoiceListIsLoading, setInvoiceListIsLoading] = useState(false);
    const [showDeleteProjectConfirmationModal, setShowDeleteProjectConfirmationModal] = useState(false);
    const [activeProjectId, setActiveProjectId] = useState(null);
    const [reloadData, setReloadData] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                setProjectListIsLoading(true);
                const responseData = await getProjectsByUserAsync();

                if (responseData) {
                    setProjectList(
                        responseData?.projects?.map((project) => {
                            const handleEditProjectAction = (e) => {
                                e.preventDefault();
                                navigate(Routes.BUILD_YOUR_TEAM + `?from=${project.id}`);
                            };

                            const handleManageProjectAction = (e) => {
                                e.preventDefault();

                                if (
                                    project.projectStatus[project.projectStatus.length - 1].status.id ===
                                        ProjectStatusIds.IN_DEVELOPMENT ||
                                    project.projectStatus[project.projectStatus.length - 1].status.id ===
                                        ProjectStatusIds.TEAM_CONFIRMED
                                ) {
                                    navigate(Routes.buildInDevelopmentPath(project.id));
                                    return;
                                }

                                navigate(Routes.buildTeamInfoResumePath(project.id));
                            };

                            const handleDeleteProjectAction = (e) => {
                                e.preventDefault();
                                setActiveProjectId(project.id);
                                setShowDeleteProjectConfirmationModal(true);
                            };

                            // const handleDetailsProjectAction = (e) => {
                            //     e.preventDefault();
                            // };

                            const handleValidateHoursAction = (e) => {
                                e.preventDefault();
                                navigate(Routes.buildValidateHoursPath(project.id));
                            };

                            const generateDefaultActions = () => {
                                let actions = [];

                                if (
                                    project.projectStatus[project.projectStatus.length - 1].status.id ===
                                        ProjectStatusIds.CREATING_NEW_PROJECT ||
                                    project.projectStatus[project.projectStatus.length - 1].status.id ===
                                        ProjectStatusIds.STARTING_SELECTION
                                ) {
                                    actions.push({
                                        label: "edit",
                                        action: handleEditProjectAction,
                                        color: "green",
                                    });
                                }

                                if (
                                    project.projectStatus[project.projectStatus.length - 1].status.id !==
                                        ProjectStatusIds.CREATING_NEW_PROJECT &&
                                    project.projectStatus[project.projectStatus.length - 1].status.id !==
                                        ProjectStatusIds.STARTING_SELECTION &&
                                    project.projectStatus[project.projectStatus.length - 1].status.id !==
                                        ProjectStatusIds.CANCELLED
                                ) {
                                    actions.push({
                                        label: "manage",
                                        action: handleManageProjectAction,
                                        color: "green",
                                    });
                                }

                                //Commented functionality until design delivered
                                // if (
                                //     project.projectStatus[project.projectStatus.length - 1].status.id ===
                                //     PROJECT_STATUS_IDS.CANCELLED
                                // ) {
                                //     actions.push({
                                //         label: "details",
                                //         action: handleDetailsProjectAction,
                                //         color: "green",
                                //     });
                                // }

                                if (
                                    project.projectStatus[project.projectStatus.length - 1].status.id ===
                                        ProjectStatusIds.FINISHED ||
                                    project.projectStatus[project.projectStatus.length - 1].status.id ===
                                        ProjectStatusIds.CANCELLED
                                ) {
                                    actions.push({
                                        label: "delete",
                                        action: handleDeleteProjectAction,
                                        color: "red",
                                    });
                                }

                                if (
                                    project.projectStatus[project.projectStatus.length - 1].status.id ===
                                        ProjectStatusIds.IN_DEVELOPMENT &&
                                    project.hasValidationHoursPending
                                ) {
                                    actions.push({
                                        label: "validateHours",
                                        action: handleValidateHoursAction,
                                        color: "green",
                                    });
                                }

                                return actions;
                            };
                            return {
                                pendingConfirmation: project.hasValidationHoursPending,
                                name: project.name,
                                status: project.projectStatus[project.projectStatus.length - 1].status.value,
                                actions: generateDefaultActions(project),
                            };
                        })
                    );
                    setProjectTotal(responseData.total);
                }
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            } finally {
                setProjectListIsLoading(false);
            }
        })();
    }, [reloadData]);

    useEffect(() => {
        (async () => {
            try {
                setStaffListIsLoading(true);
                const responseData = await getPositionsForResumeListAsync();

                if (responseData) {
                    setStaffList(
                        responseData?.positions?.map((position) => {
                            const handleAddReviewAction = (e) => {
                                e.preventDefault();
                                navigate(
                                    Routes.buildCreateReviewPathWithStaff(
                                        position.projectId,
                                        position.positionStaffs[position.positionStaffs.length - 1].staff.id
                                    )
                                );
                            };

                            return {
                                name: `${position.positionStaffs[position.positionStaffs.length - 1].staff.name} ${
                                    position.positionStaffs[position.positionStaffs.length - 1].staff.lastName
                                }`,
                                rate: `U$S ${position.rate} / ${t("hour")}`,
                                partTime: position.partTime ? "Part Time" : "Full Time",
                                actions: [
                                    {
                                        label: "addReview",
                                        action: handleAddReviewAction,
                                        color: "green",
                                    },
                                ],
                            };
                        })
                    );
                    setStaffTotal(responseData.total);
                }
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            } finally {
                setStaffListIsLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                setInvoiceListIsLoading(true);
                const responseData = await getInvoiceListAsync();

                if (responseData) {
                    setInvoiceList(
                        responseData?.invoices?.map((invoice) => {
                            const actions = [];
                            if (invoice.hostedInvoiceUrl) {
                                actions.push({
                                    label: "viewDetails",
                                    action: invoice.hostedInvoiceUrl,
                                    color: "green",
                                });
                            }

                            return {
                                projectName: invoice.projectName,
                                date: format(parseISO(invoice.date), DATE_FORMAT_ALTERNATIVE),
                                total: FORMATTER.format(invoice.amount),
                                actions: actions,
                            };
                        })
                    );
                    setInvoiceTotal(responseData.total);
                }
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            } finally {
                setInvoiceListIsLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const parsedBodyNotification = await getNotifications();

                if (parsedBodyNotification) {
                    dispatch(setNotifications({ lastUpdate: new Date(), items: parsedBodyNotification }));
                }
            } catch (e) {
                toast.error(t("unexpectedError") + ": " + e.message);
            }
        })();
    }, []);

    const handleAcceptDeleteProject = async () => {
        try {
            const response = await removeProject(activeProjectId);

            if (response) {
                toast.success(t("removeProjectSuccess"));
                setShowDeleteProjectConfirmationModal(false);
                setReloadData(!reloadData);
            }
        } catch (e) {
            toast.error(t("unexpectedError") + ": " + e.message);
        }
    };

    const handleCancelDeleteProject = () => {
        setShowDeleteProjectConfirmationModal(false);
    };
    
    const handleClickNewTeamHere = (event) => {
        event.preventDefault();
        navigate(Routes.BUILD_YOUR_TEAM);
    }

    return (
        <>
            <section className={style.mainContainer}>
                <div className={style.firstSection}>
                    <div>
                        <span className={style.tableTitle}>{t("staff")}</span>
                        <div className="position-relative">
                            {staffListIsLoading && (
                                <div className={style.loadingContainer}>
                                    <Loading allScreen={false}/>
                                </div>
                            )}

                            <Suspense fallback={<Loading allScreen={false} fullWidth={false} withText={false}/>}>
                                <TableResume
                                    header={["name", "rate", "time", "actions"]}
                                    data={staffList}
                                    total={staffTotal}
                                    viewMoreLink={Routes.STAFF_LIST}
                                />{" "}
                            </Suspense>
                        </div>
                    </div>
                    <div>
                        <span className={style.tableTitle}>{t("projects")}</span>
                        <div className="position-relative">
                            {projectListIsLoading && (
                                <div className={style.loadingContainer}>
                                    <Loading allScreen={false}/>
                                </div>
                            )}

                            <Suspense fallback={<Loading allScreen={false} fullWidth={false} withText={false}/>}>
                                <TableResume
                                    header={["", "name", "status", "actions"]}
                                    data={projectList}
                                    total={projectTotal}
                                    viewMoreLink={Routes.MANAGE_TEAMS}
                                    showHoursPendingHelp
                                />
                            </Suspense>
                        </div>
                    </div>
                </div>
                <div className={style.secondSection}>
                    <div>
                        <span className={style.tableTitle}>{t("invoices")}</span>
                        <div className="position-relative">
                            {invoiceListIsLoading && (
                                <div className={style.loadingContainer}>
                                    <Loading allScreen={false}/>
                                </div>
                            )}

                            <Suspense fallback={<Loading allScreen={false} fullWidth={false} withText={false}/>}>
                                <TableResume
                                    header={["project", "date", "finalPrice", "actions"]}
                                    data={invoiceList}
                                    total={invoiceTotal}
                                    viewMoreLink={Routes.INVOICE_LIST}
                                />
                            </Suspense>
                        </div>
                    </div>
                </div>
                <div className="flex justify-content-end">
                    <Button variant={btnVariants.PRIMARY} cssClasses={[style.newTeamBtn]} onClick={handleClickNewTeamHere}>
                        <Trans components={{ b: <b /> }}>getYourNewTeamHere</Trans>
                    </Button>
                </div>
            </section>

            <ConfirmProjectDeleteModal
                onCancel={handleCancelDeleteProject}
                show={showDeleteProjectConfirmationModal}
                onAccept={handleAcceptDeleteProject}
            />
        </>
    );
};

export default HomeResume;
