import { generateErrorToast } from "../base/js/httpError";
import { API_BASE, request } from "../utils/httpRequest";
import { onEditAction, onRemoveAction, onRestoreAction } from "../utils/tableActions";

const mapHiringCountryForTable = (hc, ...actions) => ({
    id: hc.id,
    name: hc.name,
    code: hc.code,
    description: hc.description,
    actions,
});

export const getHiringCountry = async (id, onEdit = () => {}, onRemove = () => {}) => {
    try {
        const body = await request(`${API_BASE}/api/hiringCountry/${id}`);

        if (body) {
            return mapHiringCountryForTable(body, onEditAction(onEdit, body.id), onRemoveAction(onRemove, body.id));
        }
    } catch (e) {
        generateErrorToast(e);
    }
};

export const getAllHiringCountries = async (pagingOpts, onEdit = () => {}, onRemove = () => {}) => {
    try {
        const body = await request(
            `${API_BASE}/api/hiringCountry?skip=${pagingOpts.skip}&take=${pagingOpts.take}&searchText=${
                pagingOpts.searchText || ""
            }`
        );

        if (body) {
            return {
                hiringCountries: body.hiringCountries.map((hc) =>
                    mapHiringCountryForTable(hc, onEditAction(onEdit, hc.id), onRemoveAction(onRemove, hc.id))
                ),
                total: body.total,
            };
        }
    } catch (e) {
        generateErrorToast(e);
    }
};

export const addHiringCountry = async (hiringCountry) => {
    try {
        const response = await request(`${API_BASE}/api/hiringCountry`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(hiringCountry),
        });

        if (response.ok) {
            // Return the ID of the entry recently added.
            return response;
        }
    } catch (e) {
        generateErrorToast(e);
    }
};

export const updateHiringCountryPATCH = async (id, data) => {
    try {
        await request(`${API_BASE}/api/hiringCountry/${id}`, {
            method: "PATCH",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        });
    } catch (e) {
        generateErrorToast(e);
    }
};

export const deleteHiringCountry = async (id) => {
    try {
        await request(`${API_BASE}/api/hiringCountry/${id}`, {
            method: "DELETE",
        });
    } catch (e) {
        generateErrorToast(e);
    }
};

export const updateHiringCountryPUT = async (id, data) => {
    try {
        await request(`${API_BASE}/api/hiringCountry/${id}`, {
            method: "DELETE",
        });
    } catch (e) {
        generateErrorToast(e);
    }
};

export const getAllDeletedHiringCountries = async (pagingOpts, onRestore = () => {}) => {
    try {
        const body = await request(
            `${API_BASE}/api/hiringCountry/deleted?skip=${pagingOpts.skip}&take=${pagingOpts.take}&searchText=${
                pagingOpts.searchText || ""
            }`
        );

        if (body) {
            return {
                hiringCountries: body.hiringCountries.map((hc) =>
                    mapHiringCountryForTable(hc, onRestoreAction(onRestore, hc.id))
                ),
                total: body.total,
            };
        }
    } catch (e) {
        generateErrorToast(e);
    }
};

export const restoreHiringCountry = async (id) => {
    try {
        await request(`${API_BASE}/api/hiringCountry/${id}/restore`, { method: "POST" });
    } catch (e) {
        generateErrorToast(e);
    }
};

export const getHiringCountryListAsync = async () => {
    try {
        return await request(API_BASE + `/api/hiringcountry`, {
            method: "GET",
        });
    } catch (e) {
        generateErrorToast(e);
    }
};
