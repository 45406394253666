import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ModalButtonVariant } from "../../../../base/js/constants";
import ModalButton from "../../../common/modal/buttons/ModalButton";
import Modal from "../../../common/modal/Modal";
import AdminProjectTracing from "../AdminProjectTracing";
import styles from "./HistoricalProjectLogModal.module.scss";

const HistoricalProjectLogModal = ({ show, onClose }) => {
    const { t } = useTranslation();

    return (
        <Modal show={show} className={styles.modalWidth}>
            <span className={`${styles.modalTitle} text-bold d-block text-center`}>{t("historicalProjectLog")}</span>
            <div className={styles.container}>
                <AdminProjectTracing asModal />
            </div>
            <div className="d-flex justify-content-center gap-4 mt-4">
                <ModalButton variant={ModalButtonVariant.WHITE} onClick={onClose}>
                    {t("cancel")}
                </ModalButton>
            </div>
        </Modal>
    );
};

HistoricalProjectLogModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default HistoricalProjectLogModal;
