import { generateErrorToast } from "../base/js/httpError";
import { API_BASE, request } from "../utils/httpRequest";

export const uploadFileAsync = async (data) => {
    try {
        return await request(API_BASE + "/api/file", {
            method: "POST",
            body: data,
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const uploadRangeFileAsync = async (data) => {
    try {
        return await request(API_BASE + "/api/file/add-range", {
            method: "POST",
            body: data,
        });
    } catch (error) {
        generateErrorToast(error);
    }
};

export const createLinkDownloadStaffCvFile = (staffId) => {
    return API_BASE + `/api/file/download/docs/cv/staff?staffId=${staffId}`;
};

export const createLinkDownloadStaffInformationFile = (staffId) => {
    return API_BASE + `/api/file/download/docs/staff/information?staffId=${staffId}`;
};

export const createLinkDownloadStaffShortSheetFile = (staffId) => {
    return API_BASE + `/api/file/download/docs/staff/short-sheet?staffId=${staffId}`;
};

export const createLinkDownloadProofOfPaymentFile = (paymentId) => {
    return API_BASE + `/api/file/download/docs/payment?paymentId=${paymentId}`;
};

export const createLinkDownloadStatementFile = (statementId) => {
    return API_BASE + `/api/file/download/docs/statement?statementId=${statementId}`;
};

export const generateLinkForVisualizePhoto = (img) => {
    return `https://tdbtestblogstorage.blob.core.windows.net/project-doc/${img}`;
};

export const createLinkDownloadProjectDescriptionFile = (projectId) => {
    return `${API_BASE}/api/file/download/docs/project?projectId=${projectId}`;
};

export const createLinkDownloadPositionDescriptionFile = (positionId) => {
    return `${API_BASE}/api/file/download/docs/position?positionId=${positionId}`;
};
