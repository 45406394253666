import { useContext } from "react";
import { AccordionContext, useAccordionButton } from "react-bootstrap";

import styles from "./ResourceMobile.module.scss";

const CustomResource = ({ children, eventKey }) => {
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(eventKey);

    const headerClasses = `${activeEventKey === eventKey ? styles.active : ""} position-relative`;

    return (
        <div
            className={`${headerClasses} px-3 d-flex justify-content-between align-items-center`}
            onClick={decoratedOnClick}
        >
            <div className={`${styles.headerResource}`}>
                Role: <span className={`ps-3 ${styles.rolName}`}>{children}</span>
            </div>
            <span className={`${styles.chevronIcon}`}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                    <path d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z" />
                </svg>
            </span>
        </div>
    );
};

export default CustomResource;
